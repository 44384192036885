import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Img from 'react-image';
import _ from 'lodash';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { withTableRow } from "./withTableRow";
import { toTitleCase } from "../../../../utils/stringUtils";
import TableCellBadge from './TableCellBadge';
import { getSmallImageFilename, getImage } from "../../../../utils/imageUtils";

const fetchSmallerImage = async(imageFileName) => {
  let _smallerImage;
  if (!_.isEmpty(imageFileName)) {
    const imageFileNameSmall = getSmallImageFilename(imageFileName);
    _smallerImage = await getImage(`salesReceipts/${imageFileNameSmall}`);
  }

  return _smallerImage;
};

class TableRowReceipt extends Component {
  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
  };

  constructor(props) {
    super(props);

    this.state = {
      imageFilePath: undefined,
    }
  }

  async componentDidMount() {
    const { el } = this.props;
    const { id, imageFileName = {} } = el;
    if (id) {
      const smallerImage = await fetchSmallerImage(imageFileName, id);

      this.setState(prevState => ({
        ...prevState,
        smallerImage,
      }));
    }
  }

  render() {
    const { el, pathname, onClick, onDelete } = this.props;
    const {
      receipt_id,
      // smallerImage,
      uploaded_on,
      status = '',
      captured_by,
    } = el;
    const { smallerImage } = this.state;

    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '86px'}}
        onClick={onClick}
      >
        <TableCell>{receipt_id}</TableCell>
        <TableCell>{captured_by}</TableCell>
        <TableCell>{el['deal_/_voucher']}</TableCell>
        <TableCell style={{padding: '7px 30px 0 24px'}}>
          <Img src={smallerImage} style={{
            width: 'auto',
            height: '120px',
            border: '#EDEDED 1px solid',
            borderLeft: '#EDEDED 2px solid',
          }}
          />
        </TableCell>
        <TableCell>{uploaded_on}</TableCell>
        <TableCell>
          <TableCellBadge label={toTitleCase(status)} />
        </TableCell>
      </TableRow>
    );
  }
}

const _TableRowReceipt = withTableRow(TableRowReceipt);
export { _TableRowReceipt as TableRowReceipt };