import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';
import { config } from '../../../config';
import style from '../../../assets/jss/material-dashboard-react/views/deals.jsx';
import CampaignTotals from '../../../components/Campaign/Totals/CampaignTotals';

class Header extends React.Component {
  render() {
    const {
      classes,
      totals = {},
    } = this.props;
    const styleHeader = {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 70,
    };
    return (
      <GridContainer>
        <GridItem xs={10} sm={8} md={8} lg={6}>
          {/*<h3 className={classes.upperText}>Filters</h3>*/}
          {/*<MultiSelect*/}
            {/*{...this.props}*/}
            {/*isMainPage={true}*/}
          {/*/>*/}
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <CampaignTotals totals={totals} />
          </GridItem>
        </GridItem>
        <GridItem xs={2} sm={4} md={4} lg={6} style={styleHeader}>
          <NavLink
            to={`campaigns/add`}
            className={classes.item}
            activeClassName="active"
          >
            <Button className={ classes.createButton }>
              Create campaign
            </Button>
          </NavLink>
        </GridItem>
      </GridContainer>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
  targetName: PropTypes.string,
  targetStorage: PropTypes.string,
};

Header.defaultProps = {
  value: [],
  options: [],
  onChange: () => null,
  targetName: 'category',
  targetStorage: 'categories',
};

export default withStyles(style)(Header);
