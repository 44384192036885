import * as uniqid from 'uniqid';
import * as moment from 'moment';
import _ from 'lodash';
import {
  getValueByField,
  getValueByFieldInString,
  isEmptyObject,
  removeEmpty,
} from '../index';
import {
  formatDateFromFirebase,
  getMallOpeningTime,
  getMallClosingTime,
} from '../date';
import { getDocument, getPrettiedFields } from '../layoutEngine/parser';

const getExistSection = (sectionName, data) => {
  if (!isEmptyObject(removeEmpty(data))) {
    return { [sectionName]: data };
  }
  return undefined;
};

const cleanImage = (image) => {
  if (typeof image === 'string') {
    return {
      fileName: image,
    }
  }
  const result = {
    fileName: image.fileName || image.name,
    ...(Number.isInteger(image.width) && {
      width: image.width,
    }),
    ...(Number.isInteger(image.height) && {
      height: image.height,
    }),
  };

  return result;
};

const cleanImages = (images) => {
  return images.map(cleanImage);
};

const getIncludingData = (data) => {
  const {
    siteId,
    // Percentage Discount
    conditionBrand,
    conditionCategory,
    condition: minSpend,
    // Price reduction
    productBrand,
    productName,
    productDescription: description,
    retailPrice: original,
    offerPrice: reduced,
    // Discounted Offer With Purchase
    conditionProduct,
    // Free Gift
    conditionProductPrice: productPrice,
    conditionProductDescription: productDescription,
    // Discount voucher
    expDate: expirationDate,
    voucherAmount: amount,
    voucherAppliesToBrand,
    voucherAppliesToCategory,
    productCategory,

    displayPriceReductionAs,
  } = data;

  const voucher = getExistSection('voucher', {
    discount: {
      amount,
    },
    appliesTo: {
      category: voucherAppliesToCategory,
      brand: voucherAppliesToBrand,
    },
    expirationDate: getMallClosingTime(expirationDate),
  });

  const product = getExistSection('product', {
    brand: productBrand,
    title: productName,
    category: productCategory,
    price: {
      original,
      reduced,
    },
    description,
  });

  const display = getExistSection('display', {
    priceReduction: displayPriceReductionAs,
  });

  const condition = getExistSection('condition', {
    brand: conditionBrand,
    category: conditionCategory,
    minSpend,
    productPrice,
    productDescription,
    product: conditionProduct,
  });

  return { siteId, ...voucher, ...product, ...condition, ...display };
};


export const getPrettiedSelected = (selectedDealTypeKey) => {
  const documentName = _.camelCase(selectedDealTypeKey);
  const document = getDocument(documentName);
  return { selectedDealTypeKey, ...document };
};

// Looks like onload
export const getPrettiedDealInfo = async (data) => {
  // Percentage Discount
  const { id, siteId } = data;
  const discount = _.get(data, 'discount');
  // const amount = _.get(data, 'discount.amount');
  const conditionBrand = _.get(data, 'condition.brand');
  const conditionCategory = _.get(data, 'condition.category');
  // Min Spend
  const condition = _.get(data, 'condition.minSpend');
  // Price reduction
  const productBrand = _.get(data, 'product.brand');
  const productName = _.get(data, 'product.title');
  // TODO Check (data from category??) mb I need get data from product.description
  const productDescription = getValueByFieldInString(data, 'product.description');
  const retailPrice = _.get(data, 'product.price.original');
  const offerPrice = _.get(data, 'product.price.reduced');
  // Product introduction === Price reduction
  // Discounted Offer With Purchase == Price reduction fields && Percentage Discount
  const conditionProduct = _.get(data, 'condition.product');
  // Free Gift
  const conditionProductPrice = _.get(data, 'condition.productPrice');
  const conditionProductDescription = _.get(data, 'condition.productDescription');
  const displayPriceReductionAs = _.get(data, 'display.priceReduction');
  // Discount voucher
  // TODO : Eddy changes go here ...
  const voucherAmount = _.get(data, 'voucher.discount.amount');
  const voucherAppliesToBrand = _.get(data, 'voucher.appliesTo.brand');
  const voucherAppliesToCategory = _.get(data, 'voucher.appliesTo.category');
  const exp = _.get(data, 'voucher.expirationDate');
  const expDate = (exp) ? formatDateFromFirebase(exp, { prettied: false }) : undefined;
  const productCategory = _.get(data, 'product.category');
  // Details Block
  const dealCategory = _.get(data, 'category');
  const merchant = _.get(data, 'merchant.id');
  // Images Block
  let images = Array.isArray(data.images) ? [...data.images] : []; // getValueByField(data, 'images', []);
  if (images.length && typeof images[0] === 'string') {
    images = images.map(x => ({
      fileName: x,
      ref: undefined,
    }));
  }

  const image = {
    ...data.image,
  };

  const active = Boolean(getValueByField(data, 'active', false));
  const termsAndConditions = _.get(data, 'termsAndConditions');
  const limits = _.get(data, 'limits');
  // const limit = _.get(data, 'limits.unitCount');
  // const limit = data.limit;
  // TODO : limit replaced by limits.unitCountLimit & limits.unitCountRemaining

  const start = getValueByField(data, 'startDate', moment());
  const end = getValueByField(data, 'endDate', moment().add(7, 'days'));
  const endDate = formatDateFromFirebase(end, { prettied: false });
  const startDate = formatDateFromFirebase(start, { prettied: false });
  const type = getValueByFieldInString(data, 'type');
  const props = getPrettiedSelected(type);

  const offeredProductBrand = _.get(data, 'gift.brand');
  const offeredProductName = _.get(data, 'gift.name');
  const offeredProductDescription = _.get(data, 'gift.description');
  const offeredProductValue = _.get(data, 'gift.value');

  return {
    ...props,
    siteId,
    // Percentage Discount
    conditionBrand,
    conditionCategory,
    condition,
    discount,
    // discountPercentage,
    // discountAmount,
  // Price reduction
    productBrand,
    productName,
    productDescription,
    retailPrice,
    offerPrice,
    // Discounted Offer With Purchase
    conditionProduct,
    conditionProductDescription,
    // Free Gift
    conditionProductPrice,
    // Discount voucher
    expDate,
    voucherAmount,
    voucherAppliesToBrand,
    voucherAppliesToCategory,
    productCategory,
    // Images Block
    image,
    images,
    // Details Block
    dealCategory,
    merchant,
    // General
    limits,
    active,
    termsAndConditions,
    startDate,
    endDate,

    offeredProductBrand,
    offeredProductName,
    offeredProductDescription,
    offeredProductValue,

    displayPriceReductionAs,

    // For functional with cancel
    supposeId: id || uniqid(),
  };
};

export const getPrettiedDealForFirebase = async (data) => {
  const {
    siteId,
    // Percentage Discount
    supposeId,
    type,
    discount,
    amount,
    // Details Block
    dealCategory: category,
    displayPriceReductionAs,
    // General
    merchant: merchantId,
    limits,
    active,
    termsAndConditions,

    offeredProductBrand,
    offeredProductName,
    offeredProductDescription,
    offeredProductValue,

    startDate,
    endDate,

    retailPrice,
    offerPrice,
    condition,
    conditionProductPrice,
    voucherAmount,
    image: _image,
    images: _images,
  } = data;

  // const { image, images } = getPrettiedDealImages(imagesArr, currentImage);
  const sectionData = getIncludingData(data);
// One all mighty hack to work around this appalling architecture

  const document = {
    ...sectionData,
    siteId,
    id: supposeId,
    ...(!_.isEmpty(discount) && {
      discount,
    }),
    // TODO : Need to refactor to use 'merchant.id' on update
    merchant: {
      id: merchantId,
    },
    gift: {
      brand: offeredProductBrand,
      name: offeredProductName,
      description: offeredProductDescription,
      ...(offeredProductValue && {
        value: parseFloat(offeredProductValue),
      }),
    },
    category,
    limits,
    // TODO
    // 'limits.unitCount': limit,
    // limits: {
    //   unitCount: limit,
    // },
    ...(displayPriceReductionAs && {
      display: {
        priceReduction: displayPriceReductionAs,
      },
    }),
    product: {
      ...sectionData.product,
      price: {
        ...sectionData.price,
        ...(retailPrice && {
          original: parseFloat(retailPrice),
        }),
        ...(offerPrice && {
          reduced: parseFloat(offerPrice),
        }),
      }
    },
    condition: {
      ...sectionData.condition,
      ...(condition && {
        minSpend: parseFloat(condition),
      }),
      ...(conditionProductPrice && {
        productPrice: parseFloat(conditionProductPrice),
      }),
    },
    // voucher: {
    //   ...sectionData.voucher,
    //   discount: {
    //     ...sectionData.discount,
    //     ...(voucherAmount && {
    //       amount: parseFloat(voucherAmount),
    //     }),
    //   },
    // },
    active,
    termsAndConditions,
    startDate, // : getMallOpeningTime(startDate),
    endDate, // : getMallClosingTime(endDate),
    image: cleanImage(_image),
    images: cleanImages(_images),
    type,
  };

  // This is completely broken. No chance to fix before a rewrite. Kill forcefully
  delete document.voucher;

  const doc = removeEmpty(document, ['voucher.appliesTo', 'voucher.discount', 'voucher', 'condition', 'product.price', 'product', 'gift']);

  // BRUTE FORCE any last minute corrections here
  if (typeof _.get(doc, 'discount.amount') === 'string') {
    doc.discount.amount = parseFloat(doc.discount.amount);
  }

  return doc;
};


export const getFinalData = (data, props = { id: '', type: '' }) => ({ ...data, ...props });

export const prepareData = async (data) => {
  const { supposeId, type, selectedDealTypeKey } = data;

  // const prettiedData = getPrettiedFields(data, _.camelCase(selectedDealTypeKey));
  const deal = await getPrettiedDealForFirebase(data);
  return getFinalData(deal, { id: supposeId, type });
};

export default () => null;
