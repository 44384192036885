import React, { Component } from 'react';
import { config } from '../../config';
import Grid from '../../components/Grid/Grid';
import Header from '../../components/Promotion/Header/Header';
import { withPromotions } from '../../contextApi';
import { formatDateFromFirebase } from "../../utils/date";
import { TableRowPromotion } from "../../components/Common/Table/rows";
import MultiSelect from "../../components/Engine/Common/MultiSelect/MultiSelect";
import GridPage from "../GridPage";

const tableHead = ['Promotion Id', 'Banner', 'Title', 'Start Date', 'End Date', 'Actions'];
const timePeriodsRaw = ['Expired', 'Current', 'Upcoming'];
const timePeriods = timePeriodsRaw.map(x => ({ value: x, label: x }));

class MallEvents extends Component {
  state = {
    type: config.statuses.any,
    types: [config.statuses.any],
    merchantId: config.statuses.any,
    tableData: undefined,
    active: true,
  };

  static getTableRowData(promotion) {
    const {
      id,
      title,
      creationDate: creation,
      startDate: start,
      endDate: end,
      imageFileName,
      image,
      hasBeenActive,
    } = promotion;
    const startDate = formatDateFromFirebase(start);
    const endDate = formatDateFromFirebase(end);
    const creationDate = formatDateFromFirebase(creation);
    const row = {
      id,
      title,
      creationDate,
      startDate,
      endDate,
      imageFileName,
      image,
      hasBeenActive,
    };

    return row;
  }

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.getFilterControls = this.getFilterControls.bind(this);

    this.defaultFilters = {
      timePeriod: ['Current', 'Upcoming'],
      isMallEvent: true,
    };
  }

  componentDidMount() {
    const { setPromotionsProviderActive } = this.props;
    setPromotionsProviderActive();

    this.setState(prevState => ({
      ...prevState,
      timePeriods,
    }));
  }

  getFilterControls(filterValues) {
    // const { merchants } = this.props;
    const { timePeriods } = this.state;

    console.log(`MallEvents.getFilterControls(${JSON.stringify(filterValues)})`);
    const { timePeriod = [] } = filterValues;

    return [
      <MultiSelect
        id="timePeriod"
        targetName="timePeriod"
        isMainPage={true}
        name="Time period"
        options={timePeriods}
        value={timePeriod.map(x => ({label: x, value: x}))}
        key="timePeriod"
      />
    ]
  }

  async onDelete(id) {
    const { deletePromotionById } = this.props;

    await deletePromotionById(id);
  }

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowPromotion
        el={item}
        pathname={pathname}
        key={id}
        onDelete={this.onDelete}
        itemName="promotion"
        imageNamePrefix={`${id}_`}
      />
    );
  }

  render() {
    const { mallEvents, getFilteredPromotions } = this.props;

    return (
      <GridPage
        viewName="Events"
        itemName="Event"
        pathname="mallEvents"
        items={mallEvents}
        getFilterControls={this.getFilterControls}
        getFilteredItems={getFilteredPromotions}
        getTableRowData={MallEvents.getTableRowData}
        renderRow={this.renderRow}
        tableHead={tableHead}
        filters={this.defaultFilters}
      />
    )
  }
}

export default withPromotions(MallEvents);
