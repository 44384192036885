import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Redirect } from 'react-router-dom';
import Logo from '../../assets/img/ply-icon.png';
import style
  from '../../assets/jss/material-dashboard-react/views/loginStyle';
import { AuthConsumer, AuthContext } from '../../providers/AuthProvider';


function Login({ ...props }) {
  const {
    classes,
  } = props;

  const context = useContext(AuthContext);
  useEffect(() => {
    const { logout } = context;
    setTimeout(logout, 1000);
  });

  return (

    <AuthConsumer>
      {({ isAuth }) => (
        <div>
          <div className={classes.mainRoot}>
            <div className={classes.root}>
              <img className={classes.image} src={Logo} alt="logo"/>
            </div>
          </div>
          {!isAuth && <Redirect to={'/'}/>}
        </div>
      )}
    </AuthConsumer>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Login);
