/* CacheBuster component */
import React, { Component } from 'react';
import semver from 'semver';

export default class CacheBuster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        const { caches, location } = window;
        // FAils with "caches is not defined"
        // console.log('Clearing cache and hard reloading...')
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(names => {
            for (let name of names) {
              caches.delete(name);
            }
          });
        }

        // delete browser cache and hard reload
        location.reload(true);
      }
    };
  }

  componentDidMount() {
    const options = {
      headers : {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    };
    fetch('/meta.json', options)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = process.env.REACT_APP_VERSION;
        console.log(`latestVersion=${latestVersion} currentVersion=${currentVersion}`);
        const shouldForceRefresh = semver.gt(latestVersion, currentVersion);
        if (shouldForceRefresh) {
          console.log(`We have a new version - ${latestVersion}. Should force refresh`);
          this.setState({ loading: false, isLatestVersion: false });
        } else {
          console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
          this.setState({ loading: false, isLatestVersion: true });
        }
      })
      .catch(e => {
        console.log(e);
        this.setState({ loading: false, isLatestVersion: true });
      });
  }

  render() {
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
    const { children } = this.props;

    return children({ loading, isLatestVersion, refreshCacheAndReload });
  }
}