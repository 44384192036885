import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Dropzone from 'react-dropzone';

import style from '../../../assets/jss/material-dashboard-react/components/images';

class FileDropzone extends React.Component {
  render() {
    const { onDrop, classes, children, zone, disabled } = this.props;

    return (
      <Dropzone onDrop={onDrop} disabled={disabled}>
        {({ getRootProps, getInputProps }) => (
          <div
            {...getRootProps()}
            className={zone ? classes.dropzoneWithBorder : classes.dropzone}
          >
            <input {...getInputProps()} />
            { children }
          </div>
        )}
      </Dropzone>
    );
  }
}

FileDropzone.propTypes = {
  onDrop: PropTypes.func,
  zone: PropTypes.bool,
  disabled: PropTypes.bool,
};

FileDropzone.defaultProps = {
  zone: true,
  onDrop: () => null,
  disabled: false,
};

export default withStyles(style)(FileDropzone);
