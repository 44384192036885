import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Select from 'react-select';
import _ from 'lodash';
// import makeAnimated from 'react-select/lib/animated';
import style from '../../../../assets/jss/material-dashboard-react/components/details';


const stylesForMainPage = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 'inherit',
  }),
};

const stylesForElement = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 'inherit',
    maxWidth: 400,
    minWidth: 200,
  }),
};

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);

    this.ref = React.createRef();

    this.onChange = this.onChange.bind(this);
  }

  onRef = ref => this.ref = ref;

  static getValueSingleSelect(newValue) {
    if (newValue && newValue.value) return newValue.value;
    return undefined;
  }

  onChange(newValue) {
    newValue = newValue || [];
    const { isMulti } = this.props;
    const {
      onChange,
      targetName,
      targetStorage,
      id: key,
    } = this.props;
    const { options } = this.props;
    const value = isMulti ? newValue.map(x => x.value) : MultiSelect.getValueSingleSelect(newValue); // newValue[0].value;

    onChange({
      [targetName]: newValue,
      [targetStorage]: options,
    }, { key, value });

    if (!isMulti && this.ref) {
      const { onMenuClose } = this.ref;
      onMenuClose && onMenuClose();
    }
  };

  render() {
    const {
      value,
      options,
      name,
      description,
      classes,
      flex,
      creatable,
      isMainPage,
      isMulti,
    } = this.props;
    const params = {
      styles: isMainPage ? stylesForMainPage : stylesForElement,
      maxMenuHeight: 140,
      closeMenuOnSelect: false,
      // components: makeAnimated(),
      value,
      options,
      onChange: this.onChange,
      isMulti,
      // clearable: true,
      isClearable: true,
    };

    return (
      <div className={flex ? classes.headerForRowSelect : ''}>
        <div className={classes.textContainer}>
          <label className={classes.labelInput} style={{ paddingBottom: 7, minWidth: 160, width: 200}}> {name}</label>
          {description && <label className={classes.labelDesc}> {description}</label>}
        </div>
        <div className={classes.select}>
          {/*TODO : WHAT WAS THIS FOR AND HOW TO SOLVE IT?*/}
        {/*{creatable ? <Creatable {...params} isClearable /> : <Select ref={this.onRef} {...params} />}*/}
          <Select ref={this.onRef} {...params} />
        </div>
      </div>
    );
  }
}

MultiSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
  targetName: PropTypes.string,
  targetStorage: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  flex: PropTypes.bool,
  creatable: PropTypes.bool,
  isMainPage: PropTypes.bool,
  isMulti: PropTypes.bool,
};

MultiSelect.defaultProps = {
  value: [],
  options: [],
  onChange: () => null,
  targetName: 'category',
  targetStorage: 'categories',
  name: 'Category',
  description: '',
  flex: true,
  creatable: false,
  isMainPage: false,
  isMulti: true,
};


export default withStyles(style)(MultiSelect);
