const style = {
  successButton: {
    backgroundColor: 'green',
    color: '#fff',
    opacity: 0.4,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'green',
      color: '#fff',
      opacity: 0.9,
    },
  },
  upperText: {
    textTransform: 'uppercase',
  },
  imageRow: {
    width: '90%',
    borderRadius: '15px 15px 15px 15px',
  },
  uploadBtn: {
    border: '1px solid #999',
    textTransform: 'none',
  },
  deleteButton: {
    backgroundColor: 'red',
    color: '#fff',
    opacity: 0.4,
    '&:hover': {
      backgroundColor: 'red',
      color: '#fff',
      opacity: 0.9,
    },
    marginRight: 20
  },
  cancelBtn: {
    marginRight: 20,
  },
  uploadSection: {
    display: 'flex',
    width: '60%',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  image: {
    width: '70%',
  },
  dropzoneWithBorder: {
    border: '2px dashed black',
    textAlign: 'center',
    opacity: 0.5,
    width: '60%',
    // height: 100,
    marginBottom: 15,
    borderRadius: 15,
  },
  dropzone: {
    textAlign: 'right',
    width: '53%',
    height: 100,
    marginBottom: 15,
    borderRadius: 15,
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 40,
  },
};

export default style;
