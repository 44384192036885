import React, { Component } from 'react';
import _ from 'lodash';
import { config } from '../../../config';
import { withDeals, withMerchants } from '../../../contextApi';
import { formatDateFromFirebase } from "../../../utils/date";
import { TableRowDeal } from "../../../components/Common/Table/rows";
import GridPage from '../../GridPage';
import MultiSelect from '../../../components/Engine/Common/MultiSelect/MultiSelect';
import { arrayToString } from '../../../utils';

const { dealTypes } = config;

const mapMerchantItem = item => {
  const { id: value, name, lots } = item;
  const label = `${name} [${arrayToString(lots)}]`;
  return { label, value };
};

const mapDealTypeItem = dealType => {
  const { key: value, label } = dealType;
  return { label, value };
};

const tableHead = [
  'Deal id',
  'Merchant',
  'Deal Type',
  'Limit',
  'Remaining',
  'Redeemed',
  'Creation Date',
  'Start Date',
  'End Date',
  'Image',
  'Promoted',
  'Status',
  'Actions',
];

class Deals extends Component {
  state = {
    type: config.statuses.any,
    types: [config.statuses.any],
    merchantId: config.statuses.any,
    tableData: [],
    merchants: [],
    timePeriod: ['Current', 'Upcoming'],
    deals: null,
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.onTogglePromoted = this.onTogglePromoted.bind(this);
    this.getFilterControls = this.getFilterControls.bind(this);
  }

  componentDidMount() {
    const { setDealsProviderActive, setMerchantsProviderActive } = this.props;

    setDealsProviderActive();
    setMerchantsProviderActive();

    const timePeriods = ['Expired', 'Current', 'Upcoming'].map(x => ({
      value: x,
      label: x,
    }));
    this.setState(prevState => ({
      ...prevState,
      timePeriods,
    }));
  }

  static getUnitsRemaining(unitCount, unitsRemaining) {
    if (Number.isInteger(unitsRemaining)) {
      return unitsRemaining;
    }
    if (Number.isInteger(unitCount)) {
      return unitCount;
    }
    return '∞';
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { merchants = [], deals = [] } = nextProps;
    if (merchants.length) {
      return {
        ...prevState,
        merchants,
        deals,
      };
    }
    return {
      ...prevState,
    };
  }

  static getTableRowData(deal) {
    const {
      id,
      merchant,
      creationDate: creation,
      startDate: start,
      endDate: end,
      summary,
      // primaryImage,
      active,
      type,
      limits = {},
      redemptions = {},
      promoted = false,
      image,
    } = deal;
    const startDate = formatDateFromFirebase(start);
    const endDate = formatDateFromFirebase(end);
    const creationDate = formatDateFromFirebase(creation);
    const { name, lots } = merchant;
    const merchantName = `${name} [${arrayToString(lots)}]`;
    const { unitCount, unitsRemaining } = limits;
    const numRedemptions = Object.keys(redemptions).length;
    const numRemaining = Deals.getUnitsRemaining(unitCount, unitsRemaining);
    const row = {
      id,
      deal_id: id,
      merchant: merchantName,
      deal_type: type,
      limit: unitCount ? unitCount : '∞',
      remaining: numRemaining,
      redeemed: numRedemptions,
      creation_date: creationDate,
      start_date: startDate,
      end_date: endDate,
      // Super hack to allow us to sort by dates and not their string values
      start_date_sort: (start) ? start.seconds : undefined,
      end_date_sort: (end) ? end.seconds : undefined,
      creation_date_sort: (creation) ? creation.seconds : undefined,
      status: (active) ? 'Active' : 'Inactive',
      // primaryImage,
      summary,
      promoted,
      image,
      actions: 'Edit',
    };
    return row; // { row, type, merchant: name };
  };

  onTogglePromoted(id, promoted) {
    console.log('onTogglePromoted id=', id, ' ', promoted);

    const { update } = this.props;

    update(id, {
      promoted,
    })
  }

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowDeal
        el={item}
        pathname={pathname}
        key={id}
        onTogglePromoted={this.onTogglePromoted}
        itemName="deal"
      />
    );
  }

  getFilterControls(filterValues) {
    const { timePeriods, merchants = [] } = this.state;
    console.log(`Deals.getFilterControls(${JSON.stringify(filterValues)})`);

    const merchantsData = merchants.map(mapMerchantItem);
    const dealTypesData = Object
      .values(dealTypes)
      .map(mapDealTypeItem);

    const { timePeriod = [], type = [], merchantId = [] } = filterValues;
    const merchantValue = merchants
      .filter(x => merchantId.includes(x.id))
      .map(mapMerchantItem);
    const typeValue = Object
      .values(dealTypes)
      .filter(x => type.includes(x.key))
      .map(mapDealTypeItem);

    return [
      <MultiSelect
        id="merchantId"
        targetName="merchantId"
        isMainPage={true}
        name="Merchants"
        options={merchantsData}
        value={merchantValue}
        key="merchantId"
      />,
      <MultiSelect
        id="type"
        targetName="type"
        isMainPage={true}
        name="Type"
        options={dealTypesData}
        value={typeValue}
        key="type"
      />,
      <MultiSelect
        id="timePeriod"
        targetName="timePeriod"
        isMainPage={true}
        name="Time period"
        options={timePeriods}
        value={timePeriod.map(x => ({label: x, value: x}))}
        key="timePeriod"
      />
    ]
  }

  render() {
    const { getFilteredDeals, authMerchant } = this.props;
    const { deals } = this.state;

    return (
      <GridPage
        viewName="Deals"
        itemName="Deal"
        pathname="deals"
        items={deals}
        getFilterControls={this.getFilterControls}
        getFilteredItems={getFilteredDeals}
        getTableRowData={Deals.getTableRowData}
        renderRow={this.renderRow}
        tableHead={tableHead}
        showCreateButton={!authMerchant}
      />
    )
  }
}

export default withMerchants(withDeals(Deals));
