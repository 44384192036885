import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {confirmAlert} from 'react-confirm-alert';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';

export const withImages = (WrappedComponent) => {
  return class PP extends Component {
    // static propTypes = {
    //   itemName: PropTypes.string.isRequired,
    //   el: PropTypes.shape({
    //     id: PropTypes.string.isRequired,
    //   }).isRequired,
    // };
    //
    // static defaultProps = {
    //   itemName: 'deal',
    // };
    //
    constructor(props) {
      super(props);

      this.getImage = this.getImage.bind(this);
      // this.getFromSnapshot = this.getFromSnapshot.bind(this);
    }

    async prepareRef(imageName, path) {
      const { storage } = Firebase;
      const storageRef = await storage.child(`${path}/${imageName}`);

      return storageRef;
    };

    async getImage (imageName, path) {
      try {
        const storageRef = await this.prepareRef(imageName, path);
        const ref = await storageRef.getDownloadURL();
        return ref;
      } catch (e) {
        console.log(e.name_, e.message_);
        return '';
      }
    };

    render() {
      return (
        <WrappedComponent
          {...this.props}
          getImage={this.getImage}
          getFromSnapshot={mergeSnapshotChangesIntoArray}
        />
      );
    }
  }
};