const customStyle = {
  tape: {
    display: 'flex',
  },
  upperText: {
    textTransform: 'uppercase',
  },

};

export default customStyle;
