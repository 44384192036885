import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';

import customSelectStyle
  from '../../../assets/jss/material-dashboard-react/components/select.jsx';
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  static getDisplayValue(data, value) {
    if (Array.isArray(value) && !value.length) return '';
    const item = data.find(x => x.key === value[0]);
    return (item) ? item.value : '';
  }

  onChange = (event, data) => {
    const { onChange, name } = this.props;
    const { key, props } = data;
    const value = props.value;

    if (onChange) {
      onChange(event, {
        key: name,
        value: key,
      });
    }
  };

  render() {
    const {
      classes,
      fieldName,
      name,
      data,
      value,
      width,
    } = this.props;
    const items = data.map(({ key, value }) => (
        <MenuItem key={key} value={value}>{value}</MenuItem>
    ));
    const displayValue = CustomSelect.getDisplayValue(data, value);
    return (
      <div className={classes.root} style={{ display: 'flex', width: '100%' }}>
        <GridContainer style={{ width: `${width ? 60 : 100}%` }}>
          <GridItem xs={width ? 12 : 6} sm={12} md={width ? 6 : 12} lg={6}>
            <h5 className={classes.name} style={{ marginTop: 0 }}>{fieldName}</h5>
          </GridItem>
          <GridItem xs={width ? 12 : 6} sm={12} md={width ? 6 : 12} lg={6}>
            <Select
              value={displayValue}
              onChange={this.onChange}
              name={name || fieldName.toLowerCase()}
              className={classes.formControl}
            >
              {items}
            </Select>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

CustomSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  fieldName: PropTypes.string,
  data: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.string,
  width: PropTypes.bool,
};

CustomSelect.defaultProps = {
  data: [],
  width: true,
};

export default withStyles(customSelectStyle)(CustomSelect);
