import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export class TableRowNotification extends Component {
  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
  };

  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.state = {
      imageFilePath: undefined,
    }
  }

  handleDelete(dealId) {
    const { deleteDealById } = this.props;

    deleteDealById(dealId);
  }

  onDelete(id) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Deleting this deal is not reversible. Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id),
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  render() {
    const { el, pathname, onClick } = this.props;
    const {
      id,
      title,
      sentAt,
      message,
      creation_date,
      target_group,
      read,
      sent,
      failed,
    } = el;
    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '40px'}}
        onClick={onClick}
      >
        <TableCell>{title}</TableCell>
        <TableCell>{message}</TableCell>
        <TableCell>{id}</TableCell>
        <TableCell>{creation_date}</TableCell>
        <TableCell>{target_group}</TableCell>
        <TableCell>{read}</TableCell>
        <TableCell>{sent}</TableCell>
        <TableCell>{failed}</TableCell>
      </TableRow>
    );
  }
}

