import React, { Component } from 'react';
import _ from 'lodash';
import {
  withSite,
  withMerchants,
} from '../../contextApi';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Input from '../../components/Engine/Common/Input/Input.jsx';
import Firebase from "../../components/Firebase";
import EntityEditor from '../EntityEditor';
import Select from "../../components/Engine/Common/Select/Select";
import { isCreateMode, getIdFromPath } from '../../utils/entityUtils';

const { uuid } = Firebase;

class LotInfo extends Component {
  state = {
    lot: {},
  };

  constructor(props) {
    super(props);

    this.onSaveClick = this.onSaveClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getLotById, site: { lots } } = nextProps;

    if (_.isEmpty(prevState.lot) && lots.length > 0) {
      const lot = (isCreateMode()) ? LotInfo.getNewLot() : { ...getLotById(getIdFromPath()) };
      return { ...prevState, lot };
    }

    return {
      ...prevState,
    }
  }

  static getNewLot() { return {} };

  async onSaveClick(action) {
    const { createLot } = this.props;
    const { lot } = this.state;
    const response = await createLot(lot);

    return response;
  }

  onSelectChange = (event, data) => {
    const key = _.get(event, 'target.name');
    const value = data.key;

    if (key) {
      this.updateState(key, value);
    }
  };

  onInputChange = (event, data) => {
    let { key, value } = data;

    if (key) {
      console.log('updateStae key=', key, ' value=', value);
      this.updateState(key, value);
    }
  };

  updateState(key, value) {
    this.setState(prevState => {
      const lot = {...prevState.lot};
      _.set(lot, key, value);

      return {
        ...prevState,
        lot,
      };
    });
  }

  render() {
    const { site: { levels } } = this.props;
    const { lot } = this.state;
    const { id, level } = lot;
    const levelsSelectData = levels.map(x => ({key: x, value: x}));
    return (
      <EntityEditor
        onSaveClick={this.onSaveClick}
        // getEntityDocument={this.getEntityDocument}
        collection={Firebase.sitesRef}
        createEndpoint={`lot/create`}
        cancelHref="/admin/lots"
        entityName="lot"
        id={id}
      >
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <h3>LOT</h3>
            <Input
              id="id"
              name="Lot #"
              value={id}
              onChange={this.onInputChange}
              flex={true}
            />
            <Select
              label="Level"
              name="level"
              changeSelect={this.onSelectChange}
              data={levelsSelectData}
              flex={true}
              value={level}
            />
          </GridItem>
        </GridContainer>
      </EntityEditor>
    );
  }
}

export default withMerchants(withSite(LotInfo));
