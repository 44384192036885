import React, { Component } from 'react';
import { TableRowEmployee } from "../../../components/Common/Table/rows";
import {
  withEmployees,
} from "../../../contextApi";
import ft from 'firebase-timestamp-utils';
import GridPage from "../../GridPage";

const tableHead = [
  'Employee id',
  'Name',
  'Email',
  'Access',
  'Last Accessed',
  'Actions',
];

const getFormattedLastAccessedTime = (lastAccessedAt, googleId) => {
  let formattedLastAccessed = lastAccessedAt && ft.getFormattedTimeFromNow(lastAccessedAt);
  if (formattedLastAccessed) {
    return `${formattedLastAccessed.replace('-', '')} Ago`;
  }
  return (googleId) ? 'Unknown' : 'Never';
};

class Employees extends Component {
  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
  }

  static getTableRowData(employee) {
    const {
      id,
      google,
      merchant,
      lastAccessedAt,
    } = employee;
    const { email, name = 'Unknown', id: googleId } = google;
    const access = (merchant) ? merchant.name : 'Admin';
    const last_accessed = getFormattedLastAccessedTime(lastAccessedAt, googleId);
    return {
      id,
      employee_id: id,
      email,
      access,
      name,
      last_accessed,
    };
  };

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowEmployee
        el={item}
        pathname={pathname}
        key={id}
        itemName="employee"
      />
    );
  }

  render() {
    const { employees, getFilteredEmployees } = this.props;

    return (
      <GridPage
        viewName="Employees"
        itemName="Employee"
        pathname="employees"
        items={employees}
        getTableRowData={Employees.getTableRowData}
        getFilteredItems={getFilteredEmployees}
        renderRow={this.renderRow}
        tableHead={tableHead}
      />
    )
  }
}

export default withEmployees(Employees);
