import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {confirmAlert} from 'react-confirm-alert';
import _ from 'lodash';
import CreateOrUpdate from '../components/Deals/DealBlocks/CreateOrUpdate';
import firebaseConfig from "../config";
import Modal from 'react-responsive-modal';
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay';
import { withRouterInnerRef } from '../contextApi';

class EntityEditor extends Component {
  state = {
    overlayActive: false,
    modalMessage: undefined,
    modalOpen: false,
    createMode: true,
  };

  static propTypes = {
    children: PropTypes.node.isRequired,
    cancelHref: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    getEntityDocument: PropTypes.func.isRequired,
  };

  static defaultProps = {
    getEntityDocument: () => {},
  };

  static overlayStyles = {
    overlay: (base) => ({
      ...base,
      background: 'rgba(171, 183, 183, 0.7)',
      position: 'fixed',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    })
  };

  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.clickSave = this.clickSave.bind(this);
  }

  async clickSave() {
    await this.onSaveClick();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { pathname } = nextProps.location;
    const id = pathname.split('/').pop();
    const createMode = (id === 'add');

    return {
      ...prevState,
      createMode,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { alertMessage } = this.props;
    const { alertMessage: prevAlertMessage } = prevProps;

    if (!_.isEmpty(alertMessage) && alertMessage !== prevAlertMessage) {
      confirmAlert({
        title: 'Image out of bounds',
        message: alertMessage,
        buttons: [
          {
            label: 'Yes',
            onClick: this.handleDelete,
          },
        ]
      });
    }
  }

  handleDelete() {
    const { onDeleteClick, id, history } = this.props;

    if (onDeleteClick && id) {
      onDeleteClick(id);
    }

    history.goBack();
  }

  onDelete() {
    const { itemName } = this.props;

    confirmAlert({
      title: 'Confirm to delete',
      message: `Deleting this ${itemName} is not reversible. Are you sure to do this.`,
      buttons: [
        {
          label: 'Yes',
          onClick: this.handleDelete,
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  async createOrUpdate(entityDocument) {
    const { createMode } = this.state;
    const { onSaveClick } = this.props;
    const action = (createMode) ? 'create' : 'update';

    let response;
    if (onSaveClick) {
      response = await onSaveClick(action);
    }
    else {
      response = await this[action](entityDocument);
    }

    return response;
  }

  async update(entityDocument) {
    const { collection, useUpdate } = this.props;
    const id = entityDocument.id || this.props.id;
    const documentRef = collection.doc(id);
    // Use the firebase set method by default, override with the useUpdate prop to use update
    // https://medium.com/@jasonbyrne/closer-look-at-firebase-set-versus-update-eceff34d056b
    const updateMethod = (useUpdate === true) ? 'update' : 'set';
    const response = await documentRef[updateMethod](entityDocument)
      .then(response => {
        return {
          success: true,
        }
      })
      .catch(err => {
        const { error } = err.response.data;
        return {
          error,
          success: false,
        }
      });

    return response;
  }

  async create(entityDocument) {
    const { baseUrl } = firebaseConfig;
    const { createEndpoint } = this.props;
    const requestUrl = `${baseUrl}/${createEndpoint}`;

    const response = await axios.post(requestUrl, entityDocument)
      .then(response => {
        return {
          data: response.data,
          success: true,
        }
      })
      .catch(err => {
        const error = _.get(err, 'response.data.error') || err.message;
        return {
          error,
          success: false,
        }
      });

    return response;
  }

  onCloseModal() {
    this.setState(prevState => ({
      ...prevState,
      modalOpen: false,
    }));
  };

  async onCancelClick() {
    const { imageManager } = this.props;

    if (imageManager) {
      if (Array.isArray(imageManager)) {
        let cancelHandlers = [];
        imageManager.forEach(manager => {
          if (manager) {
            const { handleCancel } = manager;
            handleCancel && cancelHandlers.push(handleCancel);
          }
        });

        await Promise.all(cancelHandlers.map(fn => fn()));
      }
      else {
        const { handleCancel } = imageManager;

        await handleCancel();
      }
    }
  }

  async onSaveClick() {
    const { history, getEntityDocument, imageManager } = this.props;
    this.setState(prevState => ({
      ...prevState,
      overlayActive: true,
    }));
    const entityDocument = getEntityDocument();
    const response = await this.createOrUpdate(entityDocument);

    if (!response.success) {
      this.setState({
        modalMessage: response.error,
        modalOpen: true,
        overlayActive: false,
      });

      return;
    }

    if (imageManager) {
      if (Array.isArray(imageManager)) {
        let commitHandlers = [];
        imageManager.forEach(x => {
          const { handleCommit } = x;
          commitHandlers.push(handleCommit);
        });

        await Promise.all(commitHandlers.map(fn => fn()));
      }
      else {
        const { handleCommit } = imageManager;

        await handleCommit();
      }
    }

    this.setState(prevState => ({
      ...prevState,
      overlayActive: false,
    }));

    history.goBack();
  }

  render() {
    const {
      children,
      cancelHref,
      entityName,
      showDelete,
      buttonBar,
      saveButtonText,
    } = this.props;
    const {
      overlayActive,
      modalMessage,
      modalOpen,
      createMode,
    } = this.state;
    const overlayText = this.props.overlayText || `Saving ${entityName}...`;

    return (
      <LoadingOverlay
        active={overlayActive}
        spinner
        text={overlayText}
        styles={EntityEditor.overlayStyles}
      >
        {children}
        <CreateOrUpdate
          createDeal={this.onSaveClick}          // TODO : will be renamed as onCreateClick
          onCancel={this.onCancelClick}
          onDelete={this.onDelete}
          name={_.startCase(entityName)}
          showDelete={showDelete !== false && !createMode}
          path={cancelHref}
          buttonBar={buttonBar}
          saveButtonText={saveButtonText}
        />
        <Modal style={{ padding: 50 }} open={modalOpen} onClose={this.onCloseModal} center>
          <h2> {modalMessage} </h2>
        </Modal>
      </LoadingOverlay>
    );
  }
}

export default withRouterInnerRef(EntityEditor, { withRef: true });
