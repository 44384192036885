import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import {NavLink, withRouter} from 'react-router-dom';

import Modal from 'react-responsive-modal';
import Filters from '../../../components/Voucher/Filters/Filters';
import Grid from '../../../components/Grid/Grid';
import Totals from '../../../components/Voucher/Totals/Totals';

import dashboardStyle
  from '../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import { config } from '../../../config';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { TableRowReimbursement } from "../../../components/Common/Table/rows";
import {
  withCampaigns,
  withMerchants,
  withVouchers,
} from "../../../contextApi";
import Button from '../../../components/Common/CustomButtons/Button';
import {formatDate} from "../../../utils/date";

const tableHead = [
  '',
  'Voucher code',
  'Merchant',
  'Redemption Date',
  'Sales Receipt',
  'Customer',
  'Amount',
  'Status',
  // 'Actions',
];

const statuses = Object.values(config.statuses);
const curPage = config.pages.reimbursement;

const getTableRowData = (voucher, selectedVoucherIds) => {
  const {
    id,
    merchant = {},
    redemption = {},
    user = {},
    discount = {},
    // lots = [],
    // categories = [],
  } = voucher;
  const {
    redeemedAt,
    status,
    salesReceipt,
  } = redemption;
  const customer = user.name;
  const salesReceiptId = _.get(redemption, 'salesReceipt.id');
  const amount = discount.amount;
  const isSelected = selectedVoucherIds.has(id);
  const row = {
    id,
    voucher_code: id,
    isSelected,
    merchant: _.get(redemption, 'merchantScan.merchant.name'),
    redemption_date: redeemedAt ? formatDate(redeemedAt.toDate()) : '',
    sales_receipt: salesReceiptId,
    customer,
    amount,
    status,
    actions: 'action'
  };

  row.actions = (status === config.statuses.pendingAuthorisation ? 'Verify' : 'Ok');

  return row;
};

class Reimbursements extends Component {
  state = {
    tableData: [],
    campaignId: config.statuses.any,
    merchants: [config.statuses.any],
    merchantId: config.statuses.any,
    status: config.statuses.pendingReimbursement,
    totals: {},
    reimburseButtonDisabled: true,
    selectedVoucherIds: new Set(),
    count: 10,
    chequeNumber: '',
    error: false,
    errorText: 'Error. We have a problem',
    unsubscribeFunctionTotals: () => {
    },
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
    this.onReimburseClick = this.onReimburseClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return Reimbursements.getData(prevState, nextProps);
  }

  onOpenModal = () => {
    this.setState({ error: true });
  };

  onCloseModal = () => {
    this.setState({ error: false });
  };

  onRowSelect(voucherId, selected) {
    console.log(voucherId, selected);
    const { selectedVoucherIds } = this.state;
    const {
      getVoucherById,
    } = this.props;

    if (!selected) {
      selectedVoucherIds.delete(voucherId);
    }
    else {
      selectedVoucherIds.add(voucherId);
    }

    const reimburseButtonDisabled = !Reimbursements.stateIsReadyToReimburse(selectedVoucherIds, getVoucherById);
    const totalSelectedAmount = Reimbursements.getTotalSelectedAmount(selectedVoucherIds, getVoucherById);
    const selectedMerchant = !reimburseButtonDisabled ? Reimbursements.getSelectedMerchant(selectedVoucherIds, getVoucherById) : undefined;

    // selectedVoucherIds

    this.setState(prevState => ({
      ...prevState,
      selectedVoucherIds,
      reimburseButtonDisabled,
      totalSelectedAmount,
      selectedMerchant,
    }));
  }

  onReimburseClick() {
    const { history } = this.props;
    const { pathname } = history.location;

    history.push(`${pathname}/add`);
  }

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowReimbursement
        el={item}
        pathname={pathname}
        key={id}
        onDelete={this.onDelete}
        itemName="merchant"
        onSelect={this.onRowSelect}
      />
    );
  }

  static getData(state, props) {
    const {
      campaignId,
      merchantId,
      selectedVoucherIds,
      status,
    } = state;
    const {
      getFilteredVouchers,
      getFilteredMerchants,
      getVoucherTotals,
    } = props;
    const filteredVouchers = getFilteredVouchers(state) || [];
    const tableData = filteredVouchers.map(x => {
      return getTableRowData(x, selectedVoucherIds);
    });
    const merchants = getFilteredMerchants({
      vouchers: filteredVouchers,
    });
    const totals = {
      ...getVoucherTotals({ campaignId, merchantId, status }),
      quantityPendingReimbursement: tableData.length,
    };

    return {
      tableData,
      merchants,
      totals,
    };
  }

  onFiltersChange = async (event, data) => {
    const { name } = event.target;
    const { key, value } = data;

    this.setState(prevState => {
      const {
        status,
        merchantId,
        campaignId,
      } = prevState;
      const updatedState = Reimbursements.getData({
        status,
        merchantId,
        campaignId,
        [name]: key || value,
      }, this.props);
      return {
        ...prevState,
        ...updatedState,
        [name]: key || value,
      };
    });
  };

  static stateIsReadyToReimburse(selectedVoucherIds = new Set(), getVoucherById) {
    if (selectedVoucherIds.size === 0) {
      return 0;
    }
    const merchantIds = [...selectedVoucherIds].map(x => {
      const voucher = getVoucherById(x);
      const merchantId = _.get(voucher, 'redemption.merchantScan.merchant.id');
      console.log(merchantId);
      return merchantId;
    });
    const uniqueMerchantIds = new Set(merchantIds);
    console.log(JSON.stringify(uniqueMerchantIds), 'uniqueMerchantIds.size=', uniqueMerchantIds.size);
    return uniqueMerchantIds.size === 1;
  }

  static getTotalSelectedAmount(selectedVoucherIds = new Set(), getVoucherById) {
    if (selectedVoucherIds.size === 0) {
      return 0;
    }

    const result = [...selectedVoucherIds].reduce((accumulator, currentValue) => {
      const voucher = getVoucherById(currentValue);
      const { amount } = voucher.discount;
      return accumulator + amount;
    }, 0);

    return result;
  }

  static getSelectedMerchant(selectedVoucherIds, getVoucherById) {
    const firstVoucherId = selectedVoucherIds.values().next().value;
    const voucher = getVoucherById(firstVoucherId);
    const merchant = _.get(voucher, 'redemption.merchantScan.merchant');

    return merchant;
  }

  render() {
    const {
      location,
      campaigns,
      history,
    } = this.props;
    const { pathname } = location;
    const {
      tableData,
      totals,
      merchants,
      status,
      merchantId,
      campaignId,
      chequeNumber,
      selectedMerchant,
      reimburseButtonDisabled,
      error,
      errorText,
      selectedVoucherIds,
      totalSelectedAmount,
    } = this.state;
    const reimburseButtonLink = `${pathname}/add`;
    console.log('reimburseButtonLink=', reimburseButtonLink);

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <Filters
              page={curPage}
              campaigns={campaigns}
              merchants={merchants}
              statuses={statuses}
              status={status}
              merchantId={merchantId}
              campaignId={campaignId}
              handleChangeSelector={this.onFiltersChange}
            />
            <NavLink to={{
              pathname: reimburseButtonLink,
              params: {
                voucherIds: [...selectedVoucherIds],
                totalAmount: totalSelectedAmount,
                merchant: selectedMerchant,
              },
            }}>
              <Button
                color="primary"
                onClick={this.onReimburseClick}
                size="lg"
                disabled={reimburseButtonDisabled}
              >
                Reimuburse
              </Button>
            </NavLink>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <Totals page={curPage} totals={totals} />
          </GridItem>
        </GridContainer>
        <Grid tableHead={tableHead}
              tableData={tableData}
              name='Vouchers'
              pathname={pathname}
              count={tableData.length}
              renderRow={this.renderRow}
        />
        <Modal style={{ padding: 50 }} open={error} onClose={this.onCloseModal} center>
          <h2> {errorText} </h2>
        </Modal>
      </div>
    );
  }
}

Reimbursements.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default  withCampaigns(withMerchants(withVouchers(withStyles(dashboardStyle)(Reimbursements))));
