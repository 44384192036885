import React, { Component } from 'react';
import _ from 'lodash';
import { withSiteVisits } from '../../contextApi';
import { TableRowSiteVisit } from "../../components/Common/Table/rows";
// import hash from 'object-hash';
// import MultiSelect from '../../../components/Engine/Common/MultiSelect/MultiSelect';
import GridPage from '../GridPage';
import { formatDateFromFirebase } from "../../utils/date";

const tableHead = [
  'Site Visit Id',
  'User Id',
  'Creation Date',
  'Task',
  'Within Geo Fence',
];

class SiteVisits extends Component {
  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
  }

  static getTableRowData = (siteVisit) => {
    const {
      id = 0,
      location = {},
      creationDate,
      user,
      task,
      withinGeoFence,
    } = siteVisit;
    const creation_date = formatDateFromFirebase(creationDate);
    const user_id = user.id;
    const result = {
      id,
      creation_date,
      user_id,
      creation_date_sort: creationDate.seconds,
      task,
      within_geo_fence: withinGeoFence.toString(),
    };

    return result;
  };

  componentDidMount() {
    const { setSiteVisitsProviderActive } = this.props;
    setSiteVisitsProviderActive();
  }

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowSiteVisit
        el={ item }
        pathname={ pathname }
        key={ id }
        itemName="siteVisit"
      />
    );
  }

  // getFilterControls(filterValues) {
  //   const { site: { lots: rawAllLots, levels: rawAllLevels } } = this.props;
  //   const { categories = [], lots = [], levels = [] } = filterValues;
  //   const { categoryOptions } = this.state;
  //
  //   return [
  //     <MultiSelect
  //       id="categories"
  //       key="categories"
  //       isMainPage={ true }
  //       options={ categoryOptions }
  //       value={ categories.map(x => ({ label: x, value: x })) }
  //     />,
  //     <MultiSelect
  //       id="lots"
  //       key="lots"
  //       name="Lot(s)"
  //       options={ rawAllLots.map(x => ({ label: x, value: x })) }
  //       isMainPage={ true }
  //       value={ lots.map(x => ({ label: x, value: x })) }
  //     />,
  //     <MultiSelect
  //       id="levels"
  //       key="levels"
  //       name="Level(s)"
  //       options={ rawAllLevels.map(x => ({ label: x, value: x })) }
  //       isMainPage={ true }
  //       value={ levels.map(x => ({ label: x, value: x })) }
  //     />
  //   ]
  // }

  render() {
    // const { getFilteredMerchants } = this.props;
    const { siteVisits } = this.props;

    return (
      <GridPage
        viewName="Site Visits"
        itemName="Site Visits"
        pathname="siteVisits"
        items={siteVisits}
        // getFilterControls={this.getFilterControls}
        // getFilteredItems={getFilteredMerchants}
        getTableRowData={SiteVisits.getTableRowData}
        renderRow={this.renderRow}
        tableHead={tableHead}
        showCreateButton={false}
      />
    )
  }
}

export default withSiteVisits(SiteVisits);
