import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {renderToStaticMarkup} from 'react-dom/server';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { NavLink } from 'react-router-dom';
import Button from '../../CustomButtons/Button';
import { withTableRow } from "./withTableRow";
import HtmlToReact from 'html-to-react';

const htmlToReactParser = new HtmlToReact.Parser(React);


class TableRowMerchant extends Component {
  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
  };

  static defaultProps = {
    el: {},
    pathname: '',
  };

  render() {
    const { el, pathname, onClick, onDelete } = this.props;
    const {
      id,
      merchant_id,
      name,
      lot,
      category,
      latLong,
    } = el;

    const reactComponent = htmlToReactParser.parse(category);

    return (
      <TableRow
        hover
        tabIndex={-1}
      >
        <TableCell>{merchant_id}</TableCell>
        <TableCell align="left">{name}</TableCell>
        <TableCell align="left" style={{maxWidth: 120}}>{lot}</TableCell>
        <TableCell align="left" style={{maxWidth: 120, lineHeight: '1.3'}}>{reactComponent}</TableCell>
        <TableCell align="left" style={{maxWidth: 30}}>{latLong}</TableCell>
        <TableCell style={{maxWidth: 120}}>
          <NavLink to={`${pathname}/${id}`}>
            <Button color="primary" size="sm">Edit</Button>
          </NavLink>
          <Button
            color="primary"
            size="sm"
            style={{marginLeft: '10px'}}
            onClick={onDelete}
          >
            Delete
          </Button>
        </TableCell>
      </TableRow>
    );
  }
}

const _TableRowMerchant = withTableRow(TableRowMerchant);
export { _TableRowMerchant as TableRowMerchant };
