export const getIdFromPath = () => {
  const { href } = window.location;
  const part = href.split('/').pop();
  return decodeURIComponent(part);
};

export const isCreateMode = () => {
  const id = getIdFromPath();
  return (id === 'add');
};
