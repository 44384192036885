import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "../../../assets/jss/material-dashboard-react/components/totals";
import Row from "../../Voucher/Row/Row";
import { config } from "../../../config";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";

class CampaignTotals extends Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {
      classes,
      totals = {},
    } = this.props;

    const {
      created = 0,
      issued = 0,
      redeemed = 0,
    } = totals;

    return (
      <Fragment>
        <h3 className={classes.upperText}>Totals</h3>
          <GridContainer style={{ paddingLeft: 20 }}>
            <Row name={config.totalsLabel.created}
                 value={created}
            />
            <Row name={config.totalsLabel.issued}
                 value={issued}
            />
            <Row name={config.totalsLabel.redeemed}
                 value={redeemed}
            />
          </GridContainer>
      </Fragment>
    );
  }
}

CampaignTotals.propTypes = {
  classes: PropTypes.object.isRequired,
  totals: PropTypes.object,
};

export default withStyles(styles)(CampaignTotals);
