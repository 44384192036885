import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {formatDateFromFirebase} from "../../../../utils/date";
import {Link} from "react-router-dom";
import { formatMoney } from 'accounting';

export class TableRowPaidReimbursement extends Component {
  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
  };

  render() {
    const { el, pathname, onClick } = this.props;
    const {
      reimbursement_id,
      merchant,
      cheque_number,
      reimbursement_date,
      amount,
    } = el;
    const formattedAmount = formatMoney(amount, '', 2);
    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '86px'}}
        onClick={onClick}
      >
        <TableCell>
          <Link to={`/admin/reimbursements/${reimbursement_id}`}>{reimbursement_id}</Link>
        </TableCell>
        <TableCell align="left">{merchant}</TableCell>
        <TableCell align="left">{cheque_number}</TableCell>
        <TableCell align="right" style={{maxWidth: 50}}>{formattedAmount}</TableCell>
        <TableCell align="left">{reimbursement_date}</TableCell>
      </TableRow>
    );
  }
}
