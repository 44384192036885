const tableCellBadgeStyles = {
  badge: {
    // backgroundColor: '#e11d21',
    color: '#ffffff',
    borderRadius: 16,
    padding: '10px 16px 10px 16px',
    whiteSpace: 'nowrap',
  },
  green: {
    backgroundColor: '#ACD281',
  },
  amber: {
    backgroundColor: '#FFB533',
  },
  red: {
    backgroundColor: '#e11d21',
  },
  grey: {
    backgroundColor: '#e9E9E9',
    color: '#818181',
  }
};

export default tableCellBadgeStyles;
