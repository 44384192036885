import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import style from '../../../assets/jss/material-dashboard-react/components/images';

class CreateOrUpdate extends Component {
  constructor(props) {
    super(props);

    this.onCancelClick = this.onCancelClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);

    this.state = {
      createMode: true,
    };
  }

  onCancelClick() {
    const { deleteImages, onCancel } = this.props;
    const { createMode } = this.state;

    if (createMode) {
      deleteImages();
    }

    if (onCancel) {
      onCancel();
    }
  }

  onDeleteClick() {
    const { onDelete } = this.props;

    if (onDelete) {
      onDelete();
    }
  }

  onSaveClick() {
    const {
      createDeal,
    } = this.props;
    const { pathname } = window.location;

    if (createDeal) {
      createDeal(pathname);
    }
  }

  componentDidMount() {
    const {
      path,
    } = this.props;
    const { pathname } = window.location;
    const createMode = (pathname === `${path}/add`);

    this.setState({
      createMode,
    })
  }

  render() {
    const {
      classes,
      path,
      name,
      showDelete,
      buttonBar,
      saveButtonText,
    } = this.props;
    const { createMode } = this.state;
    const successButtonText = saveButtonText || `${createMode ? 'Create' : 'Update'} ${name}`;

    return (
      <div className={classes.btnContainer}>
        {buttonBar}
        <NavLink
          className={classes.cancelBtn}
          to={path}
        >
          <Button
            className={classes.uploadBtn}
            onClick={this.onCancelClick}
            size="large"
          >
            Cancel
          </Button>
        </NavLink>
        {showDelete && (
          <Button
            className={classes.deleteButton}
            onClick={this.onDeleteClick}
            size="large"
          >
            Delete
          </Button>
        )}
        <Button
          className={classes.successButton}
          onClick={this.onSaveClick}
          size="large"
        >
          {successButtonText}
        </Button>
      </div>
    );
  }
}

CreateOrUpdate.propTypes = {
  createDeal: PropTypes.func,
  deleteImages: PropTypes.func,
  classes: PropTypes.object.isRequired,
  path: PropTypes.string,
  name: PropTypes.string,

  onCancel: PropTypes.func,
};

CreateOrUpdate.defaultProps = {
  createDeal: () => null,
  deleteImages: () => null,
  path: `/admin/deals`,
  name: 'Deal',
  onCancel: () => {},
};

export default withStyles(style)(CreateOrUpdate);
