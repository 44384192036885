import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import InputAdornment from '@material-ui/core/InputAdornment';
import style from '../../../../assets/jss/material-dashboard-react/components/details';
import CustomInput from '../../../Common/CustomInput/CustomInput';
import { config } from '../../../../config';

class Input extends React.Component {
  state = {
    isDirty: false,
    isValid: true,
  };

  validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email.toLowerCase());
  };

  validatePhone = (phone) => {
    const re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(phone);
  };

  checkMask = () => {
    const { phone, email } = config.types.input;
    const { type, value } = this.props;
    if (type === email) {
      return this.validateEmail(value);
    }
    if (type === phone) {
      return this.validatePhone(value);
    }
    return true;
  };

  handleFocus = () => {
    const { phone, email } = config.types.input;
    const {
      value,
      handleRequiredFields,
      name: field,
      required,
      type,
    } = this.props;
    const { isDirty } = this.state;
    if (required) {
      handleRequiredFields({
        field,
        status: Boolean(value.toString().length),
      });
    }
    const newState = {};
    if (type === email || type === phone) {
      newState.isValid = this.checkMask();
    }
    if (!isDirty) {
      newState.isDirty = true;
    }
    this.setState({ ...newState });
  };

  handleChange = (event) => {
    const { onChange, type } = this.props;
    let { id, value } = event.target;
    let allowChange = true;

    if (type === 'number') {
      value = (value === '') ? undefined : Number.parseInt(value);
      if (Number.isNaN(value)) {
        allowChange = false;
      }
    }

    if (type === 'float') {
      value = (value === '') ? undefined : Number.parseFloat(value);
      if (Number.isNaN(value)) {
        allowChange = false;
      }
    }

    if (type === 'currency') {
      const regex = /^[1-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/;

      if (!regex.test(value) && value.length !== 0) {
        allowChange = false;
      }
      if (value.split('.')[0].length > 5) {
        // limit prices to MYR 99,999 max
        allowChange = false;
      }
      value = (value === '') ? undefined : value; // Number.parseFloat(value);
    }

    if (onChange && allowChange) {
      onChange(event, {
        key: id,
        value,
      });
    }
  };

  getInputProps(type, multiline) {
    const { area, number, currency } = config.types.input;
    if (type === area || multiline) {
      return {
        multiline: true,
        rows: 6,
      }
    }
    else if (type === currency) {
      return {
        startAdornment: <InputAdornment position="start">RM</InputAdornment>,
      }
    }
    return {};
  }

  render() {
    const {
      classes,
      name,
      value,
      id,
      placeholder,
      multiline,
      description,
      type,
      flex,
      required,
      marginTop,
      style = {},
    } = this.props;
    const { isDirty, isValid } = this.state;
    const inputProps = {
      ...this.getInputProps(type, multiline),
      placeholder,
      required,
    };
    const { area, number, currency } = config.types.input;
    const classNameDiv = type === area ? classes.percentageBig : classes.percentage;
    const styleFlex = flex ? { flexDirection: 'row', marginBottom: 0 } : { flexDirection: 'column' };
    const containerStyle = {
      ...styleFlex,
      ...style,
    };
    const classNameInput = classNames({
      [`${type === number ? classes.number : type === area ? classes.area : classes.percentageInput}`]: true,
      [classes.topMargin]: marginTop,
    });

    const labelHeaderClassName = flex ? classes.headerForRowFlex : classes.headerForRow;
    return (
      <div onBlur={this.handleFocus} className={classNameDiv} style={containerStyle}>
        {(name || description) && (
          <div className={labelHeaderClassName}>
            <label className={classes.labelInput}>{name}</label>
            {description && (
              <label className={classes.labelDesc}>{description}</label>
            )}
          </div>
        )}
        <div className={classes.flexContainerForMail}>
        <CustomInput
          id={id}
          formControlProps={{
            className: classNameInput,
          }}
          type={type}
          inputProps={inputProps}
          onChange={this.handleChange}
          value={value}
        />
        {
          isDirty && !value.toString().length && required
          && <label className={classes.error}>{name} is required</label>
        }
        {
          !isValid && <label className={classes.error}>Check your {name} </label>
        }
        </div>
      </div>
    );
  }
}

Input.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  id: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
  onChange: PropTypes.func,
  type: PropTypes.string,
  description: PropTypes.string,
  required: PropTypes.bool,
  handleRequiredFields: PropTypes.func,
  flex: PropTypes.bool,
};

Input.defaultProps = {
  name: '',
  value: '',
  id: '',
  placeholder: '',
  multiline: false,
  onChange: () => null,
  type: 'string',
  description: '',
  required: false,
  handleRequiredFields: () => null,
  flex: false,
};


export default withStyles(style)(Input);
