import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import withStyles from '@material-ui/core/styles/withStyles';

import CardAvatar from '../../../Common/Card/CardAvatar';
import CardBody from '../../../Common/Card/CardBody';
import Card from '../../../Common/Card/Card';

import cardStyle from '../../../../assets/jss/material-dashboard-react/components/cardStyle.jsx';

class DealTypeCard extends Component {
  render() {
    const {
      classes,
      handleClick,
      selected,
      dealType,
    } = this.props;
    const imageClasses = classNames({
      [classes.smallCardImage]: true,
      [classes.smallCardImagePicked]: selected,
    });
    const {
      label,
      description,
      image,
      key,
    } = dealType;
    return (
      <Card className={!selected ? classes.smallCard : classes.pickedSmallCard}
            onClick={ () => handleClick(key) }>
        <CardAvatar className={classes.smallCardAvatar}>
          <img className={imageClasses} src={image} alt="..."/>
        </CardAvatar>
        <CardBody className={classes.cardBody}>
          <h4 className={classes.smallCardTitle}>{label}</h4>
          <p className={classes.smallCardDescription}>{description}</p>
        </CardBody>
      </Card>
    );
  }
}

DealTypeCard.propTypes = {
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
  selected: PropTypes.bool,
};


export default withStyles(cardStyle)(DealTypeCard);
