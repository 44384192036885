import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { DealsConsumer } from './dealsProvider';

export const withDeals = (Component) => {
  class ComponentWithDeals extends React.Component {
    static displayName = `${Component.displayName || Component.name}`;

    render() {
      const { navigation, onRef } = this.props;

      return (
        <DealsConsumer>
          {({
            deals,
            getDealById,
            categoryCounts,
            update,
            getFilteredDeals,
            getSearchedDeals,
            deleteDealById,
            setActive,
            getNumberDealsWithCategory,
          }) => (
            <Component
              {...this.props}
              navigation={navigation}
              ref={onRef}
              deals={deals}
              update={update}
              getDealById={getDealById}
              getFilteredDeals={getFilteredDeals}
              getSearchedDeals={getSearchedDeals}
              deleteDealById={deleteDealById}
              setDealsProviderActive={setActive}
              getNumberDealsWithCategory={getNumberDealsWithCategory}
            />
          )}
        </DealsConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithDeals, Component);
};
