import { getUserSummaryGoogle } from "../utils/userUtils";
import Avatar from "react-avatar";
import React from "react";

const defaultAvatarUrl = 'https://www.macritchie.io/images/default_avatar.jpg';

export const UserAvatar = ({ social, style, size = 40 }) => {
  const { google = {}, facebook = {} } = social;
  let { photoUrl } = getUserSummaryGoogle(google);
  photoUrl = photoUrl || defaultAvatarUrl;
  const { id: facebookId } = facebook;

  return (
    <Avatar
      round={true}
      size={size}
      src={photoUrl}
      // googleId={googleId}
      facebookId={facebookId}
      style={style}
    />
  );
};