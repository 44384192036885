import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from "react-router-dom";
import _ from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from '../../CustomButtons/Button';
import { confirmAlert } from "react-confirm-alert";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchIcon } from '../../../../utils/iconUtils';

export class TableRowCategory extends Component {
  state = {
    iconDefinition: undefined,
  };

  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
    onDelete: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
    onDelete: () => {},
  };

  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.onIconFetch = this.onIconFetch.bind(this);
  }

  onIconFetch(iconDefinition) {
    if (this.mounted === true) {
      this.setState(prevState => ({
        ...prevState,
        iconDefinition,
      }));
    }
  };

  componentDidMount() {
    this.mounted = true;
    const { el } = this.props;
    const { icon: { name: iconName } } = el;

    fetchIcon(iconName, this.onIconFetch);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  onDelete({ id, name }) {
    console.log('deleting category', id);
    confirmAlert({
      title: 'Confirm to delete',
      message: `Are you sure you wish to delete lot ${name}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id),
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  handleDelete(id) {
    const { onDelete } = this.props;
    onDelete(id);
  }

  render() {
    const { el, pathname, onClick } = this.props;
    const { iconDefinition } = this.state;
    if (_.isEmpty(el)) {
      debugger;
    }
    const {
      id,
      name,
      short_name,
      icon: { name: iconName } = {},
      number_merchants,
      number_deals,
    } = el;

    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '40px'}}
        onClick={onClick}
      >
        <TableCell>{id}</TableCell>
        <TableCell align="left">{name}</TableCell>
        <TableCell align="left">{short_name}</TableCell>
        <TableCell align="left">
          <FontAwesomeIcon icon={iconDefinition} />
        </TableCell>
        <TableCell align="left">{number_merchants}</TableCell>
        <TableCell align="left">{number_deals}</TableCell>
        <TableCell style={{maxWidth: 120}}>
          <NavLink to={`categories/${id}`}>
            <Button color="primary" size="sm">Edit</Button>
          </NavLink>
          {/*Only display delete button if no merchant tenanted to this lot*/}

          <Button
            color="primary"
            size="sm"
            style={{marginLeft: '10px'}}
            onClick={() => this.onDelete({ id, name })}
          >
            Delete
          </Button>

        </TableCell>
      </TableRow>
    );
  }
}
