import React, { Fragment, Component } from 'react';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';
import MomentUtils from '@date-io/moment';
import { Redirect, Route, Router, Switch, } from 'react-router-dom';
import { AuthProvider, AuthConsumer } from './providers/AuthProvider.jsx';
import AuthorisedRoutes from './layouts/AuthorisedRoutes';
import Login from './views/Login/Login.jsx';
import Logout from './views/Login/Logout.jsx';
import {createBrowserHistory} from "history";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import {config} from "./config";
import theme from './theme';
import ScaleLoader from 'react-spinners/ScaleLoader';

class App extends Component {
  componentWillMount() {
    this.history = createBrowserHistory();
  }

  static getInitialRoute(authMerchant) {
    const route = (authMerchant) ? 'deals' : 'customers';
    return `/admin/${route}`;
  };

  render() {
    const spinnerStyle = {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    };

    return (
      <MuiThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Router history={this.history}>
            <AuthProvider>
              <AuthConsumer>
                {({ isAuth, checkedLocalStorage, authMerchants }) => (
                  <Fragment>
                  <div className='moon-loader'>
                    <ScaleLoader
                      css={spinnerStyle}
                      sizeUnit={"px"}
                      size={150}
                      // color={'#123abc'}
                      loading={!isAuth}
                    />
                  </div>
                  <Switch>
                    <Route
                      path="/login"
                      component={Login}
                    />
                    <Route
                      path="/logout"
                      component={Logout}
                    />
                    {!isAuth && checkedLocalStorage && (
                      <Switch>
                        <Route exact path="/" render={() => (
                          <Redirect push to="/login" />
                        )}/>
                        <Redirect push to="/login" />
                      </Switch>
                    )}
                    {isAuth && (
                      <Switch>
                        <Route exact path="/" render={() => (
                          <Redirect to={App.getInitialRoute(authMerchants)} />
                        )}/>
                        <Route
                          path='/admin'
                          render={(props) => (
                            <AuthorisedRoutes
                              {...props}
                              authMerchants={authMerchants}
                            />
                          )}
                        />
                      </Switch>
                    )}
                  </Switch>
                  </Fragment>
                )}
              </AuthConsumer>
            </AuthProvider>
          </Router>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    )
  }
}

export default App;