import React, { Component, Fragment } from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';
import customFiltersStyle
  from '../../../assets/jss/material-dashboard-react/components/filters.jsx';

import Row from '../Row/Row';
import CustomSelect from '../../Common/CustomSelect/CustomSelect';
import { config } from '../../../config';
import Switch from '../../Engine/Common/Switch/Switch';

const anyItem = {
  key: 'Any',
  value: 'Any',
};

class Filters extends Component {
  static getFiltersData({
    campaigns = [],
    merchants = [],
    statuses = [],
    types = [],
  }) {
    const merchantsData = [anyItem].concat(
      merchants.map(x => ({
        key: x.id,
        value: x.name,
      })),
    );
    const typesData = [anyItem].concat(
      types.map(x => ({
        key: x.key,
        value: x.label,
      })),
    );
    const campaignsData = [anyItem].concat(
      campaigns.map(x => ({
        key: x.id,
        value: x.title,
      })),
    );
    const statusesData = [anyItem].concat(statuses);
    return {
      merchantsData,
      typesData,
      campaignsData,
      statusesData,
    }
  }

  render() {
    const {
      classes,
      page,
      handleChangeSelector,
      campaignId,
      merchantId,
      status,
      active,
      type,
      activeHandle,
    } = this.props;

    const {
      merchantsData,
      typesData,
      campaignsData,
      statusesData,
    } = Filters.getFiltersData(this.props);

    return (
      <Fragment>
        <h3 className={classes.upperText}>Filters</h3>
        <Fragment>
          {
            page === config.pages.deals ?
            <Switch name='Active' value={active} handleChecked={activeHandle} /> :
            <CustomSelect
              fieldName='Campaign'
              name="campaignId"
              data={campaignsData}
              value={campaignId}
              handleChangeSelector={handleChangeSelector}
            />
          }
          <CustomSelect
            fieldName='Merchant'
            name="merchantId"
            data={merchantsData}
            value={merchantId}
            handleChangeSelector={handleChangeSelector}
          />
          {
            page === config.pages.overview ?
              <CustomSelect
                fieldName='Status'
                data={statusesData}
                value={status}
                handleChangeSelector={handleChangeSelector}
              /> :
              page === config.pages.deals ?
              <CustomSelect
                fieldName='Type'
                data={typesData}
                value={type}
                handleChangeSelector={handleChangeSelector}
              /> :
              <Row name='Status'
                   value={config.statuses.pendingReimbursement}
                   upperCase={false}
                   minWidth={true}
              />
          }
        </Fragment>
      </Fragment>
    );
  }
}

Filters.propTypes = {
  classes: PropTypes.object.isRequired,
  page: PropTypes.string,
  campaigns: PropTypes.array,
  merchants: PropTypes.array,
  statuses: PropTypes.array,
  status: PropTypes.string,
  merchant: PropTypes.string,
  campaign: PropTypes.string,
  types: PropTypes.array,
  type: PropTypes.string,
  active: PropTypes.bool,
  handleChangeSelector: PropTypes.func,
  activeHandle: PropTypes.func,
};

Filters.defaultProps = {
  page: config.pages.overview,
};


export default withStyles(customFiltersStyle)(Filters);
