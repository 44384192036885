import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { ReceiptsConsumer } from './receiptsProvider';

export const withReceipts = (Component) => {
  class ComponentWithReceipts extends React.Component {
    static displayName = `withReceipts(${Component.displayName || Component.name})`;

    render() {
      const { navigation, onRef } = this.props;

      return (
        <ReceiptsConsumer>
          {({
              receipts,
              getReceiptById,
              getNumberPendingProcessing,
              setActive,
            }) => (
            <Component
              {...this.props}
              navigation={navigation}
              ref={onRef}
              receipts={receipts}
              getReceiptById={getReceiptById}
              getNumberPendingProcessing={getNumberPendingProcessing}
              setActive={setActive}
            />
          )}
        </ReceiptsConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithReceipts, Component);
};
