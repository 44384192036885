import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { NavLink } from 'react-router-dom';
import Button from '../../CustomButtons/Button';
import { withDeals } from '../../../../contextApi';
import Img from 'react-image';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import TableCellBadge from './TableCellBadge';
import { toTitleCase } from "../../../../utils/stringUtils";
import Switch from 'react-switch';
import { getImage } from "../../../../utils/imageUtils";

class TableRowDeal extends Component {
  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.state = {
      imageFilePath: undefined,
    }
  }

  handleDelete(dealId) {
    const { deleteDealById } = this.props;

    deleteDealById(dealId);
  }

  onDelete(dealId) {
    console.log('deleting', dealId);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Deleting this deal is not reversible. Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(dealId),
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  async componentDidUpdate(prevProps, prevState) {
    const { el } = this.props;
    const { id, image } = el;
    if (id !== prevProps.el.id) {
      const primaryImage = await getImage(image.name, id);

      this.setState(prevState => ({
        ...prevState,
        primaryImage,
      }));
    }
  }

  async componentDidMount() {
    const { el } = this.props;
    const { id, image = {} } = el;
    const fileName = image.fileName || image.name;

    if (id) {
      const primaryImage = await getImage(`dealImages/${id}/${fileName}`);

      this.setState(prevState => ({
        ...prevState,
        primaryImage,
      }));
    }
  }

  render() {
    const { el, pathname, onTogglePromoted } = this.props;
    const {
      id,
      deal_id,
      merchant,
      deal_type,
      limit,
      remaining,
      redeemed,
      start_date,
      end_date,
      creation_date,
      status,
      promoted,
    } = el;
    const { primaryImage } = this.state;

    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '86px'}}
      >
        <TableCell>{deal_id}</TableCell>
        <TableCell align="left">{merchant}</TableCell>
        <TableCell align="left">{toTitleCase(deal_type)}</TableCell>
        <TableCell align="left">{limit}</TableCell>
        <TableCell align="left">{remaining}</TableCell>
        <TableCell align="left">{redeemed}</TableCell>
        <TableCell align="left">{creation_date}</TableCell>
        <TableCell align="left">{start_date}</TableCell>
        <TableCell align="left">{end_date}</TableCell>
        <TableCell>
          <Img src={primaryImage} style={{
            width: '75px',
            height: '75px',
          }}
        />
        </TableCell>
        <TableCell align="left">
          <Switch
            onChange={() => onTogglePromoted(id, !promoted)}
            checked={promoted}
          />
        </TableCell>
        <TableCell>
          <TableCellBadge label={status} />
        </TableCell>
        <TableCell>
          <NavLink to={`${pathname}/${el.id}`}>
            <Button color="primary" size="sm">{el.actions}</Button>
          </NavLink>
          <Button
            color="primary"
            size="sm"
            style={{marginLeft: '10px'}}
            onClick={() => this.onDelete(el.id)}
          >Delete</Button>
        </TableCell>
      </TableRow>
    );
  }
}

TableRowDeal.propTypes = {
  el: PropTypes.object,
  pathname: PropTypes.string,
};

TableRowDeal.defaultProps = {
  el: {},
  pathname: '',
};

const _TableRowDeal = withDeals(TableRowDeal);
export { _TableRowDeal as TableRowDeal };