import React, { Component } from 'react';
import {
  withNotifications,
} from '../../contextApi';
import { TableRowNotification } from '../../components/Common/Table/rows';
import { formatDateFromFirebase } from "../../utils/date";
import GridPage from "../GridPage";
import { TargetNotificationGroup } from './NotificationInfo';

const tableHead = [
  'Title',
  'Message',
  'Id',
  'Creation Date',
  'Target Group',
  'Read',
  'Sent',
  'Failed',
];

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    const { setNotificationsProviderActive } = this.props;

    setNotificationsProviderActive();
  }

  static getTableRowData(notification) {
    const {
      id,
      title,
      sentAt,
      body,
      creationDate,
      counts = {},
      targetGroup,
    } = notification;
    const { read = 0, pushSuccess: sent = 0, pushFail: failed = 0 } = counts;
    const target_group = TargetNotificationGroup[targetGroup] || TargetNotificationGroup.ALL_CUSTOMERS;
    const message = body.substring(0, 49);

    const creation_date = formatDateFromFirebase(creationDate);
    return {
      id,
      title,
      message,
      creation_date,
      target_group,
      creation_date_sort: creationDate.seconds,
      read,
      sent,
      failed,
    };
  };

  onGridRowClick = (campaignId) => {
    this.setState({
      filters: {
        campaignId,
      }
    });
  };

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowNotification
        el={item}
        pathname={pathname}
        key={id}
        onClick={this.onGridRowClick}
      />
    );
  }

  render() {
    const { notifications } = this.props;

    return (
      <GridPage
        viewName="Notifications"
        itemName="Notification"
        pathname="notifications"
        items={notifications}
        getTableRowData={Notifications.getTableRowData}
        renderRow={this.renderRow}
        tableHead={tableHead}
      />
    )
  }
}

export default withNotifications(Notifications);