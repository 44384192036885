import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl/index';
import FormLabel from '@material-ui/core/FormLabel/index';
import Radio from '@material-ui/core/Radio/index';
import RadioGroup from '@material-ui/core/RadioGroup/index';
import FormControlLabel from '@material-ui/core/FormControlLabel/index';
import style
  from '../../../assets/jss/material-dashboard-react/components/radioGroup.jsx';
import withStyles from "@material-ui/core/styles/withStyles";

class RadioButtonGroup extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.string,
  };

  static defaultProps = {
    value: 'priceReduction',
  };

  handleChange = (event, value) => {
    const {
      changeSelect,
    } = this.props;

    if (changeSelect) {
      changeSelect(event, {
        key: value,
        value,
      })
    }
  };

  render() {
    const {
      classes = {},
      value,
    } = this.props;

    return (
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel component="legend">Display price reduction</FormLabel>
        <RadioGroup
          aria-label="Gender"
          name="displayPriceReductionAs"
          className={classes.group}
          value={value}
          onChange={this.handleChange}
        >
          <FormControlLabel value="priceReduction" control={<Radio/>} label="With reduced from price" />
          <FormControlLabel value="percentageOff" control={<Radio/>} label="As a % off" />
          {/*<FormControlLabel value="other" control={<Radio/>} label="Other"/>*/}
          {/*<FormControlLabel*/}
            {/*value="disabled"*/}
            {/*disabled*/}
            {/*control={<Radio/>}*/}
            {/*label="(Disabled option)"*/}
          {/*/>*/}
        </RadioGroup>
      </FormControl>
    );
  };
}


export default withStyles(style)(RadioButtonGroup);