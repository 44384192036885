import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import cardStyle from '../../../../assets/jss/material-dashboard-react/components/tapeStyle.jsx';
import DealTypeCard from '../DealTypeCard/DealTypeCard';

class DealsTape extends React.Component {
  render() {
    const {
      dealTypes,
      selectedDealTypeKey,
      classes,
      handleClick,
    } = this.props;

    return (
      <Fragment>
        <h3 className={classes.upperText}> Deal </h3>
        <div className={classes.tape}>
          {
            dealTypes.map(dealType => {
              const { key } = dealType;
              const selected = (key === selectedDealTypeKey);
              return (
                <DealTypeCard
                  key={key}
                  dealType={dealType}
                  selected={selected}
                  handleClick={handleClick}
                />
              );
            })
          }
        </div>
      </Fragment>
    );
  }
}

DealsTape.propTypes = {
  dealTypes: PropTypes.array,
  selectedDealTypeKey: PropTypes.string,
  classes: PropTypes.object.isRequired,
  handleClick: PropTypes.func,
};

export default withStyles(cardStyle)(DealsTape);
