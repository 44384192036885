import {Component} from "react";
import React from "react";
import GridItem from '../components/Grid/GridItem';
import GridContainer from '../components/Grid/GridContainer';
import Button from '@material-ui/core/Button';
import Card from '../components/Common/Card/Card';

// Great source for icons
// https://icons8.com/icons/set/app-store

const DashboardLink = ({ href, imgSrc, label }) => (
  <Card>
    <a target="_blank" href={href}>
      <Button style={{width: '100%', justifyContent: 'left'}}>
        <img src={imgSrc} style={{margin: '15px 22px 15px 22px'}}/>
        <p>{label}</p>
      </Button>
    </a>
  </Card>
);

const links = [{
  href: 'https://developers.facebook.com/apps/384800625417838',
  imgSrc: 'https://img.icons8.com/color/48/000000/facebook-new.png',
  label: 'Facebook App Dashboard',
},{
  href: 'https://play.google.com/apps/publish/?account=7363578220990835764',
  imgSrc: 'https://img.icons8.com/color/48/000000/google-play.png',
  label: 'Google Play Console',
},{
  href: 'https://console.firebase.google.com/u/0/project/firecrackertest-26b1e',
  imgSrc: 'https://img.icons8.com/color/48/000000/firebase.png',
  label: 'Firebase Console',
}, {
  href: 'https://appstoreconnect.apple.com',
  imgSrc: 'https://img.icons8.com/color/48/000000/apple-app-store--v1.png',
  label: 'Apple App Store Connect',
}, {
  href: 'https://console.developers.google.com/apis/credentials?project=firecrackertest-26b1e&folder=&organizationId=',
  imgSrc: 'https://img.icons8.com/color/48/000000/google-cloud-platform.png',
  label: 'Google Cloud Console'
}, {
  href: 'https://appcenter.ms/apps',
  imgSrc: 'https://img.icons8.com/color/48/000000/visual-studio-code-2019.png',
  label: 'MS App Center'
}];

class Dashboards extends Component {

  render() {

    return (
      <GridContainer>
        <GridItem xs={5} sm={4} md={4} lg={3}>
          {links.map(link => (
            <DashboardLink
              key={link.href}
              {...link}
            />
          ))}
        </GridItem>
      </GridContainer>
    );
  }
}

export default Dashboards;