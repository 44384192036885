import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControlLabel from '@material-ui/core/FormControlLabel/index';
import CheckboxMU from '@material-ui/core/Checkbox/index';
import style from '../../../../assets/jss/material-dashboard-react/components/details';


class Checkbox extends React.Component {
  state = {
    isDirty: false,
  };


  handleFocus = id => (event) => {
    const {
      value,
      handleChangeCheckbox,
      name: field,
      required,
    } = this.props;
    const { isDirty } = this.state;
    const row = !value && required ? { field, status: false } : {};
    if (!isDirty) {
      this.setState({ isDirty: true });
    }
    handleChangeCheckbox(id, row, event);
  };

  render() {
    const { isDirty } = this.state;
    const {
      label,
      id,
      value,
      required,
      classes,
    } = this.props;
    return (
      <React.Fragment>
            <FormControlLabel
              control={
                <CheckboxMU
                  checked={value}
                  onChange={this.handleFocus(id)}
                  value={ id }
                />
              }
              label={label}
            />
              {
                isDirty && !value && required
                && <label className={classes.error}>{label} is required</label>
              }
      </React.Fragment>
    );
  }
}

Checkbox.propTypes = {
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool,
  handleChangeCheckbox: PropTypes.func,
  id: PropTypes.string,
  required: PropTypes.bool,
  handleRequiredFields: PropTypes.func,
};

Checkbox.defaultProps = {
  label: '',
  value: false,
  handleChangeCheckbox: () => null,
  id: '',
  required: false,
  handleRequiredFields: () => null,
};

export default withStyles(style)(Checkbox);
