import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import customerInfoStyle  from './customerInfoStyle';

const LabelDescription = ({ label, description, classes }) => {
  console.log(`LabelDescription.render label=${label} desc=${description}`);
  return (
    <div className={classes.headerForRowFlex}>
      <span className={classes.labelContainer}>
        <label className={classes.labelInput}>{label}</label>
      </span>

      <label className={classes.labelDesc}>{description}</label>
    </div>
  );
};

export default withStyles(customerInfoStyle)(LabelDescription);