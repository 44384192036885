import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { NavLink } from 'react-router-dom';
import Button from '../../CustomButtons/Button';
import Img from 'react-image';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { withTableRow } from "./withTableRow";
import { getSmallImageFilename, getImage } from '../../../../utils/imageUtils';
import _ from "lodash";

class TableRowPromotion extends Component {
  state = {
    thumbnailImageFileName: '',
  };

  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
  };

  async getThumbnailImage() {
    if (this.mounted) {
      const { el, imageNamePrefix } = this.props;
      const { imageFileName, image = {}, id } = el;
      const imageName = (!_.isEmpty(image)) ? `${imageNamePrefix}${image.fileName}`: imageFileName;
      const thumbnailImageFileName = getSmallImageFilename(imageName);
      const thumbnailImage = await getImage(`promotionImages/${thumbnailImageFileName}`);
      this.setState(prevState => ({
        ...prevState,
        thumbnailImage,
      }));
    }
  }

  async componentDidMount() {
    this.mounted = true;
    await this.getThumbnailImage();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  async componentDidUpdate(prevProps, prevState, context) {
    const { el } = this.props;

    if (!_.isEqual(el, prevProps.el)) {
      await this.getThumbnailImage();
    }
  }

  render() {
    const { thumbnailImage = '' } = this.state;
    const {
      el,
      pathname,
      onClick,
      onDelete,
    } = this.props;
    const {
      id,
      title,
      creationDate,
      startDate,
      endDate,
      primaryImage,
      allowDelete,
    } = el;

    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '86px'}}
        onClick={onClick}
      >
        <TableCell>{id}</TableCell>
        <TableCell style={{padding: '2px'}}>
          <Img
            src={thumbnailImage}
            style={{
              width: 'auto',
              height: '65px',
            }}
          />
        </TableCell>
        <TableCell align="left">{title}</TableCell>
        <TableCell align="left">{startDate}</TableCell>
        <TableCell align="left">{endDate}</TableCell>
        <TableCell style={{minWidth: 170}}>
          <NavLink to={`${pathname}/${id}`}>
            <Button color="primary" size="sm">Edit</Button>
          </NavLink>
          {allowDelete && (
            <Button
              color="primary"
              size="sm"
              style={{marginLeft: '10px'}}
              onClick={onDelete}
            >
              Delete
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  }
}

const _TableRowPromotion = withTableRow(TableRowPromotion);
export { _TableRowPromotion as TableRowPromotion };