import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { UsersConsumer } from './usersProvider';

export const withUsers = (Component) => {
  class ComponentWithUsers extends React.Component {
    static displayName = `withUsers(${Component.displayName || Component.name})`;

    render() {
      const { navigation, onRef } = this.props;

      return (
        <UsersConsumer>
          {({
              users,
              getUserById,
              getDeviceNameByUserId,
              deleteUserById,
              getFilteredUsers,
              setActive,
              setGroups,
            }) => (
            <Component
              {...this.props}
              navigation={navigation}
              ref={onRef}
              users={users}
              getUserById={getUserById}
              getDeviceNameByUserId={getDeviceNameByUserId}
              deleteUserById={deleteUserById}
              getFilteredUsers={getFilteredUsers}
              setCustomersProviderActive={setActive}
              setUserGroups={setGroups}
            />
          )}
        </UsersConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithUsers, Component);
};
