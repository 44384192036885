import React, { Component, Fragment } from 'react';
import { Draggable } from "react-smooth-dnd";
import SnackbarContent from '../Snackbar/SnackbarContent';

export class ImageInfoView extends Component {
  constructor(props) {
    super(props);

    this.onClickDelete = this.onClickDelete.bind(this);
    this.onClickSelect = this.onClickSelect.bind(this);
  }

  onClickDelete() {
    const { onDeleteClick, imageInfo } = this.props;

    if (onDeleteClick) {
      onDeleteClick(imageInfo);
    }
  }

  onClickSelect() {
    const { onSelect, imageInfo } = this.props;

    if (onSelect) {
      onSelect(imageInfo);
    }
  }

  render() {
    const {
      selected,
      imageInfo,
    } = this.props;
    const { fileName = '', ref, progress } = imageInfo;
    // console.log('ImageInfoView.render progress=', progress, ' iamge = ', JSON.stringify(imageInfo));

    return (
      <Draggable>
        <SnackbarContent
          message={ fileName }
          progress={ progress }
          onCLickDelete={ this.onClickDelete }
          selectCurrentImage={ this.onClickSelect }
          reference={ ref }
          selected={ selected }
          close
        />
      </Draggable>
    )
  }
}