import React, { Fragment } from 'react';
import Modal from 'react-responsive-modal';
import _ from 'lodash';
import * as moment from 'moment';
import Firebase from '../../../components/Firebase';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { getMandatoryBlankFields } from '../../../utils/layoutEngine/parser';
import { config } from '../../../config';
import {
  withCampaigns,
  withIssuers,
  withSite,
} from '../../../contextApi';
import Input from '../../../components/Engine/Common/Input/Input.jsx'
import DatePicker from "../../../components/Engine/Common/DatePicker/DatePicker";
import Select from '../../../components/Engine/Common/Select/Select';
import Switch from '../../../components/Engine/Common/Switch/Switch';
import {
  getDateFromFirebase,
  getMallOpeningTime,
  getMallClosingTime,
  toDate,
} from '../../../utils/date';
import HeaderWithDescription from '../../../components/Engine/General/HeaderWithDescription/HeaderWithDescription';
import rundef from "rundef";
import EntityEditor from '../../EntityEditor';

const { uuid } = Firebase;
const minDate = new Date(2019, 0, 0); // type === date ? getMinDate(data, fromDate) : undefined;

class CampaignInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalMessage: undefined,
      modalOpen: false,
    };

    this.getEntityDocument = this.getEntityDocument.bind(this);
  }

  static getIdFromPath = (status = '') => {
    const { href } = window.location;
    return href.split('/').pop();
  };

  static getNewCampaign({ id: siteId, name: siteName }) {
    const endDate = getMallClosingTime(moment().add(7, 'days').toDate());
    const startDate = getMallOpeningTime(new Date());
    const expirationDate = moment().add(7, 'days').toDate();

    return {
      id: uuid(),
      siteId,
      active: false,
      startDate,
      endDate,
      title: undefined,
      appliesTo: undefined,
      issueCriteria: {
        type: 'ALL_CUSTOMERS',
        priority: 'PROXIMITY',
      },
      issuer: { // For now the only valid issuer is the mall. TODO : issuer management & associate merchants
        id: siteId,
        name: siteName,
      },
      voucher: {
        startDate: new Date(),
        expirationDate,
      }
    }
  }

  static getCampaign(id, { getMerchantById, site }) {
    return (id === 'add') ?
      CampaignInfo.getNewCampaign(site) :
      { ...getMerchantById(id) };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getCampaignById } = nextProps;

    if (!prevState.campaign) {
      const id = CampaignInfo.getIdFromPath();
      // Omit read only properties
      const { creationDate, ...campaign } = CampaignInfo.getCampaign(id, nextProps);
      return {
        ...prevState,
        campaign,
      }
    }
    return {
      ...prevState,
    }
  }

  onSelectChange = (event, data) => {
    const key = _.get(event, 'target.name');
    const value = data.key;

    if (key) {
      this.updateState(key, value);
    }
  };

  onInputChange = (event, data) => {
    const { key, value } = data;

    if (key) {
      this.updateState(key, value);
    }
  };

  onActiveChanged = (key) => {
    const { campaign } = this.state;
    const { active } = campaign;

    if (key) {
      this.updateState(key, !active);
    }
  };

  updateState(key, value) {
    this.setState(prevState => {
      const campaign = {...prevState.campaign};
      _.set(campaign, key, value);

      return {
        ...prevState,
        campaign,
      };
    });
  }

  onDateFieldChange = (value, key) => {
    const date = (value instanceof Date) ? value : value.toDate();

    if (date) {
      this.updateState(key, date);
    }
  };

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  static getIssuerName(issuer, issuers) {
    if (issuer.id === 'plaza-low-yat') {
      return 'Plaza Low Yat';
    }
    return issuers.find(x => x.id === issuer.id).name;
  }

  getEntityDocument() {
    const { campaign } = this.state;
    const { issuers } = this.props;
    const { startDate, endDate, voucher, issuer } = campaign;
    const { startDate: voucherStartDate, expirationDate: voucherExpirationDate, discount = {}, conditions = {} } = voucher;
    const { amount } = discount;
    const { minSpend } = conditions;

    const campaignDocument = {
      ...campaign,
      startDate: toDate(startDate),
      endDate: toDate(endDate),
      issuer: {
        id: issuer.id,
        name: CampaignInfo.getIssuerName(issuer, issuers),
      },
      voucher: {
        ...campaign.voucher,
        startDate: getMallOpeningTime(voucherStartDate),
        expirationDate: getMallClosingTime(voucherExpirationDate),
        discount: {
          amount: parseFloat(amount),
        },
        ...(!_.isEmpty(minSpend) && {
          conditions: {
            minSpend: parseFloat(minSpend),
          }
        })
      }
    };

    const result = rundef(
      campaignDocument,
      true,    // mutate - whether to mutate the original object or return a new one
      true,      // recursive - whether to apply recursively
    );

    return result;
  }

  getNeededFields = (data) => {
    const { selectedDealTypeKey } = data;
    return getMandatoryBlankFields(data, _.camelCase(selectedDealTypeKey));
  };

  render() {
    const { issuers } = this.props;
    const { campaign = {} } = this.state;
    const {
      id,
      active,
      title,
      startDate,
      endDate,
      issuer = {},
      voucher = {},
      totals = {},
      issueCriteria = {},
      appliesTo,
    } = campaign;
    const _startDate = getDateFromFirebase(startDate);
    const _endDate = getDateFromFirebase(endDate);
    const voucherStartDate = getDateFromFirebase(voucher.startDate);
    const voucherExpirationDate = getDateFromFirebase(voucher.expirationDate);
    const { discount = {}, conditions = {} } = voucher;
    const { amount } = discount;
    const { minSpend } = conditions;
    const { created } = totals;
    const { type, priority } = issueCriteria;
    const issuersList = [{key: '0', value: 'Plaza Low Yat'}, ...issuers.map(x => ({
      key: x.id,
      value: x.name,
    }))];

    return (
      <EntityEditor
        getEntityDocument={this.getEntityDocument}
        collection={Firebase.campaignsRef}
        createEndpoint="/campaign/create"
        cancelHref="/admin/campaigns"
        entityName="campaign"
        overlayText="Saving Campaign"
        id={id}
        showDelete={false}
        useUpdate={true}
      >
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <HeaderWithDescription
              title="Campaign Details"
              description=""
              economyPlace={false}
            />
            {/*<Select*/}
              {/*label="Issuer"*/}
              {/*name="issuer.id"*/}
              {/*changeSelect={this.onSelectChange}*/}
              {/*data={issuersList}*/}
              {/*value={issuer.id}*/}
            {/*/>*/}
            <Input
              id='title'
              name='Campaign Title'
              value={title}
              onChange={this.onInputChange}
              placeholder='e.g: Low Yat Anniversary Campaign'
            />
            <Input
              id="totals.created"
              name="Number of vouchers to issue"
              value={created}
              onChange={this.onInputChange}
              type="number"
            />
            <DatePicker
              label="Commence issuing on"
              id="startDate"
              onChange={this.onDateFieldChange}
              value={_startDate}
              minDate={minDate}
              includeTime={true}
            />
            <DatePicker
              label="Terminate issuing on"
              id="endDate"
              onChange={this.onDateFieldChange}
              value={_endDate}
              minDate={_startDate}
              includeTime={true}
            />
            <Select
              label="Issue to"
              name="issueCriteria.type"
              changeSelect={this.onSelectChange}
              data={config.voucherIssueCriteriaTypes}
              // handleRequiredFields={generalData.handleRequiredFields}
              value={type}
            />
            <Select
              label="Prioritise"
              name="issueCriteria.priority"
              changeSelect={this.onSelectChange}
              data={config.voucherIssueCriteriaPriority}
              // handleRequiredFields={generalData.handleRequiredFields}
              value={priority}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
            <HeaderWithDescription
              title="Voucher Details"
              description=""
              economyPlace={false}
            />
            <Input
              id="voucher.discount.amount"
              name="Amount"
              value={amount}
              onChange={this.onInputChange}
              type="currency"
            />
            <Input
              id="voucher.conditions.minSpend"
              name="Min Spend"
              value={minSpend}
              onChange={this.onInputChange}
              type="currency"
            />
            <DatePicker
              label="Voucher Valid From Date"
              id="voucher.startDate"
              onChange={this.onDateFieldChange}
              value={voucherStartDate}
              minDate={minDate}
              required={true}
            />
            <DatePicker
              label="Voucher Expiration Date"
              id="voucher.expirationDate"
              onChange={this.onDateFieldChange}
              value={voucherExpirationDate}
              minDate={voucherStartDate}
            />
            <Input
              id='appliesTo'
              name='Applies to'
              value={appliesTo}
              onChange={this.onInputChange}
              placeholder='e.g: Valid on any PC or Laptop'
            />
            <Switch
              value={active}
              name="Active"
              handleChecked={this.onActiveChanged}
            />
          </GridItem>
        </GridContainer>
      </EntityEditor>
    );
  }
}

export default withSite(withIssuers(withCampaigns(CampaignInfo)));
