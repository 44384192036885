import React, { Component } from 'react';
import { config } from '../../../config';
import Grid from '../../../components/Grid/Grid';
import Header from '../../../components/Campaign/Header/Header';
import {
  withCampaigns,
  withVouchers,
} from '../../../contextApi';
import { TableRowCampaign } from "../../../components/Common/Table/rows";
import { formatDateFromFirebase } from "../../../utils/date";
import { toTitleCase } from "../../../utils/stringUtils";

const curPage = config.pages.deals;
const tableHead = [
  // 'Campaign Id',
  'Title',
  'Issuer',
  'Issue Criteria',
  '#Created',
  '#Issued',
  '#Redeemed',
  'Creation Date',
  'Start Date',
  'End Date',
  'Actions',
];

class Campaigns extends Component {
  state = {
    tableData: [],
    filters: {},
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    console.log('Campaigns.componentDidMount');
    const { setCampaignsProviderActive } = this.props;
    setCampaignsProviderActive();
  }

  static getTableRowData(campaign, getCampaignTotals) {
    const {
      id,
      title,
      creationDate: creation,
      startDate: start,
      endDate: end,
      issueCriteria = {},
      issuer = {},
    } = campaign;
    const totals = getCampaignTotals({
      campaignId: id,
    });
    totals.created = campaign.totals.created;

    const startDate = formatDateFromFirebase(start);
    const endDate = formatDateFromFirebase(end);
    const creationDate = formatDateFromFirebase(creation);
    return {
      id,
      totals,
      title,
      startDate,
      endDate,
      creationDate,
      issuer: issuer.name,
      criteria: toTitleCase(issueCriteria.type),
      start_date_sort: (start) ? start.seconds : undefined,
      end_date_sort: (end) ? end.seconds : undefined,
      creation_date_sort: (creation) ? creation.seconds : undefined,
    };
  };

  getData(filters) {
    const { getCampaignTotals } = this.props;
    const { campaigns } = this.props;
    const tableData = campaigns.map(campaign => {
      return Campaigns.getTableRowData(campaign, getCampaignTotals);
    });

    return tableData;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { campaigns, getCampaignTotals } = nextProps;
    // const filteredDeals = getFilteredDeals(filters);
    const tableData = campaigns.map(campaign => {
      return Campaigns.getTableRowData(campaign, getCampaignTotals);
    });
    // const merchants = getFilteredMerchants({ deals, active });

    return {
      ...prevState,
      tableData,
      campaigns,
    };
  }

  activeHandle = async () => {
    this.setState((prevState) => {
      const { type, merchantId } = prevState;
      const active = !prevState.active;
      const tableData = this.getData({
        type,
        merchantId,
        active,
      });

      return {
        ...prevState,
        active,
        tableData,
      };
    });
  };

  handleChangeSelector = async (event, data) => {
    const { name } = event.target;
    const { key, value } = data;

    this.setState((prevState) => {
      const {
        type,
        merchantId,
        active,
      } = prevState;
      const tableData = this.getData({
        type,
        merchantId,
        active,
        [name]: key || value,
      });
      return {
        ...prevState,
        [name]: key || value,
        tableData,
      };
    });
  };

  onGridRowClick = (campaignId) => {
    this.setState({ filters: { campaignId } });
  };

  renderRow(item) {
    const { location, getCampaignTotals } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowCampaign
        el={item}
        pathname={pathname}
        key={id}
        onClick={this.onGridRowClick}
      />
    );
  }

  render() {
    const {
      tableData,
      filters,
    } = this.state;
    const { location, getCampaignTotals } = this.props;
    const { pathname } = location;
    const totals = getCampaignTotals(filters);
    totals.created = tableData.reduce((prevValue, row) => prevValue + row.totals.created, 0);

    return (
      <div>
        <Header
          page={curPage}
          totals={totals}
          // type={type}
          // types={types}
          // active={active}
          // merchantId={merchantId}
          // merchants={merchants}
          // activeHandle={this.activeHandle}
          // handleChangeSelector={this.handleChangeSelector}
        />
        <Grid
          tableHead={tableHead}
          tableData={tableData}
          name={config.tableName.campaigns}
          pathname={pathname}
          count={tableData.length}
          onTableRowClick={this.onGridRowClick}
          renderRow={this.renderRow}
        />
      </div>
    );
  }
}

export default withVouchers(withCampaigns(Campaigns));
