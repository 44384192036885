import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Img from 'react-image';
import Lightbox from 'react-images';

class Image extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      src: undefined,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { currentImage } = nextProps;
    console.log(`Image.getDerivedStateFromProps() currentImage=${JSON.stringify(currentImage)}`);
    if (currentImage) {
      const src = (typeof currentImage === 'string') ? currentImage : currentImage.ref;
      console.log(`Image.getDerivedStateFromProps() src=${src}`);
      return {
        ...prevState,
        src,
      }
    }
    return prevState;
  }

  handleImage = () => {
    const { isOpen } = this.state;
    this.setState({ isOpen: !isOpen });
  };

  render() {
    const { src } = this.state;
    console.log(`Image.render() src=${src}`);

    return (
      <React.Fragment>
        { src && (
          <Img
            src={src}
            style={{
              width: 'inherit',
              cursor: 'zoom-in',
            }}
            onClick={this.handleImage}
          />
        )}
        { src && (
          <Lightbox
            images={[{ src }]}
            isOpen={this.state.isOpen}
            onClose={this.handleImage}
            width={2560}
          />
        )}
      </React.Fragment>
    );
  }
}

Image.propTypes = {
  // currentImage: PropTypes.string,
};

export default Image;
