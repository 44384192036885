import React, { Component } from "react";
import MetisMenu from 'react-metismenu';
import { withRouterInnerRef } from "../../contextApi";
import withStyles from "@material-ui/core/styles/withStyles";
import SideBarLink from './SideBarLink';
import './standart.less';

import sidebarStyle
  from './sideBarMenuStyle.jsx';

const menu1 = [
  {
    id: 1,
    icon: 'dashboard',
    label: 'Menu 1',
    to: '#menu-1',
  },
  {
    id: 2,
    icon: 'bell',
    label: 'Menu 2',
    to: '#menu-2',
  },
  {
    id: 3,
    icon: 'bolt',
    label: 'Menu 3',
    content: [
      {
        id: 4,
        icon: 'bolt',
        label: 'Test',
        to: '#test',
      },
    ],
  },
  {
    id: 5,
    icon: 'bars',
    label: 'Menu 4',
    content: [
      {
        id: 6,
        icon: 'bold',
        label: 'Sub Menu 1',
        to: '#sub-menu-1',
      },
      {
        id: 7,
        icon: 'italic',
        label: 'Sub Menu 2',
        content: [
          {
            id: 8,
            icon: 'cog',
            label: 'Level 3 Menu 1',
            to: '#level-3-menu-1',
          },
          {
            id: 9,
            icon: 'group',
            label: 'Level 3 Menu 2',
            to: '#level-3-menu-2',
          },
        ],
      },
      {
        id: 10,
        icon: 'image',
        label: 'Sub Menu 3',
        content: [
          {
            id: 11,
            icon: 'cog',
            label: 'Level 3 Menu 3',
            to: '#level-3-menu-3',
          },
          {
            id: 12,
            icon: 'group',
            label: 'Level 3 Menu 4',
            to: '#level-3-menu-4',
          },
        ],
      },
      {
        id: 13,
        icon: 'check',
        label: 'Sub Menu 4',
        to: '#sub-menu-4',
      },
    ],
  },
  {
    id: 14,
    icon: 'external-link',
    label: 'External Link',
    externalLink: true,
    to: 'https://www.google.com',
  },
];

class SideBarMenu extends Component {

  render() {
    const { classes } = this.props;
    const content=[
      {
        icon: 'group',
        label: 'Label of Item',
        to: '#a-link',
      },
      {
        icon: 'icon-class-name',
        label: 'Second Item',
        content: [
          {
            icon: 'icon-class-name',
            label: 'Sub Menu of Second Item',
            to: '#another-link',
          },
        ],
      },
    ];

    return (
      // <div></div>
      <MetisMenu
        content={menu1}
        classNameItem={classes.item}
        classNameLink={classes.link}
        activeLinkFromLocation
        classNameItemHasVisibleChild={classes.open}
        // LinkComponent={SideBarLink}
      />
    )
  }
}

export default withStyles(sidebarStyle)(SideBarMenu);