import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { Redirect } from 'react-router-dom';
import Button from '../../components/Common/CustomButtons/Button';
import Logo from '../../assets/img/ply-icon.png';
import style from '../../assets/jss/material-dashboard-react/views/loginStyle';
import { AuthConsumer } from '../../providers/AuthProvider';

const getInitialRoute = (authMerchants = []) => {
  const route = (authMerchants.length) ? 'deals' : 'customers';
  return `/admin/${route}`;
};

function Login({ ...props }) {
  const {
    classes,
  } = props;

  return (
    <AuthConsumer>
      {({ isAuth, login, loginAttempt, authMerchants }) => (
        <div>
          <div className={classes.mainRoot}>
            <div className={classes.root}>
              <img className={classes.image} src={Logo} alt="logo"/>
              <Button onClick={login} color={'primary'}>
                Sign In with Google
              </Button>
              {loginAttempt && (
                <span>Unauthorised user</span>
              )}
            </div>
          </div>
          {isAuth && (
            <Redirect to={getInitialRoute(authMerchants)} />
          )}
        </div>
      )}
    </AuthConsumer>
  );
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(style)(Login);
