import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from "react-router-dom";
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from '../../CustomButtons/Button';

export class TableRowEmployee extends Component {
  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
  };

  render() {
    const { el, pathname, onClick } = this.props;
    const {
      id,
      employee_id,
      email,
      name,
      access,
      last_accessed,
    } = el;
    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '40px'}}
        onClick={onClick}
      >
        <TableCell>{employee_id}</TableCell>
        <TableCell align="left">{name}</TableCell>
        <TableCell align="left">{email}</TableCell>
        <TableCell align="left">{access}</TableCell>
        <TableCell align="left">{last_accessed}</TableCell>
        <TableCell style={{maxWidth: 120}}>
          <NavLink to={`employees/${id}`}>
            <Button color="primary" size="sm">Edit</Button>
          </NavLink>
        </TableCell>
      </TableRow>
    );
  }
}
