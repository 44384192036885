import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from "react-router-dom";
import _ from 'lodash';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Button from '../../CustomButtons/Button';
import { confirmAlert } from "react-confirm-alert";

const VACANT_TEXT = '< Vacant >';

export class TableRowLot extends Component {
  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
  };

  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  onDelete({ id, name }) {
    console.log('deleting lot', id);
    confirmAlert({
      title: 'Confirm to delete',
      message: `Are you sure you wish to delete lot ${name}`,
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id),
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  handleDelete(id) {
    const { deleteLot } = this.props;

    deleteLot(id);
  }

  render() {
    const { el, pathname, onClick } = this.props;
    if (_.isEmpty(el)) {
      debugger;
    }
    const {
      id,
      name,
      level,
      merchant = {},
      last_allocated,
    } = el;
    const hasTenant = !_.isEmpty(merchant);
    const { id: merchantId, name: tenant } = merchant;
    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '40px'}}
        onClick={onClick}
      >
        <TableCell>{id}</TableCell>
        <TableCell align="left">{level}</TableCell>
        <TableCell align="left">
          { hasTenant ? <Link to={`/admin/merchants/${merchantId}`}>{tenant}</Link> : <p>{VACANT_TEXT}</p> }
        </TableCell>
        <TableCell style={{maxWidth: 120}}>
          {/* No editing of lots. Create and delete only */}
          {/*<NavLink to={`lots/${id}`}>*/}
          {/*  <Button color="primary" size="sm">Edit</Button>*/}
          {/*</NavLink>*/}
          {/*Only display delete button if no merchant tenanted to this lot*/}
          {!hasTenant && (
            <Button
              color="primary"
              size="sm"
              style={{marginLeft: '10px'}}
              onClick={() => this.onDelete({ id, name })}
            >
              Delete
            </Button>
          )}
        </TableCell>
      </TableRow>
    );
  }
}
