const customFiltersStyle = {
  upperText: {
    textTransform: 'uppercase',
  },
  root: {
    width: '50%',
  },
};

export default customFiltersStyle;
