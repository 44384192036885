import React, { createRef, Component } from "react";
import PropTypes from "prop-types";
import _ from 'lodash';
// import LoadingOverlay from 'react-loading-overlay';
// import ScaleLoader from 'react-spinners/ScaleLoader';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "./GridItem";
import GridContainer from "./GridContainer";
import Table from "../Common/Table/Table";
import Card from '../Common/Card/Card';
import CardHeader from '../Common/Card/CardHeader';
import CardBody from '../Common/Card/CardBody';
import { withRouter } from 'react-router-dom';
import * as queryString from 'query-string';
import { CSVLink, CSVDownload } from 'react-csv';

import styles from '../../assets/jss/material-dashboard-react/components/grid';
import Icon from '@material-ui/core/Icon';

class Grid extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    tableHead: PropTypes.arrayOf(PropTypes.string).isRequired,
    tableData: PropTypes.array,
    pathname: PropTypes.string,
    count: PropTypes.number,
    rowsPerPage: PropTypes.number,
    page: PropTypes.number,
    handleChangePage: PropTypes.func,
    handleChangeRowsPerPage: PropTypes.func,
  };

  state = {
    page: 0,
    rowsPerPage: 20,
  };

  constructor(props) {
    super(props);

    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
  }

  componentDidMount() {
    const { history, location } = this.props;
    const { listen } = history;

    this.unlisten = listen((location, action) => {
      this.setGridParamsFromQueryStrings(location.search);
    });

    this.setGridParamsFromQueryStrings(location.search);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  setGridParamsFromQueryStrings(search) {
    const qs = queryString.parse(search);

    this.setState(prevState => ({
      ...prevState,
      ...qs,
    }));
  }

  handleChangePage(event, value) {
    this.handleParamChange('page', value);
  }

  handleChangeRowsPerPage(event) {
    const value = _.get(event, 'target.value');
    this.handleParamChange('rowsPerPage', value);
  }

  handleParamChange(param, value) {
    const { location, pathname, history } = this.props;
    const { search } = location;
    const qs = queryString.parse(search);
    qs[param] = value;
    const queryStrings = '?' + queryString.stringify(qs);
    const newRoute = `${pathname}${queryStrings}`;

    history.push(newRoute);
  }

  render() {
    // const spinnerStyle = {
    //   position: 'absolute',
    //   left: '50%',
    //   top: '50%',
    //   transform: 'translate(-50%, -50%)',
    // };
    const {
      classes,
      name,
      description,
      tableHead,
      tableData,
      pathname,
      count,
      // rowsPerPage,
      // page,
      // handleChangePage,
      // handleChangeRowsPerPage,
      onTableRowClick,
      renderRow,
    } = this.props;
    const {
      page,
      rowsPerPage,
      overlayActive,
    } = this.state;

    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary" style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <h4 className={classes.cardTitleWhite}>{name}</h4>
              <div style={{ textAlign: 'right', alignSelf: 'center', marginLeft: 'auto', color: 'white' }}>
                <CSVLink style={{color: 'white'}} data={tableData || []}>
                  <Icon>cloud_download</Icon>
                </CSVLink>
              </div>
              {/*<p className={classes.cardCategoryWhite}>*/}
                {/*{description}*/}
              {/*</p>*/}
            </CardHeader>
            <CardBody>
              {/*<ScaleLoader*/}
                {/*css={spinnerStyle}*/}
                {/*sizeUnit={"px"}*/}
                {/*size={150}*/}
                {/*loading={overlayActive}*/}
              {/*/>*/}
                <Table
                  tableHeaderColor="primary"
                  tableHead={tableHead}
                  tableData={tableData}
                  pathname={pathname}
                  count={count}
                  rowsPerPage={parseInt(rowsPerPage)}
                  page={parseInt(page)}
                  name={name}
                  handleChangePage={this.handleChangePage}
                  handleChangeRowsPerPage={this.handleChangeRowsPerPage}
                  onTableRowClick={onTableRowClick}
                  renderRow={renderRow}
                  onRef={ref => this.tableRef = ref}
                />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withRouter(withStyles(styles)(Grid));
