import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { EmployeesConsumer } from './employeesProvider';

export const withEmployees = (Component) => {
  class ComponentWithEmployees extends React.Component {
    static displayName = `withEmployees(${Component.displayName || Component.name})`;

    render() {
      const { onRef } = this.props;

      return (
        <EmployeesConsumer>
          {({
              employees,
              getEmployeeById,
              deleteEmployeeById,
              getFilteredEmployees,
              getOperator,
              isAdministrator,
              setActive,
            }) => (
            <Component
              {...this.props}
              ref={onRef}
              employees={employees}
              getEmployeeById={getEmployeeById}
              deleteEmployeeById={deleteEmployeeById}
              getFilteredEmployees={getFilteredEmployees}
              getOperator={getOperator}
              isAdministrator={isAdministrator}
              setEmployeesProviderActive={setActive}
            />
          )}
        </EmployeesConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithEmployees, Component);
};
