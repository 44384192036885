const customTotalsStyle = {
  mainRoot: {
    top: 0,
    height: '100vh',
    position: 'relative',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'inherit',
  },
  image: {
    width: 300,
    height: 'auto',
    marginBottom: 20,
  },

};

export default customTotalsStyle;
