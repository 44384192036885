import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import {
  CampaignsProvider,
  DealsProvider,
  MerchantsProvider,
  PromotionsProvider,
  ReceiptsProvider,
  ReimbursementsProvider,
  EmployeesProvider,
  UsersProvider,
  VouchersProvider,
  IssuersProvider,
  NotificationsProvider,
  SiteVisitsProvider,
  SiteProvider,
  SiteConsumer,
} from "../contextApi";
import Admin from './Admin';

class AuthorisedRoutes extends Component {
  render() {
    const { authMerchants } = this.props;
console.log('authMerchants = ', JSON.stringify(authMerchants));
    return (
      <SiteProvider>
        <SiteConsumer>
          {({ site }) => (
            <CampaignsProvider site={site}>
              <MerchantsProvider site={site} authMerchants={authMerchants}>
                <DealsProvider site={site} authMerchants={authMerchants}>
                  <VouchersProvider site={site}>
                    <SiteVisitsProvider>
                      <PromotionsProvider site={site}>
                        <UsersProvider site={site}>
                          <ReceiptsProvider site={site}>
                            <ReimbursementsProvider site={site}>
                              <NotificationsProvider site={site}>
                                <IssuersProvider site={site}>
                                  <EmployeesProvider site={site}>
                                    <Admin authMerchants={authMerchants} />
                                  </EmployeesProvider>
                                </IssuersProvider>
                              </NotificationsProvider>
                            </ReimbursementsProvider>
                          </ReceiptsProvider>
                        </UsersProvider>
                      </PromotionsProvider>
                    </SiteVisitsProvider>
                  </VouchersProvider>
                </DealsProvider>
              </MerchantsProvider>
            </CampaignsProvider>
          )}
        </SiteConsumer>
      </SiteProvider>
    );
  }
}

export default AuthorisedRoutes;
