import _uniqBy from 'lodash/uniqBy';
import { config } from '../../config';
import { getValuesFieldFromFirebase } from '../firebase';
import Firebase from '../../components/Firebase';

export const getMerchants = async (withAny = false) => {
  const { any } = config.statuses;
  try {
    const merchants = await getValuesFieldFromFirebase(
      'name',
      'merchantsRef',
      {
        uniqBy: 'name',
        orderBy: 'name',
      },
    );
    if (withAny) {
      merchants.push(any);
    }
    return merchants;
  } catch (e) {
    console.log('error', e);
    return [];
  }
};

export const getCategories = async (withAny = false) => {
  const { any } = config.statuses;
  try {
    const categories = await getValuesFieldFromFirebase(
      'categories',
      'merchantsRef',
      {
        uniqBy: 'categories',
        orderBy: 'categories',
        decompose: true,
        asObject: true,
      },
    );
    if (withAny) {
      categories.push(any);
    }
    return categories;
  } catch (e) {
    console.log('error', e);
    return [];
  }
};

export default () => null;
