import React, { Component } from 'react';
import Firebase from '../components/Firebase';
import { withImages } from './withImages';
import ft from "firebase-timestamp-utils";
import _ from "lodash";

const { promotionsRef } = Firebase;
const PromotionsContext = React.createContext();
export const PromotionsConsumer = PromotionsContext.Consumer;


class PromotionsProvider extends Component {
  state = {
    promotions: undefined,
    setActive: (...args) => this.setActive(...args),
    getPromotionById: id => this.getPromotionById(id),
    deletePromotionById: id => this.deletePromotionById(id),
    getFilteredPromotions: filters => this.getFilteredPromotions(filters),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  onSnapshot(snapshot, callback) {
    const { promotions: _promotions } = this.state;
    const { getFromSnapshot } = this.props;
    const promotions = getFromSnapshot(snapshot, _promotions);
    const mallEvents = promotions.filter(({ isMallEvent }) => isMallEvent === true);

    console.log(`PromotionsProvider.onSnapshot() promotions.length=${promotions.length} mallEvents.length=${mallEvents.length}`);

    this.setState({
      promotions,
      mallEvents,
    }, () => callback && callback());
  }

  setActive(callback) {
    if (!this.unsusbscribe) {
      const { site: { id: siteId } = {} } = this.props;
      const query = promotionsRef.where('siteId', '==', siteId);
      this.unsusbscribe = query.onSnapshot(snapshot => this.onSnapshot(snapshot, callback));
    }
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  getPromotionById(id) {
    const { promotions = [] } = this.state;
    return promotions.find(x => x.id === id);
  }

  async deletePromotionById(id) {
    await promotionsRef.doc(id)
      .delete();
  }

  getFilteredPromotions(filters = {}) {
    const { promotions = [] } = this.state;
    if (!promotions) return undefined;
    const {
      timePeriod = [],
      active,
      isMallEvent,
    } = filters;
    if (!promotions.length) return [];
    let filteredPromotions = [...promotions];
    if (typeof active === 'boolean') {
      filteredPromotions = filteredPromotions.filter(x => x.active === active);
    }
    if (typeof isMallEvent === 'boolean') {
      filteredPromotions = filteredPromotions.filter(({ isMallEvent: _isEvent = false }) => _isEvent === isMallEvent);
    }
    if (timePeriod.length) {
      // ['Expired', 'Current', 'Upcoming']
      filteredPromotions = filteredPromotions.filter(x => {
        if (timePeriod.includes('Upcoming') && ft.isFuture(x.startDate)) return true;
        if (timePeriod.includes('Expired') && ft.isPast(x.endDate)) return true;
        if (timePeriod.includes('Current') && ft.isPast(x.startDate) && ft.isFuture(x.endDate)) return true;
      })
    }

    // Default ordering by creation date
    return filteredPromotions.sort((a, b) => {
      const creationDateA = _.get(a, 'creationDate.seconds');
      const creationDateB = _.get(b, 'creationDate.seconds');
      return creationDateB - creationDateA;
    });
  }

  render() {
    const { children } = this.props;

    return (
      <PromotionsContext.Provider value={this.state}>
        {children}
      </PromotionsContext.Provider>
    );
  }
}

const _PromotionsProvider = withImages(PromotionsProvider);
export { _PromotionsProvider as PromotionsProvider };

