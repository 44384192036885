import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { withRouterInnerRef } from '../../contextApi';

class SideBarLink extends Component {
  // checkUrlIsParent(parent) {
  //   const { routes, location } = this.props;
  //   const isParent = routes.find((el) => {
  //     return el.layout + el.path === location.pathname && parent === el.parent;
  //   });
  //
  //   return isParent;
  // }

  getCurrentUrlByParent({ parent, layout, path, defaultQueryStrings }) {
    // let isParent = this.checkUrlIsParent(parent);
    // isParent = isParent === undefined ? {} : isParent;
    // console.log(`isParent = ${JSON.stringify(isParent)}`);
    // const { path, layout } = isParent;
    // const url = (layout + path) ? (layout + path) : (parentLayout + parentPath);
    const queryStrings = defaultQueryStrings ? `?${defaultQueryStrings}` : '';
    // return url;
    return `${layout}${path}${queryStrings}`;
  }

  getBadgeValue(badgeValueGetter) {
    if (badgeValueGetter) {
      const getter = this.props[badgeValueGetter];
      if (getter) {
        return getter();
      }
    }
  };

  activeRoute(routeName, parent) {
    const { location } = this.props;
    // const isParent = this.checkUrlIsParent(parent);
    return location.pathname.indexOf(routeName) > -1; //  || isParent;
  }

  render() {
    const { route, classes, color } = this.props;
    const {
      parent,
      layout,
      path,
      icon,
      name,
      badgeValueGetter,
    } = route;

    const listItemClasses = classNames({
      [` ${classes[color]}`]: this.activeRoute(layout + path, parent),
    });
    const whiteFontClasses = classNames({
      [` ${classes.whiteFont}`]: this.activeRoute(layout + path, parent),
    });

    const url = this.getCurrentUrlByParent(route);
    const badgeValue = this.getBadgeValue(badgeValueGetter);

    return (
      <NavLink
        to={url}
        className={classes.item}
        activeClassName="active"
        key={path}
      >
        <ListItem button className={classes.itemLink + listItemClasses}>
          {typeof icon === 'string' ? (
            <Icon className={classNames(classes.itemIcon, whiteFontClasses)}>
              {icon}
            </Icon>
          ) : (
            <icon className={classNames(classes.itemIcon, whiteFontClasses)} />
          )}
          <ListItemText
            primary={name}
            className={classNames(classes.itemText, whiteFontClasses)}
            disableTypography={true}
          />
          <Fragment>
            {Number.isInteger(badgeValue) && (
              <Badge className={classes.badge} color="secondary" badgeContent={badgeValue}>
                &nbsp;
              </Badge>
            )}
          </Fragment>
        </ListItem>
      </NavLink>
    );
  }
}

export default withRouterInnerRef(SideBarLink);