import React, { Component } from 'react';
import _ from 'lodash';
import {
  withEmployees,
  withMerchants,
} from '../../../contextApi';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import Input from '../../../components/Engine/Common/Input/Input.jsx';
import MultiSelect from '../../../components/Engine/Common/MultiSelect/MultiSelect';
import rundef from "rundef";
import Firebase from "../../../components/Firebase";
import EntityEditor from '../../EntityEditor';
import NavBarDropdownMenu from '../../../components/Navbars/NavBarDropdownMenu';
import MerchantMultiSelect from '../../../components/Engine/Common/MerchantMultiSelect';

class EmployeeInfo extends Component {
  state = {
    employee: {},
  };

  constructor(props) {
    super(props);

    this.getEntityDocument = this.getEntityDocument.bind(this);
  }

  static getIdFromPath = (status = '') => {
    const { href } = window.location;
    const part = href.split('/').pop();
    return decodeURIComponent(part);
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getEmployeeById, employees = [] } = nextProps;

    if (_.isEmpty(prevState.employee) && employees.length > 0) {
      const id = EmployeeInfo.getIdFromPath();
      // Omit read only properties
      const employee = (id === 'add') ? EmployeeInfo.getNewEmployee() : { ...getEmployeeById(id) };
      return {
        ...prevState,
        employee,
      }
    }

    return {
      ...prevState,
    }
  }

  static getNewEmployee(getNextId) {
    return {
      id: 'add', // We will use the email address as the id...!
      title: undefined,
    }
  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   const {
  //     merchants,
  //     getSearchedMerchants,
  //   } = nextProps;
  //   if (merchants && prevState.merchants.length === 0) {
  //     const merchantsMap = (merchant) => ({
  //       label: merchant.name,
  //       name: merchant.id,
  //     });
  //     // const merchants = nextProps.merchants.map(merchant => {
  //     //   return {
  //     //     label: id,
  //     //     name,
  //     //   }
  //     // });
  //
  //     return {
  //       ...prevState,
  //       merchantsMap,
  //     }
  //   }
  //
  //   return {
  //     ...prevState,
  //   };
  // }

  getEntityDocument() {
    const { employee } = this.state;

    const employeeDocument = {
      ...employee,
    };

    const result = rundef(
      employeeDocument,
      true,    // mutate - whether to mutate the original object or return a new one
      1,      // recursive - whether to apply recursively
    );

    if (_.isEmpty(result.merchant)) {
      delete result.merchant;
    }

    return result;
  }

  onInputChange = (event, data) => {
    // const { name } = event.target;
    let { key, value } = data;

    if (key) {
      this.updateState(key, value);
    }
  };

  updateState(key, value) {
    this.setState(prevState => {
      const employee = {...prevState.employee};
      _.set(employee, key, value);

      return {
        ...prevState,
        employee,
      };
    });
  }

  render() {
    const { deleteEmployeeById } = this.props;
    const {
      employee,
    } = this.state;
    const {
      id = '',
      google = {},
      merchants = [],
    } = employee;
    const { email, name } = google;
    return (
      <EntityEditor
        onDeleteClick={deleteEmployeeById}
        getEntityDocument={this.getEntityDocument}
        collection={Firebase.employeesRef}
        createEndpoint={`employee/create`}
        cancelHref="/admin/employees"
        entityName="employee"
        id={id}
      >
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <h3>EMPLOYEE</h3>
            <Input
              id="google.email"
              name="Email"
              value={email}
              onChange={this.onInputChange}
              flex={true}
            />
            <Input
              id="google.name"
              name="Name"
              value={name}
              onChange={this.onInputChange}
              flex={true}
            />
            {/*<MerchantSelect*/}
              {/*flex={true}*/}
              {/*value={merchant.id}*/}
              {/*changeSelect={this.onInputChange}*/}
              {/*name="Merchant"*/}
              {/*id="merchant.id"*/}
            {/*/>*/}
            <MerchantMultiSelect
              changeSelect={this.onInputChange}
              value={merchants}
            />
          </GridItem>

          <GridItem xs={12} sm={6} md={6} lg={6} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>

          </GridItem>
        </GridContainer>
      </EntityEditor>
    );
  }
}

export default withMerchants(withEmployees(EmployeeInfo));
