/**
 * Quick and Dirty attempt to rewrite the Images component
 * without drastically altering it's fundamental approach.
 * Allow different messages per screen, and include some basic common functionality
 * currently handled by (nearly) identical handlers across screens.
 *
 * Currently this only works for promotions (ie: single image management)
 * But expand this quickly to other screens so that the Images component
 * can be banished to the netherworld for eternity
 */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-smooth-dnd';
import _ from 'lodash';
import FileDropzone from '../Common/Dropzone/Dropzone';
import { ImageInfoView } from './ImageInfoView';
import ImageUpload from './ImageUpload';
import Firebase from "../Firebase";
import { confirmAlert } from "react-confirm-alert";
import arrayMove from 'array-move';
import sum from 'hash-sum';

const hash = sum;

const compareArrays = (first, second) => {
  return first.every((e) => second.includes(e)) && second.every((e) => first.includes(e));
};

class ImageManager extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedImage: {},
      images: [],
      dirtyImageDeletes: [],
      dirtyImageUploads: [],
    };

    this.onFileDrop = this.onFileDrop.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onImageSelect = this.onImageSelect.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCommit = this.handleCommit.bind(this);
    this.onCloseModal = this.onCloseModal.bind(this);
    this.onImageUploadStateChanged = this.onImageUploadStateChanged.bind(this);
  }

  onCloseModal() {
  };

  async getStorageRef(imageName) {
    const { storage } = Firebase;
    const { path } = this.props;
    const filePath = `${ path }/${ imageName }`;
    console.log('await storage child ', filePath);

    return await storage.child(filePath);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { imagesArr = [] } = nextProps;
    const { images: prevImages, selectedImage } = prevState;
    // const prevImagesNames = prevImages.map(x => x.fileName || x.name);
    // const imageNames = imagesArr.map(x => x.fileName || x.name);
    // TODO : add me back, but we need something more than image names, as progress can also change

    if (hash(prevImages) === hash(imagesArr)) {
      return prevState;
    }

    const images = imagesArr.map(x => ({
      ...x,
      fileName: x.fileName || x.name,
    }));

    let newSelectedImage = selectedImage;
    if (images.length && (_.isEmpty(selectedImage) || (!_.isEmpty(selectedImage) && !images.find(x => x.fileName === selectedImage.fileName)))) {
      newSelectedImage = { ...images[0] };
    }

    return {
      ...prevState,
      images,
      selectedImage: newSelectedImage,
    };
  }

  static getProgress(selectedImage, images) {
    const image = images.find(x => x.fileName === selectedImage.fileName) || {};
    const { progress = 100 } = image;

    return progress;
  };

  componentDidMount() {
    const { selectedImage = {} } = this.state;
    const { fileName, ref } = selectedImage;

    if (!ref && fileName) {
      this.appendRefToSelectedImage(selectedImage);
    }
  }

  async appendRefToSelectedImage() {
    const { selectedImage = {}, images = [] } = this.state;
    const { imageNamePrefix } = this.props;
    const { fileName, ref } = selectedImage;
    const progress = ImageManager.getProgress(selectedImage, images);

    if (progress === 100) {
      const imageName = `${ imageNamePrefix }${ fileName }`;
      const storageRef = await this.getStorageRef(imageName);

      if (storageRef) {
        const ref = await storageRef.getDownloadURL();
        const foundIndex = images.findIndex(x => x.fileName === fileName);
        let updatedSelectedImage = {};
        if (foundIndex >= 0) {
          images[foundIndex].ref = ref;
          updatedSelectedImage = {
            ...selectedImage,
            ref,
          };
        }

        this.setState(prevState => ({
          ...prevState,
          selectedImage: updatedSelectedImage,
          images,
        }));
      }
    }
  }

  /**
   * Check the selectedImage and ensure that this has a populated ref attribute
   * Required to render the image from firebase storage. If not present, generate one
   * @param prevProps
   * @param prevState
   * @param snapshot
   * @returns {Promise<void>}
   */
  componentDidUpdate(prevProps, prevState, prevContext) {
    const { selectedImage = {} } = this.state;
    const { fileName, ref } = selectedImage;

    if (!ref && fileName) {
      this.appendRefToSelectedImage(selectedImage);
    }
  }

  onImageUploadStateChanged(snapshot, fileName) {
    const { images } = this.state;
    const { onChange } = this.props;
    // Observe state change events such as progress, pause, and resume
    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
    const { bytesTransferred, totalBytes } = snapshot;
    let progress = (bytesTransferred / totalBytes) * 100;
    if (progress < 25) progress = 25;
    console.log('Upload is ' + progress + '% done');
    // debugger;
    switch (snapshot.state) {
      case 'paused':
        console.log('Upload is paused');
        break;
      case 'running':
        console.log('Upload is running');
        break;
    }

    const index = images.findIndex(x => x.fileName === fileName);
    let imagesArr = [...images];
    if (index >= 0) {
      imagesArr[index].progress = progress;
    } else {
      // image does not exist
      const rawImage = {
        fileName,
        progress,
      };
      imagesArr = this.getUpdatedImagesArray(rawImage);
    }

    // Find our image dude....
    // debugger;
    onChange(imagesArr);
  }

  getUpdatedImagesArray(rawImage) {
    const { dirtyImageUploads, images: prevImages } = this.state;
    const { maxNumberImages, onChange, path } = this.props;
    const { progress = 25 } = rawImage;
    let images = [...prevImages];

    if (maxNumberImages === 1) {
      images = [ rawImage ];
      // images[0].progress = progress;
      // images = [...prevImages, ...images];
      // TODO : should set State with selectedImage here. Ensure matches last touched (uploaded) image
      //
    } else {
      const image = {
        ...rawImage,
        progress,
      };

      const index = images.findIndex(x => x.fileName === image.fileName);
      if (index === -1) {
        images.push(image);
      } else {
        images[index] = image;
      }
    }

    return images;
  }

  removeImageFromArray(rawImage, prevImages2) {
    // TODO : rawImage is unused.
    const { dirtyImageUploads, images: prevImages } = this.state;
    const { maxNumberImages, onChange, path } = this.props;
    if (maxNumberImages === 1) {
      if (prevImages2.length === 1) {
        return [...prevImages2];
      }
      return [];
    }

    let images = [...prevImages];

    return images;
  }

  // /**
  //  * TODO : get a edecent fucking name for 5his method
  //  * @param prevImages
  //  * @param images
  //  */
  // getUpdatedImagesArray2(prevImages, images) {
  //   // return [...prevImages, ...images];
  //   const result = [...prevImages];
  //   images.forEach(image => {
  //     const index = result.map(x => x.fileName).findIndex(image.fileName);
  //     if (index === -1) {
  //       result.push(image);
  //     } else {
  //       result[index] = image;
  //     }
  //   });
  //
  //   return result;
  // }

  static getImageRatio(image) {
    const { width, height } = image;
    const ratio = width / height;

    return ratio;
  }

  static getImageRatioWithinBounds(ratio, { tolerancePercent, targetRatio }) {
    // const { width, height } = image;
    // const ratio = width / height;
    const lowerBound = targetRatio - (ratio * tolerancePercent / 100);
    const upperBound = targetRatio + (ratio * tolerancePercent / 100);
    console.log(`ratio=${ratio} lowerBound=${lowerBound} upperBound=${upperBound}`);
    const ratioWithinBounds = (ratio > lowerBound && ratio < upperBound);

    return ratioWithinBounds;
  }

  async saveImage(image) {
    const { dirtyImageUploads, images: prevImages } = this.state;
    const { imageNamePrefix, targetRatio, tolerancePercent, onChange } = this.props;
    const { name, path } = image;
    const fileName = name || path;
    const imageName = `${ imageNamePrefix }${ fileName }`;
    console.log(`ImageManager.saveImage imageName=${ imageName } image=${ JSON.stringify(image) }`);
    // const imagesInfo = this.getUpdatedImagesArray(image);
    let ref;

    const storageRef = await this.getStorageRef(imageName);
    const uploadTask = storageRef.put(image);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed', snapshot => this.onImageUploadStateChanged(snapshot, fileName), error => {
      // Handle unsuccessful uploads
      console.log('upload error = ', error);
    }, async () => {
      // Handle successful uploads on complete
      // For instance, get the download URL: https://firebasestorage.googleapis.com/...
      // uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
      //   console.log('File available at', downloadURL);
      // });
      const ref = await storageRef.getDownloadURL();
      const img = new Image();
      img.onload = () => {
        if (tolerancePercent) {
          const ratio = ImageManager.getImageRatio(img);
          const ratioWithinBounds = ImageManager.getImageRatioWithinBounds(ratio, { tolerancePercent, targetRatio });
          if (!ratioWithinBounds) {
            this.handleImageRatioOutOfBounds({ targetRatio, tolerancePercent, ratio });
            onChange(this.removeImageFromArray(undefined, prevImages));
            return;
          }
        }

        const { height, width } = img;
        const image = { fileName, ref, width, height, progress: 100 };
        const imagesInfo = this.getUpdatedImagesArray(image);
        onChange(imagesInfo);
      };

      // Call `resolve` even if the image fails to load. If we were to
      // call `reject`, the whole "system" would break
      img.onerror = error => {
        // resolve({
        //   ref,
        //   status: 'error',
        // });
        console.error(error);
        debugger;
      };

      img.src = ref;
    });

    // Stash these saves in case of a cancellation to allow us to delete
    if (!dirtyImageUploads.includes(imageName)) {
      dirtyImageUploads.push(imageName);
    }
  };

  handleImageRatioOutOfBounds({ targetRatio, tolerancePercent, ratio }) {
    console.log('ratio not within bounds');
    const title = 'Image dimensions out of bounds';
    const message = `Image must have a width to height ratio of ${ targetRatio } with a tolerance of ${ tolerancePercent }%.\nThis image is out of bounds with a ratio of ${ ratio }`;
    const buttons = [{
      label: 'OK',
      onClick: () => this.onCloseModal(),
    }];
    confirmAlert({ title, message, buttons });
  }

  async persistImages(images, path) {
    // const imagesInfo = [];

    const promises = images.map(image => {
      try {
        this.saveImage(image);
      } catch (error) {
        console.error(error);
      }
    });

    // wait until all promises are resolved
    await Promise.all(promises);
  }

  async deleteImage(image) {
    const { fileName } = image;
    const { dirtyImageDeletes } = this.state;

    if (!dirtyImageDeletes.includes(fileName)) {
      dirtyImageDeletes.push(fileName);
    }
  };

  async deleteImages(images) {
    const promises = images.map(async (imageName) => {
      try {
        const storageRef = await this.getStorageRef(imageName);
        console.log('deleting image fileName = ', imageName);
        await storageRef
          .delete()
          .catch(e => {
            console.log('Error deleting image', e);
          });
      } catch (error) {
        console.error(error);
      }
    });

    // wait until all promises are resolved
    await Promise.all(promises);
  }

  /**
   * Handle a cancellation
   * Delete all images which have been uploaded which are now being discarded
   * @returns {Promise<void>}
   */
  async handleCancel() {
    const { dirtyImageUploads } = this.state;

    await this.deleteImages(dirtyImageUploads);
  }

  /**
   * Handles document creation
   * Delete all images marked as delete which are now permanently erased
   * @returns {Promise<void>}
   */
  async handleCommit() {
    const { dirtyImageDeletes } = this.state;

    await this.deleteImages(dirtyImageDeletes);

    // TODO : really? surely the logic we have is plenty. Do some more testing here...!!!
    // We also wish to permanently delete any images which were uploaded and then deleted
    // ie: any images which were deleted which were NOT on the original
  }

  /**
   * @param {Array.<Image>} droppedImages
   */
  async onFileDrop(droppedImages) {
    const { removedIndex, addedIndex } = droppedImages;

    if (Array.isArray(droppedImages)) {
      this.persistImages(droppedImages);
    }

    if (Number.isInteger(removedIndex) && Number.isInteger(addedIndex)) {
      const { images } = this.state;
      const { onChange } = this.props;
      const imagesArr = arrayMove(images, removedIndex, addedIndex);
      onChange && onChange(imagesArr);
    }
  }

  async onDeleteClick(imageInfo) {
    const { onChange } = this.props;
    const { images } = this.state;
    const { fileName } = imageInfo;
    await this.deleteImage(imageInfo);
    // remove this image from the array
    try {
      const updatedImages = images.filter(item => item.fileName !== fileName);

      if (onChange) {
        onChange(updatedImages);
      }
    } catch (e) {
      console.error(e);
      debugger;
    }
  };

  onImageSelect(imageInfo) {
    this.setState({
      selectedImage: imageInfo,
    })
  }

  getInstructionalText2(imagesArr) {
    if (imagesArr.length === 1) {
      return 'Drag here or click to upload more images (up to 5)';
    }
    if (imagesArr.length > 1) {
      return 'To reorder the images, hold and drag the image boxes above';
    }
    return undefined;
  }

  render() {
    const {
      text = '',
      maxNumberImages,
    } = this.props;
    const {
      images,
      selectedImage = {},
    } = this.state;
    const boxText = `Drag and drop images into this box or click this box to upload (image formats allowed JPG, PNG).`;
    // const instructionalText = 'Drag and drop images into this box or click this box to upload (image formats allowed JPG, PNG). Upload the MAIN OFFER image first (200 x 200 pixels)';
    // const instructionalText2 = this.getInstructionalText2(imagesArr);
    console.log('ImageManager.render() :: selectedImage=' + JSON.stringify(selectedImage) + 'iamges=' + JSON.stringify(images));

    const uploadDisabled = (maxNumberImages > 1) && images.length >= maxNumberImages;

    return (
      <Fragment>
        { !images.length && (
          <FileDropzone onDrop={ this.onFileDrop }>
            <p>{ boxText }</p>
            <p>{ text }</p>
          </FileDropzone>
        ) }
        { (images.length > 0) && (
          <Fragment>
            <Container onDrop={ this.onFileDrop } style={ { width: '60%' } }>
              { images.map(image => (
                <ImageInfoView
                  key={ image.fileName || image.name || image }
                  imageInfo={ image }
                  onDeleteClick={ this.onDeleteClick }
                  onSelect={ this.onImageSelect }
                />
              )) }
            </Container>
            {/*{instructionalText2 && (*/ }
            {/*<p>{instructionalText2}</p>*/ }
            {/*)}*/ }
            <ImageUpload
              onFileDrop={ this.onFileDrop }
              uploadDisabled={ uploadDisabled }
              selectedImage={ selectedImage }
            />
          </Fragment>
        ) }
      </Fragment>
    );
  }
}

ImageManager.propTypes = {
  imagesArr: PropTypes.array,
  maxNumberImages: PropTypes.number,
  onChange: PropTypes.func,
  path: PropTypes.string.isRequired,
  imageNamePrefix: PropTypes.string.isRequired,
};

ImageManager.defaultProps = {
  imagesArr: [],
  onChange: () => {
  },
  imageNamePrefix: '',
  maxNumberImages: 1,
};

export default ImageManager;
