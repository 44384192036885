import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { getTargetValue } from '../../../../utils/layoutEngine/parser';
import ImageManager from '../../../Images/ImageManager';

class Block extends React.Component {
  render() {
    const { generalData } = this.props;
    const { data, section, economyPlace } = generalData;
    const { name, target } = section;
    const targetValues = getTargetValue(data, target);
    const { image, images } = targetValues;
    const { supposeId } = data;
    const primaryImageArr = _.isEmpty(image) ? undefined : [ image ];

    return (
      <React.Fragment>
        {name === 'images' && (
          <ImageManager
            imagesArr={images}
            // ref={this._logoImageManager}
            onChange={generalData.onChangeImages}
            path={`dealImages/${supposeId}`}
            text="100 x 100 pixels"
            maxNumberImages={3}
          />
        )}
        {name === 'primaryImage' && (
          <ImageManager
            imagesArr={primaryImageArr}
            // ref={this._logoImageManager}
            onChange={generalData.onChangePrimaryImage}
            path={`dealImages/${supposeId}`}
            text="100 x 100 pixels"
            // targetRatio={1}
            // tolerancePercent={15}
          />
        )}
      </React.Fragment>
    );
  }
}

Block.propTypes = {
  generalData: PropTypes.object,
};

Block.defaultProps = {
  generalData: {},
};

export default Block;
