import React from 'react';
import PropTypes from 'prop-types';
import HeaderWithDescription from '../HeaderWithDescription/HeaderWithDescription';
import Element from './Element';
import Block from './Block';


class Section extends React.Component {
  render() {
    const { props: generalData } = this;
    const { section, economyPlace } = generalData;
    const {
      title = '',
      description = '',
      items = [],
      isFull = false,
      withHeader = true,
    } = section;

    return (
      <React.Fragment>
        { withHeader && <HeaderWithDescription
          title={title}
          description={description}
          economyPlace={economyPlace}
        />
        }
        {isFull ? <Block generalData={generalData}/>
          : items.map(el => <Element element={el} key={el.name} generalData={generalData}/>)
        }
      </React.Fragment>
    );
  }
}

Section.propTypes = {
  section: PropTypes.object,
  data: PropTypes.object,
  handleChangeSelector: PropTypes.func,
  handleChangeInput: PropTypes.func,
  activeHandle: PropTypes.func,
  handleDateStartChange: PropTypes.func,
  handleDateEndChange: PropTypes.func,
  changeExpDate: PropTypes.func,
  handleChange: PropTypes.func,
  // handleDrop: PropTypes.func,
  deleteImageByName: PropTypes.func,
  uploadImages: PropTypes.func,
  onChangePrimaryImage: PropTypes.func,
  onChangeImages: PropTypes.func,
  selectCurrentImage: PropTypes.func,
  handleChangeState: PropTypes.func,
  economyPlace: PropTypes.bool,
};

Section.defaultProps = {
  handleChangeSelector: () => null,
  handleChangeInput: () => null,
  activeHandle: () => null,
  handleDateStartChange: () => null,
  handleDateEndChange: () => null,
  changeExpDate: () => null,
  handleChange: () => null,
  // handleDrop: () => null,
  deleteImageByName: () => null,
  uploadImages: () => null,
  onChangePrimaryImage: () => null,
  onChangeImages: () => null,
  selectCurrentImage: () => null,
  handleChangeState: () => null,
  section: {},
  data: {},
  economyPlace: true,
};

export default Section;
