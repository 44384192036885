import React, { Component } from 'react';
import _ from 'lodash';
import { TableRowCategory } from "../../components/Common/Table/rows";
import {
  withSite,
  withMerchants,
  withDeals,
} from "../../contextApi";
import ft from 'firebase-timestamp-utils';
import GridPage from "../GridPage";
import PropTypes from "prop-types";

const tableHead = [
  'Id',
  'Name',
  'Short Name',
  'Icon',
  'Number Merchants',
  'Number Deals',
  'Actions',
];

class Categories extends Component {
  static propTypes = {
    site: PropTypes.shape({
      id: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.object),
    }),
  };

  static defaultProps = {
    site: {},
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    const { setMerchantsProviderActive, setDealsProviderActive } = this.props;

    setMerchantsProviderActive();
    setDealsProviderActive();
  }

  static getTableRowData(item, { getNumberMerchantsWithCategory, getNumberDealsWithCategory }) {
    const { shortName, id } = item;
    return {
      ...item,
      number_merchants: getNumberMerchantsWithCategory(id),
      number_deals: getNumberDealsWithCategory(id),
      short_name: shortName,
    };
  };

  renderRow(item) {
    const { location, deleteCategory } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowCategory
        el={item}
        pathname={pathname}
        key={id}
        itemName="category"
        onDelete={deleteCategory}
      />
    );
  }

  render() {
    const { site: { categories } } = this.props;
    console.log('Categories.render categories=', JSON.stringify(categories));

    return (
      <GridPage
        viewName="Categories"
        itemName="Category"
        pathname="categories"
        items={categories}
        getTableRowData={item => Categories.getTableRowData(item, this.props)}
        renderRow={this.renderRow}
        tableHead={tableHead}
      />
    )
  }
}

export default withDeals(withMerchants(withSite(Categories)));

