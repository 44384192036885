import React, { Component } from 'react';
import _ from 'lodash';
import {
  withSite,
  withMerchants,
} from '../../contextApi';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import Input from '../../components/Engine/Common/Input/Input.jsx';
import rundef from "rundef";
import Firebase from "../../components/Firebase";
import EntityEditor from '../EntityEditor';
import Select from "../../components/Engine/Common/Select/Select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFaIconName, fetchIcon } from "../../utils/iconUtils";
import { isCreateMode, getIdFromPath } from '../../utils/entityUtils';
import categoryInfoStyle from './categoryInfoStyle.jsx';

const { uuid } = Firebase;

const FONT_AWESOME_GALLERY_URL = 'https://fontawesome.com/icons?d=gallery';

/**
 * Converts the category name into an id, converts to uppercase snake case
 * @param str
 * @returns {*}
 */
const getIdFromName = str => {
  return str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map(x => x.toUpperCase())
    .join('_');
};

class CategoryInfo extends Component {
  state = {
    category: {},
    iconDefinition: {},
  };

  constructor(props) {
    super(props);

    this.onSaveClick = this.onSaveClick.bind(this);
    this.onIconFetch = this.onIconFetch.bind(this);
  }

  static getCategory({ getCategoryById }) {
    return (isCreateMode()) ? CategoryInfo.getNewCategory() : getCategoryById(getIdFromPath());
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { site: { categories } } = nextProps;

    if (_.isEmpty(prevState.category) && categories.length > 0) {
      const category = CategoryInfo.getCategory(nextProps);
      return { ...prevState, category };
    }

    return { ...prevState };
  }

  componentDidMount() {
    const category = CategoryInfo.getCategory(this.props);
    const { icon: { name: iconName } = {} } = category;
    if (iconName) {
      console.log(`componentDidMount ::: fetching icon iconName=${iconName}`);
      fetchIcon(iconName, this.onIconFetch);
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { category } = this.state;
    console.log('category = ', JSON.stringify(category));
    const { iconDefinition } = prevState;
    const { icon: { name: iconName } = {} } = category;
    if (iconName && iconDefinition.iconName !== iconName) {
      console.log(`fetching icon iconName=${iconName} state.iconDefinition=${JSON.stringify(iconDefinition)}`);
      fetchIcon(iconName, this.onIconFetch);
    }
  }

  onIconFetch(iconDefinition) {
    this.setState(prevState => ({
      ...prevState,
      iconDefinition,
    }));
  };

  static getNewCategory() { return {} };

  async onSaveClick(action) {
    const { createCategory, updateCategory } = this.props;
    const { category } = this.state;
    let response;
    if (action === 'create') {
      response = await createCategory(category);
    }
    else {
      response = await updateCategory(category);
    }

    return response;
  }

  onSelectChange = (event, data) => {
    const key = _.get(event, 'target.name');
    const value = data.key;

    if (key) {
      this.updateState(key, value);
    }
  };

  onInputChange = (event, data) => {
    let { key, value } = data;

    if (key) {
      this.updateState(key, value);
    }
  };

  updateState(key, value) {
    this.setState(prevState => {
      const category = {...prevState.category};
      _.set(category, key, value);
      // If create mode and updating the name => generate the id
      if (isCreateMode() && key === 'name') {
        const { name } = category;
        const id = getIdFromName(name);
        _.set(category, 'id', id);
      }

      return {
        ...prevState,
        category,
      };
    });
  }

  render() {
    const { classes } = this.props;
    const { category, iconDefinition } = this.state;
    const { id, name, shortName, icon: { name: iconName } = {} } = category;

    console.log('iconDefinition = ', JSON.stringify(iconDefinition));
    return (
      <EntityEditor
        onSaveClick={this.onSaveClick}
        // getEntityDocument={this.getEntityDocument}
        collection={Firebase.sitesRef}
        createEndpoint={`category/create`}
        cancelHref="/admin/categories"
        entityName="category"
        id={id}
      >
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <h3>CATEGORY</h3>
            <Input
              id="name"
              name="Name"
              value={name}
              onChange={this.onInputChange}
              flex={true}
            />
            <Input
              id="shortName"
              name="Short Name"
              value={shortName}
              onChange={this.onInputChange}
              flex={true}
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
            <h3>CATEGORY</h3>
            <Input
              id="icon.name"
              name="Icon"
              value={iconName}
              onChange={this.onInputChange}
              flex={true}
            />
            {iconDefinition.icon && (
              <div className={classes.iconContainer}>
                <FontAwesomeIcon icon={iconDefinition} size="5x" />
              </div>
            )}
            <p style={{marginBottom: 0}}>Icons can be sourced from <a href={FONT_AWESOME_GALLERY_URL} target="_blank">fontawesome.com</a></p>
            <p style={{marginBottom: 0}}>Select the icon, copy the icon name by clicking it and past into the text box above</p>
          </GridItem>
        </GridContainer>
      </EntityEditor>
    );
  }
}

export default withStyles(categoryInfoStyle)(withMerchants(withSite(CategoryInfo)));
