import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Modal from 'react-responsive-modal';
import Grid from '../components/Grid/Grid';
import Header from './Header';
import sum from 'hash-sum';
// import { config } from "../config";
// import hash from 'object-hash';
// import {xxHash32} from 'js-xxhash';
// const hash = xxHash32;
// const XXHash = import('xxhash');
//
const hash = sum;

class GridPage extends Component {
  state = {
    filters: undefined,
    tableData: undefined,
    itemsHash: undefined,
    filtersHash: undefined,
    err: undefined,
    modalOpen: false,
  };

  static propTypes = {
    getTableRowData: PropTypes.func,
    pathname: PropTypes.string,
    getFilterControls: PropTypes.func.isRequired,
    getFilteredItems: PropTypes.func.isRequired,
    viewName: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    tableHead: PropTypes.arrayOf(PropTypes.string).isRequired,
    showCreateButton: PropTypes.bool.isRequired,
    filters: PropTypes.object,
  };

  static defaultProps = {
    getFilterControls: () => null,
    getFilteredItems: (filters, items) => items,
    showCreateButton: true,
    filters: {},
  };

  constructor(props) {
    super(props);

    this.onFiltersChange = this.onFiltersChange.bind(this);
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  componentDidMount() {
    const { filters } = this.props;

    if (!_.isEmpty(filters)) {
      this.setState(prevState => ({ filters }));
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { items, getFilteredItems, getTableRowData } = nextProps;

    if (items !== null) {
      const { itemsHash: prevItemsHash, tableData: prevTableData, filters } = prevState;
      const filteredItems = !_.isEmpty(filters) ? getFilteredItems(filters, items) : items;
      const tableData = filteredItems ? filteredItems.map(getTableRowData) : undefined;
      const itemsHash = filteredItems ? hash(filteredItems.sort()) : undefined;

      console.log(
        `GridPage.getDerivedStateFromProps itemsHash = ${itemsHash} /
      prevItemsHash=${prevItemsHash} /
      tableData.length=${tableData ? tableData.length : -1} /
      items.length=${items ? items.length : -1}`
      );
      if (itemsHash !== prevItemsHash) {
        return {
          ...prevState,
          itemsHash,
          tableData,
        };
      }
    }

    return prevState;
  }

  calculateTableData() {
    const { filters } = this.state;
    const { getTableRowData, getFilteredItems, items } = this.props;
    if (items) {
      const filteredItems = getFilteredItems(filters, items) || [];
      const tableData = filteredItems.map(getTableRowData);
      console.log(`GridPage.calculateTableData items.length=${items ? items.length : 'undef'} filters=${JSON.stringify(filters)} tableData.length=${tableData.length} filteredItems.length=${filteredItems.length}`);

      this.setState(prevState => {
        return {
          ...prevState,
          tableData,
        }
      });
    }
  }

  onFiltersChange(_filters) {
    const { filtersHash: prevFiltersHash } = this.state;
    const { filters: defaultFilters } = this.props;
    const filters = _.assign({}, defaultFilters, _filters);
    const filtersHash = hash(filters);
    console.log(`GridPage.onFiltersChange filtersHash=${filtersHash} prevFiltersHash=${prevFiltersHash}`);
    if (filtersHash !== prevFiltersHash) {
      this.setState(prevState => ({
        ...prevState,
        filters,
        filtersHash,
      }), this.calculateTableData);
    }
    else {
      console.log('gridPage:: componentDidUpdate :: hashes calculated - but failed');
    }
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { filters, itemsHash, tableData, filtersHash } = nextState;
    const { filters: prevFilters, tableData: prevTableData, itemsHash: prevItemsHash, filtersHash: prevFiltersHash } = this.state;
    const { items, tableHead } = nextProps;
    const { items: prevItems, tableHead: prevTableHead } = this.props;
    const itemsLength = items ? items.length : 0;
    const prevItemsLength = prevItems ? prevItems.length : undefined;
    const tableDataLength = tableData ? tableData.length : undefined;
    const prevTableDataLength = prevTableData ? prevTableData.length : undefined;
    const tableHeadLength = tableHead ? tableHead.length : undefined;
    const prevTableHeadLength = prevTableHead ? prevTableHead.length : undefined;

    const result = (
      (itemsLength !== prevItemsLength) ||
      (tableDataLength !== prevTableDataLength) ||
      (tableHeadLength !== prevTableHeadLength) ||
      (itemsHash !== prevItemsHash) ||
      (filtersHash !== prevFiltersHash)
    );

    console.log('GridPage.shouldComponentUpdate ', result, ' (tableData.length=', tableDataLength);

    return result;
  }

  render() {
    const {
      tableData,
      filters,
      errMessage,
      modalOpen,
    } = this.state;
    console.log(`GridPage.render ::: tableData.length=${tableData ? tableData.length : 'undef'}` );
    const {
      getFilterControls,
      renderRow,
      viewName,
      itemName,
      tableHead,
      pathname,
      showCreateButton,
    } = this.props;
    const count = tableData ? tableData.length : 0;
    const filterControls = getFilterControls(filters || {});

    return (
      <div>
        <Header
          onFiltersChange={ this.onFiltersChange }
          itemName={itemName}
          showCreateButton={showCreateButton}
          pathname={pathname}
        >
          {filterControls}
        </Header>
        <Grid
          tableHead={ tableHead }
          tableData={ tableData }
          name={ viewName }
          count={ count }
          renderRow={ renderRow }
          pathname={ pathname }
        />
        <Modal style={ { padding: 50 } } open={ modalOpen } onClose={ this.onCloseModal } center>
          <h2> { errMessage } </h2>
        </Modal>
      </div>
    );
  }
}

export default GridPage;
