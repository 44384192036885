import React from 'react';
// nodejs library to set properties for components
import PropTypes from 'prop-types';
// material-ui components
import withStyles from '@material-ui/core/styles/withStyles';

import styles
  from '../../../assets/jss/material-dashboard-react/components/totals.jsx';
import GridContainer from '../../Grid/GridContainer';
import GridItem from '../../Grid/GridItem';

class Row extends React.Component {
  state = {
    value: 0,
  };

  render() {
    const {
      classes,
      name,
      value,
      upperCase,
      minWidth,
    } = this.props;

    return (
      <GridContainer style={{ width: `${minWidth ? '60' : '100'}%` }}>
        <GridItem xs={+`${minWidth ? 12 : 8}`}
                  sm={+`${minWidth ? 6 : 9}`}
                  md={+`${minWidth ? 6 : 9}`}
                  lg={+`${minWidth ? 6 : 9}`}
        >
          <h4 className={`${classes.textRow} ${upperCase ?
            classes.label : minWidth ? classes.status : ''}`}
            style={{ marginTop: +`${value !== undefined ? 10 : 0}` }}
          >{name}</h4>
        </GridItem>

        { value !== undefined &&
         <GridItem xs={+`${minWidth ? 12 : 4}`}
                   sm={+`${minWidth ? 6 : 2}`}
                   md={+`${minWidth ? 6 : 3}`}
                   lg={+`${minWidth ? 6 : 2}`}
         >
           <h4 style={{ textAlign: 'right' }} className={classes.textRow}>{value}</h4>
         </GridItem>
        }
      </GridContainer>
    );
  }
}

Row.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.any,
  upperCase: PropTypes.bool,
  minWidth: PropTypes.bool,

};

Row.defaultProps = {
  upperCase: true,
  minWidth: false,
  value: undefined,
};

export default withStyles(styles)(Row);
