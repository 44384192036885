import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { MerchantsConsumer } from './merchantsProvider';

export const withMerchants = (Component) => {
  class ComponentWithMerchants extends React.Component {
    static displayName = `withMerchants(${Component.displayName || Component.name})`;

    render() {
      const { onRef } = this.props;

      return (
        <MerchantsConsumer>
          {({
            merchants,
            merchantKeyValueList,
            getFilteredMerchants,
            getSearchedMerchants,
            getMerchantById,
            getMerchantByLot,
            deleteMerchantById,
            getNextId,
            setActive,
            getNumberMerchantsWithCategory,
          }) => (
            <Component
              {...this.props}
              ref={onRef}
              merchants={merchants}
              merchantKeyValueList={merchantKeyValueList}
              getFilteredMerchants={getFilteredMerchants}
              getSearchedMerchants={getSearchedMerchants}
              getMerchantById={getMerchantById}
              getMerchantByLot={getMerchantByLot}
              deleteMerchantById={deleteMerchantById}
              getNextId={getNextId}
              setMerchantsProviderActive={setActive}
              getNumberMerchantsWithCategory={getNumberMerchantsWithCategory}
            />
          )}
        </MerchantsConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithMerchants, Component);
};
