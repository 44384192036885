import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { VouchersConsumer } from './vouchersProvider';

export const withVouchers = (Component) => {
  class ComponentWithVouchers extends React.Component {
    static displayName = `withVouchers(${Component.displayName || Component.name})`;

    render() {
      const { navigation, onRef } = this.props;

      return (
        <VouchersConsumer>
          {({
              vouchers,
              getFilteredVouchers,
              redemptionStatuses,
              getVoucherTotals,
              getSearchedVouchers,
              getVoucherById,
              getCampaignTotals,
              setActive,
          }) => (
            <Component
              {...this.props}
              navigation={navigation}
              ref={onRef}
              vouchers={vouchers}
              getFilteredVouchers={getFilteredVouchers}
              redemptionStatuses={redemptionStatuses}
              getVoucherTotals={getVoucherTotals}
              getSearchedVouchers={getSearchedVouchers}
              getVoucherById={getVoucherById}
              getCampaignTotals={getCampaignTotals}
              setVouchersProviderActive={setActive}
            />
          )}
        </VouchersConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithVouchers, Component);
};
