import React, { Component } from 'react';
import Grid from '../../components/Grid/Grid';
import Header from './Header.jsx';
import { withReceipts } from '../../contextApi';
import { formatDateFromFirebase } from "../../utils/date";
import { TableRowReceipt } from "../../components/Common/Table/rows";
import { getCapturedByMerchant, getDealOrVoucherId } from './receiptUtils';

// const curPage = config.pages.promotions;
const tableHead = [
  'Receipt Id',
  'Captured by',
  'Deal / Voucher',
  'Image',
  'Uploaded on',
  'Status',
];

class Receipts extends Component {
  state = {
    tableData: [],
    statusValue: ['PENDING_PROCESSING'],
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.onGridRowClick = this.onGridRowClick.bind(this);
  }

  componentDidMount() {
    const { setActive } = this.props;
    setActive();
  }

  static getTableRow = (receipt) => {
    // const { imageUploadDate } = receipt;
    const {
      id,
      imageUploadDate,
      image,
      status,
      deal,
      voucher,
      imageFileName,
      // smallerImage,
      capturedBy,
    } = receipt;
    return {
      id,
      receipt_id: id,
      uploaded_on: formatDateFromFirebase(imageUploadDate),
      captured_by: getCapturedByMerchant(capturedBy),
      'deal_/_voucher': getDealOrVoucherId(receipt),
      image,
      status,
      deal,
      voucher,
      // smallerImage,
      imageFileName,
    }
  };

  static filterReceipts(statusValue, receipt) {
    if (statusValue.length === 0) {
      return receipt
    }

    return statusValue.includes(receipt.status);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { receipts } = nextProps;
    const { statusValue } = prevState;

    const tableData = receipts
      .filter(Receipts.filterReceipts.bind(undefined, statusValue))
      .map(Receipts.getTableRow);
    // const merchants = getFilteredMerchants({ deals, active });

    return {
      ...prevState,
      tableData,
      receipts,
    };
  }

  handleChangeSelector = (selection, data) => {
    const { key, value } = data;

    this.setState(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowReceipt
        el={item}
        pathname={pathname}
        key={id}
        onClick={() => this.onGridRowClick(id)}
      />
    );
  }

  onGridRowClick(id) {
    const { history } = this.props;

    history.push(`/admin/receipts/${id}`);
  }

  render() {
    const {
      tableData,
      filters,
      statusValue,
    } = this.state;
    const { location } = this.props;
    const { pathname } = location;

    return (
      <div>
        <Header
          activeHandle={this.activeHandle}
          handleChangeSelector={this.handleChangeSelector}
          statusValue={statusValue}
        />
        <Grid
          tableHead={tableHead}
          tableData={tableData}
          name="Sales Receipts"
          pathname={pathname}
          count={tableData.length}
          renderRow={this.renderRow}
          onTableRowClick={this.onGridRowClick}
        />
      </div>
    );
  }
}

export default withReceipts(Receipts);
