import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { formatMoney } from 'accounting';
import TableCellBadge from './TableCellBadge';
import {toTitleCase} from "../../../../utils/stringUtils";

export class TableRowVoucher extends Component {
  render() {
    const { el, pathname } = this.props;
    const {
      voucher_code,
      customer,
      device,
      amount,
      status,
      merchant,
      campaign,
      redemption_date,
      actions,
    } = el;
    const formattedAmount = formatMoney(amount, '', 2);
    return (
      <TableRow
        hover
        tabIndex={-1}
      >
        <TableCell>{voucher_code}</TableCell>
        <TableCell align="left">{campaign}</TableCell>
        <TableCell align="left">{customer}</TableCell>
        <TableCell align="left">{device}</TableCell>
        <TableCell align="left" style={{maxWidth: 50}}>{formattedAmount}</TableCell>
        <TableCell>
          <TableCellBadge label={toTitleCase(status)} />
        </TableCell>
        <TableCell aligel="left">{merchant}</TableCell>
        <TableCell align="left">{redemption_date}</TableCell>
        {/*<TableCell align="left">{el.invoice_number}</TableCell>*/}
      </TableRow>
    );
  }
}

TableRowVoucher.propTypes = {
  el: PropTypes.object,
  pathname: PropTypes.string,
};

TableRowVoucher.defaultProps = {
  el: {},
  pathname: '',
};

export default TableRowVoucher;
