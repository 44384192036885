import React, { Component } from 'react';
import _ from 'lodash';
import { TableRowLot } from "../../components/Common/Table/rows";
import {
  withSite,
  withMerchants,
} from "../../contextApi";
import ft from 'firebase-timestamp-utils';
import GridPage from "../GridPage";
import PropTypes from "prop-types";
import MultiSelect from "../../components/Engine/Common/MultiSelect/MultiSelect";
import { config } from "../../config";

const tableHead = [
  'Id',
  'Level',
  'Tenant',
  'Actions',
];

const rawLotMapper = (lot, getMerchantByLot) => {
  const merchant = getMerchantByLot(lot);
  const tenant = (merchant) ? merchant.name : undefined;
  return {
    ...lot,
    merchant,
    tenant,
  }
};

class Lots extends Component {
  state = {
    lots: [],
    levels: [],
  };

  static propTypes = {
    site: PropTypes.shape({
      id: PropTypes.string,
    }),
  };

  static defaultProps = {
    site: {},
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.getFilterControls = this.getFilterControls.bind(this);
    this.getFilteredLots = this.getFilteredLots.bind(this);
  }

  componentDidMount() {
    const { setMerchantsProviderActive } = this.props;

    setMerchantsProviderActive();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { site, getMerchantByLot, merchants } = nextProps;
    const { lots: prevLots = [] } = prevState;

    if (prevLots.length === 0 && merchants) {
      const { lots: rawLots } = site;
      const lots = rawLots.map(x => rawLotMapper(x, getMerchantByLot));
      return {
        ...prevState,
        lots,
      }
    }

    return prevState;
  }

  static getTableRowData(lot) {
    return { ...lot };
  };

  getFilterControls(filterValues) {
    const { site: { levels: levelOptions } } = this.props;
    const { levels = [] } = filterValues;

    return [
      <MultiSelect
        id="levels"
        key="levels"
        name="Level"
        isMainPage={ true }
        options={ levelOptions.map(x => ({ label: x, value: x })) }
        value={ levels.map(x => ({ label: x, value: x })) }
      />
    ]
  }

  getFilteredLots(items) {
    const { getFilteredLots, getMerchantByLot } = this.props;

    const rawLots = getFilteredLots(items);
    const filteredLots = rawLots.map(lot => rawLotMapper(lot, getMerchantByLot));

    return filteredLots;
  }

  renderRow(item) {
    const { location, deleteLot } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowLot
        el={item}
        pathname={pathname}
        key={id}
        itemName="lot"
        deleteLot={deleteLot}
      />
    );
  }

  render() {
    const { lots } = this.state;

    return (
      <GridPage
        viewName="Lots"
        itemName="Lot"
        pathname="lots"
        items={lots}
        getFilterControls={this.getFilterControls}
        getTableRowData={Lots.getTableRowData}
        getFilteredItems={this.getFilteredLots}
        renderRow={this.renderRow}
        tableHead={tableHead}
      />
    )
  }
}

export default withSite(withMerchants(Lots));

