import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Filters from '../../../components/Voucher/Filters/Filters';
import Grid from '../../../components/Grid/Grid';
import Totals from '../../../components/Voucher/Totals/Totals';
import dashboardStyle
  from '../../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import { config } from '../../../config';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import {
  withVouchers,
  withMerchants,
  withCampaigns,
  withUsers,
} from '../../../contextApi';
import { TableRowVoucher } from "../../../components/Common/Table/rows";
import { formatDate } from "../../../utils/date";
import _ from "lodash";

const tableHead = [
  'Voucher code',
  'Campaign',
  'Customer',
  'Device',
  'Amount',
  'Status',
  'Redeemed at Merchant',
  'Redemption Date',
  // 'Invoice number',
  // 'Actions',
];

const curPage = config.pages.overview;

class Vouchers extends Component {
  state = {
    tableData: [],
    campaigns: [],
    campaignId: config.statuses.any,
    merchants: config.statuses.any,
    merchantId: config.statuses.any,
    status: config.statuses.any,
    totals: {},
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
  }

  componentDidMount() {
    console.log('Vouchers.componentDidMount');
    const { setVouchersProviderActive, setCustomersProviderActive } = this.props;

    setCustomersProviderActive();
    setVouchersProviderActive();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return Vouchers.getData(prevState, nextProps);
  }

  static getData(state, props) {
    const {
      campaignId,
      merchantId,
    } = state;
    const {
      getFilteredVouchers,
      getFilteredMerchants,
      getVoucherTotals,
      getCampaignTotals,
      getDeviceNameByUserId,
      getCampaignCreatedTotals,
      vouchers,
    } = props;

    const filteredVouchers = getFilteredVouchers(state) || [];
    const tableData = filteredVouchers.map(Vouchers.getTableRowData.bind(undefined, getDeviceNameByUserId));
    const merchants = getFilteredMerchants({ vouchers });

    const totals = {
      ...getCampaignTotals({ campaignId }),
      ...getVoucherTotals({ campaignId, merchantId }),
      created: getCampaignCreatedTotals({ campaignId })
    };

    return {
      tableData,
      merchants,
      totals,
    };
  }

  static getTableRowData(getDeviceNameByUserId, voucher) {
    const {
      id,
      merchant = {},
      redemption = {},
      user = {},
      discount = {},
      campaign,
      // lots = [],
      // categories = [],
    } = voucher;
    const {
      redeemedAt,
      status = 'Issued',
      salesReceipt,
    } = redemption;
    const { title: campaignTitle } = campaign;
    const customer = user.name;
    const invoiceNumber = _.get(redemption, 'values.receiptNumber');
    const amount = discount.amount;
    const device = getDeviceNameByUserId(user.id);

    const row = {
      id,
      voucher_code: id,
      campaign: campaignTitle,
      merchant: _.get(redemption, 'merchantScan.merchant.name'),
      redemption_date: redeemedAt ? formatDate(redeemedAt.toDate()) : '',
      invoice_number: invoiceNumber,
      customer,
      amount,
      status,
      actions: 'action',
      device,
    };

    row.actions = (status === config.statuses.pendingAuthorisation ? 'Verify' : 'Ok');

    return row;
  };

  handleChangeSelector = async (event, data) => {
    const { name } = event.target;
    const { key, value } = data;

    this.setState(prevState => {
      const {
        status,
        merchantId,
        campaignId,
      } = prevState;
      const updatedState = Vouchers.getData({
        status,
        merchantId,
        campaignId,
        [name]: key || value,
      }, this.props);

      return {
        ...prevState,
        ...updatedState,
        [name]: key || value,
      };
    });
  };

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return <TableRowVoucher el={item} pathname={pathname} key={id} />;
  }

  // render_NEW() {
  //   const { merchants, getFilteredMerchants, site: { categories } = {} } = this.props;
  //
  //   return (
  //     <GridPage
  //       viewName="Vouchers"
  //       itemName="Voucher"
  //       pathname="vouchers"
  //       items={merchants}
  //       getFilterControls={this.getFilterControls}
  //       getFilteredItems={getFilteredMerchants}
  //       getTableRowData={x => Merchants.getTableRowData(x, categories)}
  //       renderRow={this.renderRow}
  //       tableHead={tableHead}
  //       showCreateButton={true}
  //     />
  //   )
  // }

  render() {
    const {
      location,
      campaigns,
      redemptionStatuses,
    } = this.props;
    const { pathname } = location;
    const {
      tableData,
      totals,
      merchants,
      status,
      merchantId,
      campaignId,
    } = this.state;

    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <Filters
              page={curPage}
              campaigns={campaigns}
              merchants={merchants}
              statuses={redemptionStatuses}
              status={status}
              merchantId={merchantId}
              campaignId={campaignId}
              handleChangeSelector={this.handleChangeSelector}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={6}>
            <Totals page={curPage} totals={totals}/>
          </GridItem>
        </GridContainer>
        <Grid
          tableHead={tableHead}
          tableData={tableData}
          name={config.tableName.overview}
          pathname={pathname}
          count={tableData.length}
          renderRow={this.renderRow}
        />
      </div>
    );
  }
}

Vouchers.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withUsers(withCampaigns(withMerchants(withVouchers(withStyles(dashboardStyle)(Vouchers)))));
