import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import style from '../../../../assets/jss/material-dashboard-react/components/details';

class HeaderWithDescription extends React.PureComponent {
  render() {
    const {
      title,
      description,
      classes,
      economyPlace,
    } = this.props;
    return (
      <div className={classes.textContainer}>
        <h3 className={economyPlace ? classes.textName : classes.empty }> {title}</h3>
        { description && <h5 className={classes.textMargin}> {description} </h5> }
      </div>
    );
  }
}

HeaderWithDescription.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  economyPlace: PropTypes.bool,
};

HeaderWithDescription.defaultProps = {
  title: '',
  description: '',
  economyPlace: true,
};


export default withStyles(style)(HeaderWithDescription);
