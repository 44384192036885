import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';
import _ from 'lodash';
import ft from 'firebase-timestamp-utils';
import { creationDateComparator } from '../utils';

const { dealsRef } = Firebase;

const DealsContext = React.createContext();

export const DealsConsumer = DealsContext.Consumer;

export class DealsProvider extends Component {
  state = {
    deals: undefined,
    getDealById: id => this.getDealById(id),
    update: (id, updateDoc) => this.update(id, updateDoc),
    getFilteredDeals: filters => this.getFilteredDeals(filters),
    getSearchedDeals: term => this.getSearchedDeals(term),
    deleteDealById: dealId => this.deleteDealById(dealId),
    setActive: () => this.setActive(),
    getNumberDealsWithCategory: category => this.getNumberDealsWithCategory(category),
  };

  static propTypes = {
    merchant: PropTypes.shape({
      id: PropTypes.string,
    }),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
    this.setActive = this.setActive.bind(this);

    this.active = false;
    this.unsusbscribes = [];
    // this.debounced = _.debounce(this.onSnapshot, 2000);
  }

  setActive() {
    if (!this.active) {
      const { authMerchants = [], site: { id: siteId } = {} } = this.props;

      let query = dealsRef
        .where('siteId', '==', siteId);

      if (authMerchants.length === 1) {
        const id = authMerchants[0];
        query = query.where('merchant.id', '==', id);
      }
      if (authMerchants.length >= 2) {
        const dealPromises = authMerchants.map(id => {
          return dealsRef.where('merchant.id', '==', id);
        });
        Promise.all(dealPromises)
          .then(queries => {
            queries.forEach(query => {
              this.unsusbscribes.push(query.onSnapshot(this.onSnapshot));
            });
            this.active = true;
          })
          .catch(err => {
            console.log(err);
            // handle error
          })
      }
      else {
        this.unsusbscribes.push(query.onSnapshot(this.onSnapshot));
      }
    }
  }

  async onSnapshot(snapshot) {
    const { deals } = this.state;
    const updatedDeals = mergeSnapshotChangesIntoArray(snapshot, deals);

    // const filtered = (authMerchants.length > 1) ? updatedMerchants.filter(x => authMerchants.includes(x.id)) : updatedMerchants;
    // const sorted = _.sortBy(filtered, 'name');

    this.setState({
      deals: updatedDeals,
    });
  }

  componentWillUnmount() {
    this.unsusbscribes.length && this.unsusbscribes.forEach(x => x());
  }

  getDealById(id) {
    console.log(`dealsProvider.getDealById(${id})`);
    const { deals } = this.state;
    return deals.find(x => x.id === id);
  }

  update(id, updateDoc) {
    // set state &&
    console.log(`dealsProvider.update(${id}, ${JSON.stringify(updateDoc)})`);
    dealsRef
      .doc(id)
      .update(updateDoc)
      .catch((error) => {
        console.log('Error updating remote deal', error);
      });
  }

  getSearchedDeals(term) {
    if (!term) return [];
    const { deals } = this.state;

    const searchedDeals = deals
      .filter(deal => _.get(deal, 'merchant.name').toLowerCase().includes(term)
        || deal.id.includes(term))
      .map((deal) => {
        const { id } = deal;
        const prettiedName = `${id}`;

        return { id, name: prettiedName };
      });

    return searchedDeals;
  }

  getFilteredDeals(filters = {}) {
    const { deals } = this.state;
    if (!deals) return undefined;
    const {
      type = [],
      merchantId = [],
      active,
      timePeriod = [],
    } = filters;
    if (!deals.length) return [];
    let filteredDeals = [...deals];
    if (merchantId.length) {
      filteredDeals = filteredDeals.filter(deal => merchantId.some(x => deal.merchant.id === x));
    }
    if (type.length) {
      filteredDeals = filteredDeals.filter(({ type: dealType }) => type.some(x => dealType === x || x === 'Any')); // filteredDeals.filter(x => x.type === type);
    }
    if (typeof active === 'boolean') {
      filteredDeals = filteredDeals.filter(x => x.active === active);
    }
    if (timePeriod.length) {
      // ['Expired', 'Current', 'Upcoming']
      filteredDeals = filteredDeals.filter(x => {
        if (timePeriod.includes('Upcoming') && ft.isFuture(x.startDate)) return true;
        if (timePeriod.includes('Expired') && ft.isPast(x.endDate)) return true;
        if (timePeriod.includes('Current') && ft.isPast(x.startDate) && ft.isFuture(x.endDate)) return true;
      })
    }

    // Default ordering by creation date
    return filteredDeals.sort(creationDateComparator);
  }

  async deleteDealById(id) {
    await dealsRef.doc(id)
      .delete();
  }

  getNumberDealsWithCategory(_category) {
    const { deals } = this.state;
    if (!deals) return undefined;

    return deals.reduce((acc, { category }) => {
      return (category === _category) ? acc + 1 : acc;
    }, 0);
  }

  render() {
    const { children } = this.props;

    return (
      <DealsContext.Provider value={this.state}>
        {children}
      </DealsContext.Provider>
    );
  }
}

export default DealsProvider;
