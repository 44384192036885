import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl/index';
import InputLabel from '@material-ui/core/InputLabel/index';
import SelectMU from '@material-ui/core/Select/index';
import MenuItem from '@material-ui/core/MenuItem/index';
import style
  from '../../../../assets/jss/material-dashboard-react/components/details.jsx';
import { alphaComparator } from '../../../../utils';


class Select extends Component {
  state = {
    isDirty: false,
    isOpenSelect: false,
    items: <Fragment />,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { data } = nextProps;

    if (data) {
      const sorted = data.sort(alphaComparator('value'));
      const items = sorted.map(({ key, value }) => {
        return (
          <MenuItem key={key} value={value}>{value}</MenuItem>
        );
      });

      return {
        ...prevState,
        items,
      }
    }
    return {
      ...prevState,
    }
  }

  handleFocus = () => {
    const {
      value,
      handleRequiredFields,
      name: field,
      required,
    } = this.props;
    if (!value.length && required) {
      handleRequiredFields({ field, status: false });
    }
    this.setState({ isDirty: true, isOpenSelect: false });
  };

  onOpen = () => {
    this.setState({ isDirty: false, isOpenSelect: true });
  };

  onChange = (event, data) => {
    const { changeSelect } = this.props;
    const { key, props } = data;
    const value = props.value;

    if (changeSelect) {
      changeSelect(event, {
        key,
        value,
      })
    }
  };

  static getDisplayValue(data, value) {
    const item = data.find(x => x.key === value);
    return (item) ? item.value : '';
  }

  render() {
    const { isDirty, isOpenSelect, items } = this.state;
    const {
      classes,
      value,
      name,
      label,
      data,
      required,
      flex,
    } = this.props;
    const displayValue = Select.getDisplayValue(data, value);
    const styleFlex = flex ? { flexDirection: 'row', marginBottom: 0 } : { };

    return (
      <div className={classes.selectForm}>
        <FormControl className={classes.formSelect} style={styleFlex}>
          <InputLabel
            className={classes.labelForSelector}
            shrink
            htmlFor={name}
            style={flex ? {display: 'flex', marginTop: 5, width: 180} : {}}
          >
            {label}
          </InputLabel>
          <SelectMU
            onClose={this.handleFocus}
            onOpen={this.onOpen}
            open={isOpenSelect}
            value={displayValue}
            onChange={this.onChange}
            name={name}
            className={classes.formControl}
            style={flex ? {display: 'flex', marginLeft: 180, marginTop: 0, width: 200} : {}}
          >
            {items}
          </SelectMU>
        </FormControl>
        {isDirty && !value.length && required && (
          <label className={classes.error}>{label} is required</label>
        )}
      </div>
    );
  }
}

Select.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  required: PropTypes.bool,
  changeSelect: PropTypes.func,
  handleRequiredFields: PropTypes.func,
  getNeedFields: PropTypes.func,
};

Select.defaultProps = {
  value: '',
  data: [],
  label: '',
  name: '',
  required: false,
  changeSelect: () => null,
  handleRequiredFields: () => null,
  getNeedFields: () => null,
};

export default withStyles(style)(Select);
