import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import CacheBuster from './CacheBuster';
import './assets/css/material-dashboard-react.css?v=1.6.0';
import { config } from 'dotenv'; // Enable .env file

config();

const root = document.getElementById('root');
// const jsx = <App />;
const jsx = (
  <CacheBuster>
    {({ loading, isLatestVersion, refreshCacheAndReload }) => {
      if (loading) return null;
      if (!loading && !isLatestVersion) {
        // You can decide how and when you want to force reload
        refreshCacheAndReload();
      }

      return (
        <App />
      );
    }}
  </CacheBuster>
);

ReactDOM.render(jsx, root);
