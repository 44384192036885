import { config } from '../../config';
import Firebase from '../../components/Firebase';

export const getAllDeals = (orderBy = 'id') => {
  const { dealsRef } = Firebase;
  return dealsRef.orderBy(orderBy);
};

export const getQuery = (filters, forCount = false) => {
  const {
    merchant = '',
    type = '',
    active = false,
    rowsPerPage = 10,
    id = '',
    direction = config.direction.updateAll,
    prevRowsPerPage = 0,
  } = filters;

  let query = getAllDeals();
  let needRows = rowsPerPage - prevRowsPerPage;
  if (merchant !== config.statuses.any) {
    query = query.where('merchant.name', '==', merchant);
  }
  if (type !== config.statuses.any) {
    query = query.where('type', '==', type);
  }
  query = query.where('active', '==', active);

  if (direction && !forCount) {
    query = query.startAt(id);
    // Because 'start at' starting from  includes selected id
    needRows++;
  }
  if (!forCount) {
    query = query.limit(needRows);
  }
  return query;
};

export const getQueryDealById = (id) => {
  const { dealsRef } = Firebase;
  return dealsRef.where('id', '==', id);
};


export default () => null;
