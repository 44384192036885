import Firebase from "../components/Firebase";
import _ from "lodash";

const smallImageSuffix = '_small';

const appendToFilename = (fileName, suffix) => {
  if (fileName) {
    const dotIndex = fileName.lastIndexOf(".");
    if (dotIndex === -1) {
      return fileName + suffix;
    }
    const extension = fileName.substring(dotIndex);
    return `${fileName.substring(0, dotIndex)}${suffix}${extension}`;
  }
};

export const getSmallImageFilename = (fileName) => {
  return appendToFilename(fileName, smallImageSuffix);
};

/**
 * Removes properties not required for remote image persistence
 * @param entity
 * @param attribute
 * @returns {{}}
 */
export const prepareImageForFirebase = (entity = {}, attribute = 'image') => {
  const { [attribute]: image } = entity;
  delete entity.imageFileName;
  if (!_.isEmpty(image)) {
    delete entity.image.ref;
    delete entity.image.progress;
  }

  return entity;
};

/**
 * TODO : abstract these methods, can't modify image utils yet as legacy code still accesses
 * @param imageName
 * @param path
 * @returns {Promise<*>}
 */
const prepareRef = async (path) => {
  const { storage } = Firebase;
  return storage.child(path);
};

export const getImage = async (path) => {
  const storageRef = await prepareRef(path)
    .catch(error => {
      console.log(error);
    });
  const ref = await storageRef.getDownloadURL()
    .catch(error => {
      const { name_, code_, message_ } = error;
      console.log(['>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', name_, code_, message_].join(' '));
    });

  return ref;
};