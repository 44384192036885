import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import GridItem from '../../Grid/GridItem';
import GridContainer from '../../Grid/GridContainer';

import style from '../../../assets/jss/material-dashboard-react/views/deals.jsx';

class Header extends React.Component {
  render() {
    const {
      classes,
      createButtonLabel = 'Create Promotion',
      path = 'promotions',
    } = this.props;
    const styleHeader = {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 70,
    };
    return (
      <GridContainer>
        <GridItem xs={10} sm={8} md={8} lg={6}>
          <p/>
        </GridItem>
        <GridItem xs={2} sm={4} md={4} lg={6} style={styleHeader}>
          <NavLink
            to={`${path}/add`}
            className={classes.item}
            activeClassName="active"
          >
            <Button className={ classes.createButton }>
              {createButtonLabel}
            </Button>
          </NavLink>
        </GridItem>
      </GridContainer>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
};

Header.defaultProps = {
  value: [],
  options: [],
  onChange: () => null,
};

export default withStyles(style)(Header);
