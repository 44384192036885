import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {confirmAlert} from 'react-confirm-alert';

export const withTableRow = (WrappedComponent) => {
  return class PP extends Component {
    static propTypes = {
      itemName: PropTypes.string.isRequired,
      el: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }).isRequired,
    };

    static defaultProps = {
      itemName: 'deal',
    };

    constructor(props) {
      super(props);

      this.onDelete = this.onDelete.bind(this);
      this.handleDelete = this.handleDelete.bind(this);
    }

    handleDelete() {
      const { onDelete, el } = this.props;
      const { id } = el;

      if (onDelete) {
        onDelete(id);
      }
    }

    onDelete() {
      const { itemName } = this.props;

      confirmAlert({
        title: 'Confirm to delete',
        message: `Deleting this ${itemName} is not reversible. Are you sure to do this.`,
        buttons: [
          {
            label: 'Yes',
            onClick: this.handleDelete,
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ]
      });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          onDelete={this.onDelete}
        />
      );
    }
  }
}