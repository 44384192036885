import React, { Component, Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import GridItem from '../components/Grid/GridItem';
import GridContainer from '../components/Grid/GridContainer';
import style from '../assets/jss/material-dashboard-react/views/deals.jsx';
import { withRouter } from 'react-router-dom';
import * as queryString from "query-string";

const arrayFormat = 'comma';

class Header extends Component {
  constructor(props) {
    super(props);

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onPathChange = this.onPathChange.bind(this);
  }

  componentDidMount() {
    const { history, location } = this.props;

    this.unlisten = history.listen(this.onPathChange);

    this.onPathChange(location);
  }

  componentWillUnmount() {
    this.unlisten();
  }

  static objectMap(object, mapFn) {
    return Object.keys(object).reduce((result, key) => {
      result[key] = mapFn(key, object[key]);
      return result
    }, {});
  }

  static parseQueryStrings(search) {
    let qsObj = queryString.parse(search);

    return Header.objectMap(qsObj, (k, v) => {
      return v.split(',');
    });
  }

  /**
   * Returns an array of the filter names from the filter controls in the children prop
   * @returns {*}
   */
  getFilterNames() {
    const { children } = this.props;

    if (_.isEmpty(children)) return [];

    return React.Children.map(children, child => {
      const { id } = child.props;
      return id;
    });
  }

  onPathChange({ search }) {
    console.log('Header.ONPATHCHANGE')
    const filters = Header.parseQueryStrings(search);
    let allFilters = this.getFilterNames().reduce((acc, key) => ({
      ...acc,
      [key]: (filters[key]) ? filters[key] : [],
    }), {});

    this.setState(prevState => ({ ...allFilters }), () => {
      const { onFiltersChange } = this.props;
      console.log('Header.ONPATHCHANGE ::: callback');
      onFiltersChange(allFilters);
    });
  }

  // Is this the inf loop? --> new values from qs --> onfilerChange
  onFilterChange(ignoreMe, { key, value }) {
    console.log('Header.onFilterChange')
    const { history, location: { pathname, search } } = this.props;
    const qsObj = queryString.parse(search);
    const newQsObj = { ...qsObj, [key]: value };
    const qsString = queryString.stringify(newQsObj, { arrayFormat });
    const newPath = `${pathname}?${qsString}`;
    console.log('Header.onFilterChange ::: end')
    history.push(newPath);
  }

  // 1. Filter changes - calls Header.onFilterChange
  // 2. Header.onFilterChange - updates path
  // 3. Header.onPathChange - triggers onFiltersChange
  // 4. GridPage - onFiltersChange event triggered
  // 5. GridPage - filters state updated
  // 6. GridPage.componentDidUpdate : tableData = filteredItems.map(getTableRowData);

  getFilterControls() {
    console.log('Header.getFilterControls')
    const { children } = this.props;
    const filterControls = [];
    Children.forEach(children,(child, index) => filterControls.push(
      cloneElement(child, {
        onChange: this.onFilterChange,
        key: index,
      })
    ));
    console.log('Header.getFilterControls ::: end')
    return filterControls;
  }

  render() {
    const {
      classes,
      showCreateButton,
      itemName,
      pathname,
    } = this.props;

    const styleHeader = {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 70,
    };
    const filterControls = this.getFilterControls();
// getFilterNames() ::: showFilters ???
    return (
      <GridContainer>
        <GridItem xs={ 10 } sm={ 8 } md={ 8 } lg={ 6 }>
          {/*<h3 className={ classes.upperText }>Filters</h3>*/}
          {filterControls}
        </GridItem>
        <GridItem xs={ 2 } sm={ 4 } md={ 4 } lg={ 6 } style={ styleHeader }>
          <NavLink
            to={ `${pathname}/add` }
            className={ classes.item }
            activeClassName="active"
          >
            { showCreateButton && (
              <Button className={ classes.createButton }>{`Create ${itemName}`}</Button>
            ) }
          </NavLink>
        </GridItem>
      </GridContainer>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
  showCreateButton: PropTypes.bool.isRequired,
};

Header.defaultProps = {
  value: [],
  options: [],
  onChange: () => null,
  showCreateButton: true,
};

export default withRouter(withStyles(style)(Header));
