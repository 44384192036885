import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import style from '../../assets/jss/material-dashboard-react/components/images';
import withStyles from '@material-ui/core/styles/withStyles';
import FileDropzone from '../Common/Dropzone/Dropzone';
import Image from '../Common/Image/Image';
import Button from '@material-ui/core/Button';

class ImageUpload extends Component {
  static getShouldDisplayImage(image = {}) {
    const { progress, ref = '' } = image;
    const shouldDisplayImage = (!_.isEmpty(image) && ref.length > 0);

    return shouldDisplayImage;
  }

  render() {
    const {
      selectedImage,
      onFileDrop,
      classes,
      uploadDisabled,
    } = this.props;
    const displayImage = ImageUpload.getShouldDisplayImage(selectedImage);
    console.log(`ImageUpload.render() selectedImage=${JSON.stringify(selectedImage)} selectedImage=displayImage=${displayImage}`);

    return (
      <div className={classes.uploadSection}>
        <div className={classes.image}>
          {displayImage && (
            <Image currentImage={selectedImage} />
          )}
        </div>
        <FileDropzone onDrop={onFileDrop} zone={false} disabled={uploadDisabled}>
          <Button
            className={ classes.uploadBtn }
            size="large"
            disabled={uploadDisabled}
          >
            Upload
          </Button>
        </FileDropzone>
      </div>
    )
  }
}

ImageUpload.propTypes = {
  maxNumberImages: PropTypes.number,
  selectedImage: PropTypes.object,
  uploadDisabled: PropTypes.bool,
};

ImageUpload.defaultProps = {
  selectedImage: {},
  onFileDrop: () => {},
  uploadDisabled: false,
};

export default withStyles(style)(ImageUpload);