import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { SiteVisitsConsumer } from './siteVisitsProvider';

export const withSiteVisits = (Component) => {
  class ComponentWithSiteVisits extends React.Component {
    static displayName = `withSiteVisits(${Component.displayName || Component.name})`;

    render() {
      const { navigation, onRef } = this.props;

      return (
        <SiteVisitsConsumer>
          {({
              // notifications,
              siteVisits,
              setActive,
            }) => (
            <Component
              {...this.props}
              ref={onRef}
              navigation={navigation}
              siteVisits={siteVisits}
              // notifications={notifications}
              setSiteVisitsProviderActive={setActive}
            />
          )}
        </SiteVisitsConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithSiteVisits, Component);
};
