const receiptInfoStyle = {
  imageMagnifyContainer: {
    width: 300,
  },
  headerForRowSelect: {
    display: 'flex',
    marginTop: 12,
    marginBottom: 12,
    alignItems: 'center',
  },
  textContainer: {
    display: 'flex',
  },
  labelInput: {
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 10,
    minWidth: 72,
  },
  labelDesc: {
    fontSize: 10,
    alignSelf: 'flex-end',
    marginTop: 13,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 18,
  },
  rejectButton: {
    backgroundColor: 'red',
    color: '#fff',
    opacity: 0.4,
    '&:hover': {
      backgroundColor: 'red',
      color: '#fff',
      opacity: 0.9,
    },
    marginRight: 20
  },
  select: {
    // [theme.breakpoints.down('lg')]: {
    //   maxWidth: 400,
    //   minWidth: 300,
    // },
    // [theme.breakpoints.down('md')]: {
    //   maxWidth: 400,
    //   minWidth: 300,
    // },
    // [theme.breakpoints.down('sm')]: {
    //   maxWidth: 400,
    //   minWidth: 300,
    // },
    // [theme.breakpoints.down('xs')]: {
    //   maxWidth: 150,
    //   minWidth: 150,
    // },
  },
};

export default receiptInfoStyle;
