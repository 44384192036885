import React, { Component } from 'react';
import _ from 'lodash';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';
import { arrayToString } from '../utils';
import { filterMerchantsByCategories } from '../utils/merchant/utils';

const { merchantsRef } = Firebase;

const MerchantsContext = React.createContext();

export const MerchantsConsumer = MerchantsContext.Consumer;

export class MerchantsProvider extends Component {
  state = {
    merchants: undefined,
    setActive: () => this.setActive(),
    getFilteredMerchants: filters => this.getFilteredMerchants(filters),
    getSearchedMerchants: term => this.getSearchedMerchants(term),
    getMerchantList: merchantId => this.getMerchant(merchantId),
    getMerchantById: id => this.getMerchant(id),
    getMerchantByLot: lot => this.getMerchantByLot(lot),
    getNumberMerchantsWithCategory: category => this.getNumberMerchantsWithCategory(category),
    deleteMerchantById: dealId => this.deleteMerchantById(dealId),
    getNextId: () => this.getNextId(),
  };

  constructor(props) {
    super(props);

    this.active = false;
    this.unsusbscribes = [];

    this.onSnapshot = this.onSnapshot.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  static getKeyValueList = (list = []) => list.map(item => ({
    key: item.id.toString(),
    value: item.name,
  }));

  // @autobind
  async onSnapshot(snapshot) {
    const { merchants } = this.state;
    const { authMerchants = [] } = this.props;
    const updatedMerchants = mergeSnapshotChangesIntoArray(snapshot, merchants);

    const filtered = (authMerchants.length > 1) ? updatedMerchants.filter(x => authMerchants.includes(x.id)) : updatedMerchants;
    const sorted = _.sortBy(filtered, 'name');

    this.setState({
      merchants: sorted,
      merchantKeyValueList: MerchantsProvider.getKeyValueList(sorted),
    });

    // prune any promotions which are no longer relevant
    // await this.pruneExpiredPromotions();
  }

  setActive() {
    if (!this.active) {
      const { authMerchants = [], site: { id: siteId } = {} } = this.props;

      let query = merchantsRef
        .orderBy('name')
        .where('siteId', '==', siteId);
      if (authMerchants.length === 1) {
        const id = authMerchants[0];
        query = query
          .where('id', '==', id);
      }
      if (authMerchants.length >= 2) {
        const merchantPromises = authMerchants.map(id => {
          return merchantsRef
            .orderBy('name')
            .where('id', '==', id);
        });
        return Promise.all(merchantPromises)
          .then(queries => {
            queries.forEach(query => {
              this.unsusbscribes.push(query.onSnapshot(this.onSnapshot));
            });
            this.active = true;
          })
          .catch(err => {
            console.log(err);
            // handle error
          })
      }
      else {
        this.unsusbscribes.push(query.onSnapshot(this.onSnapshot));
      }
    }
  }

  componentWillUnmount() {
    this.unsusbscribes.length && this.unsusbscribes.forEach(x => x());
  }

  getMerchant(id) {
    const { merchants } = this.state;
    return _.find(merchants, x => x.id === id);
  }

  getMerchantByLot(lot) {
    const { merchants } = this.state;
    const { id: lotId } = lot;
    const merchant = _.find(merchants, x => x.lots.includes(lotId));
    return merchant;
  }

  getFilteredMerchants(filters = {}) {
    const { merchants } = this.state;
    if (!merchants) return undefined;
    const {
      categories = [],
      deals = [],
      vouchers = [],
      lots = [],
      levels = [],
    } = filters;

    let filteredMerchants = [...merchants];
    if (categories.length > 0) {
      filteredMerchants = filterMerchantsByCategories(merchants, categories);
    }
    if (deals.length > 0) {
      // const filteredDeals = deals.filter(el => el.active === active);
      const merchantIdsFromDeals = deals.map(x => x.merchant.id);
      filteredMerchants = filteredMerchants.filter(merchant => merchantIdsFromDeals.includes(merchant.id));
    }
    if (lots.length > 0) {
      filteredMerchants = filteredMerchants.filter(merchant => merchant.lots.some(x => lots.includes(x)));
    }
    if (levels.length > 0) {
      filteredMerchants = filteredMerchants.filter(merchant => merchant.lots.some(lot => levels.some(level => lot.startsWith(level))));
    }
    if (Array.isArray(vouchers) && vouchers.length) {
      const merchantIdsFromVouchers = vouchers.map(x => _.get(x, 'redemption.merchantScan.merchant.id'));
      const distinctMerchantIds = [...new Set(merchantIdsFromVouchers)];
      filteredMerchants = filteredMerchants.filter(merchant => distinctMerchantIds.includes(merchant.id));
    }

    return filteredMerchants;
  }

  getNumberMerchantsWithCategory(category) {
    const { merchants = [] } = this.state;

    return merchants.reduce((acc, { categories }) => {
      return (categories.includes(category)) ? acc + 1 : acc;
    }, 0);
  }

  getSearchedMerchants(searchTerm) {
    if (!searchTerm) return [];
    const { merchants } = this.state;
    const term = searchTerm.toLowerCase();

    const searchedMerchants = merchants
      .filter(merchant => merchant.name.toLowerCase().includes(term)
        || merchant.id.includes(term)
        || merchant.lots.join(',').includes(term))
      .map((merchant) => {
        const { name, lots, id } = merchant;
        const prettiedName = `${name} [${arrayToString(lots)}]`;
        console.log(prettiedName);
        return { id, name: prettiedName };
      });

    return searchedMerchants;
  }

  getNextId() {
    const { merchants } = this.state;
    const maxId = Math.max(...merchants.map(x => Number(x.id)));
    const result = String(maxId + 1).padStart(6, '0');

    return result;
  }

  async deleteMerchantById(id) {
    await merchantsRef.doc(id)
      .delete();
  }

  render() {
    const { children } = this.props;

    return (
      <MerchantsContext.Provider value={this.state}>
        {children}
      </MerchantsContext.Provider>
    );
  }
}
