import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import _ from 'lodash';
import memoize from 'memoize-one';
import LoadingOverlay from "react-loading-overlay";
import EnhancedTableHead from './EnhancedTableHead.jsx';
import tableStyle from '../../../assets/jss/material-dashboard-react/components/tableStyle.jsx';
import { config } from '../../../config';
import {
  TableRowVoucher,
  TableRowDeal,
} from './rows';

const desc = (a, b, orderBy, isNumeric = false) => {
  const aVal = (isNumeric) ? parseFloat(a[orderBy]) : a[orderBy];
  const bVal = (isNumeric) ? parseFloat(b[orderBy]) : b[orderBy];
  if (bVal < aVal) return -1;
  if (bVal > aVal) return 1;
  return 0;
};

const stableSort = (array, cmp) => {
  const stabilizedThis = array.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
};

const getSorting = (order, orderBy) => {
  const isNumeric = orderBy.endsWith('_sort');
  return (order === 'desc' ? (a, b) => desc(a, b, orderBy, isNumeric) : (a, b) => -desc(a, b, orderBy, isNumeric));
};

const TableRowType = ({ name, n, pathname, onClick }) => {
  const { overview, vouchers } = config.tableName;

  if (name === overview || name === vouchers) {
    return <TableRowVoucher el={n} pathname={pathname} key={n.id} />;
  }

  return <TableRowDeal el={n} pathname={pathname} key={n.id} />;
};

class EnhancedTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: undefined,
    loadingOverlayActive: true,
  };

  componentDidMount() {
    this.props.onRef(this);

    this.setState(prevState => ({
      ...prevState,
      loadingOverlayActive: true,
    }))
  }

  componentWillUnmount() {
    this.props.onRef(null);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { tableData } = nextProps;
    const { loadingOverlayActive } = prevState;

    console.log(`Table.getDerivedStateFromProps loadingOverlayActive=${loadingOverlayActive} Array.isArray(tableData)=${Array.isArray(tableData)}`);

    if (loadingOverlayActive && Array.isArray(tableData)) {
      return {
        ...prevState,
        loadingOverlayActive: false,
      }
    }

    return prevState;
  }

  handleRequestSort = (orderBy) => {
    console.log('orderBy = ', orderBy);
    // const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === orderBy && this.state.order === 'desc') {
      order = 'asc';
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.props.handleChangePage(event, page);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.handleChangeRowsPerPage(event);
  };

  sortTable = memoize(
    (tableData, order, orderBy) => stableSort(tableData, getSorting(order, orderBy)),
  );

  getTableRow = (n) => {
    const { onTableRowClick, pathname, name, renderRow } = this.props;

    if (renderRow) {
      return renderRow(n);
    }

    return <TableRowType key={n.id} name={name} n={n} pathname={pathname} onClick={() => onTableRowClick(n.id)} />
  };

  /**
   * Super hack to allow us to sort by dates and not their string representations
   * @param tableData
   */
  getSortedTable(tableData = []) {
    const { order, orderBy } = this.state;
    let sortedTable = tableData;
    if (orderBy) {
      if (orderBy.endsWith('date') || orderBy.endsWith('seen') || orderBy.endsWith('at') || orderBy.endsWith('location')) {
        sortedTable = this.sortTable(tableData, order, `${orderBy}_sort`);
      } else {
        sortedTable = this.sortTable(tableData, order, orderBy);
      }
    }

    return sortedTable;
  }

  render() {
    const {
      classes,
      tableData,
      tableHead,
      rowsPerPage,
      page,
      count,
    } = this.props;
    const { order, orderBy, loadingOverlayActive } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);
    const sortedTable = this.getSortedTable(tableData);
    const visibleRows = sortedTable.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const overlayStyles = {
      overlay: (base) => ({
        ...base,
        background: 'rgba(175, 175, 175, 0.5)',
      })
    };

    return (
      <Paper className={classes.root} style={{ boxShadow: 'none' }}>
        <div className={classes.tableWrapper}>
          <LoadingOverlay
            active={loadingOverlayActive}
            spinner
            text="Loading"
            styles={overlayStyles}
          >
            <Table className={classes.table} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={this.handleRequestSort}
                tableHead={tableHead}
              />
              <TableBody >
                {visibleRows.map(n => this.getTableRow(n))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </LoadingOverlay>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 50, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          backIconButtonProps={{
            'aria-label': 'Previous Page',
          }}
          nextIconButtonProps={{
            'aria-label': 'Next Page',
          }}
          labelDisplayedRows={ paginator => `${paginator.page + 1} of ${Math.ceil(paginator.count / rowsPerPage)}`
          }
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  tableData: PropTypes.arrayOf(PropTypes.any),
  tableHead: PropTypes.arrayOf(PropTypes.string),
  pathname: PropTypes.string,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func,
  count: PropTypes.number,
  name: PropTypes.string,
  onTableRowClick: PropTypes.func.isRequired,
};

EnhancedTable.defaultProps = {
  onTableRowClick: () => {},
};

export default withStyles(tableStyle)(EnhancedTable);
