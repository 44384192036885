import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import rundef from 'rundef';
import { withRouter } from 'react-router-dom';
import Firebase from '../../components/Firebase';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { config } from '../../config';
import { withPromotions, withSite } from '../../contextApi';
import Input from '../../components/Engine/Common/Input/Input.jsx'
import DatePicker from "../../components/Engine/Common/DatePicker/DatePicker";
import Switch from '../../components/Engine/Common/Switch/Switch';
import {
  getDateFromFirebase,
  getMallOpeningTime,
  getMallClosingTime,
} from '../../utils/date';
import { prepareImageForFirebase } from '../../utils/imageUtils';
import ImageManager from '../../components/Images/ImageManager';
import EntityEditor from '../EntityEditor';

const { uuid } = Firebase;
const minDate = new Date(2019, 0, 0); // type === date ? getMinDate(data, fromDate) : undefined;

class PromotionInfo extends Component {
  state = {
    promotion: undefined,
  };

  constructor(props) {
    super(props);

    this.onImagesUpdate = this.onImagesUpdate.bind(this);
    this.getEntityDocument = this.getEntityDocument.bind(this);

    this._imageManager = React.createRef();
  }

  componentDidMount() {
    const id = PromotionInfo.getIdFromPath();
    const { setPromotionsProviderActive, getPromotionById } = this.props;
    let promotion = getPromotionById(id);

    if (!promotion) {
      setPromotionsProviderActive(() => {
        promotion = getPromotionById(id);
        this.setState(prevState => ({
          ...prevState,
          promotion,
        }));
      });
    }
  }

  static getIdFromPath = (status = '') => {
    const { href } = window.location;
    return href.split('/').pop();
  };

  static getNewPromotion(siteId) {
    const endDate = getMallClosingTime(moment().add(1, 'months').toDate());
    const startDate = getMallOpeningTime(new Date());

    return {
      id: uuid(),
      active: false,
      startDate,
      endDate,
      title: undefined,
      siteId,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getPromotionById, site: { id: siteId } } = nextProps;

    if (!prevState.promotion) {
      const id = PromotionInfo.getIdFromPath();
      // Omit read only properties
      const promotion = (id === 'add') ? PromotionInfo.getNewPromotion(siteId) : { ...getPromotionById(id) };
      return {
        ...prevState,
        promotion,
      }
    }

    return {
      ...prevState,
    }
  }

  static getImagesArray(promotion) {
    const { image, imageFileName } = promotion;

    if (!_.isEmpty(image)) return [image];
    if (typeof imageFileName === 'string' && imageFileName.length) return [{ fileName: imageFileName }];
    return [];
  }

  onInputChange = (event, data) => {
    const { key, value } = data;

    if (key) {
      this.updateState(key, value);
    }
  };

  onActiveChanged = (key) => {
    const { promotion } = this.state;
    const { active } = promotion;

    if (key) {
      this.updateState(key, !active);
    }
  };

  updateState(key, value) {
    this.setState(prevState => {
      const promotion = {...prevState.promotion};
      _.set(promotion, key, value);

      return {
        ...prevState,
        promotion,
      };
    });
  }

  onDateFieldChange = (value, key) => {
    const date = (value instanceof Date) ? value : value.toDate();

    if (date) {
      this.updateState(key, date);
    }
  };

  getEntityDocument() {
    const { promotion } = this.state;

    const { startDate, endDate } = promotion;
    const _startDate = getMallOpeningTime(startDate);
    const _endDate = getMallClosingTime(endDate);
    const promotionDocument = {
      ...promotion,
      startDate: _startDate,
      endDate: _endDate,
    };

    // imageFileName is legacy. delete it
    prepareImageForFirebase(promotionDocument);

    // Removes undefined fields, necessary for Firebase to not throw a wobbly
    // When we refactor the I/O we can move this closer to the metal
    // For now placed here...
    return rundef(
      promotionDocument,
      true,    // mutate - whether to mutate the original object or return a new one
      1,      // recursive - whether to apply recursively
    );
  }

  onImagesUpdate(images) {
    const image = images.length ? images[0] : undefined;

    this.setState(prevState => ({
      ...prevState,
      promotion: {
        ...prevState.promotion,
        image,
        imageFileName: undefined, // TODO : get rid of this
      },
    }));
  }

  render() {
    const { deletePromotionById } = this.props;
    const {
      promotion = {},
    } = this.state;
    const {
      id,
      active,
      title,
      description,
      startDate,
      endDate,
    } = promotion;
    const _startDate = getDateFromFirebase(startDate);
    const _endDate = getDateFromFirebase(endDate);
    const imagesArr = PromotionInfo.getImagesArray(promotion);

    return (
      <EntityEditor
        onCancelClick={this.onCancelClick}
        onDeleteClick={deletePromotionById}
        getEntityDocument={this.getEntityDocument}
        collection={Firebase.promotionsRef}
        createEndpoint={`promotion/create`}
        cancelHref={`/${config.pages.mainLayout}/promotions`}
        imageManager={this._imageManager.current}
        entityName="promotion"
        id={id}
      >
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Input
              id='title'
              name='Promotion Title'
              value={title}
              onChange={this.onInputChange}
              placeholder='e.g: Low Yat Anniversary Campaign'
            />
            <ImageManager
              imagesArr={imagesArr}
              ref={this._imageManager}
              imageNamePrefix={`${id}_`}
              onChange={this.onImagesUpdate}
              path="promotionImages"
              targetRatio={1.5}
              tolerancePercent={30}
            />
            <Input
              id="description"
              value={description}
              onChange={this.onInputChange}
              placeholder="description for the promotion"
              flex={true}
              type="area"
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6} style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <Switch
              value={active}
              name="Active"
              handleChecked={this.onActiveChanged}
            />
            <DatePicker
              label="Promotion Start Date"
              id="startDate"
              onChange={this.onDateFieldChange}
              value={_startDate}
              minDate={minDate}
            />
            <DatePicker
              label="Promotion End Date"
              id="endDate"
              onChange={this.onDateFieldChange}
              value={_endDate}
              minDate={_startDate}
            />

          </GridItem>
        </GridContainer>
      </EntityEditor>
    );
  }
}

export default withRouter(withSite(withPromotions(PromotionInfo)));
