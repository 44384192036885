import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { CampaignsConsumer } from './campaignsProvider';

export const withCampaigns = (Component) => {
  class ComponentWithCampaigns extends React.Component {
    static displayName = `withMerchants(${Component.displayName || Component.name})`;

    render() {
      const { onRef } = this.props;

      return (
        <CampaignsConsumer>
          {({
              campaigns,
              getCampaignById,
              getCampaignCreatedTotals,
              setActive,
          }) => (
            <Component
              {...this.props}
              ref={onRef}
              campaigns={campaigns}
              getCampaignCreatedTotals={getCampaignCreatedTotals}
              getCampaignById={getCampaignById}
              setCampaignsProviderActive={setActive}
            />
          )}
        </CampaignsConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithCampaigns, Component);
};
