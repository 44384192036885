import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl/index';
import InputLabel from '@material-ui/core/InputLabel/index';
import style
  from '../../../assets/jss/material-dashboard-react/components/details.jsx';
import NavBarDropdownMenu from "../../../components/Navbars/NavBarDropdownMenu";
import { withMerchants } from '../../../contextApi';


class Select extends Component {
  // state = {
  //   isDirty: false,
  //   isOpenSelect: false,
  // };

  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    const { setMerchantsProviderActive } = this.props;

    setMerchantsProviderActive();
  }

  // handleFocus = () => {
  //   const {
  //     value,
  //     handleRequiredFields,
  //     name: field,
  //     required,
  //   } = this.props;
  //   if (!value.length && required) {
  //     handleRequiredFields({ field, status: false });
  //   }
  //   this.setState({ isDirty: true, isOpenSelect: false });
  // };

  // onOpen = () => {
  //   this.setState({ isDirty: false, isOpenSelect: true });
  // };

  onChange(event, data) {
    const { changeSelect } = this.props;
    const { suggestion } = data;

    const { label, name } = suggestion;

    if (changeSelect) {
      changeSelect({
        ...event,
        target: {
          ...event.target,
          name: 'merchant', // fucking hack
        }
      }, {
        key: name,
        value: label,
      })
    }
  };

  // static getDisplayValue(data, value) {
  //   const item = data.find(x => x.key === value);
  //   return (item) ? item.value : '';
  // }

  render() {
    // const { isDirty, isOpenSelect } = this.state;
    const {
      classes,
      value,
      name,
      // label,
      // required,
      getSearchedMerchants,
      getMerchantById,
      flex = false,
    } = this.props;

    const merchantsMap = (merchant) => ({
      label: merchant.name,
      name: merchant.id,
    });
    let merchantName;
    if (value) {
      merchantName = _.get(getMerchantById(value), 'name');
    }

    const styleFlex = flex ? {
      flexDirection: 'row',
      marginBottom: 0,
      minWidth: '64%',
    } : { flexDirection: 'column' };
    const containerStyle = {
      ...styleFlex,
      // ...style,
    };
    const labelHeaderClassName = flex ? classes.headerForRowFlex : classes.headerForRow;
    return (
      <div className={classes.selectForm} style={containerStyle}>
        {flex === true && (
          <Fragment>
            <div className={labelHeaderClassName}>
              <label className={classes.labelInput}>{name}</label>
            </div>
              <NavBarDropdownMenu
                onSelect={this.onChange}
                map={merchantsMap}
                getSuggestions={getSearchedMerchants}
                displayMax={10}
                className={{marginTop: '0', width: '100%'}}
                value={merchantName}
              />
          </Fragment>
        )}
        {flex !== true && (
          <FormControl className={classes.formSelect}>
            <InputLabel
              className={classes.labelForSelector}
              shrink
              htmlFor={name}
            >
              Merchant
            </InputLabel>
            <NavBarDropdownMenu
              onSelect={this.onChange}
              map={merchantsMap}
              getSuggestions={getSearchedMerchants}
              displayMax={10}
              // className={{marginTop: '24px'}}
              value={merchantName}
            />
          </FormControl>
        )}
      </div>
    );
  }
}

Select.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  required: PropTypes.bool,
  changeSelect: PropTypes.func,
  handleRequiredFields: PropTypes.func,
  getNeedFields: PropTypes.func,
};

Select.defaultProps = {
  value: '',
  data: [],
  label: '',
  name: '',
  required: false,
  changeSelect: () => null,
  handleRequiredFields: () => null,
  getNeedFields: () => null,
};

export default withMerchants(withStyles(style)(Select));
