import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { NotificationsConsumer } from './notificationsProvider';

export const withNotifications = (Component) => {
  class ComponentWithNotifications extends React.Component {
    static displayName = `withNotifications(${Component.displayName || Component.name})`;

    render() {
      const { navigation, onRef } = this.props;

      return (
        <NotificationsConsumer>
          {({
              notifications,
              getNotificationById,
              setActive,
            }) => (
            <Component
              {...this.props}
              navigation={navigation}
              ref={onRef}
              notifications={notifications}
              getNotificationById={getNotificationById}
              setNotificationsProviderActive={setActive}
            />
          )}
        </NotificationsConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithNotifications, Component);
};
