import React, { Component } from 'react';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';
import { withImages } from './withImages';
import { creationDateComparator } from '../utils';

const { notificationsRef } = Firebase;
const NotificationsContext = React.createContext();

export const NotificationsConsumer = NotificationsContext.Consumer;

class NotificationsProvider extends Component {
  state = {
    notifications: undefined,
    getNotificationById: id => this.getNotificationById(id),
    setActive: () => this.setActive(),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
  }

  setActive() {
    const { site: { id: siteId } = {} } = this.props;

    if (!this.unsusbscribe) {
      const query = notificationsRef.where('siteId', '==', siteId);
      this.unsusbscribe = query.onSnapshot(this.onSnapshot);
    }
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  async onSnapshot(snapshot) {
    const { notifications: prevNotifications } = this.state;

    const notifications = mergeSnapshotChangesIntoArray(snapshot, prevNotifications)
      .sort(creationDateComparator);
    this.setState({ notifications });
  }

  getNotificationById(id) {
    const { notifications } = this.state;
    return notifications.find(x => x.id === id);
  }

  render() {
    const { children } = this.props;

    return (
      <NotificationsContext.Provider value={this.state}>
        {children}
      </NotificationsContext.Provider>
    );
  }
}

const _NotificationsProvider = withImages(NotificationsProvider);
export { _NotificationsProvider as NotificationsProvider };

