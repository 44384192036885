import Firebase from '../components/Firebase';
import { config } from '../config';

const prepareRef = async (image, id, ref = config.imageFolder.dealImages) => {
  const { storage } = Firebase;
  if (ref === config.imageFolder.dealImages) {
    const storageRef = await storage.child(`${ref}/${id}/${image}`);
    return storageRef;
  }
  const storageRef = await storage.child(`${ref}/${image}`);
  return storageRef;
};

export const getImage = async (image, id, refer = config.imageFolder.dealImages) => {
  try {
    const fileName = (typeof image === 'string') ? image : image.fileName || image.name;
    const storageRef = await prepareRef(fileName, id, refer);
    console.log(`id=${id} fileName=${fileName} refer=${refer}`);
    const ref = await storageRef.getDownloadURL();
    return ref;
  } catch (e) {
    console.log(e.message.text);
    return '';
  }
};

export const saveImage = async (image, id, refer = config.imageFolder.dealImages) => {
  let { name } = image;
  name = refer === config.imageFolder.dealImages ? name : `${id}_${name}`;
  const storageRef = await prepareRef(name, id, refer);
  await storageRef.put(image);
  const ref = await storageRef.getDownloadURL();
  return {
    ref,
    name,
  };
};
export const deleteImage = async (name, id, ref = config.imageFolder.dealImages) => {
  const storageRef = await prepareRef(name, id, ref);
  await storageRef.delete();
};

export const getImages = async (images, id, refer = config.imageFolder.dealImages) => {
  const prettiedImages = [];

  if (Array.isArray(images)) {
    for (let index = 0; index < images.length; ++index) {
      const image = images[index];
      // const { fileName } = image;
      const ref = await getImage(image, id, refer);
      prettiedImages.push({ ref, ...image });
    }
    return prettiedImages;
  }
  else {
    alert('Unexpected condition. This is an issue');
  }
  // const ref = await getImage(images, id, refer);
  // return [{ ref, name: images }];
};

export const deleteImages = async (images, id, refer = config.imageFolder.dealImages) => {
  for (let index = 0; index < images.length; ++index) {
    const { name } = images[index];
    await deleteImage(name, id, refer);
  }
};

export const savePrettiedImages = async (newImages, id, refer = config.imageFolder.dealImages) => {
  const images = [];
  for (let index = 0; index < newImages.length; ++index) {
    const { ref, name } = await saveImage(newImages[index], id, refer);
    images.push({
      ref,
      name,
    });
  }
  return images;
};

export const getRefImageFromImages = (images,
  { index, ref: name = '' } = {}) => {

  if (images.length) {
    if (index !== undefined) {
      const { ref } = images[index];
      return ref;
    }
    const data = images.find(image => image.name === name);
    const { ref = '' } = data;
    return ref;
  }
  return '';
};
