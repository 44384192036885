import React, { Component, Fragment } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import style
  from '../../../assets/jss/material-dashboard-react/components/details.jsx';
import { withMerchants } from '../../../contextApi';
import MultiSelect from '../../../components/Engine/Common/MultiSelect/MultiSelect';

const merchantsMap = (merchant) => ({
  label: merchant.name,
  value: merchant.id,
});

const merchantValuesMap = (getMerchantById) => {
  return merchantId => {
    const merchant = getMerchantById(merchantId);
    const { name, id } = merchant;
    return {
      label: name,
      value: id,
    }
  };
};

class Select extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
  }

  onChange(event, data) {
    const { changeSelect } = this.props;

    if (changeSelect) {
      changeSelect({ target: { name: 'merchants'} }, data);
    }
  };


  render() {
    const {
      classes,
      value = [],
      merchants = [],
      getMerchantById,
      flex = false,
    } = this.props;

    const styleFlex = flex ? {
      flexDirection: 'row',
      marginBottom: 0,
      minWidth: '64%',
    } : { flexDirection: 'column' };
    const containerStyle = {
      ...styleFlex,
      // ...style,
    };
    // const labelHeaderClassName = flex ? classes.headerForRowFlex : classes.headerForRow;
    const options = merchants.map(merchantsMap);

    return (
      <div className={classes.selectForm} style={containerStyle}>
        <MultiSelect
          id="merchants"
          name="Merchants"
          isMainPage={true}
          onChange={this.onChange}
          options={options}
          value={value.map(merchantValuesMap(getMerchantById))}
        />
      </div>
    );
  }
}

export default withMerchants(withStyles(style)(Select));
