const customTotalsStyle = {
  upperText: {
    textTransform: 'uppercase',
  },

  row: {
    display: 'flex',

  },

  status: {
    minWidth: 100,
  },

  label: {
    textTransform: 'uppercase',
  },

  textRow: {
    marginTop: 10,
    marginBottom: 10,
    // width: '10px',
  },

  rows: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  root: {
    width: '50%',
  },
};

export default customTotalsStyle;
