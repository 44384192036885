import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Line } from 'rc-progress';

import withStyles from '@material-ui/core/styles/withStyles';
import Snack from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
// @material-ui/icons
import Close from '@material-ui/icons/Delete';
// core components
import snackbarContentStyle
  from '../../assets/jss/material-dashboard-react/components/snackbarContentStyle.jsx';

export class SnackbarContent extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    message: PropTypes.node.isRequired,
    color: PropTypes.oneOf(['info', 'success', 'warning', 'danger', 'primary']),
    close: PropTypes.bool,
    icon: PropTypes.func,
    rtlActive: PropTypes.bool,
    onCLickDelete: PropTypes.func,
    selectCurrentImage: PropTypes.func,
    reference: PropTypes.string,
    progress: PropTypes.number,
  };

  static defaultProps = {
    progress: 100,
  };

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  clickedInsideIcon(event) {
    const domNodeIcon = ReactDOM.findDOMNode(this.icon);
    return domNodeIcon.contains(event.target);
  }

  onClick(event) {
    const { selectCurrentImage, reference, message } = this.props;

    if (selectCurrentImage && !this.clickedInsideIcon(event)) {
      selectCurrentImage({
        currentImage: reference,
        currentImageName: message,
      });
    }
  }

  onDeleteClick() {
    const { onCLickDelete, reference } = this.props;

    if (onCLickDelete) {
      onCLickDelete(reference);
    }
  }

  getMessage() {
    const { classes, progress, icon, message } = this.props;
    const messageClasses = classNames({
      [classes.iconMessage]: icon !== undefined,
    });

    return (
      <Fragment>
        {icon !== undefined && (
          <props.icon className={classes.icon} />
        )}
        {progress === 100 && (
          <span className={messageClasses}>
            {message}
          </span>
        )}
        {progress < 100 && (
          <div className={classes.progressBarContainer}>
            <Line
              percent={progress}
              strokeWidth="4"
              strokeColor="#1ddbde"
            />
          </div>
        )}
      </Fragment>
    );
  }

  getDeleteButton() {
    const {
      classes,
    } = this.props;

    return (
      <IconButton
        className={classes.iconButton}
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={this.onDeleteClick}
        ref={(node) => {
          this.icon = node;
        }}
      >
        <Close className={classes.close} />
      </IconButton>
    )
  }

  render() {
    const {
      classes,
      color,
      close,
      rtlActive,
      selected,
    } = this.props;
    let action = [];
    const rootClasses = classNames(
      classes.root,
      classes[color], {
        [classes.selected]: selected,
      }
    );
    const message = this.getMessage();
    close && action.push(this.getDeleteButton());

    return (
      <Snack
        message={message}
        classes={{
          root: rootClasses,
          message: classes.message,
          action: classNames({ [classes.actionRTL]: rtlActive }),
        }}
        action={action}
        onClick={this.onClick}
      />
    );
  }
}

export default withStyles(snackbarContentStyle)(SnackbarContent);
