const headerLinksStyle = theme => ({
  iconContainer: {
    backgroundColor: 'white',
    width: '100px',
    height: '100px',
    display: 'flex',         /* NEW, Spec - Firefox, Chrome, Opera */
    justifyContent: 'center',
    alignItems: 'center',
  }

});

export default headerLinksStyle;
