import React, { Component } from 'react';
import _ from 'lodash';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';
import { creationDateComparator } from '../utils';

const { siteVisitsRef } = Firebase;
const SiteVisitsContext = React.createContext();

export const SiteVisitsConsumer = SiteVisitsContext.Consumer;

export class SiteVisitsProvider extends Component {
  state = {
    siteVisits: undefined,
    setActive: () => this.setActive(),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
  }

  // static getKeyValueList = (list = []) => list.map(item => ({
  //   key: item.id.toString(),
  //   value: item.name,
  // }));

  async onSnapshot(snapshot) {
    const { siteVisits: prevSiteVisits } = this.state;

    const siteVisits = mergeSnapshotChangesIntoArray(snapshot, prevSiteVisits)
      .sort(creationDateComparator);

    this.setState({ siteVisits });
  }

  setActive() {
    // const { site: { id: siteId } = {} } = this.props;

    if (!this.unsusbscribe) {
      this.unsusbscribe = siteVisitsRef
        .onSnapshot(this.onSnapshot);
    }
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  render() {
    const { children } = this.props;

    console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> SiteVisitsProvider this.state=' + JSON.stringify(this.state));

    return (
      <SiteVisitsContext.Provider value={this.state}>
        {children}
      </SiteVisitsContext.Provider>
    );
  }
}

// export default SiteVisitsProvider;