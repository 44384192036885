// This function is date formatter
import moment from 'moment-timezone';
import ft from 'firebase-timestamp-utils';

ft.setTimezone('Asia/Kuala_Lumpur');

export const formatDate = (date) => {
  let dd = date.getDate();
  dd = dd < 10 ? `0${dd}` : dd;

  let mm = date.getMonth() + 1;
  mm = mm < 10 ? `0${mm}` : mm;

  let yy = date.getFullYear() % 100;
  yy = yy < 10 ? `0${yy}` : yy;

  let h = date.getHours();
  h = h < 10 ? `0${h}` : h;

  let m = date.getMinutes();
  m = m < 10 ? `0${m}` : m;

  return `${dd}.${mm}.${yy} ${h}:${m}`;
};

// This function displaying difference between 2 date
export const dateDiffInDays = (dateFirst, dateSecond) => {
  const t1 = dateFirst.getTime();
  const t2 = dateSecond.getTime();
  return Math.floor((t2 - t1) / (24 * 3600 * 1000));
};

export const getDateFromFirebase = (value) => {
  if (value instanceof Date) {
    return value
  }
  if (value && (value.seconds || value._seconds)) {
    const seconds = value.seconds || value._seconds;
    return new Date(seconds * 1000);
  }
};

export const formatDateFromFirebase = (timestamp, { prettied = true } = {}) => {
  if (timestamp) {
    const seconds = timestamp.seconds || timestamp._seconds;
    const date = new Date(seconds * 1000);
    return prettied ? formatDate(date) : date;
  }
  return 'Invalid date';
};

export const toDate = (timestamp) => {
  if (timestamp instanceof Date) {
    return timestamp;
  }
  if (timestamp.seconds) {
    const result = moment.unix(timestamp.seconds);
    return result.toDate();
  }
};


export const getMallOpeningTime = (date) => {
  return ft.getStartOfDay(date, 10);
};

export const getMallClosingTime = (date) => {
  return ft.getStartOfDay(date, 22);
};

