import React, { Component, Fragment } from "react";
import _ from 'lodash';
import DeviceView from './DeviceView';

export default class DevicesList extends Component {
  state = {
    devicesArray: [],
  };

  constructor(props) {
    super(props);

    this.onDeleteClick = this.onDeleteClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { devices = {}, primaryDeviceId } = nextProps;
    let devicesArray = [];

    for (let [key, value] of Object.entries(devices)) {
      const device = {
        key,
        ...value,
      };
      devicesArray.push(device);
    }

    if (primaryDeviceId) {
      // Ensure primary device is at top
      // TODO : order by last accessed DT
      devicesArray.sort((a, b) => a.id === primaryDeviceId ? 1 : -1);
    }

    return {
      ...prevState,
      devicesArray,
    }
  }

  onDeleteClick(deviceId) {
    const { onDelete } = this.props;

    if (onDelete) {
      onDelete(deviceId)
    }
  }

  render() {
    const { devicesArray } = this.state;
    const { showRemove, showAppVersion, showLastAccessed, showDeviceId } = this.props;

    return (
      <Fragment>
        {devicesArray.map(device => (
          <DeviceView
            key={device.key}
            device={device}
            showRemove={showRemove}
            showAppVersion={showAppVersion}
            showLastAccessed={showLastAccessed}
            showDeviceId={showDeviceId}
            onDeleteClick={this.onDeleteClick}
          />
        ))}
        {_.isEmpty(devicesArray) && (
          <p>No devices currently registered for this merchant</p>
        )}
      </Fragment>
    )
  }
}