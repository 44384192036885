import React, { Component } from 'react';
import _ from 'lodash';
import Firebase from '../components/Firebase';
const { reimbursementsRef } = Firebase;
const ReimbursementsContext = React.createContext();
export const ReimbursementsConsumer = ReimbursementsContext.Consumer;

export class ReimbursementsProvider extends Component {
  state = {
    reimbursements: [],
    getReimbursementById: id => this.getReimbursementById(id),
  };

  getQuery() {
    const { site: { id: siteId } = {} } = this.props;

    return reimbursementsRef.where('siteId', '==', siteId);
  }

  async componentDidMount() {
    const rawReimbursements = await this.fetchRemote();
    const reimbursements = rawReimbursements.filter(x => !['0', 'dummy'].includes(x.id));

    this.setState({
      reimbursements,
      // redemptionStatuses: this.getRedemptionStatuses(vouchers),
    });

    this.unsusbscribe = this.getQuery().onSnapshot((list) => {
      const rawReimbursements = list.docs.map(x => x.data());
      const reimbursements = rawReimbursements.filter(x => !['0', 'dummy'].includes(x.id));
      // const sorted = _.sortBy(raw, 'name');

      this.setState({
        reimbursements,
        // redemptionStatuses: this.getRedemptionStatuses(vouchers),
      });
    });
  }

  async fetchRemote() {
    const snap = this.getQuery().get()
      .catch((error) => {
        console.log('Error fetching reimbursements', error);
      });

    const list = (snap.docs) ? snap.docs.map(item => item.data()) : [];

    return list;
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  getReimbursementById(id) {
    const { reimbursements } = this.state;
    return reimbursements.find(x => x.id === id);
  }

  render() {
    const { children } = this.props;

    return (
      <ReimbursementsContext.Provider value={this.state}>
        {children}
      </ReimbursementsContext.Provider>
    );
  }
}

export default ReimbursementsProvider;
