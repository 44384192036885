import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { IssuersConsumer } from './issuersProvider';

export const withIssuers = (Component) => {
  class ComponentWithIssuers extends React.Component {
    static displayName = `withMerchants(${Component.displayName || Component.name})`;

    render() {
      const { onRef } = this.props;

      return (
        <IssuersConsumer>
          {({
              issuers,
              getIssuerById,
            }) => (
            <Component
              {...this.props}
              ref={onRef}
              issuers={issuers}
              getIssuerById={getIssuerById}
            />
          )}
        </IssuersConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithIssuers, Component);
};
