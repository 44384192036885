import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import withStyles from '@material-ui/core/styles/withStyles';
import Navbar from '../components/Navbars/Navbar.jsx';
import Footer from '../components/Footer/Footer.jsx';
import Sidebar from '../components/Sidebar/Sidebar.jsx';
import routes from '../routes.js';
import dashboardStyle
  from '../assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx';
import image from '../assets/img/sidebar-2.jpg';
import logo from '../assets/img/reactlogo.png';
import ProtectedRoute from '../providers/ProtectedRoute';
import { withRouterInnerRef } from "../contextApi";

const SwitchRoutes = ({ classes, authMerchants = [], ...rest }) => {
  const filteredRoutes = routes.filter(x => {
    if (authMerchants.length) {
      return x.displayToMerchantEmployee === true;
    }
    return true;
  });
  return (
    <Switch>
      { filteredRoutes.map((prop, key) => {
        if (prop.layout === '/admin') {
          const path = prop.layout + prop.path;

          return (
            <ProtectedRoute
              path={ path }
              component={ prop.component }
              key={ key }
              authMerchants={ authMerchants }
            />
          );
        }
      }) }
      <Redirect to="/admin/customers"/>
    </Switch>
  )
};

// #EBEFF4
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: image,
      color: 'blue',
      hasImage: true,
      fixedClasses: 'dropdown',
      mobileOpen: false,
    };
  }

  handleImageClick = image => {
    this.setState({ image: image });
  };
  handleColorClick = color => {
    this.setState({ color: color });
  };
  handleFixedClick = () => {
    if (this.state.fixedClasses === 'dropdown') {
      this.setState({ fixedClasses: 'dropdown show' });
    } else {
      this.setState({ fixedClasses: 'dropdown' });
    }
  };
  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  resizeFunction = () => {
    if (window.innerWidth >= 960) {
      this.setState({ mobileOpen: false });
    }
  };

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      const ps = new PerfectScrollbar(this.refs.mainPanel);
    }
    window.addEventListener('resize', this.resizeFunction);
  }

  componentDidUpdate(e) {
    if (e.history.location.pathname !== e.location.pathname) {
      this.refs.mainPanel.scrollTop = 0;
      if (this.state.mobileOpen) {
        this.setState({ mobileOpen: false });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeFunction);
  }

  render() {
    const { classes, ...rest } = this.props;
    return (
      <div className={ classes.wrapper }>
        <Sidebar
          routes={ routes }
          logoText='Mall Management Console'
          logo={ logo }
          image={ this.state.image }
          handleDrawerToggle={ this.handleDrawerToggle }
          open={ this.state.mobileOpen }
          color={ this.state.color }
          { ...rest }
        />
        <div className={ classes.mainPanel } ref="mainPanel">
          <Navbar
            routes={ routes }
            handleDrawerToggle={ this.handleDrawerToggle }
            { ...rest }
          />
          <div className={ classes.content }>
            {/*<div className={classes.container}>{switchRoutes}</div>*/ }
            <SwitchRoutes { ...this.props } />
          </div>
          <Footer/>
          {/*<FixedPlugin*/ }
          {/*handleImageClick={this.handleImageClick}*/ }
          {/*handleColorClick={this.handleColorClick}*/ }
          {/*bgColor={this.state['color']}*/ }
          {/*bgImage={this.state['image']}*/ }
          {/*handleFixedClick={this.handleFixedClick}*/ }
          {/*fixedClasses={this.state.fixedClasses}*/ }
          {/*/>*/ }
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouterInnerRef(withStyles(dashboardStyle)(Dashboard));
