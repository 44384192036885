import React, { Component } from 'react';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';

const { issuersRef } = Firebase;
const IssuersContext = React.createContext();

export const IssuersConsumer = IssuersContext.Consumer;

export class IssuersProvider extends Component {
  state = {
    issuers: [],
    getIssuerById: id => this.getIssuerById(id),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
  }

  async onSnapshot(snapshot) {
    const { issuers: prevIssuers } = this.state;
    const issuers = mergeSnapshotChangesIntoArray(snapshot, prevIssuers);

    this.setState({ issuers });
  }

  componentDidMount() {
    const { site: { id: siteId } = {} } = this.props;

    this.unsubscribe = issuersRef
      .where('siteId', '==', siteId)
      .onSnapshot(this.onSnapshot);
  }

  getIssuerById(id) {
    const { issuers } = this.state;
    return issuers.find(x => x.id === id);
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  render() {
    const { children } = this.props;

    return (
      <IssuersContext.Provider value={this.state}>
        {children}
      </IssuersContext.Provider>
    );
  }
}

export default IssuersProvider;
