import { capitalizeFirstLetter, toCamelCase } from "./stringUtils";
import { library } from "@fortawesome/fontawesome-svg-core";


export const getFaIconName = (iconName) => {
  return `fa${capitalizeFirstLetter(toCamelCase(iconName))}`;
};

export const fetchIcon = (iconName, callback) => {
  const iconType = 'solid';
  const iconFileName = getFaIconName(iconName);
  return import(
    `@fortawesome/free-${iconType}-svg-icons/${iconFileName}.js`)
    .then((response) => {
      // this.fetched = true;
      if (response && response.definition) {
        // this.fetchedIcon = response.definition;
        library.add(response.definition);
        const iconDefinition = response.definition;
        // console.log('setState: iconDefinition = ' + JSON.stringify(iconDefinition));
        callback && callback(iconDefinition);
      }
    })
    .catch(err => {
      console.error('fetchIcon.err = ' + err);
      callback && callback({ iconName });
      // this.fetched = true;
    });
};