import React, { Component } from 'react';
import _ from 'lodash';
import { withSite, withMerchants } from '../../../contextApi';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { config } from '../../../config';
import Input from '../../../components/Engine/Common/Input/Input.jsx';
import MultiSelect from '../../../components/Engine/Common/MultiSelect/MultiSelect';
import ImageManager from '../../../components/Images/ImageManager';
import rundef from "rundef";
import { QRCode } from 'react-qr-svg';
import DevicesList from './DevicesList';
import Firebase from "../../../components/Firebase";
import EntityEditor from '../../EntityEditor';
import { getIdFromPath } from '../../../utils/entityUtils';
import { prepareImageForFirebase } from "../../../utils/imageUtils";

const mapLotToSelectItem = ({ id }) => ({ label: id, value: id });
const mapCategoryToSelectItem = ({ id, name }) => ({ label: name, value: id });
const mapCategoryIdToSelectItem = (id, dealCategories = []) => {
  if (dealCategories.length) {
    return {
      label: dealCategories.find(y => y.id === id).name,
      value: id,
    }
  }
};

const Placement = {
  above: 'above',
  adjacent: 'adjacent',
  below: 'below',
};
const labelPlacements = Object.keys(Placement).map(x => ({ value: x, label: x }));

export const Direction = {
  left: 'left',
  right: 'right',
};
const labelDirections = Object.keys(Direction).map(x => ({ value: x, label: x }));

class MerchantInfo extends Component {
  state = {
    merchant: {},
    lotsOptions: [],
    categoriesOptions: [],
  };

  constructor(props) {
    super(props);

    this.onImageUpdate = this.onImageUpdate.bind(this);
    this.onLogoUpdate = this.onLogoUpdate.bind(this);
    this.onRemoveDevice = this.onRemoveDevice.bind(this);
    this.getEntityDocument = this.getEntityDocument.bind(this);
    this.setImageManagerRef = this.setImageManagerRef.bind(this);

    this._imageManager =  React.createRef();
    this._logoImageManager = React.createRef();
  }

  componentDidMount() {
    const id = getIdFromPath();
    const { setMerchantsProviderActive, getMerchantById } = this.props;
    let merchant = getMerchantById(id);

    if (!merchant) {
      setMerchantsProviderActive(() => {
        merchant = getMerchantById(id);
        this.setState(prevState => ({
          ...prevState,
          merchant,
        }));
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { merchants = [], site: { lots: rawAllLots, categories: rawAllCategories } } = nextProps;
    const {
      merchant: prevMerchant,
      lotsOptions: prevLotsOptions,
      categoriesOptions: prevCategoriesOptions,
    } = prevState;
    // dealCategories.map(x => ({label: x.value, value: x.key}))
    let merchant, lotsOptions, categoriesOptions;

    if (_.isEmpty(prevMerchant) && merchants.length) {
      merchant = MerchantInfo.getMerchant(nextProps);
    }

    if (_.isEmpty(prevLotsOptions) && !_.isEmpty(rawAllLots)) {
      lotsOptions = rawAllLots.map(mapLotToSelectItem);
    }

    if (_.isEmpty(prevCategoriesOptions) && !_.isEmpty(rawAllCategories)) {
      categoriesOptions = rawAllCategories.map(mapCategoryToSelectItem);
    }

    return {
      ...prevState,
      ...(merchant && { merchant }),
      ...(lotsOptions && { lotsOptions }),
      ...(categoriesOptions && { categoriesOptions }),
    }
  }

  static getMerchant({ getNextId, getMerchantById, site }) {
    const id = getIdFromPath();
    return (id === 'add') ?
      MerchantInfo.getNewMerchant(getNextId, site) :
      { ...getMerchantById(id) };
  }

  static getNewMerchant(getNextId, { id: siteId }) {
    return {
      siteId,
      id: getNextId(),
    }
  }

  getEntityDocument() {
    const { merchant } = this.state;

    const merchantDocument = {
      ...merchant,
    };

    prepareImageForFirebase(merchantDocument);
    prepareImageForFirebase(merchantDocument, 'logo');

    // Removes undefined fields, necessary for Firebase to not throw a wobbly
    const result = rundef(
      merchantDocument,
      true,    // mutate - whether to mutate the original object or return a new one
      3,     // recursive - the level to apply recursively
    );

    return result;
  }

  onRemoveDevice = (deviceId) => {
    this.setState(prevState => {
      const merchant = {...prevState.merchant};
      delete merchant.devices[deviceId];

      return {
        ...prevState,
        merchant,
      };
    });
  };

  onInputChange = (event, data) => {
    const { key, value } = data;

    if (key) {
      this.updateState(key, value);
    }
  };

  updateState(key, value) {
    this.setState(prevState => {
      const merchant = {...prevState.merchant};
      _.set(merchant, key, value);

      return {
        ...prevState,
        merchant,
      };
    });
  }

  onImageUpdate(imagesArr) {
    const image = imagesArr[0];
    this.setState(prevState => ({
      ...prevState,
      merchant: {
        ...prevState.merchant,
        image,
      },
    }));
  }

  onLogoUpdate(imagesArr) {
    const logo = imagesArr[0];
    this.setState(prevState => ({
      ...prevState,
      merchant: {
        ...prevState.merchant,
        logo,
      },
    }));
  }

  static getImagesArray(merchant) {
    const { image, imageFileName } = merchant;

    if (!_.isEmpty(image)) return [{
      ...image,
      fileName: image.fileName || image.name,
    }];
    if (typeof imageFileName === 'string' && imageFileName.length) return [{ fileName: imageFileName }];
    return [];
  }

  static getLogoImagesArray(merchant) {
    const { logo } = merchant;
    if (typeof logo === 'string' && logo.length) return [{ fileName: logo }];
    if (!_.isEmpty(logo)) return [logo];
    return [];
  }

  getImageManagers() {
    const { _imageManager: imageMgrCurrent, _logoImageManager: logoImageMgrCurrent } = this;
    return [imageMgrCurrent, logoImageMgrCurrent];
  }

  setImageManagerRef = (ref, value) => {
    console.log(`setImageManagerRef this[value]=${this[value]} ref=${ref}`);
    if (this[value] !== ref && !_.isEmpty(ref)) {
      this[value] = ref;
      const imageManagers = this.getImageManagers();
      this.setState(prevState => ({
        ...prevState,
        imageManagers,
      }));
    }
  };

  render() {
    const { deleteMerchantById, site: { categories: dealCategories } } = this.props;
    const { merchant = {}, imageManagers, lotsOptions, categoriesOptions } = this.state;
    const {
      id = '',
      name,
      phone,
      website,
      email,
      lots = [],
      categories = [],
      image = {},
      logo,
      devices = {},
      description,
      googleMapsPlaceId,
      location = {},
    } = merchant;
    const { coords = {} } = location;
    const imageFileName = image.name;
    const imagesArr = MerchantInfo.getImagesArray(merchant);
    const logoImagesArr = MerchantInfo.getLogoImagesArray(merchant);
    const categoriesSelectValue = categories.map(x => mapCategoryIdToSelectItem(x, dealCategories));
    const labelPlacement = (location.labelPlacement) ? [{ label: location.labelPlacement, value: location.labelPlacement }] : undefined;
    const labelDirection = (location.labelDirection) ? [{ label: location.labelDirection, value: location.labelDirection }] : undefined;

    return (
      <EntityEditor
        onDeleteClick={deleteMerchantById}
        getEntityDocument={this.getEntityDocument}
        collection={Firebase.merchantsRef}
        createEndpoint={`merchant/create`}
        cancelHref="/admin/merchants"
        imageManager={imageManagers}
        entityName="merchant"
        id={id}
      >
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <h3>MERCHANT</h3>
            <Input
              id="name"
              name="Name"
              value={name}
              onChange={this.onInputChange}
              flex={true}
            />
            <MultiSelect
              id="lots"
              onChange={this.onInputChange}
              name="Lot(s)"
              value={lots.map(x => ({label: x, value: x}))}
              flex={true}
              options={lotsOptions}
            />
            <Input
              id="phone"
              name="Phone"
              value={phone}
              onChange={this.onInputChange}
              flex={true}
            />
            <Input
              id="website"
              name="Website"
              value={website}
              onChange={this.onInputChange}
              flex={true}
            />
            <Input
              id="email"
              name="Email"
              value={email}
              onChange={this.onInputChange}
              flex={true}
            />
            <MultiSelect
              id="categories"
              onChange={this.onInputChange}
              name="Category"
              value={categoriesSelectValue}
              flex={true}
              options={categoriesOptions}
            />
            <h3>STORE DESCRIPTION</h3>
            <Input
              id="description"
              value={description}
              onChange={this.onInputChange}
              placeholder="describe the merchant's business here"
              flex={true}
              type="area"
            />
            <h3>MERCHANT LOGO</h3>
            <ImageManager
              imagesArr={logoImagesArr}
              ref={ref => this.setImageManagerRef(ref, '_logoImageManager')}
              imageNamePrefix={`${id}_`}
              onChange={this.onLogoUpdate}
              path="merchantLogos"
              text="100 x 100 pixels"
            />
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}>
            <h3>LOCATION</h3>
            <Input
              id="location.coords.latitude"
              name="Latitude"
              value={coords.latitude}
              onChange={this.onInputChange}
              type="float"
              flex={true}
            />
            <Input
              id="location.coords.longitude"
              name="Longitude"
              value={coords.longitude}
              onChange={this.onInputChange}
              type="float"
              flex={true}
            />
            <Input
              id="location.placeId"
              name="Google Place Id"
              value={location.placeId}
              onChange={this.onInputChange}
              flex={true}
            />
            <Input
              id="location.atZoom"
              name="Display at zoom level"
              value={location.atZoom}
              onChange={this.onInputChange}
              flex={true}
            />
            <Input
              id="location.labelAtZoom"
              name="Display label at zoom level"
              value={location.labelAtZoom}
              onChange={this.onInputChange}
              flex={true}
            />
            <MultiSelect
              id="location.labelPlacement"
              targetName="location.labelPlacement"
              isMainPage={true}
              name="Label Placement"
              options={labelPlacements}
              value={labelPlacement}
              key="labelPlacement"
              onChange={this.onInputChange}
              isMulti={false}
            />
            <MultiSelect
              id="location.labelDirection"
              targetName="Label Direction"
              isMainPage={true}
              name="Label Direction"
              options={labelDirections}
              value={labelDirection}
              key="labelDirection"
              onChange={this.onInputChange}
              isMulti={false}
            />
            <Input
              id="location.type"
              name="Marker type"
              value={location.type}
              onChange={this.onInputChange}
              flex={true}
            />
            <Input
              id="location.shortName"
              name="Short map name"
              value={location.shortName}
              onChange={this.onInputChange}
              flex={true}
            />

            <h3>REGISTERED DEVICES</h3>
            <DevicesList
              devices={devices}
              onDelete={this.onRemoveDevice}
            />
            <h3>ADD REGISTERED DEVICE</h3>
            <p>To add a device, install the Plaza Low Yat merchant app and scan the following code</p>
            <QRCode
              bgColor="#FFFFFF"
              fgColor="#000000"
              level="Q"
              style={{ width: 128 }}
              value={id}
            />
            <h3>STOREFRONT IMAGE</h3>
            <ImageManager
              imagesArr={imagesArr}
              ref={ref => this.setImageManagerRef(ref, '_imageManager')}
              // imageNamePrefix={`${id}_`}
              onChange={this.onImageUpdate}
              path="merchantImages"
            />
          </GridItem>
        </GridContainer>
      </EntityEditor>
    );
  }
}

export default withSite(withMerchants(MerchantInfo));
