import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { SiteConsumer } from './siteProvider';

export const withSite = (Component) => {
  class ComponentWithSite extends React.Component {
    static displayName = `withSite(${Component.displayName || Component.name})`;

    render() {
      const { navigation, onRef } = this.props;

      return (
        <SiteConsumer>
          {({
              site,
              getFilteredLots,
              deleteLot,
              getLotById,
              createLot,
              getCategoryById,
              updateCategory,
              createCategory,
              deleteCategory,
            }) => (
            <Component
              {...this.props}
              navigation={navigation}
              ref={onRef}
              site={site}
              getFilteredLots={getFilteredLots}
              deleteLot={deleteLot}
              getLotById={getLotById}
              getCategoryById={getCategoryById}
              createLot={createLot}
              updateCategory={updateCategory}
              createCategory={createCategory}
              deleteCategory={deleteCategory}
            />
          )}
        </SiteConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithSite, Component);
};
