import React, { Component } from 'react';
import Firebase from '../components/Firebase';
import axios from 'axios';
import _ from 'lodash';
import firebaseConfig from '../config';

const { sitesRef, FieldValue } = Firebase;

const id = 'plaza-low-yat'; // parseDomain(window.location).subdomain;
const SiteContext = React.createContext();

const filterLotsByLevels = (lots, levels) => lots.filter(({level}) => levels.includes(level));

const getLotsSorter = (levels) => {
  return (lot1, lot2) => {
    const { level: level1 } = lot1;
    const { level: level2 } = lot2;

    const index1 = levels.findIndex(x => x === level1);
    const index2 = levels.findIndex(x => x === level2);

    if (index1 > index2) return 1;
    else if (index1 < index2) return -1;

    // If the same level, sort alpha
    if (lot1 < lot2) return -1;
    else if (lot1 > lot2) return 1;

    return 0;
  };
};

export const SiteConsumer = SiteContext.Consumer;

export class SiteProvider extends Component {
  state = {
    site: {
      id,
      name: 'Plaza Low Yat',
      categories: [],
      levels: [],
      lots: [],
    },
    getFormattedCategories: categoryIds => this.getFormattedCategories(categoryIds),
    getFilteredLots: filters => this.getFilteredLots(filters),
    deleteLot: id => this.deleteLot(id),
    getLotById: id => this.getLotById(id),
    // updateLot: lot => this.updateLot(lot),
    createLot: lot => this.createLot(lot),
    getCategoryById: id => this.getCategoryById(id),
    updateCategory: category => this.updateCategory(category),
    createCategory: category => this.createCategory(category),
    deleteCategory: id => this.deleteCategory(id),
  };

  constructor(props) {
    super(props);

    this.getFilteredLots = this.getFilteredLots.bind(this);
    this.deleteLot = this.deleteLot.bind(this);
    this.onSnapshot = this.onSnapshot.bind(this);
  }

  async onSnapshot(snapshot) {
    const { site: prevSite } = this.state;

    snapshot.docChanges().forEach(change => {
      const remoteSite = change.doc.data();
      const { levels, categories: rawCategories, lots: rawLots } = remoteSite;
      const lots = Object.entries(rawLots)
        .map(([key, value]) => ({ ...value }))
        .sort(getLotsSorter(levels));
      const categories = Object.entries(rawCategories)
        .map(([key, value]) => ({ ...value }));

      const site = {
        ...prevSite,
        lots,
        levels,
        categories,
      };

      console.log('siteProvider.onSnapshot()');
      this.setState({ site });
    });
  }

  componentDidMount() {
    if (!this.unsusbscribe) {
      this.unsusbscribe = sitesRef
        .where('id', '==', id)
        .onSnapshot(this.onSnapshot);
    }
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  getFilteredLots(filters) {
    const { site: { lots } } = this.state;
    const { levels } = filters;

    let filteredLots = [...lots];
    if (levels.length > 0) {
      filteredLots = filterLotsByLevels(lots, levels);
    }

    console.log('getFilteredLots filteredLots.length=', filteredLots.length);
    return filteredLots;
  }

  async deleteLot(lotId) {
    console.log('deleting lot = ', lotId);
    sitesRef.doc(id).update({
      [`lots.${lotId}`]: FieldValue.delete(),
    });
  }

  async deleteCategory(categoryId) {
    console.log('deleting category = ', categoryId);
    sitesRef.doc(id).update({
      [`categories.${categoryId}`]: FieldValue.delete(),
    });
  }

  async updateCategory(category) {
    const { id: categoryId } = category;
    const updateDocument = { [`categories.${categoryId}`]: category };
    const response = await sitesRef.doc(id).update(updateDocument)
      .then(response => ({ success: true }))
      .catch(err => {
        const { error } = err.response.data;
        return { error, success: false }
      });

    return response;
  }

  async createCategory(category) {
    const { baseUrl } = firebaseConfig;
    const createEndpoint = `category/create/${id}`;
    const requestUrl = `${baseUrl}/${createEndpoint}`;
    const response = await axios.post(requestUrl, category)
      .then(response => {
        return {
          data: response.data,
          success: true,
        }
      })
      .catch(err => {
        const error = _.get(err, 'response.data.error') || err.message;
        return {
          error,
          success: false,
        }
      });

    return response;
  }

  async createLot(lot) {
    const { baseUrl } = firebaseConfig;
    const { id: lotId } = lot;
    const createEndpoint = `lot/create/${id}`;
    const requestUrl = `${baseUrl}/${createEndpoint}`;
    console.log('lot = ', JSON.stringify(lot));
    const response = await axios.post(requestUrl, lot)
      .then(response => {
        const { data } = response;
        return { data, success: true };
      })
      .catch(err => {
        const error = _.get(err, 'response.data.error') || err.message;
        return { error, success: false };
      });

    return response;
  }

  getLotById(id) {
    const { site: { lots } } = this.state;
    return lots.find(x => x.id === id);
  }

  getCategoryById(id) {
    const { site: { categories } } = this.state;
    const category = categories.find(x => x.id === id);
    return _.cloneDeep(category);
  }

  render() {
    const { children } = this.props;

    return (
      <SiteContext.Provider value={this.state}>
        {children}
      </SiteContext.Provider>
    )
  }
}
