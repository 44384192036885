import * as firebase from 'firebase';
import _ from 'lodash';
import config from '../../config';

import short from 'short-uuid';
// const short = require('short-uuid');

const translator = short(); // Defaults to flickrBase58

// const uuid = require('uuid/v1');

firebase.initializeApp(config);

const databaseRef = firebase.firestore();
const storageImg = firebase.storage();
const storage = storageImg.ref();
const auth = firebase.auth();
const FieldValue = firebase.firestore.FieldValue;
const serverTimestamp = FieldValue.serverTimestamp;
const arrayRemove = FieldValue.arrayRemove;
const arrayUnion = FieldValue.arrayUnion;
const googleProvider = new firebase.auth.GoogleAuthProvider();
const deleteType = firebase.firestore.FieldValue.delete();


export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export const signOut = () => auth.signOut();

export const onAuthChanged = (user) => {
  if (user) {
    const { displayName, photoURL } = user;
    localStorage.setItem('name', displayName);
    localStorage.setItem('photo', photoURL);
    localStorage.setItem('auth', 'true');

    return true;
  }
  localStorage.clear();
  return false;
};

export const observerAuth = (changeState) => {
  firebase.auth().onAuthStateChanged(onAuthChanged);
};

const Firebase = {
  vouchersRef: databaseRef.collection('vouchers'),
  salesReceiptsRef: databaseRef.collection('salesReceipts'),
  dealsRef: databaseRef.collection('deals'),
  usersRef: databaseRef.collection('users'),
  campaignsRef: databaseRef.collection('campaigns'),
  employeesRef: databaseRef.collection('merchantEmployees'),
  merchantsRef: databaseRef.collection('merchants'),
  promotionsRef: databaseRef.collection('promotions'),
  receiptsRef: databaseRef.collection('salesReceipts'),
  notificationsRef: databaseRef.collection('notifications'),
  reimbursementsRef: databaseRef.collection('reimbursements'),
  issuersRef: databaseRef.collection('issuers'),
  sitesRef: databaseRef.collection('sites'),
  siteVisitsRef: databaseRef.collection('userSiteVisits'),
  FieldValue,
  serverTimestamp,
  arrayRemove,
  arrayUnion,
  storage,
  storageImg,
  auth,
  googleProvider,
  deleteType,
  uuid: translator.generate,
};

export const mergeSnapshotChangesIntoArray = (snapshot, sourceArray = []) => {
  const resultArray = [...sourceArray];

  snapshot.docChanges().forEach(change => {
    const item = {
      ...change.doc.data(),
      remote: true, // tag the item with a remote attribute
    };
    const { id } = item;

    if (change.type === "added") {
      const index = resultArray.findIndex(x => x.id === id);
      if (index === -1) {
        resultArray.unshift(item);
      }
      else {
        resultArray[index] = item;
      }
    }

    if (change.type === "modified") {
      const index = resultArray.findIndex(x => x.id === id);
      resultArray[index] = item;
    }

    if (change.type === "removed") {
      console.log('change.type.removed');
      const index = resultArray.findIndex(x => x.id === id);
      if (index >= 0) {
        resultArray.splice(index, 1);
      }
    }
  });

  return resultArray;
};

export default Firebase;
