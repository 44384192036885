import React, {Component, Fragment} from 'react';
import {withRouter} from "react-router-dom";
import PropTypes from 'prop-types';

export const withRouterInnerRef = (WrappedComponent) => {

  class InnerComponent extends React.Component {
    render() {
      const { forwardRef, ...rest } = this.props;
      return <WrappedComponent {...rest} ref={forwardRef} />;
    }
  }

  const InnerComponentWithRef = withRouter(InnerComponent, { withRef: true });

  return React.forwardRef((props, ref) => {
    return <InnerComponentWithRef {...props} forwardRef={ref} />;
  });
}