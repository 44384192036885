
const style = {
  successButton: {
    backgroundColor: 'green',
    color: '#fff',
    opacity: 0.4,
    '&:hover': {
      backgroundColor: 'green',
      color: '#fff',
      opacity: 0.9,
    },
  },
  leftCol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  nothingSelect: {
    fontSize: '3vw',
    textAlign: 'center',
    paddingTop: 50,
  },
};

export default style;
