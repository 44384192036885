import {
  defaultFont,
  dangerColor,
  whiteColor,
} from '../../material-dashboard-react.jsx';

import dropdownStyle from '../dropdownStyle.jsx';

const headerLinksStyle = theme => ({
  ...dropdownStyle(theme),
  search: {
    '& > div': {
      marginTop: '0',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '10px 15px !important',
      float: 'none !important',
      paddingTop: '1px',
      paddingBottom: '1px',
      padding: '0!important',
      width: '60%',
      marginTop: '40px',
      '& input': {
        color: whiteColor,
      },
    },
  },
  linkText: {
    zIndex: '4',
    ...defaultFont,
    fontSize: '14px',
    margin: '0px',
  },
  buttonLink: {
    // marginTop: '-10px !important ',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '10px 15px 0',
      width: '-webkit-fill-available',
      '& svg': {
        width: '24px',
        height: '30px',
        marginRight: '15px',
        marginLeft: '-15px',
      },
      '& .fab,& .fas,& .far,& .fal,& .material-icons': {
        fontSize: '24px',
        lineHeight: '30px',
        width: '24px',
        height: '30px',
        marginRight: '15px',
        marginLeft: '-15px',
      },
      '& > span': {
        justifyContent: 'flex-start',
        width: '100%',
      },
    },
  },
  searchButton: {
    [theme.breakpoints.down('sm')]: {
      top: '-50px !important',
      marginRight: '22px',
      float: 'right',
    },
  },
  margin: {
    zIndex: '4',
    margin: '0',
  },
  searchIcon: {
    width: '17px',
    zIndex: '4',
  },
  searchBar: {
    alignSelf: 'flex-start',
    justifyContent: 'center',
  },
  notifications: {
    zIndex: '4',
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: '2px',
      border: `1px solid ${whiteColor}`,
      right: '4px',
      fontSize: '9px',
      background: dangerColor[0],
      color: whiteColor,
      minWidth: '16px',
      height: '16px',
      borderRadius: '10px',
      textAlign: 'center',
      lineHeight: '16px',
      verticalAlign: 'middle',
      display: 'block',
    },
    [theme.breakpoints.down('sm')]: {
      ...defaultFont,
      fontSize: '14px',
      marginRight: '8px',
    },
  },
  manager: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    display: 'inline-block',
  },
  searchWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: '-webkit-fill-available',
      margin: '10px 15px 0',
      paddingLeft: '0 !important',
    },
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'center',
  },
  flexContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'block',
    },
    width: 'inherit',
    display: 'flex',
  },
  selectColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
  },
  containerSelect: {
    display: 'flex',
    flexDirection: 'column',
    width: '45%',
    maxWidth: 350,
  },
  containerSelectedItems: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    marginTop: -9,
    boxShadow: '0 14px 26px -12px rgba(156, 39, 176, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(156, 39, 176, 0.2)',
    borderRadius: '15px 15px 15px 15px',
  },
  searchContent: {
    flex: '1 1 auto',
  },
  myIcons: {
    [theme.breakpoints.down('sm')]: {
      margin: '-5px 10px 0 -19px',
      height: 35,
      borderRadius: 3,
    },
    height: 40,
    borderRadius: 10,
  },
  userProfileButton: {
    margin: 0,
    padding: 0,
  },
  nameDiv: {
    [theme.breakpoints.down('lg')]: {
      display: 'flex',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
    },
  },
  labelName: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    paddingRight: 16,
    textTransform: 'none',
    fontSize: '20px',
    // alignItems: 'center',
    //
    // textAlign: 'center',
    // verticalAlign: 'middle',
  },
});

export default headerLinksStyle;
