import {
  blackColor,
  whiteColor,
  hexToRgb,
} from '../../material-dashboard-react.jsx';
import dropdownStyle from '../dropdownStyle';

const cardStyle = theme => ({
  ...dropdownStyle(theme),
  card: {
    border: '0',
    marginBottom: '30px',
    marginTop: '30px',
    borderRadius: '6px',
    color: `rgba(${hexToRgb(blackColor)}, 0.87)`,
    background: whiteColor,
    width: '100%',
    boxShadow: `0 1px 4px 0 rgba(${hexToRgb(blackColor)}, 0.14)`,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: '0',
    wordWrap: 'break-word',
    fontSize: '.875rem',
  },
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none',
  },
  cardProfile: {
    marginTop: '30px',
    textAlign: 'center',
  },
  cardChart: {
    '& p': {
      marginTop: '0px',
      paddingTop: '0px',
    },
  },
  smallCard: {
    marginLeft: 0,
    marginRight: 20,
    cursor: 'pointer',
    width: '115 !important',
    [theme.breakpoints.down('lg')]: {
      height: 190,
    },
    [theme.breakpoints.down('md')]: {
      height: 145,
    },
    [theme.breakpoints.down('sm')]: {
      height: 100,
    },
    [theme.breakpoints.down('xs')]: {
      height: 85,
    },

    '&:hover': {
      backgroundColor: '#298B8E',
      color: 'white',
      opacity: 0.5,
      '& img': {
        filter: 'invert(100%)',
      },
    },
  },
  pickedSmallCard: {
    marginLeft: 0,
    marginRight: 20,
    cursor: 'pointer',
    [theme.breakpoints.down('lg')]: {
      height: 190,
    },
    [theme.breakpoints.down('md')]: {
      height: 145,
    },
    [theme.breakpoints.down('sm')]: {
      height: 100,
    },
    [theme.breakpoints.down('xs')]: {
      height: 85,
    },


    width: '115 !important',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'white',
    backgroundColor: '#298B8E',
    opacity: 0.9,
  },
  smallCardImage: {
    height: '3vw',
    paddingTop: 8,
  },
  smallCardImagePicked: {
    filter: 'invert(100%)',
  },
  smallCardTitle: {
    fontSize: '1vw',
    textAlign: 'center',
    marginBottom: 0,
    marginTop: 0,
    opacity: 0.5,
    lineHeight: 1.1,
  },
  smallCardDescription: {
    textAlign: 'center',
    opacity: 0.5,
    fontSize: '0.7vw',
    lineHeight: 1.1,
  },
  smallCardAvatar: {
    textAlign: 'center',
  },
  cardBody: {
    paddingTop: 0,
    paddingLeft: 5,
    paddingRight: 5,
  },

});

export default cardStyle;
