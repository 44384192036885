const radioGroupStyle = theme => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: '3',
  },
  group: {
    margin: '1',
  },
});

export default radioGroupStyle;