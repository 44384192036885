import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { ReimbursementsConsumer } from './reimbursementsProvider';

export const withReimbursements = (Component) => {
  class ComponentWithReimbursements extends React.Component {
    static displayName = `withReimbursements(${Component.displayName || Component.name})`;

    render() {
      const { navigation, onRef } = this.props;

      return (
        <ReimbursementsConsumer>
          {({
              reimbursements,
              getReimbursementById,
            }) => (
            <Component
              {...this.props}
              navigation={navigation}
              ref={onRef}
              reimbursements={reimbursements}
              getReimbursementById={getReimbursementById}
            />
          )}
        </ReimbursementsConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithReimbursements, Component);
};
