const styles = theme => ({
  root: {
    width: "100%",
    marginTop: 0, // theme.spacing.unit * 3
  },
  table: {
    minWidth: 1020
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

export default styles;
