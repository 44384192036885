import { config } from '../../config';

/**
 * Filters the merchants by including those that are members of at least one of the categories
 * @param merchants
 * @param categories
 * @returns {*}filterMerchantsByCategories
 */
export const filterMerchantsByCategories = (merchants, categories) => merchants.filter((el) => {
  const { any } = config.statuses;
  const { categories: merchantCategory } = el;
  const category = merchantCategory.join(', ');
  if (!categories.length || (categories.length === 1 && categories[0].value === any)) {
    return true;
  }

  return categories.reduce((previousValue, categoryNeed) => {
    return previousValue || category.includes(categoryNeed)
  }, false);
});


