import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import rundef from 'rundef';
import { withRouter } from 'react-router-dom';
import Firebase from '../../components/Firebase';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import ReactImageMagnify from 'react-image-magnify';
import { withReceipts, withMerchants } from '../../contextApi';
import receiptInfoStyle from './receiptInfoStyle';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from '@material-ui/core/Button';
import Input from '../../components/Engine/Common/Input/Input.jsx';
import DatePicker from "../../components/Engine/Common/DatePicker/DatePicker";
import EntityEditor from "../EntityEditor";
import {config} from "../../config";
import { getSmallImageFilename } from '../../utils/imageUtils';
import { formatDateFromFirebase } from "../../utils/date";
import { getDateFromFirebase } from '../../utils/date';
import NavBarDropdownMenu from '../../components/Navbars/NavBarDropdownMenu';
import { getCapturedByMerchant } from './receiptUtils';

class ReceiptInfo extends Component {
  state = {
    receipt: undefined,
  };

  constructor(props) {
    super(props);

    this.entityEditor = React.createRef();

    this.getEntityDocument = this.getEntityDocument.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onDateFieldChange = this.onDateFieldChange.bind(this);
    this.onMerchantChange = this.onMerchantChange.bind(this);
    this.onRejectButtonClick = this.onRejectButtonClick.bind(this);
  }


  static getIdFromPath = (status = '') => {
    const { href } = window.location;
    return href.split('/').pop();
  };

  /**
   * TODO : abstract these methods, can't modify image utils yet as legacy code still accesses
   * @param imageName
   * @param path
   * @returns {Promise<*>}
   */
  async prepareRef(imageName, path) {
    const { storage } = Firebase;
    const storageRef = await storage.child(`${path}/${imageName}`);

    return storageRef;
  };

  async getImage(imageName, path) {
    try {
      const storageRef = await this.prepareRef(imageName, path);
      const ref = await storageRef.getDownloadURL();
      return ref;
    } catch (e) {
      console.log(e.name_, e.message_);
      return '';
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getReceiptById, receipts = [] } = nextProps;

    if (!prevState.receipt && receipts.length) {
      const id = ReceiptInfo.getIdFromPath();
      // Omit read only properties
      const receipt = getReceiptById(id);

      return {
        ...prevState,
        receipt,
      }
    }

    return {
      ...prevState,
    }
  }

  async componentDidMount() {
    const { receipt } = this.state;
    if (receipt) {
      await this.fetchImages(receipt);
    }
  }

  async componentDidUpdate() {
    const { receipt } = this.state;
    if (receipt) {
      await this.fetchImages(receipt);
    }
  }

  async fetchImages(receipt) {
    const { primaryImage, imageFileName } = receipt;

    // Check to see if our selected image still exists
    if (receipt && !primaryImage) {
      const _primaryImage = await this.getImage(imageFileName, 'salesReceipts');
      const smallerImage = await this.getImage(getSmallImageFilename(imageFileName), 'salesReceipts');

      this.setState(prevState => ({
        ...prevState,
        receipt: {
          ...prevState.receipt,
          primaryImage: _primaryImage,
          smallerImage,
        }
      }));
    }
  }

  static getImageMagnifyStyles(imageSrc, smallerImage) {
    return {
      smallImage: {
        alt: 'Wristwatch by Ted Baker London',
        isFluidWidth: true,
        src: smallerImage,
      },
      largeImage: {
        src: imageSrc,
        width: 1200,
        height: 1800
      },
      isHintEnabled: true,
      shouldHideHintAfterFirstActivation: false,
      enlargedImageContainerStyle: {
        width: 500,
        height: 'auto',
      },
    };
    return {
      enlargedImageContainerStyle: {
        // width: 300,
        // height: 'auto',
      },
      smallImageStyle: {
        // alt: 'Wristwatch by Ted Baker London',
        isFluidWidth: true,
        src: imageSrc,
        width: 300,
        height: 'auto',
      },
      largeImageStyle: {
        src: imageSrc,
        width: 1200,
        height: 1800
      },
      // enlargedImageContainerDimensions: {
      //   width: 1000,
      //   height: 'auto',
      // }
      enlargedImageContainerDimensions: {
        // width: 10,
        // height: 10,
      },
      isHintEnabled: true,
      shouldHideHintAfterFirstActivation: false
    };
  }

  updateState(key, value) {
    this.setState(prevState => {
      const receipt = {...prevState.receipt};
      _.set(receipt, key, value);

      return {
        ...prevState,
        receipt,
      };
    });
  }

  onInputChange(event, data) {
    const { key, value } = data;

    if (key) {
      // TODO : methodise this for all possible types
      // const _value = (type === 'currency') ? Number.parseFloat(value) : value;
      this.updateState(key, value);
    }
  }

  onDateFieldChange(value, key) {
    const date = (value instanceof Date) ? value : value.toDate();

    if (date) {
      this.updateState(key, date);
    }
  }

  onMerchantChange(event, { suggestion }) {
    const { getMerchantById } = this.props;
    // name is the id apparently
    const { name: merchantId } = suggestion;
    const merchant = getMerchantById(merchantId);
    const merchantSummary = _.pick(merchant, ['id', 'name', 'lots']);

    this.updateState('values.humanEntered.merchant', merchantSummary);
  }

  getEntityDocument() {
    const { receipt } = this.state;
    const {
      values = {},
      status,
    } = receipt;
    const { humanEntered = {} } = values;
    const { transactionDate, totalAmount, netTotalAmount } = humanEntered;
    const _transactionDate = getDateFromFirebase(transactionDate);
    const receiptDocument = {
      'values.humanEntered': {
        ...humanEntered,
        transactionDate: _transactionDate,
        totalAmount: (totalAmount) ? Number.parseFloat(totalAmount) : undefined,
        netTotalAmount: (netTotalAmount) ? Number.parseFloat(netTotalAmount) : undefined,
      },
      status,
    };

    // Removes undefined fields, necessary for Firebase to not throw a wobbly
    // When we refactor the I/O we can move this closer to the metal
    // For now placed here...
    return rundef(
      receiptDocument,
      true,    // mutate - whether to mutate the original object or return a new one
      1,      // recursive - whether to apply recursively
    );
  }

  onRejectButtonClick() {
    const { clickSave } = this.entityEditor.current;

    this.setState(prevState => ({
      ...prevState,
      receipt: {
        ...prevState.receipt,
        status: 'REJECTED',
      }
    }));

    this.setState(prevState => ({
      ...prevState,
      receipt: {
        ...prevState.receipt,
        status: 'REJECTED',
      }
    }), () => {
      if (clickSave) {
        clickSave();
      }
    });
  }

  render() {
    const {
      classes,
      getSearchedMerchants,
    } = this.props;
    const {
      receipt = {},
    } = this.state;
    const {
      id,
      imageFileName,
      primaryImage,
      imageUploadDate,
      smallerImage,
      capturedBy,
      status,
      values = {},
    } = receipt;
    const { humanVerified = {}, extractFromOCR, humanEntered = {} } = values;
    const {
      receiptNumber,
      transactionDate,
      totalAmount,
      netTotalAmount,
      merchant = {},
    } = humanEntered;
    const { name: merchantName } = merchant;
    const _transactionDate = getDateFromFirebase(transactionDate);
    const imageMagnifyProps = ReceiptInfo.getImageMagnifyStyles(primaryImage, smallerImage);
    const merchantMap = (merchant) => ({
      label: merchant.name,
      name: merchant.id,
    });
    const capturedByMerchant = getCapturedByMerchant(capturedBy);

    return (
      <EntityEditor
        ref={this.entityEditor}
        getEntityDocument={this.getEntityDocument}
        collection={Firebase.receiptsRef}
        cancelHref={`/${config.pages.mainLayout}/receipts`}
        entityName="receipt"
        useUpdate={true}
        showDelete={false}
        buttonBar={(   <Button className={classes.rejectButton}
                              style={{ minWidth: 64, width: 124 }}
                              onClick={this.onRejectButtonClick}
                              color='danger'> Reject </Button>)}
        id={id}
      >
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <div className={classes.textContainer}>
              <p>Receipt scanned by: {capturedByMerchant}</p>
            </div>
            <div className={classes.textContainer}>
              <p>Captured at: {formatDateFromFirebase(imageUploadDate)}</p>
            </div>
            <div className={classes.textContainer}>
              <p>Receipt ID: {id}</p>
            </div>
            <div className={classes.textContainer}>
              <p>Status: {status}</p>
            </div>
            <div className={classes.imageMagnifyContainer}>
              {primaryImage && (
                <ReactImageMagnify {...imageMagnifyProps} />
              )}
            </div>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6} style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div style={{maxWidth: 400}}>
              <div className={classes.textContainer}>
                <label className={classes.labelInput} style={{ paddingBottom: 7 }}>Merchant</label>
              </div>
              <NavBarDropdownMenu
                getSuggestions={getSearchedMerchants}
                value={merchantName}
                map={merchantMap}
                displayMax={10}
                onSelect={this.onMerchantChange}
                style={{
                  marginTop: 0,
                  // borderBottom: '9px dotted #D2D2D2'
                }}
              />
            </div>
            <Input
              id="values.humanEntered.receiptNumber"
              name="Receipt Number"
              value={receiptNumber}
              onChange={this.onInputChange}
              style={{marginBottom: 0}}
            />
            {/*<p>transactionDate = {transactionDate}</p>*/}
            <DatePicker
              label="Transaction Date"
              id="values.humanEntered.transactionDate"
              onChange={this.onDateFieldChange}
              value={_transactionDate}
            />
            <Input
              id="values.humanEntered.netTotalAmount"
              name="Sub Total (pre tax)"
              value={netTotalAmount}
              onChange={this.onInputChange}
              type="currency"
            />
            <Input
              id="values.humanEntered.totalAmount"
              name="Total Amount"
              value={totalAmount}
              onChange={this.onInputChange}
              type="currency"
            />
          </GridItem>
        </GridContainer>
      </EntityEditor>
    );
  }
}

export default withRouter(withMerchants(withReceipts(withStyles(receiptInfoStyle)(ReceiptInfo))));
