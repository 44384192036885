import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";

import styles from "../../../assets/jss/material-dashboard-react/components/totals";
import Row from "../Row/Row";
import { config } from "../../../config";
import GridContainer from "../../Grid/GridContainer";
import GridItem from "../../Grid/GridItem";

class Totals extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {
      classes,
      totals,
      page,
    } = this.props;

    const {
      created = 0,
      issued = 0,
      pending_authorisation = 0,
      pending_reimbursement = 0,
      reimbursed = 0,
      totalAmount = 0,
      maxLiabilityAge = 0,
      quantityPendingReimbursement = 0,
      redeemed = 0,
    } = totals;


    return (
      <React.Fragment>
        <h3 className={classes.upperText}>Totals</h3>
        {
          page === config.pages.overview ?
            <GridContainer>
              <GridItem xs={12} sm={4} md={5} lg={5}>
                <div>
                  <Row name={config.totalsLabel.created}
                       value={created}
                  />
                  <Row name={config.totalsLabel.issued}
                       value={issued}
                  />
                  <Row name={config.totalsLabel.redeemed}
                       value={redeemed}
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={8} md={7} lg={7}>
                <div>
                  <Row name={config.totalsLabel.pendingAuthorization}
                       value={pending_authorisation}
                  />
                  <Row name={config.totalsLabel.pendingReimbursement}
                       value={pending_reimbursement}
                  />
                  <Row name={config.totalsLabel.reimbursed}
                       value={reimbursed}
                  />
                </div>
              </GridItem>
            </GridContainer>
            :
            <GridContainer>
              <GridItem xs={12} sm={12} md={12} lg={12}>
                <Row name={config.totalsLabel.quantityPendingReimbursement}
                     value={quantityPendingReimbursement}
                />
                <Row name={config.totalsLabel.maxLiabilityAge}
                     value={`${maxLiabilityAge} DAYS`}
                />
                <Row name={config.totalsLabel.totalAmount}
                     value={`RM ${totalAmount} `}
                />
              </GridItem>
            </GridContainer>
        }

      </React.Fragment>
    );
  }
}

Totals.propTypes = {
  classes: PropTypes.object.isRequired,
  totals: PropTypes.object,
  page: PropTypes.string,
};

export default withStyles(styles)(Totals);
