import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { config } from '../../../../config';
import { getTargetValue } from '../../../../utils/layoutEngine/parser';
import DealSelect from '../../Common/Select/Select';
import DealInput from '../../Common/Input/Input';
import MerchantSelect from '../../Common/MerchantSelect';
import Checkbox from '../../Common/Checkbox/Checkbox';
import Switch from '../../Common/Switch/Switch';
import DatePicker from '../../Common/DatePicker/DatePicker';
import Section from './Section';
import MultiSelect from '../../Common/MultiSelect/MultiSelect';
import RadioButtonGroup from '../../Common/RadioButtonGroup';

const {
  input,
  select,
  checkbox,
  switch: switcher,
  date,
  section,
  createSelect,
  multiSelect,
} = config.types;

const inputValues = Object.values(input);

class Element extends Component {
  handleDate = name => ev => this.props.generalData.handleChangeDate(ev, name);

  render() {
    const {
      element,
      generalData,
    } = this.props;
    const { data, authMerchants } = generalData;
    const {
      type,
      target,
      label,
      name,
      placeholder,
      description = '',
      mandatory = false,
      flex = false,
      from: fromDate,
    } = element;
    let targetValues;
    if (!data && !target && !type) {
      // return <Fragment />;
    }
    else {
      targetValues = getTargetValue(data, target, type);
    }
    // TODO : Ignore the min date calculation for now, we'll hardcode to start of 2019
    const minDate = new Date(2019, 0, 0); // type === date ? getMinDate(data, fromDate) : undefined;

    return (
      <Fragment>
        {type === select && name === 'merchant' && (_.isEmpty(authMerchants) || authMerchants.length > 1) && (
          <MerchantSelect
            changeSelect={generalData.handleChangeSelector}
            handleRequiredFields={generalData.handleRequiredFields}
            required={mandatory}
            value={data.merchant}
          />
        )}
        {type === select && name !== 'merchant' && (
          <DealSelect
            {...targetValues}
            label={label}
            changeSelect={generalData.handleChangeSelector}
            handleRequiredFields={generalData.handleRequiredFields}
            required={mandatory}
          />
        )}
        {type === 'radioGroup' && (
          <RadioButtonGroup
            {...targetValues}
            changeSelect={generalData.handleChangeSelector}
          />
        )}
        {(inputValues.includes(type)) && (
          <DealInput
            {...targetValues}
            type={type}
            name={label}
            description={description}
            placeholder={placeholder}
            required={mandatory}
            onChange={generalData.handleChangeInput}
            handleRequiredFields={generalData.handleRequiredFields}
            flex={flex}
            marginTop={generalData.marginTop}
          />
        )}

        {
          type === checkbox && <Checkbox
            {...targetValues}
            label={label}
            handleChangeCheckbox={generalData.handleChangeCheckbox}
            handleRequiredFields={generalData.handleRequiredFields}
            required={mandatory}
          />
        }

        {_.isEmpty(authMerchants) && (
          type === switcher && <Switch
            {...targetValues}
            name={label}
            handleChecked={generalData.activeHandle}
            informationForActive={data.informationForActive}
            required={mandatory}
          />
        )}

        {
          type === date && <DatePicker
            {...targetValues}
            label={label}
            onChange={this.handleDate(targetValues.id)}
            required={mandatory}
            minDate={minDate}
            includeTime={true}
          />
        }
        {
          (type === createSelect || type === multiSelect) && <MultiSelect
            {...targetValues}
            onChange={generalData.handleChangeState}
            name={label}
            description={description}
            flex={flex}
            creatable={ type === createSelect }
          />
        }

        {
          type === section && <Section
            section={element}
            data={data}
            handleChangeSelector={generalData.handleChangeSelector}
            handleChangeInput={generalData.handleChangeInput}
            activeHandle={generalData.activeHandle}
            handleDateStartChange={generalData.handleDateStartChange}
            handleDateEndChange={generalData.handleDateEndChange}
            changeExpDate={generalData.changeExpDate}
            handleChange={generalData.handleChange}
            // handleDrop={generalData.handleDrop}
            // deleteImageByName={generalData.deleteImageByName}
            // uploadImages={generalData.uploadImages}
            onChangePrimaryImage={generalData.onChangePrimaryImage}
            onChangeImages={generalData.onChangeImages}
            selectCurrentImage={generalData.selectCurrentImage}
            handleChangeDate={generalData.handleChangeDate}
          />
        }
      </Fragment>
    );
  }
}

Element.propTypes = {
  element: PropTypes.object,
  generalData: PropTypes.object,
};

Element.defaultProps = {
  element: {},
  generalData: {},
};


export default Element;
