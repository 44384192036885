import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { Link } from 'react-router-dom';
import { formatMoney } from 'accounting';
import {toTitleCase} from "../../../../utils/stringUtils";
import TableCellBadge from './TableCellBadge';

export class TableRowReimbursement extends Component {
  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
  };

  render() {
    const { el, pathname, onSelect } = this.props;
    const {
      voucher_code,
      redemption_date,
      sales_receipt,
      merchant,
      customer,
      amount,
      status,
      isSelected,
    } = el;
    const formattedAmount = formatMoney(amount, '', 2);
    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '86px'}}
        // onClick={() => onClick(voucher_code)}
      >
        <TableCell>
          <Checkbox
            checked={isSelected}
            onClick={() => onSelect(voucher_code, !isSelected)}
          />
        </TableCell>
        <TableCell>{voucher_code}</TableCell>
        <TableCell align="left">{merchant}</TableCell>
        <TableCell align="left">{redemption_date}</TableCell>
        <TableCell align="left">
          <Link to={`/admin/receipts/${sales_receipt}`}>{sales_receipt}</Link>
        </TableCell>
        <TableCell align="left">{customer}</TableCell>
        <TableCell align="right" style={{maxWidth: 50}}>{formattedAmount}</TableCell>
        <TableCell>
          <TableCellBadge label={toTitleCase(status)} />
        </TableCell>
      </TableRow>
    );
  }
}
