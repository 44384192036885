import React, { Component } from 'react';
import _ from 'lodash';
import { withSite, withMerchants } from '../../../contextApi';
import { TableRowMerchant } from "../../../components/Common/Table/rows";
import hash from 'object-hash';
import MultiSelect from '../../../components/Engine/Common/MultiSelect/MultiSelect';
import GridPage from '../../GridPage';
import { getCategoryString } from "../../../utils/stringUtils";

const tableHead = [
  'Merchant Id',
  'Name',
  'Lot',
  'Category',
  'LatLong',
  'Actions',
];

class Merchants extends Component {
  state = {
    categoryOptions: [],
    merchants: null,
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.getFilterControls = this.getFilterControls.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { merchants = [], site: { categories: categoryTypes } = {} } = nextProps;

    if (merchants.length && Object.keys(categoryTypes).length) {
      const categoryOptions = categoryTypes.map(x => ({
        value: x.id,
        label: x.name,
      }));

      return {
        ...prevState,
        merchants,
        categoryOptions,
      };
    }
    return {
      ...prevState,
    };
  }

  static getTableRowData = (merchant, categoryOptions) => {
    const {
      id = 0,
      name = '',
      lots = [],
      categories = [],
      location = {},
    } = merchant;
    const lot = lots.join(', ');
    const category = getCategoryString(categories, categoryOptions);
    const latLong = (!_.isEmpty(location.coords)) ? 'Yes' : 'no';
    const result = {
      id,
      merchant_id: id,
      name,
      lot,
      category,
      fuck: category,
      latLong,
      actions: 'Edit',
    };

    return result;
  };

  componentDidMount() {
    const { setMerchantsProviderActive, site: { categories } = {} } = this.props;
    setMerchantsProviderActive();
  }

  async onDelete(id) {
    const { deleteMerchantById } = this.props;

    await deleteMerchantById(id);
  }

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return (
      <TableRowMerchant
        el={ item }
        pathname={ pathname }
        key={ id }
        onDelete={ this.onDelete }
        itemName="merchant"
      />
    );
  }

  getFilterControls(filterValues) {
    const { site: { lots: rawAllLots, levels: rawAllLevels } } = this.props;
    const { categories = [], lots = [], levels = [] } = filterValues;
    const { categoryOptions } = this.state;
    const lotsOptions = rawAllLots.map(({ id, name }) => ({ label: name, value: id }));
    const levelsOptions = rawAllLevels.map(x => ({ label: x, value: x }));

    return [
      <MultiSelect
        id="categories"
        key="categories"
        isMainPage={ true }
        options={ categoryOptions }
        value={ categories.map(x => ({ label: x, value: x })) }
      />,
      <MultiSelect
        id="lots"
        key="lots"
        name="Lot(s)"
        options={ lotsOptions }
        isMainPage={ true }
        value={ lots.map(x => ({ label: x, value: x })) }
      />,
      <MultiSelect
        id="levels"
        key="levels"
        name="Level(s)"
        options={ levelsOptions }
        isMainPage={ true }
        value={ levels.map(x => ({ label: x, value: x })) }
      />
    ]
  }

  render() {
    const { getFilteredMerchants } = this.props;
    const { merchants, categoryOptions } = this.state;

    return (
      <GridPage
        viewName="Merchants"
        itemName="Merchant"
        pathname="merchants"
        items={merchants}
        getFilterControls={this.getFilterControls}
        getFilteredItems={getFilteredMerchants}
        getTableRowData={x => Merchants.getTableRowData(x, categoryOptions)}
        renderRow={this.renderRow}
        tableHead={tableHead}
        showCreateButton={true}
      />
    )
  }
}

export default withSite(withMerchants(Merchants));
