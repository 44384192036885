import React from 'react';
import hoistStatics from 'hoist-non-react-statics';
import { PromotionsConsumer } from './promotionsProvider';

export const withPromotions = (Component) => {
  class ComponentWithPromotions extends React.Component {
    static displayName = `withPromotions(${Component.displayName || Component.name})`;

    render() {
      const { onRef } = this.props;

      return (
        <PromotionsConsumer>
          {({
              promotions,
              mallEvents,
              getPromotionById,
              deletePromotionById,
              getFilteredPromotions,
              setActive,
            }) => (
            <Component
              {...this.props}
              ref={onRef}
              promotions={promotions}
              mallEvents={mallEvents}
              deletePromotionById={deletePromotionById}
              getPromotionById={getPromotionById}
              getFilteredPromotions={getFilteredPromotions}
              setPromotionsProviderActive={setActive}
            />
          )}
        </PromotionsConsumer>
      );
    }
  }

  return hoistStatics(ComponentWithPromotions, Component);
};
