import dropdownStyle from '../dropdownStyle';

const headerLinksStyle = theme => ({
  ...dropdownStyle(theme),
  upperText: {
    textTransform: 'uppercase',
  },
  buttonUnregister: {
    border: '1px solid #999',
    marginTop: 14,
    height: 30,
    textTransform: 'none',
  },
  error: {
    fontSize: 10,
    color: '#b00020',
  },
  headerTerms: {
    marginBottom: 20,
  },
  root: {
    width: '50%',
  },
  headerForRow: {
    display: 'flex',
    marginTop: 5,
  },
  headerForRowFlex: {
    display: 'flex',
    // marginTop: 18,
    marginTop: 6,
    width: '170px',
  },
  headerForRowSelect: {
    display: 'flex',
    marginTop: 12,
    marginBottom: 12,
    alignItems: 'center',
  },
  labelForSelector: {
    fontSize: 24,
  },
  formControlMain: {
    paddingBottom: 25,
  },
  selectForm: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 250,
    paddingBottom: 25,
  },
  formSelect: {
    minWidth: 275,
  },
  percentage: {
    paddingLeft: 2,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 32,
    maxHeight: 65,
  },
  availability: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 170,
  },
  percentageBig: {
    paddingLeft: 2,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 32,
    maxHeight: 160,
  },
  number: {
    maxWidth: 85,
    paddingBottom: 2,
  },
  percentageInput: {
    maxWidth: '60%',
    paddingBottom: 5,
    marginBottom: 8,
  },
  labelInput: {
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 10,
    minWidth: 72,
    width: 180,
  },
  labelDesc: {
    fontSize: 10,
    alignSelf: 'flex-end',
    marginTop: 13,
  },
  textContainer: {
    display: 'flex',
    width: 170,
  },

  rowContainer: {
    display: 'flex',
  },
  textMargin: {
    // marginTop: 26,
    marginTop: 30,
    marginLeft: 16,
  },
  topMargin: {
    marginTop: 14,
  },
  textName: {
    textTransform: 'uppercase',
    [theme.breakpoints.down('sm')]: {
      width: 'min-content',
    },
    [theme.breakpoints.down('md')]: {
      width: 'min-content',
    },
  },
  labelNameMerchant: {
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.54)',
    marginRight: 10,
    [theme.breakpoints.down('lg')]: {
      minWidth: 141,
    },
  },
  empty: {

  },
  rowDeviceSection: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
    width: '100%',
  },
  qrCodeSection: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-between',
      flexDirection: 'row',
    },
    width: '100%',
  },
  successButton: {
    backgroundColor: 'green',
    color: '#fff',
    opacity: 0.4,
    '&:hover': {
      backgroundColor: 'green',
      color: '#fff',
      opacity: 0.9,
    },
  },
  itemFlex: {
    alignSelf: 'flex-end',
  },
  area: {
    maxWidth: '65%',
    width: '100%',
    paddingBottom: 5,
  },
  pleaseActivateAccount: {
    textAlign: 'center',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 21,
    minWidth: 72,
  },
  flexContainerForMail: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  select: {
    [theme.breakpoints.down('lg')]: {
      maxWidth: 400,
      minWidth: 300,
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 400,
      minWidth: 300,
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400,
      minWidth: 300,
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      minWidth: 150,
    },
  },
  marginTop: {
    marginTop: 24,
  }
});

export default headerLinksStyle;
