import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { NavLink } from 'react-router-dom';
import Button from '../../CustomButtons/Button';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export class TableRowCampaign extends Component {
  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
  };

  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.handleDelete = this.handleDelete.bind(this);

    this.state = {
      imageFilePath: undefined,
    }
  }

  handleDelete(dealId) {
    const { deleteDealById } = this.props;

    deleteDealById(dealId);
  }

  onDelete(id) {
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Deleting this deal is not reversible. Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id),
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  render() {
    const { el, pathname, onClick } = this.props;
    const {
      id,
      criteria,
      startDate,
      endDate,
      creationDate,
      totals,
      issuer,
    } = el;
    const {
      created = 0,
      issued = 0,
      redeemed = 0,
    } = totals;
    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '40px'}}
        onClick={onClick}
      >
        <TableCell>{el.title}</TableCell>
        <TableCell align="left">{issuer}</TableCell>
        <TableCell align="left">{criteria}</TableCell>
        {/*<TableCell align="left">{el.creation_date}</TableCell>*/}

        <TableCell align="left" style={{maxWidth: 50}}>{created}</TableCell>
        <TableCell align="left" style={{maxWidth: 50}}>{issued}</TableCell>
        <TableCell align="left" style={{maxWidth: 50}}>{redeemed}</TableCell>

        <TableCell align="left">{creationDate}</TableCell>
        <TableCell align="left">{startDate}</TableCell>
        <TableCell align="left">{endDate}</TableCell>
        <TableCell>
          <NavLink to={`${pathname}/${id}`}>
            <Button color="primary" size="sm">Edit</Button>
          </NavLink>
        </TableCell>
      </TableRow>
    );
  }
}

