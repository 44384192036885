import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableHeadCell from './TableHeadCell';
import _ from "lodash";

class EnhancedTableHead extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
    };
  }

  // componentDidMount() {
  //   const { tableHead } = this.props;
  //   const columns = EnhancedTableHead.createColumns(tableHead);
  //   this.setState(prevState => ({
  //     ...prevState,
  //     columns,
  //   }));
  // }

  static getDerivedStateFromProps(nextProps, prevState) {
    let { tableHead } = nextProps;
    const columns = EnhancedTableHead.createColumns(tableHead);

    return {
      ...prevState,
      columns,
    };
  }

  static createColumns = (tableHead) => {
    const columns = tableHead.map(elem => ({
      id: elem.split(' ').join('_').toLowerCase(),
      numeric: false,
      disablePadding: false,
      label: elem,
    }));

    return columns;
  };

  render() {
    const { order, orderBy, onRequestSort } = this.props;
    const { columns } = this.state;

    return (
      <TableHead>
        <TableRow>
          {columns.map(
            column => (
              <TableHeadCell
                key={column.id}
                column={column}
                orderBy={orderBy}
                order={order}
                onRequestSort={onRequestSort}
              />
            ),
          )}
        </TableRow>
      </TableHead>
    );
  }
}

export default EnhancedTableHead;

EnhancedTableHead.propTypes = {
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  tableHead: PropTypes.arrayOf(PropTypes.string),
  onRequestSort: PropTypes.func,
};
