import _uniqBy from 'lodash/uniqBy';
import axios from 'axios';
import Firebase from '../components/Firebase';
import { getValueByField } from './index';
import { config } from '../config';

/**
 *
 * @param nameField name field, which we want see array or string
 * @param refName reference for db from Firebase.js
 * @param params
 * @returns {Promise<any[]>}
 */
export const getValuesFieldFromFirebase = async (nameField,
  refName,
  {
    orderBy = 'id',
    uniqBy = undefined,
    decompose = false,
  } = {},
) => {
  try {
    const ref = Firebase[refName];
    const data = await ref.orderBy(orderBy)
      .get();
    let rawData = (data.docs.map((el) => {
      if (Array.isArray(nameField)) {
        const response = {};
        nameField.forEach((props) => {
          response[props] = getValueByField(el.data(), props, '');
        });
        return response;
      }
      return getValueByField(el.data(), nameField, '');
    }));
    rawData = decompose ? rawData.flat() : rawData;
    return !uniqBy ? rawData
      : Array.isArray(nameField) ? _uniqBy(rawData, uniqBy) : [...new Set(rawData)];
  } catch (e) {
    console.log('error', e);
    throw (e);
  }
};

const getResultMessage = async (
  ref,
  data,
  {
    action = '',
    requestUrl = '',
  } = {},
) => {
  const message = config.response[`${action}Message`];
  const { typeRequest, response } = config;
  const { createUpdateMessage } = response;
  const { create } = typeRequest;
  if (requestUrl) {
    try {
      await axios.post(requestUrl, data);
      return createUpdateMessage;
    } catch (e) {
      return e.response.data.error;
    }
  }
  try {
    if (action === create) {
      ref.set(data);
    } else {
      ref.update(data);
    }
  } catch (e) {
    return e.message;
  }

  return message;
};

/**
 *
 * @param refName name reference from firebase
 * @param data Data for Update or Create
 * @param forcedRequest If you want create force request insert or update
 * @returns {Promise<*>}
 */
export const createOrUpdateDocumentFirebase = async (
  refName,
  data,
  { req, requestUrl = '' } = {},
) => {
  const { response } = config;
  const { createUpdateMessage } = response;
  const { id = '' } = data;
  const documentRef = Firebase[refName].doc(`${id}`);
  try {
    if (!req) {
      const doc = await documentRef.get();
      if (doc.exists) {
        documentRef.update(data);
      } else {
        documentRef.set(data);
      }
      return createUpdateMessage;
    }
    return getResultMessage(documentRef, data, { action: req, requestUrl });
  } catch (e) {
    console.log('error', e);
    return e.response.data.error;
  }
};

export const createFirebaseDeletedFields = (fields) => {
  const { deleteType } = Firebase;
  if (Array.isArray(fields)) {
    return fields.reduce((prevFields, el) => {
      prevFields[el] = deleteType;
      return prevFields;
    }, {});
  }
  return { [fields]: fields };
};

export const deleteFields = (refName, id, fields = []) => {
  try {
    const documentRef = Firebase[refName].doc(`${id}`);
    const deletedFields = createFirebaseDeletedFields(fields);
    documentRef.update(deletedFields);
    return config.response.deleteMessage;
  } catch (e) {
    return e.response.data.error;
  }
};


export default () => null;
