import React, { Component, Fragment } from 'react';
import * as moment from 'moment';
import PropTypes from 'prop-types';
import { InlineDatePicker } from 'material-ui-pickers';
import SelectMU from '@material-ui/core/Select/index';
import MenuItem from '@material-ui/core/MenuItem/index';
import withStyles from '@material-ui/core/styles/withStyles';
import style
  from '../../../../assets/jss/material-dashboard-react/components/datePicker.jsx';

class DatePicker extends Component {
  state = {
    timeValues: undefined,
    timeMenuItems: <div />,
  };

  constructor(props) {
    super(props);

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  static getTimeValues() {
    let items = [];
    for (let i = 10; i <= 22; i++) {
      const formattedTime = new Date(Date.UTC(2012, 11, 20, i, 0, 0))
        .toLocaleTimeString({},
          {timeZone:'UTC',hour12:true,hour:'numeric',minute:'numeric'}
        );
      items.push({
        key: i,
        value: formattedTime.toUpperCase(),
      })
    }
    return items;
  }

  componentDidMount() {
    const timeValues = DatePicker.getTimeValues();
    const timeMenuItems = timeValues.map(({ key, value }) => {
      return (
        <MenuItem key={key} value={value}>{value}</MenuItem>
      );
    });

    this.setState({
      timeValues,
      timeMenuItems,
    })
  }

  handleTimeChange(event, data) {
    const {
      onChange,
      value,
      id,
    } = this.props;
    const { key, props } = data;
    // const timeValue = props.value;

    const newValue = moment(value);
    newValue.set({
      hour: key,
      minute: 0,
      second: 0,
    });

    if (onChange) {
      onChange(newValue, id)
    }
  }

  handleDateChange(value) {
    const {
      onChange,
      id,
    } = this.props;

    if (onChange) {
      onChange(value, id)
    }
  }

  getDisplayTimeValue(date) {
    const { timeValues } = this.state;

    if ((date instanceof Date) && timeValues) {
      const hour = date.getHours();
      const timeValue = timeValues.find(x => x.key === hour);
      return (timeValue) ? timeValue.value : '10:00 AM';
    }
    return '10:00 AM';
  }

  render() {
    const {
      includeTime,
      classes,
      value,
      label,
      id,
    } = this.props;
    const {
      timeMenuItems,
    } = this.state;
    const displayTimeValue = includeTime && this.getDisplayTimeValue(value);
    // Hack to prevent this piece of crap material ui picker to just decide to display a "default date" when value is undefined
    const dateDisplayFormat = (value) ? 'DD/MM/YYYY' : '            ';

    return (
      <div>
        <InlineDatePicker
          margin="normal"
          label={label}
          value={value}
          id={id}
          format={dateDisplayFormat}
          onChange={this.handleDateChange}
        />
        <Fragment>
          {includeTime && (
            <SelectMU
              onClose={this.handleFocus}
              onOpen={this.onOpen}
              value={displayTimeValue}
              onChange={this.handleTimeChange}
              className={classes.timePicker}
            >
              {timeMenuItems}
            </SelectMU>
          )}
        </Fragment>
      </div>
    );
  }
}

DatePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  onChange: PropTypes.func,
  id: PropTypes.string,
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  includeTime: PropTypes.bool,
};

DatePicker.defaultProps = {
  label: '',
  value: undefined,
  minDate: undefined,
  onChange: () => null,
  id: '',
  includeTime: false,
};

export default withStyles(style)(DatePicker);
