import * as uniqid from 'uniqid';
import { getAllDeals, getQuery, getQueryDealById } from './queryHelper';
import firebaseConfig, { config } from '../../config';
import {
  getPrettiedDealInfo,
  prepareData,
} from './prettierData';
import { createOrUpdateDocumentFirebase } from '../firebase';
import { getMerchants } from '../merchant/getters';

const { baseUrl } = firebaseConfig;

export const subscribeDealInformation = (id, changeState) => {
  const query = getQueryDealById(id);
  return query.onSnapshot(snapshot => snapshot.docs.map(async (doc) => {
    const res = await getPrettiedDealInfo(doc.data());
    changeState(res);
  }));
};

export const subscribeDeals = (changeState) => {
  const query = getAllDeals();
  changeState({ supposeId: uniqid() });
  return query.onSnapshot(async (snapshot) => {
    const merchants = await getMerchants();
    changeState({ merchants });
  });
};

export const createOrUpdateDealFirebase = async (data, id) => {
  const {
    pages,
    typeRequest,
    requests,
  } = config;
  const deal = await prepareData(data);
  const { deal: dealUrl } = requests;
  const { add } = pages;
  const { update, create } = typeRequest;
  const req = (id === add) ? create : update;
  const requestUrl = (req === create) ? `${baseUrl}/${dealUrl}/${req}` : '';
  const message = await createOrUpdateDocumentFirebase('dealsRef', deal, { req, requestUrl });
  return message;
};
