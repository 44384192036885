import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import AdminNavbarLinks from '../Navbars/AdminNavbarLinks.jsx';
import { withRouterInnerRef, withReceipts, withEmployees } from '../../contextApi';
import SideBarLink from './SideBarLink';
import SideBarMenu from './SideBarMenu';

import sidebarStyle
  from '../../assets/jss/material-dashboard-react/components/sidebarStyle.jsx';

import Logo from '../../assets/img/ply-icon.png';
import { config } from '../../config';

const {
  REACT_APP_ENVIRONMENT,
} = process.env;

class Sidebar extends Component {
  state = {
    routes: undefined,
  };

  constructor(props) {
    super(props);

    const { setEmployeesProviderActive } = props;
    setEmployeesProviderActive();
  }

  static getFilteredRoutes(routes, authMerchants, isAdmin) {
    return routes.filter(route => {
      const {
        leftBar,
        adminOnly = false,
        displayToMerchantEmployee = false,
      } = route;
      if (!leftBar) { return false; }
      if (!displayToMerchantEmployee && authMerchants.length) { return false; }
      if (adminOnly && !isAdmin) { return false; }
      return true;
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { routes = [], authMerchants = [], isAdministrator, getOperator } = nextProps;
    const isAdmin = isAdministrator();

    if (isAdmin || (!prevState.routes && routes.length)) {
      let filteredRoutes = Sidebar.getFilteredRoutes(routes, authMerchants, isAdmin);

      return {
        ...prevState,
        routes: filteredRoutes,
      }
    }

    return {
      ...prevState,
    }
  }

  getLinks() {
    const { routes } = this.state;
    const {
      classes,
      color,
    } = this.props;
    return (
      <List className={classes.list}>
        {routes.map((route, key) => (
           <SideBarLink
             key={key}
             route={route}
             classes={classes}
             routes={routes}
             color={color}
           />
        ))}
      </List>
    )
  }

  getBrand() {
    const { classes } = this.props;
    return (
      <div className={classes.logo}>
        <NavLink to={`/admin/vouchers`} className={classNames(classes.logoLink)}>
          <div className={classes.logoImage}>
            <img src={Logo} alt="logo" className={classes.img}/>
          </div>
          {config.name}
        </NavLink>
        {REACT_APP_ENVIRONMENT === 'staging' && (
          <p style={{paddingTop: 10, margin: 0, lineHeight: '16px', color: 'orange', textAlign: 'right'}}>STAGING
            ENVIRONMENT</p>
        )}
      </div>
    );
  }

  render() {
    const {
      classes,
      image,
      open,
      handleDrawerToggle,
    } = this.props;
    const brand = this.getBrand();
    const links = this.getLinks();

    return (
      <div>
        <Hidden mdUp implementation="css">
          {/*<Drawer*/}
          {/*  variant="temporary"*/}
          {/*  anchor={'right'}*/}
          {/*  open={open}*/}
          {/*  classes={{*/}
          {/*    paper: classNames(classes.drawerPaper),*/}
          {/*  }}*/}
          {/*  onClose={handleDrawerToggle}*/}
          {/*  ModalProps={{*/}
          {/*    keepMounted: true, // Better open performance on mobile.*/}
          {/*  }}*/}
          {/*>*/}
          {/*  {brand}*/}
          {/*  <div className={classes.sidebarWrapper}>*/}
          {/*    <AdminNavbarLinks/>*/}
          {/*    {links}*/}
          {/*  </div>*/}
          {/*  {image !== undefined ? (*/}
          {/*    <div*/}
          {/*      className={classes.background}*/}
          {/*      style={{ backgroundImage: `url(${image})` }}*/}
          {/*    />*/}
          {/*  ) : null}*/}
          {/*</Drawer>*/}
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            anchor={'left'}
            variant="permanent"
            open
            classes={{
              paper: classNames(classes.drawerPaper),
            }}
          >
            {brand}
            {/*<div className={classes.sidebarWrapper}>*/}
            {/*  <SideBarMenu />*/}
            {/*</div>*/}
            <div className={classes.sidebarWrapper}>{links}</div>
            {image !== undefined ? (
              <div
                className={classes.background}
                style={{ backgroundImage: `url(${image})` }}
              />
            ) : null}
          </Drawer>
        </Hidden>
      </div>
    )
  }
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withRouterInnerRef(withStyles(sidebarStyle)(withEmployees(withReceipts(Sidebar))));
