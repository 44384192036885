import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Avatar from 'react-avatar';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import 'react-confirm-alert/src/react-confirm-alert.css';

import iosIcon from '../../../../assets/img/ios-icon.png';
import androidIcon from '../../../../assets/img/android-icon.png';
import Button from '../../CustomButtons/Button';
import { UserAvatar } from '../../../../components/UserAvatar';
import { confirmAlert } from "react-confirm-alert";

const getPushTokenText = pushToken => {
  let result = pushToken;
  if (!pushToken) return '';
  // if (_.isString(pushToken)) {
  //   result = pushToken
  // }
  if (pushToken.data) {
    result = pushToken.data;
  }

  return result.replace('ExponentPushToken', '');
};

export class TableRowCustomer extends Component {
  static propTypes = {
    el: PropTypes.object,
    pathname: PropTypes.string,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    el: {},
    pathname: '',
    onClick: () => {},
  };

  constructor(props) {
    super(props);

    this.onDelete = this.onDelete.bind(this);
    this.onTableRowClick = this.onTableRowClick.bind(this);
  }

  handleDelete(id) {
    const { deleteCustomerById } = this.props;

    deleteCustomerById(id);
  }

  onTableRowClick(evt) {
    const { onClick, el } = this.props;
    const { id } = el;

    if (typeof onClick === 'function') {
      onClick(evt, id);
    }
  }

  onDelete(evt, id) {
    evt.stopPropagation();
    console.log('deleting', id);
    confirmAlert({
      title: 'Confirm to delete',
      message: 'Deleting this deal is not reversible. Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.handleDelete(id),
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });
  }

  render() {
    const { el, pathname, onClick, isAdmin } = this.props;
    const {
      name,
      customer_id,
      device,
      brand,
      os,
      location,
      installed_at,
      last_seen,
      status,
      pushToken = '',
      social,
      groups = '',
      photoUrl,
      registration,
    } = el;
    const iconSrc = (os === 'android') ? androidIcon : iosIcon;
    const token = getPushTokenText(pushToken);
    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '30px'}}
        onClick={this.onTableRowClick}
      >
        <TableCell align="left" style={{display: 'flex', flexDirection: 'row'}}>
          <UserAvatar
            social={social}
            style={{marginRight: '10px'}}
          />
          <span style={{ margin: 'auto 0' }}>{name}</span>
        </TableCell>
        <TableCell align="left">
          <img src={iconSrc} alt="logo" style={{
            width: 'auto',
            height: '30px',
            padding: '0px',
          }} />
        </TableCell>
        <TableCell align="left">{brand}</TableCell>
        <TableCell align="left">{device}</TableCell>
        <TableCell align="left">{registration}</TableCell>
        <TableCell align="left">{customer_id}</TableCell>
        {/*<TableCell align="left">{token}</TableCell>*/}
        <TableCell align="left">{installed_at}</TableCell>
        <TableCell align="left">{last_seen}</TableCell>
        {/*<TableCell align="left">{location}</TableCell>*/}
        <TableCell align="left">{status}</TableCell>
        <TableCell align="left">{groups}</TableCell>
        {isAdmin && (
          <TableCell>
            <Button
              color="primary"
              size="sm"
              style={{marginLeft: '10px'}}
              onClick={evt => this.onDelete(evt, customer_id)}
            >Delete</Button>
          </TableCell>
        )}
      </TableRow>
    );
  }
}

