import Dashboard from '@material-ui/icons/Dashboard';
import Person from '@material-ui/icons/Person';
import Home from '@material-ui/icons/Home';

import Vouchers from './views/Vouchers/Overview/Vouchers.jsx';
import DealsPage from './views/Deals/Deals/Deals.jsx';
import CampaignsPage from './views/Campaigns/Campaigns/Campaigns.jsx';
import UserProfile from './views/UserProfile/UserProfile.jsx';
import Reimbursements from './views/Vouchers/Reimbursement/Reimbursements.jsx';
import PaidReimbursements from './views/Vouchers/PaidReimbursements';
import Login from './views/Login/Login.jsx';
import Logout from './views/Login/Logout.jsx';
import DealInfo from './views/Deals/DealInfo/DealInfo';
import Merchants from './views/Merchants/Merchants/Merchants';
import ReceiptInfo from './views/Receipts/ReceiptInfo';
import MerchantInfo from './views/Merchants/MerchantInfo/MerchantInfo';
import CampaignInfo from './views/Campaigns/CampaignInfo/CampaignInfo';
import ReimbursementInfo from './views/Vouchers/Reimbursement/ReimbursementInfo';
import NotificationInfo from './views/Notifications/NotificationInfo';
import Promotions from './views/Promotions/Promotions';
import PromotionInfo from './views/Promotions/PromotionInfo';
import MallEvents from './views/MallEvents/MallEvents';
import MallEventInfo from './views/MallEvents/MallEventInfo';
import Customers from './views/Customers/Customers';
import Categories from './views/Categories/Categories';
import CategoryInfo from './views/Categories/CategoryInfo';
import Receipts from './views/Receipts/Receipts';
import Employees from './views/Employees/Employees/Employees';
import SiteVisits from './views/SiteVisits/SiteVisits';
import Lots from './views/Lots/Lots';
import LotInfo from './views/Lots/LotInfo';
import EmployeeInfo from './views/Employees/EmployeeInfo/EmployeeInfo';
import CustomerInfo from './views/Customers/CustomerInfo';
import Dashboards from './views/Dashboards';
import Notifications from './views/Notifications/Notifications';
// import MapUtil from './views/Maps/MapUtil';

import { config } from './config';

const dashboardRoutes = [
  {
    path: '/user',
    name: 'User Profile',
    icon: Person,
    component: UserProfile,
    layout: '/admin',
    parent: config.parent.user,
  },
  {
    path: `/customers/:code`,
    name: 'CustomerInfo',
    component: CustomerInfo,
    layout: '/admin',
    parent: 'customers',
  },
  {
    path: `/customers`,
    name: 'Customers',
    icon: 'group',
    title: 'Customers',
    component: Customers,
    layout: '/admin',
    parent: 'customers',
    // parent: config.parent.customers,
    leftBar: true,
  },
  {
    path: `/deals/:code`,
    name: 'DealsInfo',
    icon: 'deal',
    component: DealInfo,
    layout: '/admin',
    parent: config.parent.deals,
    displayToMerchantEmployee: true,
  },
  {
    path: `/deals`,
    name: 'Deals',
    title: 'Deal Management',
    icon: 'dashboard',
    component: DealsPage,
    layout: '/admin',
    leftBar: true,
    parent: config.parent.deals,
    defaultQueryStrings: 'timePeriod=Current,Upcoming',
    displayToMerchantEmployee: true,
  },
  {
    path: `/merchants/:code`,
    component: MerchantInfo,
    layout: '/admin',
    parent: config.parent.merchants,
    displayToMerchantEmployee: true,
  },
  {
    path: `/merchants`,
    name: 'Merchants',
    title: 'Merchant Management',
    icon: 'store',
    component: Merchants,
    layout: '/admin',
    leftBar: true,
    parent: config.parent.merchants,
    displayToMerchantEmployee: true,
  },
  {
    path: `/siteVisits`,
    name: 'SiteVisits',
    title: 'Site Visits',
    icon: 'store',
    component: SiteVisits,
    layout: '/admin',
    leftBar: true,
    adminOnly: true,
  },
  {
    path: `/promotions/:code`,
    name: 'PromotionInfo',
    // icon: 'merchants',
    component: PromotionInfo,
    layout: '/admin',
    parent: config.parent.promotions,
  },
  {
    path: `/promotions`,
    name: 'Promotions',
    title: 'Promotion Management',
    icon: 'view_carousel',
    component: Promotions,
    layout: '/admin',
    parent: config.parent.promotions,
    leftBar: true,
    defaultQueryStrings: 'timePeriod=Current,Upcoming',
  },
  {
    path: `/mallEvents/:code`,
    name: 'MallEventInfo',
    // icon: 'merchants',
    component: MallEventInfo,
    layout: '/admin',
    parent: 'mallEvents',
  },
  {
    path: `/mallEvents`,
    name: 'Events',
    title: 'Events',
    icon: 'event',
    component: MallEvents,
    layout: '/admin',
    parent: 'mallEvents',
    leftBar: true,
    defaultQueryStrings: 'timePeriod=Current,Upcoming',
  },
  {
    path: `/receipts/:code`,
    name: 'ReceiptInfo',
    title: 'Process Receipt',
    icon: 'merchants',
    component: ReceiptInfo,
    layout: '/admin',
    parent: 'receipts'
  },
  {
    path: `/receipts`,
    name: 'Receipts',
    title: 'Receipt Management',
    icon: 'receipt',
    component: Receipts,
    layout: '/admin',
    leftBar: true,
    parent: 'receipts',
    badgeValueGetter: 'getNumberPendingProcessing',
  },
  {
    path: `/campaigns/:code`,
    name: 'CampaignInfo',
    component: CampaignInfo,
    layout: '/admin',
    parent: config.parent.campaigns,
  },
  {
    path: `/campaigns`,
    name: 'Campaigns',
    title: 'Voucher Campaigns',
    icon: 'star',
    component: CampaignsPage,
    layout: '/admin',
    leftBar: true,
    parent: config.parent.campaigns,
  },
  {
    path: `/vouchers`,
    name: 'Vouchers',
    title: 'Vouchers',
    icon: 'loyalty',
    component: Vouchers,
    layout: '/admin',
    leftBar: true,
    parent: config.parent.vouchers,
  },
  {
    path: `/notifications/:code`,
    name: 'NotificationInfo',
    component: NotificationInfo,
    layout: '/admin',
    parent: 'notifications'
  },
  {
    path: `/notifications`,
    name: 'Notifications',
    title: 'Notifications',
    icon: 'sms',
    component: Notifications,
    layout: '/admin',
    leftBar: true,
    parent: 'notifications'
  },
  {
    path: `/reimbursements/:code`,
    name: 'ReimbursementsInfo',
    title: '${action} Reimbursement',
    icon: 'merchants',
    component: ReimbursementInfo,
    layout: '/admin',
    parent: 'reimbursements'
  },
  {
    path: `/reimbursements`,
    name: 'Reimbursements',
    icon: 'attach_money',
    title: 'Reimbursements Pending',
    component: Reimbursements,
    layout: '/admin',
    parent: 'reimbursements',
    leftBar: true,
  },
  {
    path: `/paid`,
    name: 'Payments',
    title: 'Reimbursement Payments',
    icon: 'input',
    component: PaidReimbursements,
    layout: '/admin',
    leftBar: true,
  },
  {
    path: `/employees/:code`,
    name: 'EmployeeInfo',
    component: EmployeeInfo,
    layout: '/admin',
    parent: 'employees',
  },
  {
    path: `/employees`,
    name: 'Employees',
    title: 'Employees',
    icon: 'supervisor_account',
    component: Employees,
    layout: '/admin',
    parent: 'employees',
    leftBar: true,
  },
  // {
  //   path: `/mapUtil`,
  //   name: 'MapUtil',
  //   title: 'Map Utility',
  //   icon: 'map',
  //   component: MapUtil,
  //   layout: '/admin',
  //   leftBar: true,
  //   parent: 'mapUtil',
  // },
  {
    path: `/lots/:code`,
    name: 'LotInfo',
    component: LotInfo,
    layout: '/admin',
    parent: 'lots',
  },
  {
    path: `/lots`,
    name: 'Lots',
    title: 'Lots',
    icon: 'account_tree',
    component: Lots,
    layout: '/admin',
    leftBar: true,
    parent: 'lots',
  },
  {
    path: `/categories/:code`,
    name: 'CategoryInfo',
    component: CategoryInfo,
    layout: '/admin',
    parent: 'categories',
  },
  {
    path: `/categories`,
    name: 'Categories',
    title: 'Categories',
    icon: 'category',
    component: Categories,
    layout: '/admin',
    leftBar: true,
    parent: 'categories',
  },
  {
    path: `/dashboards`,
    name: 'Dashboards',
    title: 'Dashboards',
    icon: 'web',
    component: Dashboards,
    layout: '/admin',
    parent: 'dashboards',
    leftBar: true,
  },
  {
    path: `/${config.pages.login}`,
    name: 'Login',
    icon: 'login',
    component: Login,
    layout: '',
  },
  {
    path: `/${config.pages.logout}`,
    name: 'Logout',
    icon: 'Logout',
    component: Logout,
    layout: '',
  },
];

export default dashboardRoutes;
