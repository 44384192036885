import React, { Component } from 'react';
import { withUsers, withEmployees } from '../../contextApi';
import { withRouter } from 'react-router-dom';
import { TableRowCustomer } from "../../components/Common/Table/rows";
import {
  getUserSummary,
  getRegistrationMethod,
  getGroupsText,
  getFormattedLastAccessedTime,
  getMostRecent,
} from '../../utils/userUtils';
import { getDeviceName, getDeviceManufacturerFromUser, getDeviceOSFromUser } from "../../utils/deviceUtils";
import ft from 'firebase-timestamp-utils';
import GridPage from "../GridPage";
import MultiSelect from '../../components/Engine/Common/MultiSelect/MultiSelect';

const tableHead = [
  'Name',
  'OS',
  'Brand',
  'Device',
  'Registration',
  'Customer Id',
  'Installed at',
  'Last seen',
  // 'Location',
  'Status',
  'Groups',
  // 'Actions',
];

const appendActionsToTableHead = tableHead => {
  if (tableHead.includes('Actions')) {
    return tableHead;
  }
  return [...tableHead, 'Actions'];
};

const getLocationDescription = (location = {}) => {
  const { distance, updatedAt } = location;
  let locationDescription = 'Unknown';
  if (distance) {
    const meters = distance * 1000;
    if (meters < 50) {
      locationDescription = 'Plaza Low Yat';
    }
    else if (meters > 1000) {
      locationDescription = `${Math.round(distance)}km`;
    }
    else {
      locationDescription = `${Math.round(meters)}m`;
    }
  }

  return {
    locationDescription,
  }
};

const getTableRowData = (user) => {
  const userSummary = getUserSummary(user);
  const { name, photoUrl } = userSummary;
  const {
    id,
    lastAccessedAt,
    createdAt,
    onboardingStage,
    devices,
    location = {},
    pushToken,
    social,
  } = user;
  const { updatedAt } = location;
  const formattedLastAccessed = getFormattedLastAccessedTime(getMostRecent(lastAccessedAt, updatedAt));
  const FormattedCreatedAt = getFormattedLastAccessedTime(createdAt);
  const sortCreatedAt = ft.secondsFromNow(createdAt);
  const device = getDeviceName(user);
  const brand = getDeviceManufacturerFromUser(user);
  const deviceOS = getDeviceOSFromUser(user);
  const groups = getGroupsText(user);
  let sortLastAccessed = ft.secondsFromNow(lastAccessedAt);
  sortLastAccessed = isNaN(sortLastAccessed) ? Number.MIN_SAFE_INTEGER : sortLastAccessed;
  const registration = getRegistrationMethod(social);
  // const { locationDescription } = getLocationDescription(location);
  const locationSort = (location && location.distance) ? location.distance : Number.MAX_SAFE_INTEGER;

  return {
    id,
    customer_id: id,
    name,
    device,
    brand,
    os: deviceOS,
    photoUrl,
    social,
    registration,
    pushToken,
    groups,
    // location: locationDescription,
    location_sort: locationSort,
    installed_at: FormattedCreatedAt,
    installed_at_sort: sortCreatedAt,
    last_seen: formattedLastAccessed,
    last_seen_sort: sortLastAccessed,
    sortLastAccessed,
    status: onboardingStage,
  };
};

const enumValue = (name) => Object.freeze({
  toString: () => name
});

const OnboardingStage = Object.freeze({
  SPLASH: enumValue('Splash').toString(),
  ALLOW_LOCATION_SERVICES: enumValue('AllowLocationServices').toString(),
  ALLOW_PUSH_NOTIFICATIONS: enumValue('AllowPushNotifications').toString(),
  SOCIAL_SIGN_UP: enumValue('SocialSignUp').toString(),
  COMPLETED: enumValue('Completed').toString(),
  REGISTERED: enumValue('Registered').toString(),
});

const RegistrationMethod = Object.freeze({
  NONE: enumValue('None').toString(),
  GOOGLE: enumValue('Google').toString(),
  FACEBOOK: enumValue('Facebook').toString(),
  PHONE: enumValue('Phone').toString(),
});

class Customers extends Component {
  state = {
    onboardingStages: [],
    registrationMethods: [],
    tableHead,
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
    this.getFilterControls = this.getFilterControls.bind(this);
    this.handleOperatorEntitlements = this.handleOperatorEntitlements.bind(this);
    this.onGridRowClick = this.onGridRowClick.bind(this);
  }

  handleOperatorEntitlements() {
    const { isAdministrator } = this.props;
    const isAdmin = isAdministrator();

    this.setState(prevState => {
      const tableHead = appendActionsToTableHead(prevState.tableHead);
      const result = {
        ...prevState,
        ...(isAdmin && {
          tableHead,
        }),
        isAdmin,
      };
      console.log('new state = ', JSON.stringify(result));
      return result;
    });
  }

  componentDidMount() {
    console.log('Customers.componentDidMount');
    const { setEmployeesProviderActive, setCustomersProviderActive } = this.props;
    setCustomersProviderActive();
    setEmployeesProviderActive(this.handleOperatorEntitlements);

    const onboardingStages = Object.values(OnboardingStage)
      .map(x => ({ value: x, label: x }));
    const registrationMethods = Object.values(RegistrationMethod)
      .map(x => ({ value: x, label: x }));

    this.setState(prevState => ({
      ...prevState,
      onboardingStages,
      registrationMethods,
    }));
  }

  getFilterControls(filterValues) {
    console.log(`Customers.getFilterControls filterValues=${filterValues}`);
    const { onboardingStage = [], registrationMethod = [] } = filterValues; // The value
    const { onboardingStages, registrationMethods } = this.state; // The options

    return [
      <MultiSelect
        id="onboardingStage"
        key="onboardingStage"
        targetName="onboardingStage"
        isMainPage={true}
        name="Onboarding Stage"
        options={onboardingStages}
        value={onboardingStage.map(x => ({label: x, value: x}))}
      />,
      <MultiSelect
        id="registrationMethod"
        key="registrationMethod"
        targetName="registrationMethod"
        isMainPage={true}
        name="Registration Method"
        options={registrationMethods}
        value={registrationMethod.map(x => ({label: x, value: x}))}
      />
    ];
  }

  onGridRowClick(evt, id) {
    const { history } = this.props;
    console.log('nav to ' + id);
    history.push(`customers/${id}`);
  };

  renderRow(item) {
    const { location, deleteUserById } = this.props;
    const { isAdmin } = this.state;
    const { pathname } = location;

    return (
      <TableRowCustomer
        el={item}
        pathname={pathname}
        isAdmin={isAdmin}
        key={item.id}
        onClick={this.onGridRowClick}
        deleteCustomerById={deleteUserById}
      />
    );
  }

  render() {
    const { users, getFilteredUsers } = this.props;
    const { tableHead } = this.state;
    console.log('Customers.render users.length=', users ? users.length : 'undef');
    console.log('tableHead = ' + JSON.stringify(tableHead));

    return (
      <GridPage
        viewName="Customers"
        pathname="customers"
        itemName="Customer"
        items={users}
        getFilterControls={this.getFilterControls}
        getFilteredItems={getFilteredUsers}
        getTableRowData={getTableRowData}
        renderRow={this.renderRow}
        tableHead={tableHead}
        showCreateButton={false}
      />
    )
  }
}

export default withRouter(withUsers(withEmployees(Customers)));
