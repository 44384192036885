import React, { Fragment, Component } from "react";
import PropTypes from 'prop-types';
import headerLinksStyle
  from '../../assets/jss/material-dashboard-react/components/headerLinksStyle.jsx';
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import classNames from 'classnames';

const useStyles = theme => ({
  root: {
    height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
    // marginTop: '14px',
    marginTop: '16px',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '1px',
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
    backgroundColor: 'white',
  },
  divider: {
    height: '2px',
  },
});

const classes = useStyles();

const renderInputComponent = (inputProps) => {
  const { classes, inputRef = () => {}, ref, ...other } = inputProps;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputRef: node => {
          ref(node);
          inputRef(node);
        },
        classes: {
          input: classes.input,
        },
      }}
      {...other}
    />
  );
};

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const matches = match(suggestion.label, query);
  const parts = parse(suggestion.label, matches);

  return (
    <MenuItem selected={isHighlighted} component="div">
      <div>
        {parts.map(part => (
          <span key={part.text} style={{ fontWeight: part.highlight ? 500 : 400 }}>
            {part.text}
          </span>
        ))}
      </div>
    </MenuItem>
  );
};

function getSuggestionValue(suggestion) {
  return suggestion.label;
}

class NavBarDropdownMenu extends Component {
  state = {
    suggestions: [],
    value: '',
  };

  onSuggestionsFetchRequested = ({ value }) => {
    const { getSuggestions, map, displayMax } = this.props;
    const suggestions = getSuggestions(value)
      .slice(0, displayMax)
      .map(map);

    this.setState({
      suggestions,
    });
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { value } = nextProps;
    if (value && !prevState.value) {
      return {
        ...prevState,
        value,
      };
    }
    return {
      ...prevState,
    };
  }

  onChange = (event, { newValue }) => {
    const { onSelect } = this.props;
    console.log(newValue, '<<');
    this.setState({
      value: newValue,
    });
    if (newValue.length === 0) {
      onSelect({
        target: undefined,
      }, {
        suggestion: {
          label: undefined,
          name: undefined,
        }
      });
    }
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  render() {
    const { onSelect, className = {}, placeholder = '', style = {} } = this.props;
    const { suggestions = [], value } = this.state;
    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange,
      classes,
    };

    const containerClasses = {
      ...classes.container,
      ...className,
      ...style,
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        renderInputComponent={renderInputComponent}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSelect}
        style={style}
        theme={{
          container: containerClasses,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion,
        }}
      />
    );
  }
}

export default withStyles(headerLinksStyle)(NavBarDropdownMenu);