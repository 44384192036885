import _ from "lodash";

export const getCapturedByMerchant = (capturedBy) => {
  const capturedByMerchant = (capturedBy) ? _.get(capturedBy, 'merchant.name') : 'Unknown';

  return capturedByMerchant;
};

export const getDealOrVoucherId = (receipt) => {
  const { voucher, deal } = receipt;
  if (voucher) {
    return `Voucher id: ${voucher.id}`;
  }
  else if (deal) {
    return `Deal id: ${deal.id}`;
  }
  return '';
};