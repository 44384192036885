import React, { Fragment, Component } from 'react';
import _ from 'lodash';
import * as moment from 'moment';
import rundef from 'rundef';
import { withRouter } from 'react-router-dom';
import Firebase from '../../components/Firebase';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import { config } from '../../config';
import { withSite, withNotifications, withPromotions } from '../../contextApi';
import Input from '../../components/Engine/Common/Input/Input.jsx'
import EntityEditor from '../EntityEditor';
import Select from '../../components/Engine/Common/Select/Select';
import InputEmoji from 'react-input-emoji';
import ft from "firebase-timestamp-utils";
import Image from '../../components/Common/Image/Image';
import ImageManager from "../../components/Images/ImageManager";
import { getSmallImageFilename, getImage } from '../../utils/imageUtils';

const { uuid } = Firebase;

const enumValue = (name) => Object.freeze({
  toString: () => name
});

export const TargetNotificationGroup = Object.freeze({
  ALL_CUSTOMERS: enumValue('All Customers').toString(),
  TESTERS: enumValue('Testers').toString(),
});

class NotificationInfo extends Component {
  state = {
    notification: undefined,
    promotionsSelectData: undefined,
    targetGroupSelectData: undefined,
    thumbnailImage: undefined,
  };

  constructor(props) {
    super(props);

    this.getEntityDocument = this.getEntityDocument.bind(this);
    this.onEditorChange = this.onEditorChange.bind(this);
    this.getNotificationImage = this.getNotificationImage.bind(this);
  }

  static getIdFromPath = (status = '') => {
    const { href } = window.location;
    return href.split('/').pop();
  };

  componentDidMount() {
    const { setNotificationsProviderActive, setPromotionsProviderActive } = this.props;

    setNotificationsProviderActive();
    setPromotionsProviderActive();
  }

  static getNewNotification(siteId) {
    return {
      id: uuid(),
      siteId,
      title: undefined,
      body: undefined,
      targetGroup: 'ALL_CUSTOMERS',
    }
  }

  static getNotification(id, { getNotificationById, site: { id: siteId } = {} }) {
    return (id === 'add') ? NotificationInfo.getNewNotification(siteId) : { ...getNotificationById(id) };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { notifications, promotions = [] } = nextProps;
    let notification, id, promotionsSelectData, targetGroupSelectData;

    if (!prevState.notification && Array.isArray(notifications)) {
      id = NotificationInfo.getIdFromPath();
      notification = NotificationInfo.getNotification(id, nextProps);
    }

    if (!prevState.promotionsSelectData && promotions.length) {
      promotionsSelectData = promotions
        .filter(x => ft.isPast(x.startDate) && ft.isFuture(x.endDate))
        .map(x => ({ key: x.id, value: x.title }));
    }

    if (!prevState.targetGroupSelectData) {
      targetGroupSelectData = Object.entries(TargetNotificationGroup)
        .map(x => ({ key: x[0], value: x[1] }));
    }

    return {
      ...prevState,
      ...(notification && {
        notification,
      }),
      ...(promotionsSelectData && {
        promotionsSelectData,
      }),
      ...(targetGroupSelectData && {
        targetGroupSelectData,
      })
    };
  }

  onSelectChange = (event, data) => {
    let callback;
    const key = _.get(event, 'target.name');
    const value = data.key;

    if (key) {
      console.log(`key = ${key}`);
      if (key === 'promotion.id') {
        // notificationImage
        callback = this.getNotificationImage;
      }
      this.updateState(key, value, callback);
    }
  };

  async getNotificationImage() {
    const { getPromotionById } = this.props;
    const { notification } = this.state;
    const { promotion: { id: promotionId } = {} } = notification;
    if (promotionId) {
      const promotion = getPromotionById(promotionId);
      const { image } = promotion;
      const imageName = `${promotionId}_${image.fileName}`;
      const thumbnailImageFileName = getSmallImageFilename(imageName);
      const thumbnailImage = await getImage(`promotionImages/${thumbnailImageFileName}`);
      const promotionImage = await getImage(`promotionImages/${imageName}`);

      // calculates as https://firebasestorage.googleapis.com/v0/b/low-yat-staging.appspot.com/o/promotionImages%2Fpromo-2_small.jpg (404)
                       https://firebasestorage.googleapis.com/v0/b/low-yat-staging.appspot.com/o/promotionImages%2FgdX2NV8HHbs7DzDZMPrE16_promo-2_small.jpg?alt=media&token=eab29baf-f705-47e3-9ea9-50584945c329

      // const thumbnailImageFileName = getSmallImageFilename(fileName);
      // const thumbnailImage = await getImage(`promotionImages/${thumbnailImageFileName}`);
      console.log(`go promotion thumb=${thumbnailImage} id=${JSON.stringify(promotion)}`);

      this.setState(prevState => ({
        ...prevState,
        thumbnailImage,
        promotionImage,
      }));
    }
    else {
      console.log('nah mate nah');
    }
  }

  onEditorChange(value) {
    this.onInputChange(null, { key: 'body', value });
  }

  onInputChange = (event, data) => {
    if (!data) {
      debugger;
    }
    const { key, value } = data;

    if (key) {
      this.updateState(key, value);
    }
  };

  updateState(key, value, callback) {
    this.setState(prevState => {
      const notification = {...prevState.notification};

      console.log(`setting notification key=${key} value=${value}`);
      _.set(notification, key, value);

      return {
        ...prevState,
        notification,
      };
    }, () => callback && callback());
  }

  onDateFieldChange = (value, key) => {
    const date = (value instanceof Date) ? value : value.toDate();

    if (date) {
      this.updateState(key, date);
    }
  };

  // promotionIsMallEvent(id) {
  //   const { promotions } = this.props;
  //
  //   const promotion = promotions.find(x => x.id === id);
  //   return (promotion && promotion.isMallEvent);
  // }

  getEntityDocument() {
    const { notification } = this.state;

    const notificationDocument = {
      ...notification,
    };

    return rundef(
      notificationDocument,
      true,    // mutate - whether to mutate the original object or return a new one
      1,      // recursive - whether to apply recursively
    );
  }

  render() {
    const {
      promotions = [],
    } = this.props;
    const {
      notification = {},
      promotionsSelectData = [],
      targetGroupSelectData = [],
      thumbnailImage,
    } = this.state;
    const {
      title,
      body,
      // imageFileName,
      targetGroup,
      promotion: { id: promotionId } = {},
    } = notification;
    // const notificationImage = undefined;

    const selectedPromotion = promotions
      .find(x => x.id === promotionId);
    let selectedPromotionData = (!_.isEmpty(selectedPromotion)) ? selectedPromotion.id : undefined;

    return (
      <EntityEditor
        // onCancelClick={this.onCancelClick}
        getEntityDocument={this.getEntityDocument}
        collection={Firebase.notificationsRef}
        createEndpoint={`notification/create`}
        cancelHref={`/${config.pages.mainLayout}/notifications`}
        entityName="notification"
      >
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <Input
              id="title"
              name="Title"
              value={title}
              flex={true}
              onChange={this.onInputChange}
            />
            <Select
              label="Event/Promotion"
              name="promotion.id"
              changeSelect={this.onSelectChange}
              data={promotionsSelectData}
              flex={true}
              value={selectedPromotionData}
            />
            <Select
              label="Target Group"
              name="targetGroup"
              changeSelect={this.onSelectChange}
              data={targetGroupSelectData}
              flex={true}
              value={targetGroup}
            />

            < br/>
            < br/>
            < br/>
            < br/>
            < br/>
            < br/>
            <h3>MESSAGE</h3>
            <div style={{marginLeft: -10}}>
              <InputEmoji
                value={body}
                onChange={this.onEditorChange}
                cleanOnEnter={true}
                height={60}
              />
            </div>
            {thumbnailImage && (
              <Image currentImage={thumbnailImage} />
            )}

            {/*<h3>BIG IMAGE (optional)</h3>*/}
            {/*<ImageManager*/}
            {/*  imagesArr={imagesArr}*/}
            {/*  ref={ref => this.setImageManagerRef(ref, '_imageManager')}*/}
            {/*  imageNamePrefix={`${id}_`}*/}
            {/*  onChange={this.onImageUpdate}*/}
            {/*  path="merchantImages"*/}
            {/*/>*/}
          </GridItem>
        </GridContainer>
      </EntityEditor>
    );
  }
}

export default withRouter(withSite(withNotifications(withPromotions(NotificationInfo))));
