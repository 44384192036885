import React, {Component, Fragment} from 'react';
import _ from 'lodash';
import {
  withVouchers,
  withReimbursements,
} from '../../../contextApi';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { config } from '../../../config';
import Input from '../../../components/Engine/Common/Input/Input.jsx';
import Firebase from "../../../components/Firebase";
import EntityEditor from '../../EntityEditor';
import CustomInput from '../../../components/Common/CustomInput/CustomInput.jsx';
import DatePicker from "../../../components/Engine/Common/DatePicker/DatePicker";
import { formatMoney } from 'accounting';
import { formatDateFromFirebase, getStartOfDay } from '../../../utils/date';

const VoucherIdList = ({ list }) => {
  return (
    <Fragment>
      {
        list.map(id => {
          return (
            <div key={id}>{id}</div>
          );
        })
      }
    </Fragment>
  )
};

class ReimbursementInfo extends Component {
  state = {
    reimbursement: {
      vouchers: [],
      payment: {
        amount: 0,
        chequeNumber: '',
        chequeDate: new Date(),
      },
      merchant: {},
    }
  };

  constructor(props) {
    super(props);

    this.getEntityDocument = this.getEntityDocument.bind(this);
  }

  static getIdFromPath = (status = '') => {
    const { href } = window.location;
    return href.split('/').pop();
  };

  static getIdFromPath = (status = '') => {
    const { href } = window.location;
    return href.split('/').pop();
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getReimbursementById, location } = nextProps;
    const { params = {} } = location;

    if (!prevState.reimbursement.reimbursementDate) {
      const id = ReimbursementInfo.getIdFromPath();
      if (id === 'add' && !_.isEmpty(params)) {
        const {
          voucherIds,
          totalAmount,
          merchant,
        } = params;

        return {
          ...prevState,
          reimbursement: {
            merchant,
            payment: {
              chequeNumber: '',
              chequeDate: new Date(),
              amount: totalAmount,
            },
            vouchers: voucherIds,
          }
        }
      }
      else {
        const reimbursement = getReimbursementById(id);
        if (reimbursement) {
          return {
            ...prevState,
            reimbursement,
          }
        }
      }
    }

    return {
      ...prevState,
    }
  }

  getEntityDocument() {
    const {
      voucherIds,
      totalAmount,
      chequeNumber,
      chequeDate,
      merchant,
    } = this.state;
    const operator = JSON.parse(localStorage.getItem('userProfile'));
    const data = {
      merchant,
      payment: {
        chequeNumber,
        chequeDate,
        amount: totalAmount,
      },
      vouchers: voucherIds,
      processedBy: operator,
    };

    return data;
  }

  onInputChange = (event, data) => {
    const { key, value } = data;

    if (key) {
      this.updateState(key, value);
    }
  };

  onDateFieldChange = (value, key) => {
    const date = (value instanceof Date) ? value : value.toDate();

    if (date) {
      this.updateState(key, date);
    }
  };

  updateState(key, value) {
    this.setState(prevState => {
      // const merchant = {...prevState.merchant};
      // _.set(merchant, key, value);

      return {
        ...prevState,
        [key]: value,
      };
    });
  }

  render() {
    const {
      reimbursement,
    } = this.state;
    const {
      payment,
      vouchers,
      processedBy = {},
      reimbursementDate,
    } = reimbursement;
    const {
      amount,
      chequeNumber,
      chequeDate,
    } = payment;
    const {
      name
    } = processedBy;
    const formattedAmount = formatMoney(amount, '', 2);

    return (
      <EntityEditor
        onCancelClick={this.onCancelClick}
        // onDeleteClick={deleteMerchantById}
        getEntityDocument={this.getEntityDocument}
        collection={Firebase.merchantsRef}
        createEndpoint={`/reimbursement/create`}
        cancelHref={`/${config.pages.mainLayout}/reimbursements`}
        saveButtonText="Submit Reimbursement"
        entityName="merchant"
        overlayText="Submitting Reimbursement"
      >
        <GridContainer>
          <GridItem xs={12} sm={6} md={6} lg={6}>
            <h3>REIMBURSEMENT</h3>
            <Input
              id="chequeNumber"
              name="Cheque No."
              value={chequeNumber}
              onChange={this.onInputChange}
              flex={false}
            />
            <DatePicker
              label="Cheque Date"
              id="chequeDate"
              onChange={this.onDateFieldChange}
              value={chequeDate}
              minDate={new Date()}
            />
            {name && (
              <h5>Processed by: {name}</h5>
            )}
            {reimbursementDate && (
              <h5>Reimbursed at: {formatDateFromFirebase(reimbursementDate)}</h5>
            )}
            <h3>AMOUNT RM {formattedAmount}</h3>
          </GridItem>
          <GridItem xs={12} sm={6} md={6} lg={6} style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <h3>VOUCHER CODES</h3>
            <VoucherIdList list={vouchers} />
          </GridItem>
        </GridContainer>
      </EntityEditor>
    );
  }
}

export default withReimbursements(withVouchers(ReimbursementInfo));
