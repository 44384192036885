import React, { Component } from 'react';
import _ from 'lodash';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';
import { config } from '../config';
import { dateDiffInDays } from '../utils/date';
import { toTitleCase } from "../utils/stringUtils";

const { vouchersRef } = Firebase;
const VouchersContext = React.createContext();

export const VouchersConsumer = VouchersContext.Consumer;

export class VouchersProvider extends Component {
  state = {
    vouchers: undefined,
    redemptionStatuses: [],
    getFilteredVouchers: filters => this.getFilteredVouchers(filters),
    getVoucherTotals: filters => this.getVoucherTotals(filters),
    getSearchedVouchers: term => this.getSearchedVouchers(term),
    getVoucherById: id => this.getVoucherById(id),
    getCampaignTotals: filters => this.getCampaignTotals(filters),
    setActive: () => this.setActive(),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
  }

  setActive() {
    const { site: { id: siteId } = {} } = this.props;

    if (!this.unsusbscribe) {
      const query = vouchersRef.where('siteId', '==', siteId);
      this.unsusbscribe = query.onSnapshot(this.onSnapshot);
    }
  }

  async onSnapshot(snapshot) {
    const { vouchers: prevVouchers } = this.state;
    const vouchers = mergeSnapshotChangesIntoArray(snapshot, prevVouchers);

    this.setState({
      vouchers,
      redemptionStatuses: this.getRedemptionStatuses(vouchers),
    });
  }

  getRedemptionStatuses(vouchers) {
    const statusIds = vouchers.reduce((acc, voucher) => {
      const redemptionStatus = _.get(voucher, 'redemption.status');
      if (redemptionStatus && !acc.includes(redemptionStatus)) {
        acc.push(redemptionStatus);
      }
      return acc;
    }, []);

    return statusIds.map(x => ({
      key: x,
      value: toTitleCase(x),
    }));
  }

  getVoucherById(id) {
    const { vouchers } = this.state;
    return vouchers.find(x => x.id === id);
  }

  getFilteredVouchers(filters = {}) {
    const { vouchers } = this.state;
    if (!vouchers) return undefined;
    const {
      status,
      type,
      merchantId,
      campaignId,
      active,
    } = filters;

    // Firebase min one object in collection workaround hack
    let filteredVouchers = vouchers.filter(x => !['0', 'dummy'].includes(x.id));
    if (merchantId && merchantId !== 'Any') {
      filteredVouchers = filteredVouchers.filter(voucher => _.get(voucher, 'redemption.merchantScan.merchant.id') === merchantId);
    }
    if (campaignId && campaignId !== 'Any') {
      filteredVouchers = filteredVouchers.filter(voucher => _.get(voucher, 'campaign.id') === campaignId);
    }
    if (status && status !== 'Any') {
      filteredVouchers = filteredVouchers.filter(voucher => _.get(voucher, 'redemption.status') === status);
    }
    // Filter out all merchant issued vouchers completely for now
    filteredVouchers = filteredVouchers.filter(voucher => _.get(voucher, 'merchant.id') === undefined);

    return filteredVouchers;
  }

  getCampaignTotals(filters = {}) {
    const { campaignId } = filters;
    const { vouchers = [] } = this.state;

    const totals = vouchers.reduce((acc, voucher) => {
      if (!voucher.campaign) {
        return acc;
      }
      if (campaignId && campaignId !== 'Any' && voucher.campaign.id !== campaignId) {
        return acc;
      }
      acc.issued += 1;
      acc.redeemed += (!_.isEmpty(voucher.redemption) ? 1 : 0);
      acc.reimbursed += (_.get(voucher, 'redemption.status') === 'REIMBURSED');

      return acc;
    }, {
      // created: 0,
      issued: 0,
      redeemed: 0,
      reimbursed: 0,
      // consumed: 0,
    });

    return totals;
  }

  getSearchedVouchers(searchTerm) {
    if (!searchTerm) return [];
    const { vouchers } = this.state;
    const term = searchTerm.toLowerCase();

    const searchedVouchers = vouchers
      .filter(voucher => voucher.id.toLowerCase().includes(term))
      .map(voucher => {
        const { id } = voucher;
        return { id };
      });

    return searchedVouchers;
  }

  getVoucherTotals(filters = {}) {
    const { campaignId, merchantId, status } = filters;
    const { vouchers } = this.state;
    if (!vouchers) return undefined;

    let filteredVouchers = vouchers;
    if (campaignId && campaignId !== 'Any') {
      filteredVouchers = filteredVouchers.filter(x => _.get(x, 'campaign.id') === campaignId);
    }
    if (merchantId && merchantId !== 'Any') {
      filteredVouchers = filteredVouchers.filter(x => _.get(x, 'redemption.merchantScan.merchant.id') === merchantId);
    }
    if (status && status !== 'Any') {
      filteredVouchers = filteredVouchers.filter(x => _.get(x, 'redemption.status') === status);
    }

    const totals = filteredVouchers.reduce((acc, voucher) => {
      const { redemption } = voucher;

      if (!_.isEmpty(redemption)) {
        const { merchantScan } = redemption;
        const { scannedAt } = merchantScan;
        if (scannedAt) {
          const dateDiff = dateDiffInDays(scannedAt.toDate(), new Date());
          if (dateDiff > acc.maxLiabilityAge) {
            acc.maxLiabilityAge = dateDiff;
          }
        }

        if (redemption.status === config.statuses.pendingAuthorisation) {
          acc.pending_authorisation++;
        }
        if (redemption.status === config.statuses.pendingReimbursement) {
          acc.pending_reimbursement++;
        }
        acc.totalAmount += _.get(voucher, 'discount.amount');
      }

      return acc;
    }, {
      pending_authorisation: 0,
      pending_reimbursement: 0,
      totalAmount: 0,
      maxLiabilityAge: 0,
    });

    return totals;
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  render() {
    const { children } = this.props;

    return (
      <VouchersContext.Provider value={this.state}>
        {children}
      </VouchersContext.Provider>
    );
  }
}

export default VouchersProvider;
