import React, {Component} from "react";
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import styles
  from './tableCellBadgeStyles.jsx';

// Ah, the hack of hacks
const getColorClassName = (classes, label) => {
  if (label.startsWith('Pending')) {
    return classes.amber;
  }
  if (label.startsWith('Processed') || label.startsWith('Reimbursed') || label.startsWith('Active')) {
    return classes.green;
  }
  if (label.startsWith('Issued') || label.startsWith('Inactive')) {
    return classes.grey;
  }
  return classes.red;
};

class TableCellBadge extends Component {
  render() {
    const { label, classes } = this.props;
    const badgeClasses = classNames(classes.badge, getColorClassName(classes, label));

    return (
      <span className={badgeClasses}>{label}</span>
    );
  }
}

export default withStyles(styles)(TableCellBadge);
