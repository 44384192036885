import React, { Component } from 'react';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';
import { getRegistrationMethod } from '../utils/userUtils';

const { usersRef } = Firebase;
const UsersContext = React.createContext();
export const UsersConsumer = UsersContext.Consumer;

export class UsersProvider extends Component {
  state = {
    users: undefined,
    setActive: () => this.setActive(),
    setGroups: (id, groups) => this.setGroups(id, groups),
    getUserById: id => this.getUserById(id),
    getDeviceNameByUserId: id => this.getDeviceNameByUserId(id),
    deleteUserById: id => this.deleteUserById(id),
    getFilteredUsers: filters => this.getFilteredUsers(filters),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  async onSnapshot(snapshot) {
    const { users: prevUsers } = this.state;
    const users = mergeSnapshotChangesIntoArray(snapshot, prevUsers);

    console.log('UsersProvider.onSnapshot ::: users.length=', users.length);
    this.setState({ users });
  }

  setActive() {
    const { site: { id: siteId } = {} } = this.props;

    if (!this.unsusbscribe) {
      this.unsusbscribe = usersRef
        // .where('siteId', '==', siteId)
        .onSnapshot(this.onSnapshot);
    }
  }

  async setGroups(id, groups) {
    await usersRef
      .doc(id)
      .update({ groups });
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  getFilteredUsers(filters = {}) {
    const { users } = this.state;
    if (!users) return undefined;
    const { onboardingStage = [], registrationMethod = [] } = filters;

    let filteredUsers = [...users];

    if (onboardingStage.length) {
      filteredUsers = users.filter(x => onboardingStage.includes(x.onboardingStage));
    }
    if (registrationMethod.length) {
      filteredUsers = filteredUsers.filter(({ social }) => registrationMethod.includes(getRegistrationMethod(social)));
    }

    // Launch day filter
    // filteredUsers = filteredUsers.filter(x => {
    //   return x.createdAt.seconds >= 1566608401 && x.createdAt.seconds <= 1566694801
    // });

    // Default ordering by creation date
    // return filteredDeals.sort((a, b) => {
    //   const creationDateA = _.get(a, 'creationDate.seconds');
    //   const creationDateB = _.get(b, 'creationDate.seconds');
    //   return creationDateB - creationDateA;
    // });

    return filteredUsers;
  }

  getDeviceNameByUserId(id) {
    const user = this.getUserById(id);
    const { devices } = user;
    const firstDevice = devices[Object.keys(devices)[0]];

    return firstDevice.deviceName;
  }

  getUserById(id) {
    const { users = [] } = this.state;
    return users.find(x => x.id === id);
  }

  async deleteUserById(id) {
    await usersRef.doc(id)
      .delete();
  }

  render() {
    const { children } = this.props;

    return (
      <UsersContext.Provider value={this.state}>
        {children}
      </UsersContext.Provider>
    );
  }
}

export default UsersProvider;
