import React, { Component } from 'react';
import _ from 'lodash';
import {
  signInWithGoogle,
  signOut,
  observerAuth,
} from '../components/Firebase/Firebase';
import Firebase from '../components/Firebase/Firebase';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import firebaseConfig, { config } from "../config";

const AuthContext = React.createContext();
const { employeesRef } = Firebase;

class _AuthProvider extends Component {
  state = {
    isAuth: false,
    loginAttempt: false,
    checkedLocalStorage: false,
    authMerchants: [],
  };

  constructor(props) {
    super(props);

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.updateState = this.updateState.bind(this);
  }

  async componentDidMount() {
    observerAuth(this.updateState);

    console.log('AuthProvider.componentDidMount()');
    let isAuth = false;
    let authMerchants = [];
    const raw = localStorage.getItem('userProfile');
    const userProfile = raw ? JSON.parse(raw) : undefined;
    if (userProfile) {
      const { email } = userProfile;
      const obj = await this.authoriseEmployee(email);
      isAuth = obj.isAuth;
      authMerchants = obj.authMerchants;
    }

    this.setState(prevState => ({
      ...prevState,
      isAuth,
      checkedLocalStorage: true,
      authMerchants,
    }));
  }

  updateState = (data) => {
    this.setState({ ...data });
  };

  async updateEmployeeProfile(employeeId, google) {
    try {
      await employeesRef
        .doc(employeeId)
        .update({ google });
    } catch (error) {
      console.log(error);
    }
  }

  async confirmEmployeeExists(email) {
    const { baseUrl } = firebaseConfig;
    const requestUrl = `${baseUrl}/employee/exists/${email}`;
    return axios.get(requestUrl)
      .then(response => {
        const { employee } = response.data;
        return {
          employee,
          success: true,
        }
      })
      .catch(err => {
        const error = _.get(err, 'response.data.error') || err.message;
        return {
          error,
          success: false,
        }
      });
  }

  /**
   * Confirm that an entry in the employees collection exists having a matching google.id
   * @returns {boolean}
   */
  async authoriseEmployee(email) {
    const response = await this.confirmEmployeeExists(email);
    const { success, employee } = response;
    const isAuth = success && !_.isEmpty(employee);
    if (isAuth) {
      const {
        id,
        merchants: authMerchants,
        google,
      } = employee;

      if (id) {
        await this.updateEmployeeProfile(employee.id, google);
      }

      return {
        isAuth,
        authMerchants,
      }
    }

    return {
      isAuth,
      authMerchants: undefined,
    }
  }

  async login() {
    const { history } = this.props;

    try {
      const result = await signInWithGoogle();
      const userProfile = _.get(result, 'additionalUserInfo.profile');
      localStorage.setItem('userProfile', JSON.stringify(userProfile));
      const { additionalUserInfo: { profile } = {} } = result;
      const { email } = profile;
      const { isAuth, authMerchants } = await this.authoriseEmployee(email);

      this.setState(prevState => ({
        ...prevState,
        isAuth,
        authMerchants,
        loginAttempt: true,
      }));
    } catch (error) {
      console.log(error);
    }
  }

  logout() {
    this.setState({
      isAuth: false,
      loginAttempt: false,
      authMerchants: undefined,
    });
    localStorage.clear();

    signOut();
  }

  render() {
    const { children } = this.props;
    const options = {
      ...this.state,
      login: this.login,
      logout: this.logout,
    };

    return (
      <AuthContext.Provider value={options} >
        {children}
      </AuthContext.Provider>
    );
  }
}

const AuthConsumer = AuthContext.Consumer;

const AuthProvider = withRouter(_AuthProvider);

export { AuthProvider, AuthConsumer, AuthContext };
