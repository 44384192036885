import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Firebase from '../components/Firebase';

const { campaignsRef } = Firebase;
const CampaignsContext = React.createContext();
export const CampaignsConsumer = CampaignsContext.Consumer;

export class CampaignsProvider extends Component {
  state = {
    campaigns: [],
    setActive: () => this.setActive(),
    getCampaignById: id => this.getCampaignById(id),
    getCampaignCreatedTotals: filters => this.getCreatedTotals(filters),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
  }

  setActive() {
    if (!this.unsusbscribe) {
      const { site: { id: siteId } = {} } = this.props;
      const query = campaignsRef.where('siteId', '==', siteId);
      this.unsusbscribe = query.onSnapshot(this.onSnapshot);
    }
  }

  async onSnapshot(snapshot) {
    console.log('CampaignsProvider.snapshot')
    // const promotions = list.docs.map(x => x.data());
    const { campaigns } = this.state;

    // TODO : let's do this .... for the love of God
    snapshot.docChanges().forEach(change => {
      const campaign = change.doc.data();
      if (change.type === "added") {
        campaigns.unshift(campaign);
      }
      if (change.type === "modified") {
        const index = campaigns.findIndex(x => x.id === campaign.id);
        campaigns[index] = campaign;
      }
      if (change.type === "removed") {
        const index = campaigns.findIndex(x => x.id === campaign.id);
        campaigns.splice(index, 1);
      }
    });

    this.setState({
      // Empty collections in firebase workaround
      campaigns: campaigns.filter(x => x.id !== '0'),
    });
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  getCampaignById(id) {
    const { campaigns } = this.state;
    return campaigns.find(x => x.id === id);
  }

  getCreatedTotals(filters = {}) {
    const { campaignId } = filters;
    const { campaigns } = this.state;

    let filteredCampaigns = campaigns;
    if (campaignId && campaignId !== 'Any') {
      filteredCampaigns = filteredCampaigns.filter(campaign => campaign.id === campaignId);
    }

    const totals = filteredCampaigns.reduce((acc, campaign) => {
      return acc += _.get(campaign, 'totals.created') || 0;
    }, 0);

    return totals;
  }

  render() {
    const { children } = this.props;
    console.log('CampaignsProvider.render')
    return (
      <CampaignsContext.Provider value={this.state}>
        {children}
      </CampaignsContext.Provider>
    );
  }
}

export default CampaignsProvider;
