import React, { Component } from 'react';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';
import { withImages } from './withImages';
import { getSmallImageFilename } from '../utils/imageUtils';

const { receiptsRef } = Firebase;
const ReceiptsContext = React.createContext();

export const ReceiptsConsumer = ReceiptsContext.Consumer;

class ReceiptsProvider extends Component {
  state = {
    receipts: [],
    setActive: () => this.setActive(),
    getReceiptById: id => this.getReceiptById(id),
    getNumberPendingProcessing: () => this.getNumberPendingProcessing(),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
    this.setActive = this.setActive.bind(this);
  }

  setActive() {
    const { site: { id: siteId } = {} } = this.props;

    if (!this.unsusbscribe) {
      const query = receiptsRef.where('siteId', '==', siteId);
      this.unsusbscribe = query.onSnapshot(this.onSnapshot);
    }
  }

  // componentDidMount() {
  //   this.unsusbscribe = receiptsRef.onSnapshot(this.onSnapshot);
  // }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  async onSnapshot(snapshot) {
    const { receipts: prevReceipts } = this.state;
    const receipts = mergeSnapshotChangesIntoArray(snapshot, prevReceipts);

    // await snapshot.docChanges().forEach(async change => {
    //   const receipt = change.doc.data();
    //   console.log('change.type=', change.type);
    //   if (change.type === "added") {
    // TODO : image piece here... removed pending replacement
    //     const _receipt = await this.fetchSmallerImage(receipt);
    //     receipts.unshift(_receipt);
    //     console.log('receipts.length=', receipts.length);
    //   }
    //   if (change.type === "modified") {
    //     const index = receipts.findIndex(x => x.id === receipt.id);
    //     receipts[index] = receipt;
    //   }
    //   if (change.type === "removed") {
    //     const index = receipts.findIndex(x => x.id === receipt.id);
    //     receipts.splice(index, 1);
    //   }
    // })

    this.setState({ receipts });
  }

  // TODO : abstract - ensure only for those which are updated
  async fetchPrimaryImages(receipts) {
    const { getImage } = this.props;

    const updatedReceipts = await Promise.all(
      receipts.map(async receipt => this.fetchSmallerImage(receipt))
    );

    this.setState(prevState => ({
      ...prevState,
      receipts: updatedReceipts,
    }));
  }

  async fetchSmallerImage(receipt) {
    const { getImage } = this.props;

    const {
      imageFileName,
      primaryImage,
      smallerImage,
      id,
    } = receipt;

    let _smallerImage;
    if (imageFileName && !smallerImage) {
      const imageFileNameSmall = getSmallImageFilename(imageFileName);
      _smallerImage = await getImage(imageFileNameSmall, 'salesReceipts');
    }

    const result = {
      ...receipt,
      ...(_smallerImage && !smallerImage && {
        // primaryImage: await getImage(imageFileName, 'salesReceipts'),
        smallerImage: _smallerImage,
      }),
    };

    return result;
  }

  getNumberPendingProcessing() {
    const { receipts } = this.state;
    return receipts.filter(x => x.status === 'PENDING_PROCESSING').length;
  }

  getReceiptById(id) {
    const { receipts } = this.state;
    return receipts.find(x => x.id === id);
  }

  render() {
    const { children } = this.props;

    return (
      <ReceiptsContext.Provider value={this.state}>
        {children}
      </ReceiptsContext.Provider>
    );
  }
}

const _ReceiptsProvider = withImages(ReceiptsProvider);
export { _ReceiptsProvider as ReceiptsProvider };

