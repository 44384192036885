import { config } from '../../config';
import _ from 'lodash';

const pages = {};
const fields = {};


// This function need for adding all files from directory
// https://webpack.js.org/guides/dependency-management/
const importAll = (obj) => {
  obj.keys().forEach((key) => {
    pages[key] = obj(key);
  });
};


importAll(require.context('../../pages/', false, /\.json$/));

const parseNTree = (section, response = {}) => {
  if (Array.isArray(section)) {
    section.forEach((el) => {
      const {
        items = [],
        title: nameSection,
        name: nameElement,
        mandatory = false,
        isFull = false,
        target = [],
      } = el;
      // debugger;
      if (items.length) {
        let includeResponse = {};
        for (let index = 0; index < items.length; ++index) {
          const data = parseNTree(items[index]);
          includeResponse = { ...includeResponse, ...data };
        }
        response = { ...response, ...includeResponse };
      }
      if (isFull) {
        const label = nameSection || nameElement;
        target.forEach((el) => {
          response[el] = { mandatory, label };
        });
      }
      const name = nameSection || nameElement;
      response[name] = { mandatory, label: name };
      return response;
    });
  }
  const {
    title: nameSection,
    name: nameElement,
    mandatory = false,
    label = '',
  } = section;
  const name = nameSection || nameElement;
  if (name) {
    response[name] = { mandatory, label };
  }
  return response;
};

export const getFields = (page, data = []) => {
  if (!fields[page]) {
    let result = {};
    for (const key in data) {
      result = parseNTree(data[key], result);
    }
    fields[page] = result;
  }
  return fields[page];
};

export const getDocument = (name) => {
  const { section = {} } = pages[`./${name}.json`];
  const { position = { middle: section } } = section;
  const curFields = getFields(name, position);

  return {
    document: position,
    fields: curFields,
  };
};

export const getTargetValue = (data, target, type) => {
  const targetValue = {};
  const { select, createSelect, multiSelect } = config.types;
  if (Array.isArray(target) && target.length === 2 && type === select) {
    target.forEach((el) => {
      if (Array.isArray(data[el])) {
        // all values in select
        targetValue.data = data[el];
      } else {
        // current value in select
        targetValue.value = data[el];
        targetValue.name = el;
      }
    });
    return targetValue;
  }
  if (Array.isArray(target) && target.length === 2 && (type === createSelect || multiSelect)) {
    target.forEach((el, index) => {
      // first parametr  === value
      // second parametr === options
      if (!index) {
        targetValue.value = data[el];
        targetValue.targetName = el;
      }
      if (index) {
        targetValue.options = data[el];
        targetValue.targetStorage = el;
      }
    });
    return targetValue;
  }

  if (Array.isArray(target)) {
    target.forEach((el) => {
      targetValue[el] = data[el];
    });
    return targetValue;
  }
  targetValue.value = _.get(data, target);
  targetValue.id = target;

  return targetValue;
};

export const getMinDate = (data, date) => {
  if (date === undefined) {
    return date;
  }
  if (date === 'now') {
    return new Date();
  }
  if (data[date]) {
    return data[date];
  }
  // we want data format day(d or dd 1 - 31)/month(1-12)/year
  date = date.split('/');
  return new Date(date[2], date[1] + 1, date[0]);
};

export const getPrettiedFields = (data, documentName) => {
  const documentFields = getFields(documentName);
  const prettiedData = {};

  for (const property in data) {
    if (property in documentFields) {
      prettiedData[property] = data[property];
    }
  }
  return prettiedData;
};

export const getMandatoryBlankFields = (data, documentName, deleteActive = true) => {
  const documentFields = getFields(documentName);
  const errors = {};
  for (const property in data) {
    if (property in documentFields && !data[property] && documentFields[property].mandatory) {
      if (!deleteActive || (deleteActive && property !== 'active')) {
        errors[property] = documentFields[property].label;
      }
    }
  }
  const uniqErrors = new Set(Object.values(errors));
  return [...uniqErrors];
};
