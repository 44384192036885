import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Switch from 'react-switch';
import GridContainer from '../../../Grid/GridContainer';
import GridItem from '../../../Grid/GridItem';
import customSelectStyle
  from '../../../../assets/jss/material-dashboard-react/components/select.jsx';

class SwitchWithName extends React.Component {
  state = {
    isDirty: false,
  };

  handleFocus = (id) => {
    const {
      value,
      handleChecked,
      name: field,
      required,
    } = this.props;
    const { isDirty } = this.state;
    const row = !value && required ? {
      field,
      status: false,
    } : {};
    if (!isDirty) {
      this.setState({ isDirty: true });
    }
    handleChecked(id, row);
  };

  render() {
    const { isDirty } = this.state;
    const {
      classes,
      width,
      name,
      value,
      required,
      id,
      informationForActive,
    } = this.props;
    // const disabledSwitch = informationForActive.length ? { disabled: true } : {};
    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <GridContainer
            style={{
              maxWidth: `${width ? 'min-content' : 100}%`,
              paddingRight: 30,
            }}>
            <GridItem xs={12} sm={12} md={width ? 6 : 12} lg={6}>
              <h5 className={classes.name}
                  style={{ marginTop: 0 }}>{name}</h5>
            </GridItem>
            <GridItem xs={12} sm={12} md={width ? 6 : 12} lg={6}>
              <Switch onChange={() => this.handleFocus(id)} checked={value} />
            </GridItem>
          </GridContainer>
          {informationForActive.length > 0
          && <div className={classes.errorContainer}>
            <label className={classes.headerError}>Fill out the fields</label>
            <div className={classes.errors}>
            {informationForActive.map(el => <p key={el} className={classes.error}>{el}</p>)}
            </div>
          </div>
          }

        </div>
        {
          isDirty && !value && required
          && <label className={classes.error}>{name} is required</label>
        }
      </React.Fragment>
    );
  }
}

SwitchWithName.propTypes = {
  classes: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.bool,
  handleChecked: PropTypes.func,
  width: PropTypes.bool,
  id: PropTypes.string,
  required: PropTypes.bool,
  informationForActive: PropTypes.array,
};

SwitchWithName.defaultProps = {
  width: true,
  id: 'active',
  value: false,
  required: false,
  informationForActive: [],
};


export default withStyles(customSelectStyle)(SwitchWithName);
