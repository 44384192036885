import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import withStyles from '@material-ui/core/styles/withStyles';
import Modal from 'react-responsive-modal';
import Grid from '../../components/Grid/Grid';
import dashboardStyle
  from '../../assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import { config } from '../../config';
import { TableRowPaidReimbursement } from "../../components/Common/Table/rows";
import {
  withReimbursements,
} from "../../contextApi";
import { formatDate, formatDateFromFirebase } from "../../utils/date";

const tableHead = [
  'Reimbursement id',
  'Merchant',
  'Cheque Number',
  'Amount',
  'Reimbursement Date',
];

const getPaidReimbursementTableRow = (data) => {
  const {
    id = 0,
    merchant = {},
    payment,
    reimbursementDate,
  } = data;
  const { name } = merchant;
  const { chequeNumber, amount = '' } = payment;

  return {
    id,
    reimbursement_id: id,
    cheque_number: chequeNumber,
    amount,
    merchant: name || 'Unknown Merchant',
    reimbursement_date: reimbursementDate ? formatDateFromFirebase(reimbursementDate) : '',
    reimbursement_date_sort: reimbursementDate.seconds,
  };
};

class PaidReimbursements extends Component {
  state = {
    tableData: [],
    campaignId: config.statuses.any,
    merchants: [config.statuses.any],
    merchantId: config.statuses.any,
    status: config.statuses.pendingReimbursement,
    totals: {},
    count: 10,
    rowsPerPage: 5,
    page: 0,
    chequeNumber: '',
    error: false,
    errorText: 'Error. We have a problem',
    unsubscribeFunctionTotals: () => {
    },
  };

  constructor(props) {
    super(props);

    this.renderRow = this.renderRow.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return PaidReimbursements.getData(prevState, nextProps);
  }

  onOpenModal = () => {
    this.setState({ error: true });
  };

  onCloseModal = () => {
    this.setState({ error: false });
  };

  renderRow(item) {
    const { location } = this.props;
    const { pathname } = location;
    const { id } = item;

    return <TableRowPaidReimbursement el={item} pathname={pathname} key={id} onDelete={this.onDelete} itemName="merchant" />
  }

  static getData(state, props) {
    const {
      reimbursements,
    } = props;
    const tableData = reimbursements.map(getPaidReimbursementTableRow);

    return {
      tableData,
      reimbursements,
    };
  }

  handleChangeInput = (event) => {
    const chequeNumber = _get(event, 'target.value');


    this.setState({
      chequeNumber,
    });
  };

  static stateIsReadyToReimburse(merchant, chequeNumber) {
    const ready = (merchant !== config.statuses.any)
      && chequeNumber.length > 4;

    return ready;
  }

  render() {
    const { location, campaigns } = this.props;
    const { pathname } = location;
    const {
      tableData,
      error,
      errorText,
    } = this.state;

    return (
      <div>
        <Grid
          tableHead={tableHead}
          tableData={tableData}
          name="Reimbursements"
          pathname={pathname}
          count={tableData.length}
          renderRow={this.renderRow}
        />
        <Modal style={{ padding: 50 }} open={error} onClose={this.onCloseModal} center>
          <h2> {errorText} </h2>
        </Modal>
      </div>
    );
  }
}

PaidReimbursements.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withReimbursements(withStyles(dashboardStyle)(PaidReimbursements));
