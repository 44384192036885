import React, { Component } from 'react';
import _ from 'lodash';
import Firebase, { mergeSnapshotChangesIntoArray } from '../components/Firebase';

const { employeesRef } = Firebase;

const EmployeesContext = React.createContext();

export const EmployeesConsumer = EmployeesContext.Consumer;

export class EmployeesProvider extends Component {
  state = {
    employees: undefined,
    setActive: (...args) => this.setActive(...args),
    getEmployeeById: id => this.getEmployee(id),
    deleteEmployeeById: id => this.deleteEmployeeById(id),
    getFilteredEmployees: filters => this.getFilteredEmployees(filters),
    getOperator: () => this.getOperator(),
    isAdministrator: () => this.isAdministrator(),
  };

  constructor(props) {
    super(props);

    this.onSnapshot = this.onSnapshot.bind(this);
    this.snapshotCallbacks = [];
  }

  static getKeyValueList = (list = []) => list.map(item => ({
    key: item.id.toString(),
    value: item.name,
  }));

  async onSnapshot(snapshot) {
    const { employees: prevEmployees } = this.state;
    const employees = mergeSnapshotChangesIntoArray(snapshot, prevEmployees);
    this.setState({ employees }, () => {
      while (this.snapshotCallbacks.length > 0) {
        const callback = this.snapshotCallbacks.pop();
        callback();
      }
    });
  }

  setActive(callback) {
    if (!this.unsusbscribe) {
      const { site: { id: siteId } = {} } = this.props;
      const query = employeesRef.where('siteId', '==', siteId);
      typeof callback === 'function' && this.snapshotCallbacks.push(callback);
      this.unsusbscribe = query.onSnapshot(this.onSnapshot);
    }
    else if (typeof callback === 'function') {
      const { employees } = this.state;
      if (Array.isArray(employees)) {
        callback();
      }
      else {
        this.snapshotCallbacks.push(callback);
      }
    }
  }

  getOperator() {
    let operator;
    const userProfileString = localStorage.getItem('userProfile');

    if (userProfileString) {
      const userProfile = JSON.parse(userProfileString);
      const { email = '' } = userProfile;
      operator = this.getEmployeeByEmail(email);
    }

    return operator;
  }

  isAdministrator() {
    const operator = this.getOperator();
    if (_.isEmpty(operator)) return false;
    const { groups: { admin } = {} } = operator;
    const isAdministrator = (admin === true);
    return isAdministrator;
  }

  getEmployeeByEmail(email) {
    const { employees } = this.state;
    if (!Array.isArray(employees)) return undefined;
    return employees.find(x => x.google.email === email);
  }

  getFilteredEmployees(filters = {}) {
    const { employees } = this.state;
    return employees;
  }

  componentWillUnmount() {
    this.unsusbscribe && this.unsusbscribe();
  }

  getEmployee(id) {
    const { employees } = this.state;
    return _.find(employees, x => x.id === id);
  }

  async deleteEmployeeById(id) {
    await employeesRef.doc(id)
      .delete();
  }

  render() {
    const { children } = this.props;

    return (
      <EmployeesContext.Provider value={this.state}>
        {children}
      </EmployeesContext.Provider>
    );
  }
}
