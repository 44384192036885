import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import { NavLink } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import MultiSelect from '../../components/Engine/Common/MultiSelect/MultiSelect';
import { config } from '../../config';
import { toTitleCase } from "../../utils/stringUtils";

import style from '../../assets/jss/material-dashboard-react/views/deals.jsx';

class Header extends Component {
  render() {
    const {
      classes,
      categoryOptions,
      handleChangeSelector,
      statusValue = [],
    } = this.props;

    const styleHeader = {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginTop: 70,
    };

    return (
      <GridContainer>
        <GridItem xs={10} sm={8} md={8} lg={6}>
          <h3 className={classes.upperText}>Filters</h3>
          <MultiSelect
            id="statusValue"
            name="Status"
            isMainPage={true}
            onChange={handleChangeSelector}
            options={['PROCESSED', 'REJECTED', 'PENDING_PROCESSING'].map(x => ({label: toTitleCase(x), value: x}))}
            value={statusValue.map(x => ({label: toTitleCase(x), value: x}))}
          />
        </GridItem>
        <GridItem xs={2} sm={4} md={4} lg={6} style={styleHeader}>
        </GridItem>
      </GridContainer>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
  targetName: PropTypes.string,
  targetStorage: PropTypes.string,
};

Header.defaultProps = {
  value: [],
  options: [],
  onChange: () => null,
  targetName: 'category',
  targetStorage: 'categories',
};

export default withStyles(style)(Header);
