import React, { Fragment, Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import Hidden from '@material-ui/core/Hidden';
import Person from '@material-ui/icons/Person';
import Search from '@material-ui/icons/Search';
import memoize from 'memoize-one';
import classNames from 'classnames';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import { config } from '../../config';
import headerLinksStyle
  from '../../assets/jss/material-dashboard-react/components/headerLinksStyle.jsx';
import Button from '../Common/CustomButtons/Button.jsx';
import { withMerchants, withDeals, withVouchers } from '../../contextApi';
import NavBarDropdownMenu from './NavBarDropdownMenu';

const UserLabel = ({ name, photoURL, classes }) => {
  const icon = (photoURL) ? (
    <img
      className= { classes.myIcons }
      alt='label'
      src={photoURL}
    />
  ) : (
    <Person className={classes.icons} />
  );

  return (
    <Fragment>
      <label className={classes.labelName}>{name}</label>
      { icon }
    </Fragment>
  )
};

class HeaderLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      name: localStorage.getItem('name'),
      photoURL: localStorage.getItem('photo'),
      map: x => x,
      getSuggestions: () => {},
    };

    this.onSearchSelect = this.onSearchSelect.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const {
      curPage,
      parentPath,
      getSearchedMerchants,
      getSearchedDeals,
      getSearchedVouchers,
    } = nextProps;
    let getSuggestions, map;

    if (curPage === config.pages.overview || parentPath === config.pages.overview) {
      getSuggestions = getSearchedVouchers;
      map = (voucher) => ({
        label: voucher.id.toUpperCase(),
        name: voucher.id,
      });
    }
    if (curPage === config.pages.merchants || parentPath === config.pages.merchants) {
      getSuggestions = getSearchedMerchants;
      map = (merchant) => ({
        label: merchant.name,
        name: merchant.id,
      });
    }
    if (curPage === config.pages.deals || parentPath === config.pages.deals) {
      getSuggestions = getSearchedDeals;
      map = (deal) => ({
        label: deal.id.toUpperCase(),
        name: deal.id,
      })
    }

    return {
      getSuggestions,
      map,
    };
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  };

  handleClose = (event) => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  /**
   * Set the wrapper ref
   */
  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };


  handleFocus = (event) => {
    this.setState({
      showMenu: true,
    });
  };

  getPage = memoize(
    (curPage) => {
      const pages = curPage.split('/');
      if (pages.length > 3) {
        pages.pop();
      }
      return pages.join('/');
    },
  );

  onSearchSelect(event, selection) {
    const { suggestion } = selection;
    const id = _.get(suggestion, 'name');
    const { history, location } = this.props;
    const page = this.getPage(location.pathname);

    if (id) {
      history.push(`${page}/${id}`);
    }
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      open,
      name,
      photoURL,
      getSuggestions,
      map,
    } = this.state;

    return (
        <div className={classes.flexContainer}>
          <div className={classes.searchWrapper}>
            <div ref={this.setWrapperRef} className={classes.containerSelect}>
              <NavBarDropdownMenu
                onSelect={this.onSearchSelect}
                getSuggestions={getSuggestions}
                placeholder="Search"
                map={map}
                displayMax={10}
              />
            </div>
            <Button color="white" aria-label="edit" justIcon round>
              <Search />
            </Button>
          </div>
          <div className={classes.nameDiv} >
            {/*<Button*/}
                {/*buttonRef={(node) => {*/}
                  {/*this.anchorEl = node;*/}
                {/*}}*/}
                {/*color={window.innerWidth > 959 ? 'transparent' : 'white'}*/}
                {/*justIcon={window.innerWidth > 959}*/}
                {/*simple={!(window.innerWidth > 959)}*/}
                {/*aria-label="Person"*/}
                {/*// className={classes.fuckYouRussia}*/}
                {/*onClick={this.handleToggle}*/}
                {/*style={{ width: 'auto' }}*/}
            {/*>*/}
            <Button
              className={classes.userProfileButton}
              aria-label="Person"
              onClick={this.handleToggle}
              color={window.innerWidth > 959 ? 'transparent' : 'white'}
              buttonRef={(node) => {
                this.anchorEl = node;
              }}
            >
              <UserLabel name={name} photoURL={photoURL} classes={classes} />
              {/*<Hidden mdUp implementation="css">*/}
                {/*<p className={classes.linkText}>Profile</p>*/}
              {/*</Hidden>*/}
            </Button>
            <Popper open={open} anchorEl={this.anchorEl} transition disablePortal className={
              `${classNames({ [classes.popperClose]: !open })
              } ${
                classes.pooperNav}`
            }>
              {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    id="menu-list-grow"
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={this.handleClose}>
                      <MenuList>
                        <NavLink
                            to={config.pathForUser}
                            className={classes.item}
                            activeClassName="active"
                        >
                        <MenuItem className={classes.dropdownItem}
                                  onClick={this.handleClose}>Profile</MenuItem>
                        </NavLink>

                        <NavLink
                            to={`/${config.pages.logout}`}
                            className={classes.item}
                            activeClassName="active"
                        >
                        <MenuItem className={classes.dropdownItem}
                                  onClick={this.goBack}>Logout</MenuItem>
                        </NavLink>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
    );
  }
}

export default withRouter(withVouchers(withDeals(withMerchants(withStyles(headerLinksStyle)(HeaderLinks)))));
