import React, { Fragment } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import { matchPath } from "react-router";
import AdminNavbarLinks from './AdminNavbarLinks.jsx';
import routes from '../../routes.js';
import { withRouterInnerRef } from '../../contextApi';

import headerStyle
  from '../../assets/jss/material-dashboard-react/components/headerStyle.jsx';

const getPageTitle = (route, pathname) => {
  if (route) {
    const titleTemplate = _.template(route.title);
    const action = (pathname.endsWith('/add')) ? 'Create' : 'View';
    const title = titleTemplate({ action });

    return title;
  }
};

function Header({ ...props }) {
  const { pathname } = props.location;
  const pathArray = pathname.split('/');
  const curPage = pathArray.pop();
  const parentPath = pathArray.pop();
  const { classes, color } = props;
  const appBarClasses = classNames({
    [` ${classes[color]}`]: color,
  });
  const route = routes.find(x => matchPath(pathname.replace('/admin', ''), x)) || {};
  const title = getPageTitle(route, pathname);

  return (
      <AppBar className={classes.appBar + appBarClasses}>
        <Toolbar className={classes.container}>
          {title && (
            <div className={classes.title}>{title}</div>
          )}
          <div style={{ width: '100%' }} >
            <Hidden smDown implementation="css">
              <AdminNavbarLinks
                curPage={curPage}
                parentPath={parentPath}
              />
            </Hidden>
          </div>
          <Hidden mdUp implementation="css">
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={props.handleDrawerToggle}
            >
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
};

export default withRouterInnerRef(withStyles(headerStyle)(Header));
