const customSelectStyle = {
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  formControl: {
    minWidth: 120,
  },
  name: {
    marginBottom: 0,
    margitTop: '0px !important',
    minWidth: 100,
  },
  widthContainer: {
    width: '60% !important',
  },
  error: {
    fontSize: 10,
    color: '#b00020',
    marginBottom: 0,
    marginTop: 0,
  },
  errorContainer: {
    lineHeight: 1.2,
    border: '1px #b00020 solid',
    padding: 5,
    borderRadius: 15,
    textAlign: 'center',
    opacity: 0.8,
  },
  errors: {
    textAlign: 'center',
  },
  headerError: {
    color: '#b00020',
  }
};

export default customSelectStyle;
