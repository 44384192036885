const customerInfoStyle = {
  imageMagnifyContainer: {
    width: 300,
  },
  headerForRowSelect: {
    display: 'flex',
    marginTop: 12,
    marginBottom: 12,
    alignItems: 'center',
  },
  headerForRowFlex: {
    display: 'flex',
    // marginTop: 18,
    marginTop: 6,
    width: '700px',
  },
  textContainer: {
    display: 'flex',
  },
  labelInput: {
    fontSize: 18,
    color: 'rgba(0, 0, 0, 0.54)',
  },
  labelContainer: {
    minWidth: 72,
    width: 200,
    marginRight: 10,
  },
  labelDesc: {
    fontSize: 16,
    alignSelf: 'flex-end',
    width: 500,
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 18,
  },
};

export default customerInfoStyle;
