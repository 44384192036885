import dealDiscountOffer from './assets/img/deals/deal-discount-offer-with-purchase.png';
import dealDiscountVoucher from './assets/img/deals/deal-discount-voucher.png';
import dealFreeGift from './assets/img/deals/deal-free-gift.png';
import dealFreeGiftWithPurchase from './assets/img/deals/deal-gift-with-purchase.png';
import dealNewProduct from './assets/img/deals/deal-new-product.png';
import dealPercentage from './assets/img/deals/deal-percentage.png';
import dealPriceReduction from './assets/img/deals/deal-price-reduction.png';
import dealPrizeDraw from './assets/img/deals/deal-prize-draw.png';
import dealBogof from './assets/img/deals/deal-bogof.png'

console.log(JSON.stringify(process.env));

const {
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_WEB_API_KEY,
} = process.env;

/**
 * PLAZA LOW YAT STAGING
 */
const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_WEB_API_KEY,
  authDomain: `${REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
  databaseURL: `https://${REACT_APP_FIREBASE_PROJECT_ID}.firebaseio.com`,
  projectId: `${REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
  messagingSenderId: '451487436783',
  baseUrl: `https://us-central1-${REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net`,
};

export const config = Object.freeze({
  pathForVerify: '/admin/overview',
  pathForUser: '/admin/user',
  name: 'Plaza Low Yat',
  create: 'CREATE',
  update: 'UPDATE',
  selectedNothing: -1,
  response: {
    createMessage: 'Successful create',
    updateMessage: 'Successful update',
    createUpdateMessage: 'Successful',
    deleteMessage: 'Successful delete',
    errorMessage: 'Error',
  },
  typeRequest: {
    create: 'create',
    update: 'update',
  },
  requests: {
    deal: 'deal',
  },
  dealBlocks: {
    merchant: 'Merchant',
    category: 'Deal Category',
    categoryDiscount: 'Category',
    percentage: 'Percentage Discount',
    detailsPrice: {
      brand: 'Brand',
      productName: 'Product name (don’t repeat brand)',
      productDescription: 'Product description',
      retailPrice: 'Standard Retail Price (RM)',
    },
    prize: {
      brand: 'Prize Brand ( optional)',
      productName: 'Prize name (don’t repeat brand)',
      productDescription: 'Prize description',
      retailPrice: 'Prize Retail (RM)',
    },
    gift: {
      brand: 'Gift Brand ( optional)',
      productName: 'Gift name (don’t repeat brand)',
      productDescription: 'Gift description (optional)',
      retailPrice: 'Gift Value (RM)',
    },
    offeredProduct: {
      brand: 'Offered Product Brand',
      productName: 'Product name (don’t repeat brand)',
      productDescription: 'Product description',
      retailPrice: 'Standard Retail Price',
    },
    offerPrice: 'Offer Price(RM)',
    limit: 'Unit limit',
    condition: 'Min Speed (RM)',
  },
  statuses: {
    pendingAuthorisation: 'PENDING_AUTHORISATION',
    pendingReimbursement: 'PENDING_REIMBURSEMENT',
    rejected: 'REJECTED',
    reimbursed: 'REIMBURSED',
    any: 'Any',
  },
  totalsLabel: {
    created: 'Created',
    issued: 'Issued',
    redeemed: 'Redeemed',
    pendingAuthorization: 'Pending authorization',
    pendingReimbursement: 'Pending reimbursement',
    reimbursed: 'Reimbursed',
    quantityPendingReimbursement: 'No. Pending reimbursement',
    maxLiabilityAge: 'Max liability age',
    totalAmount: 'Total amount',
  },
  types: {
    input: {
      area: 'area',
      string: 'string',
      number: 'number',
      currency: 'currency',
      email: 'email',
      phone: 'phone',
    },
    select: 'select',
    checkbox: 'checkbox',
    switch: 'switch',
    date: 'date',
    section: 'section',
    createSelect: 'createSelect',
    multiSelect: 'multiSelect',
  },
  voucherIssueCriteriaTypes: [
    {
      key: 'NEW_CUSTOMERS',
      value: 'New Customers'
    },
    {
      key: 'ALL_CUSTOMERS',
      value: 'All Customers'
    },
  ],
  voucherIssueCriteriaPriority: [
    {
      key: 'PROXIMITY',
      value: 'Proximity to mall'
    },
    {
      key: 'LAST_SEEN',
      value: 'Most recently active'
    },
  ],
  dealTypes: {
    priceDiscount: {
      key: 'PRICE_DISCOUNT',
      label: 'Discount',
      description: 'A percentage or an RM amount discount offered on a category, brand or store wide',
      image: dealPercentage,
    },
    priceReduction: {
      key: 'PRICE_REDUCTION',
      label: 'Price Reduction',
      description: 'A price reduction is offered on a product',
      image: dealPriceReduction,
    },
    productIntroduction: {
      key: 'PRODUCT_INTRODUCTION',
      label: 'Product Introduction',
      description: 'A promotion of a product normally a new market entrant',
      image: dealNewProduct,
    },
    discountedProductWithPurchase: {
      key: 'DISCOUNTED_PRODUCT_WITH_PURCHASE',
      label: 'Discounter Offer with purchase',
      description: 'An option to purchase a specific product at a  discount with purchase of offered product, category or brand',
      image: dealDiscountOffer,
    },
    prizeDrawWithPurchase: {
      key: 'PRIZE_DRAW_WITH_PURCHASE',
      label: 'Prize Draw with purchase',
      description: 'Entry into a prize draw is offered on purchase of a product',
      image: dealPrizeDraw,
    },
    giftWithPurchase: {
      key: 'GIFT_WITH_PURCHASE',
      label: 'Gift With Purchase',
      description: 'A free gift is offered either on purchase of a product',
      image: dealFreeGiftWithPurchase,
    },
    freeGift: {
      key: 'FREE_GIFT',
      label: 'Free Gift',
      description: 'A free gift is offered just for entering the store',
      image: dealFreeGift,
    },
    // cashVoucherWithPurchase: {
    //   key: 'CASH_VOUCHER_WITH_PURCHASE',
    //   label: 'Discount voucher with purchase',
    //   description: 'A cash voucher is offered for future use on purchase of offered product',
    //   image: dealDiscountVoucher,
    // },
    buyOneGetOneFree: {
      key: 'BUY_ONE_GET_ONE_FREE',
      label: 'BUY 1 FREE 1',
      description: 'Purchase one of a product or category and receive another free',
      image: dealBogof,
    }
  },
  tableName: {
    overview: 'Overview',
    vouchers: 'Vouchers',
    deals: 'Deals',
    campaigns: 'Campaigns',
  },
  infoLabel: {
    voucherCode: 'Voucher code',
    status: 'Status',
    scannedText: 'Scanned Text',
    extractedValues: 'Extracted values',
    transactionDate: 'Date / Time',
    receiptNumber: 'Invoice no.',
    netTotalAmount: 'Net Total',
    totalAmount: 'Total (inc GST)',
  },
  pages: {
    overview: 'overview',
    reimbursement: 'reimbursement',
    mainLayout: 'admin',
    login: 'login',
    logout: 'logout',
    user: 'user',
    deals: 'deals',
    merchants: 'merchants',
    add: 'add',
    campaigns: 'campaigns',
    promotions: 'promotions',
    customers: 'customers',
  },
  headNavigation: {
    overview: 'Overview',
    reimbursement: 'Reimbursement',
  },
  anotherLabels: {
    chequeNumber: 'Cheque number',
  },
  filtersCategories: {
    campaign: 'campaign',
  },
  authVoucher: {
    empty: '',
    invoice: 'Invoice image unclear',
    reject: 'Reject',
  },
  direction: {
    updateAll: 0,
    addNew: 1,
  },
  cheqNumber: {
    reimburse: 'Reimburse',
  },
  parent: {
    vouchers: 'vouchers',
    user: 'user',
    deals: 'deals',
    merchants: 'merchants',
    campaigns: 'campaigns',
    promotions: 'promotions',
    customers: 'customers',
  },
  merchantsApp: {
    registeredDeviceText: 'Registered Device',
    activationDateText: 'Activation Date',
    qrCodeText: 'QR Code',
  },
  imageFolder: {
    dealImages: 'dealImages',
    merchantImages: 'merchantImages',
    promotionImages: 'promotionImages',
  },
});

export default firebaseConfig;
