import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import {AuthConsumer} from './AuthProvider';

const ProtectedRoute = ({component: Component, authMerchants, ...rest}) => (
  <AuthConsumer>
    {({isAuth}) => (
      <Route
        render={
          props => isAuth ? <Component authMerchants={authMerchants} {...props} /> : <Redirect to="/" />
        }
        {...rest}
      />
    )}
  </AuthConsumer>
);

export default ProtectedRoute;
