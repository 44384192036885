const properCase = (str) => {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export const toTitleCase = (str) => {
  return properCase(str.replace(/_/gi, ' '));
};

/**
 * Change string from snake case to camelCase
 *
 * @param {string} str Input snake case string
 * @return {string} Output camel case string
 */
export const toCamelCase = (str) => {
  // eslint-disable-line no-param-reassign
  str = str.replace(/[-_\s]+(.)?/g, (match, ch) => (ch ? ch.toUpperCase() : ''));

  // Ensure first chat is always lowercase
  return str.substr(0, 1).toLowerCase() + str.substr(1);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getCategoryString = (categories, categoryOptions) => {
  const result = categories.map((x) => {
    const cat = categoryOptions.find(y => y.value === x);
    return (cat) ? cat.label : undefined;
  }).join('<br/>');

  return result;
};
