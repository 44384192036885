import _ from 'lodash';

// This function creating status name from upperCase
export const createStatusName = string => string.split(/\s+/)
  .map(word => word[0].toUpperCase() + word.substring(1))
  .join(' ');


// This function it is analogue lodash function .get
export const getValueByField = (obj, path, defaultValue) => path.split('.')
  .reduce((a, c) => (a && a[c] ? a[c] : (defaultValue || '')), obj);

export const getValueByFieldInString = (obj, path, defaultValue) => getValueByField(obj, path, defaultValue).toString();

export const isEmptyObject = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

function isInteger(n) {
  return ((typeof n==='number')&&(n%1===0));
}

function isFloat(n) {
  return ((typeof n==='number')&&(n%1!==0));
}

const isNumeric = function(n){
  return (isInteger(n) || isFloat(n));
};

export const isEmpty = obj => {
  return (typeof obj === 'boolean' || obj instanceof Date || isNumeric(obj) ? false : _.isEmpty(obj));
};

export const arrayToString = (arr = []) => {
  const len = arr.length;
  const _arr = (len > 3) ? arr.slice(0, 3) : arr;
  if (len > 3) {
    _arr.push('...');
  }
  return _arr.join(', ');
};

const deleteEmptyKeys = (object, keys = []) => keys.reduce((accumulator, key) => {
  if (_.isEmpty(accumulator[key])) {
    delete accumulator[key];
  }

  return accumulator;
}, { ...object });

export const removeEmpty = (obj, keys) => {
  Object.keys(obj)
    .forEach(key => isEmpty(obj[key]) && delete obj[key]
      || (obj[key] && typeof obj[key] === 'object') && removeEmpty(obj[key]));

  return deleteEmptyKeys(obj, keys);
};

export const getPrettiedMessage = message => message[0].toUpperCase() + message.slice(1);

export const alphaComparator = (attribute) => {
  return (a, b) => {
    const _nameA = a[attribute];
    const _nameB = b[attribute];
    if (_nameA && _nameB) {
      const nameA = _nameA.toLowerCase();
      const nameB = _nameB.toLowerCase();
      if (nameA < nameB) //sort string ascending
        return -1;
      if (nameA > nameB)
        return 1;
    }
    return 0; //default return value (no sorting)
  };
};

export const creationDateComparator = (a, b) => {
  const creationDateA = _.get(a, 'creationDate.seconds');
  const creationDateB = _.get(b, 'creationDate.seconds');
  return creationDateB - creationDateA;
};

export default () => null;
