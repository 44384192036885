import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { withSite } from '../../../contextApi';
import PropTypes from 'prop-types';
import Modal from 'react-responsive-modal';
import _ from 'lodash';
import * as moment from 'moment';

import style from '../../../assets/jss/material-dashboard-react/views/deal.jsx';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';

import DealsTape from '../../../components/Deals/Common/DealTape/DealsTape';
import Section from '../../../components/Engine/General/Builder/Section';
import CreateOrUpdateDeal from '../../../components/Deals/DealBlocks/CreateOrUpdate';
import { config } from '../../../config';
import {
  getPrettiedMessage,
} from '../../../utils';
import { getDocument, getMandatoryBlankFields } from '../../../utils/layoutEngine/parser';
import { createOrUpdateDeal, getDealInformation } from '../../../utils/deals/api';
import { deleteImages } from '../../../utils/image';
import {
  getDateFromFirebase,
  getMallOpeningTime,
  getMallClosingTime,
  toDate,
} from '../../../utils/date';

const {
  dealTypes,
  response,
} = config;

const responseValues = Object.values(response);

class DealInfo extends React.Component {
  state = {
    init: false,
    document: {},
    fields: {},
    selectedDealTypeKey: '',
    // Details Block
    dealCategory: '',
    merchant: '',
    // Images Block
    images: [],
    image: {},
    // Percentage Discount
    amount: '',
    percentage: '',
    conditionBrand: '',
    // conditionCategories: allDealCategories,
    conditionCategory: '',
    condition: '',
    storeWide: false,
    // Price reduction
    productBrand: '',
    productName: '',
    productDescription: '',
    retailPrice: undefined,
    offerPrice: undefined,
    // Product introduction == Price reduction fields
    // Discounted Offer With Purchase == Price reduction fields && Percentage Discount
    conditionProduct: '',
    // Prize Draw == Price reduction fields && Percentage Discount
    // Free Gift == Price reduction fields && Percentage Discount
    conditionProductPrice: undefined,
    conditionProductDescription: undefined,
    displayPriceReductionAs: 'priceReduction',
    // Discount voucher
    voucherAmount: '',
    expDate: new Date(),
    productCategory: '',
    // productCategories: allDealCategories,
    // General
    limits: undefined,
    termsAndConditions: '',
    active: false,
    startDate: getMallOpeningTime(new Date()),
    endDate: getMallClosingTime(moment().add(7, 'days').toDate()),
    // For functional with cancel
    startImages: [],
    errors: {},
    supposeId: '',
    modalMessage: '',
    modalOpen: false,
    informationForActive: [],

    offeredProductBrand: '',
    offeredProductName: '',
    offeredProductDescription: '',
    offeredProductValue: '',
  };

  constructor(props) {
    super(props);

    this.onChangePrimaryImage = this.onChangePrimaryImage.bind(this);
    this.onChangeImages = this.onChangeImages.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.getInformationAboutDeal();
    const { authMerchants } = this.props;

    // If we only have the one merchant, get the value
    if (Array.isArray(authMerchants) && authMerchants.length === 1) {
      this.onFieldValueChange({
        merchant: authMerchants[0],
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.unsubscribeDeal();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { site: { categories, id: siteId } = {} } = nextProps;
    const { dealCategories: prevDealCategories } = prevState;
    const dealCategories = categories.map(x => ({key: x.id, value: x.name}));
    if (prevDealCategories !== categories && !_.isEmpty(categories)) {
      return {
        ...prevState,
        siteId,
        dealCategories,
      }
    }

    return prevState;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { supposeId, init } = prevState;
    const { pathname } = this.props.location;
    const id = pathname.split('/').pop();
    if (!init) {
      this.setState({ init: true });
      return;
    }
    if (id !== supposeId && id !== config.pages.add) {
      this.unsubscribeDeal();
      this.getInformationAboutDeal();
    }
  }

  onCloseModal = () => {
    this.setState({ modalOpen: false });
  };

  getIdFromPath = (status = '') => {
    if (status === 'images') {
      const { supposeId } = this.state;
      return supposeId;
    }
    const { href } = window.location;
    return href.split('/').pop();
  };

  getInformationAboutDeal = () => {
    const id = this.getIdFromPath();
    this.unsubscribeDeal = getDealInformation(id, this.updateState);
  };

  updateState = (data) => {
    if (!this._isMounted) { return; }
    this.setState({ ...data });
  };

  generateError = (requiredField = {}) => {
    const { status, field } = requiredField;
    const { errors } = this.state;
    if (status && field) {
      delete errors[field];
    } else {
      errors[field] = status;
    }
    return errors;
  };

  changePageBlocks = (selectedDealTypeKey) => {
    if (selectedDealTypeKey) {
      const documentName = _.camelCase(selectedDealTypeKey);
      const document = getDocument(documentName);

      this.setState({
        ...document,
        selectedDealTypeKey,
      });
    }
  };

  deleteImagesByCancel = () => {
    debugger;
    // TODO TODO TODO
    const { imagesArr } = this.state;
    // const id = this.getIdFromPath(config.dealBlocksName.images);
    deleteImages(imagesArr, 'images');
  };

  createDeal = async (path) => {
    const id = path.split('/').pop();
    const { state: data } = this;
    const { history } = this.props;

    data.type = this.state.selectedDealTypeKey;
    let modalMessage = await createOrUpdateDeal(data, id);
    if (!responseValues.includes(modalMessage)) {
      modalMessage = getPrettiedMessage(modalMessage);
      this.setState({ modalMessage, modalOpen: true });
    } else {
      // this.props.history.goBack();
      // TODO : ok, absolutely we don't wish to do this! idiot
      history.push('/admin/deals');
    }
  };

  handleClick = (selectedDealTypeKey) => {
    this.setState({ selectedDealTypeKey });
    this.changePageBlocks(selectedDealTypeKey);
  };

  renderSections = (list, generalData) => {
    const { authMerchants } = this.props;

    return list.map((el, index) => (
      <Section
        key={index}
        section={el}
        data={generalData}
        handleChangeSelector={this.handleChangeSelector}
        handleChangeInput={this.handleChangeInput}
        activeHandle={this.activeHandle}
        handleDateStartChange={this.handleDateStartChange}
        handleDateEndChange={this.handleDateEndChange}
        changeExpDate={this.changeExpDate}
        handleChange={this.handleChange}
        onChangePrimaryImage={this.onChangePrimaryImage}
        onChangeImages={this.onChangeImages}
        selectCurrentImage={this.selectCurrentImage}
        handleChangeDate={this.handleChangeDate}
        handleRequiredFields={this.handleRequiredFields}
        handleChangeCheckbox={this.handleChangeCheckbox}
        authMerchants={authMerchants}
      />
    ));
  };

  // //////// Methods for Blocks(params full : true) //////////////
  handleDateStartChange = (date) => {
    this.setState({ startDate: date.toDate() });
  };

  handleDateEndChange = (date) => {
    this.setState({ endDate: date.toDate() });
  };

  changeExpDate = (date) => {
    this.setState({ expDate: date.toDate() });
  };

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.checked });
  };
  // /////////////////////////////////////////////

  // //////// Methods for SECTIONS //////////////

  getNeededFields(data) {
    const { selectedDealTypeKey } = data;
    return getMandatoryBlankFields(data, _.camelCase(selectedDealTypeKey));
  }

  handleChangeSelector = async (event, data) => {
    const { name } = event.target;
    const { key, value } = data;

    this.onFieldValueChange({
      [name]: key,
    });
  };

  onFieldValueChange(additionalState) {
    this.setState((prevState) => {
      const newState = {
        ...prevState,
        ...additionalState,
      };
      const informationForActive = this.getNeededFields(newState);
      return {
        ...newState,
        informationForActive,
      };
    });
  }

  stringToObj = function(path,value,state) {
    let parts = path.split("."), part;
    const firstPart = parts[0];
    let obj = {};
    let last = parts.pop();
    while(part = parts.shift()) {
      if( typeof obj[part] != "object") obj[part] = {};
      obj = obj[part]; // update "pointer"
    }
    obj[last] = value;

    return {
      [firstPart]: {
        ...state[firstPart],
        ...obj,
      }
    }
  };

  handleChangeInput = (event, data) => {
    let { id, value } = event.target;

    if (data) {
      id = data.key;
      value = data.value;
    } else {
      debugger;
    }

    if (id.includes('.')) {
      let obj = this.stringToObj(id, value, this.state);

      this.setState(prevState => {
        return {
          ...prevState,
          ...obj,
        };
      });
    }
    else {
      this.setState({ [id]: value });
    }
  };

  activeHandle = (elem, requiredField = {}) => {
    const errors = this.generateError(requiredField);
    const { state: data } = this;
    const { selectedDealTypeKey } = data;
    const informationForActive = getMandatoryBlankFields(data, _.camelCase(selectedDealTypeKey));

    // if (!informationForActive.length) {
      this.setState({ [elem]: !data[elem], errors, informationForActive });
    // } else {
    //   this.setState({ informationForActive });
    // }
  };

  handleChangeCheckbox = async (name, requiredField = {}, event) => {
    const errors = this.generateError(requiredField);
    const value = event.target.checked;
    this.onFieldValueChange({
      [name]: value,
      errors,
    });
  };

  handleChangeDate = (date, name) => {
    this.setState({ [name]: date.toDate() });
  };

  /**
   * @param data Information on the selected image
   * @param data.currentImage The url of the image
   * @param data.currentImageName The name of the image
   */
  selectCurrentImage = (data) => {
    this.setState({ ...data });
  };

  handleRequiredFields = (requiredField = {}) => {
    const errors = this.generateError(requiredField);
    this.onFieldValueChange({ errors });
  };

  onChangeImages(images) {
    const newImages = images.map(x => ({
      fileName: x.fileName || x.name || x.path,
      width: x.width,
      height: x.height,
      ref: x.ref,
      progress: x.progress,
    }));

    this.setState(prevState => ({
      ...prevState,
      images: newImages,
    }));
  }

  onChangePrimaryImage(images) {
    const rawImage = images.length ? images[0] : {};
    console.log('onChangePrimaryImage = ', JSON.stringify(rawImage));

    let image = {};
    if (!_.isEmpty(rawImage)) {
      image = {
        ...rawImage,
        fileName: rawImage.fileName || rawImage.name || rawImage.path,
      };
    }

    this.setState(prevState => ({
      ...prevState,
      image,
    }));
  }

  render() {
    const { state: generalData } = this;
    const {
      selectedDealTypeKey,
      document,
      modalMessage,
      modalOpen,
    } = generalData;
    const { left = [], right = [] } = document;

    return (
      <div>
        <DealsTape
          dealTypes={Object.values(dealTypes)}
          selectedDealTypeKey={selectedDealTypeKey}
          handleClick={this.handleClick}
        />
        {selectedDealTypeKey && (
          <GridContainer>
            <GridItem xs={12} sm={6} md={6} lg={6}>
              {this.renderSections(left, generalData)}
            </GridItem>
            <GridItem xs={12} sm={6} md={6} lg={6} style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
              <div> {this.renderSections(right, generalData)} </div>
              <CreateOrUpdateDeal
                createDeal={this.createDeal}
                deleteImages={this.deleteImagesByCancel}
              />
            </GridItem>
          </GridContainer>
        )}
        <Modal style={{ padding: 50 }} open={modalOpen} onClose={this.onCloseModal} center>
          <h2> {modalMessage} </h2>
        </Modal>
      </div>
    );
  }
}

export default withSite(withStyles(style)(DealInfo));


DealInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};
