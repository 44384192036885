import React, { Component } from "react";
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';

class TableHeadCell extends Component {
  constructor(props) {
    super(props);

    this.handleRequestSort = this.handleRequestSort.bind(this);
  }

  handleRequestSort() {
    const { column, onRequestSort } = this.props;
    const { id } = column;

    if (onRequestSort) {
      onRequestSort(id);
    }
  }

  render() {
    const {
      column,
      order,
      orderBy,
      onRequestSort,
    } = this.props;
    const {
      id,
      numeric,
      disablePadding,
      label,
    } = column;
    const cellStyle = label.startsWith('#') ? {
      maxWidth: '70px',
      paddingRight: '10px',
    } : {};
    return (
      <TableCell
        key={id}
        align={numeric ? 'right' : 'left'}
        padding={disablePadding ? 'none' : 'default'}
        sortDirection={orderBy === id ? order : false}
        style={cellStyle}
      >
        <Tooltip
          title="Sort"
          placement={numeric ? 'bottom-end' : 'bottom-start'}
          enterDelay={300}
        >
          <TableSortLabel
            active={orderBy === id}
            direction={order}
            onClick={this.handleRequestSort}
          >
            {label}
          </TableSortLabel>
        </Tooltip>
      </TableCell>
    )
  }
}

export default TableHeadCell;