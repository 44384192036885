import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
// @material-ui/icons
import Clear from '@material-ui/icons/Clear';
import Check from '@material-ui/icons/Check';
// core components
import customInputStyle
  from '../../../assets/jss/material-dashboard-react/components/customInputStyle.jsx';

class CustomInput extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    labelText: PropTypes.node,
    labelProps: PropTypes.object,
    id: PropTypes.string,
    inputProps: PropTypes.object,
    formControlProps: PropTypes.object,
    error: PropTypes.bool,
    success: PropTypes.bool,
    onChange: PropTypes.func,
    onClear: PropTypes.func,
    isNavLink: PropTypes.bool,
    path: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    onFocus: PropTypes.func,
    statusStyle: PropTypes.bool,
    type: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    isNavLink: false,
    path: '',
    value: '',
    statusStyle: undefined,
    type: 'string',
  };

  getRootClasses(type, isNavLink) {
    const { classes } = this.props;
    if (type === 'currency') {
      return classes.currency;
    }
    return classNames({
      [classes.currency]: (type === 'currency'),
      [classes.marginTop]: isNavLink,
    });
    // root: `${statusStyle === undefined ? marginTop : statusStyle === true ? classes.green : classes.gray} `,
  }

  render() {
    const {
      classes,
      formControlProps,
      labelText,
      id,
      labelProps,
      inputProps,
      error,
      success,
      onChange,
      onClear,
      path,
      isNavLink,
      value,
      onFocus,
      statusStyle,
      type,
    } = this.props;
    const labelClasses = classNames({
      [` ${classes.labelRootError}`]: error,
      [` ${classes.labelRootSuccess}`]: success && !error,
    });
    const underlineClasses = classNames({
      [classes.underlineError]: error,
      [classes.underlineSuccess]: success && !error,
      [classes.underline]: true,
    });
    const rootClasses = this.getRootClasses(type, isNavLink);
    const propsValue = value !== undefined ? { value } : {};

    return (
      <FormControl
        {...formControlProps}
      >
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + labelClasses}
            htmlFor={id}
            {...labelProps}
          >
            {labelText}
          </InputLabel>
        ) : null}
        <Input
          onFocus={onFocus}
          classes={{
            disabled: classes.disabled,
            underline: underlineClasses,
            root: rootClasses,
          }}
          id={id}
          {...inputProps}
          onChange={onChange}
          {...propsValue}
        />
        {error ? (
          <Clear className={`${classes.feedback} ${classes.labelRootError}`} />
        ) : success ? (
          <Check className={`${classes.feedback} ${classes.labelRootSuccess}`} />
        ) : null}
      </FormControl>
    )
  }
}

export default withStyles(customInputStyle)(CustomInput);
