import { config } from '../../config';
import {
  subscribeDealInformation,
  subscribeDeals,
  createOrUpdateDealFirebase,
} from './utils';

export const getDealInformation = (id, changeState) => {
  try {
    return (id !== config.pages.add) ? subscribeDealInformation(id, changeState)
      : subscribeDeals(changeState);
  } catch (e) {
    console.log('error', e);
    return () => null;
  }
};

export const createOrUpdateDeal = (data, id = config.pages.add) => {
  try {
    return createOrUpdateDealFirebase(data, id);
  } catch (e) {
    console.log('error', e);
    return e.message;
  }
};
