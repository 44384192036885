import React, { Component } from 'react';
import _ from 'lodash';
import Switch from 'react-switch';
import {
  withUsers,
} from '../../contextApi';
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';
import rundef from "rundef";
import { UserAvatar } from '../../components/UserAvatar';
import TableCell from "@material-ui/core/TableCell";
import customerInfoStyle  from './customerInfoStyle';
import LabelDescription from './LabelDescription';
import { getIdFromPath } from "../../utils/entityUtils";
import {
  getUserSummary,
  getFormattedLastAccessedTime,
  getMostRecent,
  getRegistrationMethod,
} from '../../utils/userUtils';
import sum from 'hash-sum';
import DevicesList from "../Merchants/MerchantInfo/DevicesList";

const hash = sum;

// TODO : add push token & add permissions & app version installed

class CustomerInfo extends Component {
  state = {
    customer: {},
  };

  constructor(props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
  }

  static getIdFromPath = (status = '') => {
    const { href } = window.location;
    const part = href.split('/').pop();
    return decodeURIComponent(part);
  };

  componentDidMount() {
    const id = getIdFromPath();
    const { setCustomersProviderActive, getUserById } = this.props;
    let customer = getUserById(id);

    if (!customer) {
      setCustomersProviderActive(() => {
        customer = getUserById(id);
        this.setState(prevState => ({
          ...prevState,
          customer,
        }));
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { getUserById, users = [] } = nextProps;

    if (_.isEmpty(prevState.customer) && users.length > 0) {
      const id = CustomerInfo.getIdFromPath();
      const customer = getUserById(id);
      // Omit read only properties
      // const employee = (id === 'add') ? EmployeeInfo.getNewEmployee() : { ...getEmployeeById(id) };
      return {
        ...prevState,
        customer,
      }
    }

    return {
      ...prevState,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const id = getIdFromPath();
    const { getUserById } = this.props;
    const customer = getUserById(id);
    const { customer: prevCustomer } = prevState;

    if (hash(customer) !== hash(prevCustomer)) {
      console.log('updating state.customer');
      this.setState(prevState => ({
        ...prevState,
        customer,
      }));
    }
  }

  onToggle(id, groups) {
    const { setUserGroups } = this.props;

    setUserGroups(id, groups);
  }

  static getCustomerInfo(customer) {
    const {
      id,
      lastAccessedAt,
      createdAt,
      onboardingStage,
      devices,
      location = {},
      pushToken,
      social = {},
      groups = {},
      permissions = {},
      notificationToken = 'NO TOKEN',
      appVersion,
      primaryDeviceId,
    } = customer;
    const { updatedAt } = location;
    const { admin: isAdmin, tester: isTester } = groups;
    const formattedCreatedAt = getFormattedLastAccessedTime(createdAt);
    const formattedLastAccessed = getFormattedLastAccessedTime(getMostRecent(lastAccessedAt, updatedAt));
    const registration = getRegistrationMethod(social);
    const permissionLocation = String(permissions.location);
    const permissionPushNotifications = String(permissions.notifications);

    return {
      ...getUserSummary(customer),
      ...permissions,
      formattedCreatedAt,
      formattedLastAccessed,
      onboardingStage,
      registration,
      isAdmin,
      isTester,
      groups,
      notificationToken: notificationToken.substring(0, 22),
      permissionLocation,
      permissionPushNotifications,
      appVersion,
      devices,
      primaryDeviceId,
    }
  }

  render() {
    const { customer } = this.state;
    const {
      name,
      email,
      formattedCreatedAt,
      formattedLastAccessed,
      onboardingStage,
      registration,
      isAdmin,
      isTester,
      groups,
      permissionLocation,
      permissionPushNotifications,
      notificationToken,
      appVersion,
      devices,
      primaryDeviceId,
    } = CustomerInfo.getCustomerInfo(customer);
    const {
      classes,
    } = this.props;
    const {
      id = '',
      social = {},
    } = customer;
    return (
      <GridContainer>
        <GridItem xs={3} sm={3} md={3} lg={3}>
          <UserAvatar
            social={social}
            style={{marginRight: '10px'}}
            size={300}
          />
        </GridItem>
        <GridItem xs={6} sm={6} md={6} lg={6} style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          // lineHeight: '0.5em',
        }}>
          {/*classes.headerForRowFlex : classes.headerForRow;*/}
          <h2>{name}</h2>
          <h3>Personal Details</h3>
          {email && (
            <LabelDescription label="Email" description={email} />
          )}
          <LabelDescription label="Customer ID" description={id} />
          <LabelDescription label="App installed at" description={formattedCreatedAt} />
          <LabelDescription label="Last seen at" description={formattedLastAccessed} />
          <LabelDescription label="Onboarding Status" description={onboardingStage} />
          <LabelDescription label="Registration" description={registration} />
          <LabelDescription label="Notification Token" description={notificationToken} />
          {appVersion && (
            <LabelDescription label="Installed App Version" description={appVersion} />
          )}
          <h3>Devices</h3>
          <DevicesList
            devices={devices}
            primaryDeviceId={primaryDeviceId}
            showRemove={false}
            showAppVersion={true}
            showLastAccessed={false}
            showDeviceId={true}
          />
        </GridItem>
        <GridItem xs={3} sm={3} md={3} lg={3} style={{
          display: 'flex',
          flexDirection: 'column',
          // lineHeight: '0.5em',
        }}>
          <h2>&nbsp;</h2>
          <h3>Permissions</h3>
          <LabelDescription label="Share Location" description={permissionLocation} />
          <LabelDescription label="Push Notifications" description={permissionPushNotifications} />
          {/*[messaging/unknown] The operation couldn’t be completed*/}
          <h2>&nbsp;</h2>
          <h3>Groups</h3>
          <TableCell align="left">
            <div className={classes.headerForRowFlex}>
              <span className={classes.labelContainer}>
                <label className={classes.labelInput}>Administrator</label>
              </span>
              <Switch
                onChange={() => this.onToggle(id, { ...groups, admin: !isAdmin })}
                checked={isAdmin}
              />
            </div>
            <div className={classes.headerForRowFlex}>
              <span className={classes.labelContainer}>
                <label className={classes.labelInput}>Tester</label>
              </span>
              <Switch
                onChange={() => this.onToggle(id, { ...groups, tester: !isTester })}
                checked={isTester}
              />
            </div>
          </TableCell>
        </GridItem>
      </GridContainer>
    );
  }
}

export default withUsers(withStyles(customerInfoStyle)(CustomerInfo));
