
const style = {
  successButton: {
    backgroundColor: 'green',
    // backgroundColor: 'yellow',
    color: '#fff',
    opacity: .4,
    '&:hover': {
      backgroundColor: 'green',
      // backgroundColor: 'yellow',
      color: '#fff',
      opacity: .9,
    },
  },
  createButton: {
    backgroundColor: 'green',
    padding: '20px',
    // backgroundColor: 'yellow',
    color: '#fff',
    opacity: .4,
    '&:hover': {
      backgroundColor: 'green',
      // backgroundColor: 'yellow',
      color: '#fff',
      opacity: .9,
    },
  },
};

export default style;
