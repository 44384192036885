import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  overrides: {
    MuiTableCell: {
      root: {
        // paddingTop: 4,
        // paddingBottom: 4,
        // padding: '4px 8px 4px 4px',
        padding: '2px 0 2px 0',
        // '&  $lastChild': { paddingRight: '5px' },
      },
      // paddingDefault: {
      //   padding: '8px 8px 8px 4px',
      // },
    }
  }
});