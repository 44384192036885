import React, { Component } from 'react';
import PropTypes from 'prop-types';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import { formatMoney } from 'accounting';
import TableCellBadge from './TableCellBadge';
import {toTitleCase} from "../../../../utils/stringUtils";

export class TableRowSiteVisit extends Component {
  render() {
    const { el, pathname } = this.props;
    const {
      id,
      task,
      creation_date,
      user_id,
      within_geo_fence,
    } = el;
    // const formattedAmount = formatMoney(amount, '', 2);
    return (
      <TableRow
        hover
        tabIndex={-1}
        style={{height: '40px'}}
      >
        <TableCell>{id}</TableCell>
        <TableCell align="left">{user_id}</TableCell>
        <TableCell align="left">{creation_date}</TableCell>
        <TableCell align="left">{task}</TableCell>
        <TableCell align="left">{within_geo_fence}</TableCell>
      </TableRow>
    );
  }
}

TableRowSiteVisit.propTypes = {
  el: PropTypes.object,
  pathname: PropTypes.string,
};

TableRowSiteVisit.defaultProps = {
  el: {},
  pathname: '',
};

export default TableRowSiteVisit;
